import { FC } from 'react';

import { clsx, TextInput as MantineTextInput } from '@mantine/core';

import styles from './styles.module.scss';
import { TTextInputProps } from './types';

const TextInput: FC<TTextInputProps> = (props) => (
  <MantineTextInput
    classNames={{
      input: styles.input,
      invalid: styles.invalid,
      error: styles.error,
      icon: clsx(styles.icon, { [styles.iconError]: props.error })
    }}
    {...props}
  />
);

export default TextInput;
