import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TAboutPrize, TAboutPrizeForm } from 'src/modules/about-prize/types';

export const getAboutPrize = async (): Promise<TAboutPrize> => {
  return privateApi.get('/admin-content/aboutprize').then((res) => res.data.data);
};

export const updateAboutPrize = async (data: TAboutPrizeForm): Promise<SuccessResponse> => {
  return privateApi.put('/aboutprize', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};
