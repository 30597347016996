import { useState } from 'react';

import { Box, Anchor, Flex, Loader, Text } from '@mantine/core';
import { IconFile, IconVideo, IconDownload, IconEye } from '@tabler/icons';
import saveAs from 'file-saver';

import styles from './styles.module.scss';

type Props = {
  filename?: string;
  href?: string | null;
};

const AttachmentButton = ({ filename, href }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const name = filename || (href && href.length <= 18 ? href : href?.slice(-18));
  const Icon = href?.includes('.mp4') ? IconVideo : IconFile;

  const downloadAsBlob = () => {
    if (!href) return;

    setIsLoading(true);

    fetch(href)
      .then((r) => r.blob())
      .then((blob) => saveAs(blob, name))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  if (!href) return <>-</>;

  return (
    <Flex justify="space-between" gap="sm" p={4} className={styles.root}>
      <Flex align="center" gap="sm">
        <Flex>
          <Icon width={24} height={24} />
        </Flex>
        <Text color="initial">{name}</Text>
      </Flex>
      <Flex gap="sm" justify="center" align="center">
        <Box
          onClick={downloadAsBlob}
          className={styles.iconWrapper}
          sx={{ pointerEvents: isLoading ? 'none' : 'auto' }}
        >
          {isLoading ? <Loader size="xs" /> : <IconDownload width="20px" />}
        </Box>
        <Anchor href={href} target="_blank" className={styles.iconWrapper}>
          <IconEye width="20px" />
        </Anchor>
      </Flex>
    </Flex>
  );
};

export default AttachmentButton;
