import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  TArbitrationCriteriaCreatePayload,
  TArbitrationCriteriaUpdatePayload
} from 'src/api/arbitration-criteria/types';
import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TArbitrationCriteriaItem } from 'src/modules/arbitration-criteria/types';

import {
  createArbitrationCriteria,
  deleteArbitrationCriteria,
  getArbitrationCriteriaAll,
  updateArbitrationCriteria
} from './api';
import { ARBITRATION_CRITERIA_ALL_QUERY_KEY } from './constants';

export const useArbitrationCriteriaListQuery = () => {
  return useQuery<TArbitrationCriteriaItem[]>({
    queryKey: [ARBITRATION_CRITERIA_ALL_QUERY_KEY],
    queryFn: getArbitrationCriteriaAll
  });
};

export const useCreateArbitrationCriteriaMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TArbitrationCriteriaCreatePayload>({
    mutationFn: createArbitrationCriteria,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATION_CRITERIA_ALL_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateArbitrationCriteriaMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TArbitrationCriteriaUpdatePayload>({
    mutationFn: updateArbitrationCriteria,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATION_CRITERIA_ALL_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteArbitrationCriteriaMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteArbitrationCriteria,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATION_CRITERIA_ALL_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};
