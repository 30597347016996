import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type LayoutState = {
  isNavbarOpen: boolean;
  toggleNavbar: () => void;
  selectedItems: string[];
  selectedApprovalItems: string[];
  selectedInterviewItems: string[];
  newEmailTemplate: string;
  openedInovationItem: string;
  selectedEmailTemplateTab: 'Users' | 'Group';
};

export const useLayoutStore = create(
  persist<LayoutState>(
    (set) => ({
      isNavbarOpen: true,
      toggleNavbar: () => set((state) => ({ isNavbarOpen: !state.isNavbarOpen })),
      selectedItems: [],
      selectedApprovalItems: [],
      selectedInterviewItems: [],
      newEmailTemplate: '',
      openedInovationItem: '',
      selectedEmailTemplateTab: 'Users'
    }),
    {
      name: 'layout-storage',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
