import { FC, useMemo } from 'react';

import { Group, Pagination, Text } from '@mantine/core';

import { TTableFooterProps } from './types';

const TableFooter: FC<TTableFooterProps> = ({ table }) => {
  const showingRows = useMemo(() => {
    const { pageIndex, pageSize } = table.options.state.pagination || table.initialState.pagination;
    const pageLast = (pageIndex + 1) * pageSize;
    const total = table.getFilteredRowModel().rows.length;

    return {
      from: pageIndex * pageSize + 1,
      to: pageLast > total ? total : pageLast,
      total
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.options.state, table]);

  return showingRows.total ? (
    <Group position="apart">
      <Text size={14}>
        Showing {showingRows.from} - {showingRows.to} of {showingRows.total}
      </Text>
      <Pagination
        page={table.getState().pagination.pageIndex + 1}
        onChange={(page) => table.setPageIndex(page - 1)}
        total={table.getPageCount()}
      />
    </Group>
  ) : null;
};

export default TableFooter;
