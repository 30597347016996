export enum Modals {
  LoginVerify = 'LoginVerify',
  AdminChangeStatus = 'AdminChangeStatus',
  AdminDeleteUser = 'AdminDeleteUser',
  AdminAddNew = 'AdminAddNew',
  FiltrationSendEmails = 'FiltrationSendEmails',
  ArbitratingAssignDelete = 'ArbitratingAssignDelete',
  NewEmailTemplate = 'NewEmailTemplate',
  DeleteEmailTemplate = 'DeleteEmailTemplate',
  SendEmailToUsers = 'SendEmailToUsers',
  InnovationDelete = 'InnovationDelete',
  CreateGroupModal = 'CreateGroupModal'
}
