import { FC } from 'react';

import { Box, clsx } from '@mantine/core';

import styles from './Paper.module.scss';
import { TPaperProps } from './types';

const Paper: FC<TPaperProps> = ({
  children,
  className,
  ...props
}) => (
  <Box className={clsx(styles.root, className)} {...props}>
    {children}
  </Box>
);

export default Paper;
