import { openModal } from '@mantine/modals';

import { RequestEvaluationStage, RequestStatus } from 'src/modules/competitions/constants';
import UpdateRequestStatus from 'src/pages/ParticipantsRequestsItemEvaluation/UpdateRequestStatus';

export const openUpdateRequestStatusModal = (
  requestId: string,
  evaluationStageId: RequestEvaluationStage,
  requestStatus?: RequestStatus
) => openModal({
  title: 'Change Status',
  size: 700,
  padding: 30,
  centered: false,
  closeOnClickOutside: false,
  children: (
    <UpdateRequestStatus
      requestId={requestId}
      evaluationStageId={evaluationStageId}
      requestStatus={requestStatus}
    />
  )
});
