import { Button, Flex } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

import styles from '../ParticipantsRequestsItemEvaluation/styles.module.scss';

type Props = {
  steps: string[];
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
};

const FiltrationApprovalControls = ({ steps, active, setActive }: Props) => {
  const [, scrollTo] = useWindowScroll();

  const nextStep = () => {
    setActive((current) => (current < steps.length - 1 ? current + 1 : current));

    scrollTo({ y: 0 });
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));

    scrollTo({ y: 0 });
  };

  return (
    <Flex justify="space-between">
      <Button
        disabled={active === 0}
        leftIcon={<IconChevronLeft />}
        variant="subtle"
        className={styles.paginationButton}
        onClick={prevStep}
      >
        Prev
      </Button>

      <Button
        disabled={active === 3}
        rightIcon={<IconChevronRight />}
        variant="subtle"
        className={styles.paginationButton}
        onClick={nextStep}
      >
        Next
      </Button>
    </Flex>
  );
};

export default FiltrationApprovalControls;
