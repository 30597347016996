import { useMutation, useQuery } from '@tanstack/react-query';

import {
  addEmailTemplateGroups,
  addNewEmailTemplate,
  deleteEmailTemplate,
  deleteEmailTemplateGroup,
  getEmailTemplateGroups,
  getEmailTemplateItem,
  getEmailTemplateList,
  sendEmailTemplate,
  sendEmailTemplateGroups,
  updateEmailTemplate
} from './api';
import { EMAIL_TEMPLATE_REQUEST_KEY, EMAIL_TEMPLATE_ITEM_KEY, EMAIL_TEMPLATE_GROUP_LIST_KEY } from './constants';
import { TCreateEmailTemplate, TEmailTemplate, TGroupListData, TPutUpdateEmailTemplate, TSendToGroup, TSendToUser } from './types';

import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import queryClient from 'src/common/configs/query-client';
import { showErrorNotification, showSuccessNotification } from 'src/common/helpers/notifications';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';
import { TCreatedGroups } from 'src/providers/ModalsProvider/CreateGroupModal/CreateGroupModal';

export const useEmailTemplateListQuery = () => {
  return useQuery<TEmailTemplate>({
    queryKey: [EMAIL_TEMPLATE_REQUEST_KEY],
    queryFn: getEmailTemplateList
  });
};

export const useEmailTemplateItemQuery = (template: string) => {
  return useQuery<any>({
    queryKey: [EMAIL_TEMPLATE_ITEM_KEY],
    queryFn: () => getEmailTemplateItem(template)
  });
};

export const usePutWinnerDataQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, TPutUpdateEmailTemplate>({
    mutationFn: updateEmailTemplate,
    onSuccess: async () => {
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_REQUEST_KEY]);
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_ITEM_KEY]);
      showSuccessNotification({ message: 'Email Template changed successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useDeleteEmailTemplateQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, any>({
    mutationFn: deleteEmailTemplate,
    onSuccess: async () => {
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_REQUEST_KEY]);
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_ITEM_KEY]);
      showSuccessNotification({ message: 'Email Template deleted successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useSendEmailTemplateQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, TSendToUser>({
    mutationFn: sendEmailTemplate,
    onSuccess: async () => {
      showSuccessNotification({ message: 'Email send successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useAddNewEmailTemplateQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCreateEmailTemplate>({
    mutationFn: addNewEmailTemplate,
    onSuccess: async () => {
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_REQUEST_KEY]);
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_ITEM_KEY]);
      showSuccessNotification({ message: 'Email Template created successfully' });
      router.navigate(Paths.emailTemplate);
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useGetEmailTemplateGroupsQuery = () => {
  return useQuery<TGroupListData>({
    queryKey: [EMAIL_TEMPLATE_GROUP_LIST_KEY],
    queryFn: getEmailTemplateGroups
  });
};

export const useAddEmailTemplateGroupsQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCreatedGroups>({
    mutationFn: addEmailTemplateGroups,
    onSuccess: async () => {
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_GROUP_LIST_KEY]);
      showSuccessNotification({ message: 'Group created successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useSendEmailTemplateGroupsQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, TSendToGroup>({
    mutationFn: sendEmailTemplateGroups,
    onSuccess: async () => {
      showSuccessNotification({ message: 'Email send successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useDeleteEmailTemplateGroupQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, any>({
    mutationFn: deleteEmailTemplateGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries([EMAIL_TEMPLATE_GROUP_LIST_KEY]);
      showSuccessNotification({ message: 'Group deleted successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};
