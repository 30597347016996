import { Flex, Group, Paper, Title, TextInput, Text, Button, Textarea } from '@mantine/core';
import BackPageButton from 'src/components/BackPageButton';
import styles from './CreateNewTemplate.module.scss';
import { useLayoutStore } from 'src/store/useLayoutStore';
import { Link } from 'react-router-dom';
import { Paths } from 'src/router/constants';
import { useAddNewEmailTemplateQuery } from 'src/api/email-template/hooks';
import { useState } from 'react';
import { TCreateEmailTemplate } from 'src/api/email-template/types';
import RichTextEditor from 'src/components/RichTextEditor';

const CreateNewTemplate = () => {
  const { newEmailTemplate } = useLayoutStore();
  const [form, setForm] = useState<TCreateEmailTemplate>({
    subject_en: '',
    subject_ar: '',
    content_en: '',
    content_ar: '',
    name_en: newEmailTemplate,
    name_ar: newEmailTemplate,
  })
  const { mutate: createTemplate } = useAddNewEmailTemplateQuery();

  return (
    <Flex direction='column' h='100%'>
      <Flex align='center' justify='space-between' pb={30}>
        <BackPageButton label='Create New Template' />
      </Flex>

      <Flex gap={32} justify='center'>
        <Paper w='50%' radius='lg' display='grid'>
          <Group bg='#E8F3FD'>
            <Title size={24} color='#228BE6' fw={400} px={30} py={26}>{newEmailTemplate}</Title>
          </Group>

          <Flex p={30} direction='column' justify='space-between' gap={30}>
            <Flex direction='column'>
              <Text fz={14} color='#767676'>
                Subject - En
                <Text display='inline' color='#FF2929' ml={4}>*</Text>
              </Text>
              <TextInput
                placeholder='Enter subject'
                value={form.subject_en}
                onChange={(e) => setForm({...form, subject_en: e.target.value})}
              />
            </Flex>

            <Flex direction='column'>
              <Text fz={14} color='#767676'>
                Subject - Ar
                <Text display='inline' color='#FF2929' ml={4}>*</Text>
              </Text>
              <TextInput
                dir='rtl'
                className={styles.rtl}
                placeholder='أدخل الموضوع'
                value={form.subject_ar}
                onChange={(e) => setForm({...form, subject_ar: e.target.value})}
              />
            </Flex>

            <Flex direction='column'>
              <Text fz={14} color='#767676'>
                Body - En
                <Text display='inline' color='#FF2929' ml={4}>*</Text>
              </Text>
              <RichTextEditor
                onUpdate={(e) => setForm({...form, content_en: e})}
                content={form.content_en}
                placeholder='Enter text'
              />
            </Flex>

            <Flex direction='column'>
              <Text fz={14} color='#767676'>
                Body - Ar
                <Text display='inline' color='#FF2929' ml={4}>*</Text>
              </Text>
              <RichTextEditor
                onUpdate={(e) => setForm({...form, content_ar: e})}
                content={form.content_ar}
                placeholder='أدخل النص'
                isRtl
              />
            </Flex>

            <Flex gap={20}>
              <Button onClick={() => createTemplate(form)}>Create New Template</Button>
              <Button
                variant='outline'
                component={Link}
                to={Paths.emailTemplate}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Paper>
      </Flex>
    </Flex>
  );
};

export default CreateNewTemplate;
