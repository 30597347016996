import { AxiosResponse } from 'axios';

import { GetUsersListResponse } from 'src/api/users/types';
import privateApi from 'src/common/configs/private-api';

export const getUsersList = async (): Promise<AxiosResponse<GetUsersListResponse>> => {
  return privateApi.get('/admin/manage-member', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  });
};
