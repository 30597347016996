import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TGeneralSecretariatItemForm, TGeneralSecretariatList } from 'src/modules/general-secretariat/types';

import { TGeneralSecretariatItemUpdateRequest } from './types';

export const getGeneralSecretariatList = async (): Promise<TGeneralSecretariatList> => {
  return privateApi.get('/admin-content/generalsecretariat').then((res) => res.data.data);
};

export const createGeneralSecretariatItem = async (data: TGeneralSecretariatItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/general-secretariat', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateGeneralSecretariatItem = async (data: TGeneralSecretariatItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/general-secretariat', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteGeneralSecretariatItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/general-secretariat', {
    params: { id }
  }).then((res) => res.data);
};

export const sortGeneralSecretariatList = async (data: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/general-secretariat/sort', data).then((res) => res.data);
};
