import { FC } from 'react';

import { LoadingOverlay, Flex, Stack, Text, Button, Box } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useUpdateAdminStatusMutation } from 'src/api/admin/hooks';
import { renderAdminStatus } from 'src/modules/admin/helpers';
import { AdminUser } from 'src/modules/admin/types';

const AdminChangeStatusModal: FC<ContextModalProps<{ admin: AdminUser }>> = ({
  context,
  id,
  innerProps: { admin }
}) => {
  const { mutate: updateStatus, isLoading } = useUpdateAdminStatusMutation();

  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleUpdate = () => {
    updateStatus({ admin_id: admin.id, is_active: !admin.activeStatus });
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Stack spacing={30}>
        <Box>
          <Text align="center">
            Are you sure you want change active status for {admin.fullName}
          </Text>
          <Text align="center">
            from {renderAdminStatus(admin.activeStatus)} to {renderAdminStatus(!admin.activeStatus)} ?
          </Text>
        </Box>
        <Flex justify="space-between">
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleUpdate}>
            Update
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default AdminChangeStatusModal;
