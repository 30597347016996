import { FC, useMemo } from 'react';

import { useUpdateGeneralSecretariatItemMutation } from 'src/api/general-secretariat/hooks';
import ManageGeneralSecretariat from 'src/components/ManageGeneralSecretariat';
import { TGeneralSecretariatItem, TGeneralSecretariatItemForm } from 'src/modules/general-secretariat/types';

type TProps = {
  item: TGeneralSecretariatItem;
};

const EditModalContent: FC<TProps> = ({ item }) => {
  const { mutate, isLoading } = useUpdateGeneralSecretariatItemMutation();

  const defaultValues = useMemo((): TGeneralSecretariatItemForm => {
    return {
      name_en: item.name_en,
      name_ar: item.name_ar,
      position_en: item.position_en,
      position_ar: item.position_ar,
      image: item.image_path
    };
  }, [item]);

  const handleItemUpdate = (data: TGeneralSecretariatItemForm) => {
    mutate({ id: item.id, ...data });
  };

  return (
    <ManageGeneralSecretariat
      defaultValues={defaultValues}
      onSubmit={handleItemUpdate}
      submitText="Save"
      isLoading={isLoading}
    />
  );
};

export default EditModalContent;
