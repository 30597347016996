import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, ItemWithIdAndName, SuccessResponse } from 'src/common/types/api';
import { TTicket } from 'src/modules/tickets/types';

import {
  getTicketById,
  getTicketsList,
  getTicketStatusList,
  getTicketTypeList,
  updateTicketStatus
} from './api';
import {
  TICKETS_LIST_QUERY_KEY,
  TICKET_DETAILS_QUERY_KEY,
  TICKET_STATUS_LIST_QUERY_KEY,
  TICKET_TYPE_LIST_QUERY_KEY
} from './constants';
import { TUpdateTicketStatusRequestBody } from './types';

export const useTicketsListQuery = () => {
  return useQuery<TTicket[]>({
    queryKey: [TICKETS_LIST_QUERY_KEY],
    queryFn: getTicketsList
  });
};

export const useTicketByIdQuery = (id: string) => {
  return useQuery<TTicket>({
    queryKey: [TICKET_DETAILS_QUERY_KEY, id],
    queryFn: () => getTicketById(id)
  });
};

export const useTicketStatusListQuery = () => {
  return useQuery<ItemWithIdAndName[]>({
    queryKey: [TICKET_STATUS_LIST_QUERY_KEY],
    queryFn: getTicketStatusList
  });
};

export const useTicketTypeListQuery = () => {
  return useQuery<ItemWithIdAndName[]>({
    queryKey: [TICKET_TYPE_LIST_QUERY_KEY],
    queryFn: getTicketTypeList
  });
};

export const useUpdateTicketStatusMutation = ({
  onError
}: {
  onError: (error: ErrorResponse) => void;
}) => {
  return useMutation<SuccessResponse, ErrorResponse, TUpdateTicketStatusRequestBody>({
    mutationFn: updateTicketStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries([TICKETS_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Status updated successfully' });
    },
    onError
  });
};

