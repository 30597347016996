import { FC, useRef, useState } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useTopWinnerDataQuery } from 'src/api/arbitrating-requests/hooks';
import DataTable from 'src/components/DataTable';
import { use3TopWinnersTableColumns, use3TopWinnersTableFilters } from 'src/modules/top-winners/hooks';
import VisibleFilter from 'src/components/VisibleFilter';
import { useSetShowHidePageQuery, useShowHidePageListQuery } from 'src/api/content-management/hooks';

const TopWinners: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();

  const {data: WinnerData, isLoading: WinnerDataLoading} = useTopWinnerDataQuery();
  const { data: Content, isLoading: isContent } = useShowHidePageListQuery();
  const { mutate: updateShowHidePage } = useSetShowHidePageQuery('3 Top Winners');

  const columns = use3TopWinnersTableColumns();
  const filters = use3TopWinnersTableFilters();
  const isPageShow = !isContent && Content && Content.data.find((el) => el.name === '3 Top Winners');

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>3 Top Winners</Title>
      </Flex>
      {WinnerDataLoading && isContent && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      <DataTable
        columns={columns}
        data={WinnerData ? WinnerData : []}
        filters={filters}
        extraFilterElement={
          <VisibleFilter
            value={isPageShow && isPageShow.is_show}
            onChange={() => isPageShow && updateShowHidePage({content_id: isPageShow.id, is_show: !isPageShow.is_show})}
          />
        }
        tableInstanceRef={tableInstanceRef}
      />
    </Flex>
  );
};

export default TopWinners;
