import { Button } from '@mantine/core';
import { IconTableExport } from '@tabler/icons';

import { Worksheet, exportDataAsExcel } from './utils';

type Props = {
  filename: string;
  data: Array<Worksheet>;
};

const ExportButton = ({filename, data}: Props) => {

  const handleExport = () => {
    exportDataAsExcel(data, filename);
  };

  return (
    <Button
      leftIcon={<IconTableExport size={18} />}
      onClick={handleExport}
    >
      Export
    </Button>
  );
};

export default ExportButton;