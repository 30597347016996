import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TRegulations, TRegulationsForm } from 'src/modules/regulations/types';

import { getRegulations, updateRegulations } from './api';
import { REGULATIONS_QUERY_KEY } from './constants';

export const useRegulationsQuery = () => {
  return useQuery<TRegulations>({
    queryKey: [REGULATIONS_QUERY_KEY],
    queryFn: getRegulations
  });
};

export const useRegulationsMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TRegulationsForm>({
    mutationFn: updateRegulations,
    onSuccess: async () => {
      await queryClient.invalidateQueries([REGULATIONS_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
