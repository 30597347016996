import { useState } from 'react';

import { Paper, Flex, Stack, Center, Button } from '@mantine/core';

import BackPageButton from 'src/components/BackPageButton';

import ParticipantsRequestsItemView from '../ParticipantsRequestsItemView';
import CustomStepper from '../ParticipantsRequestsItemView/CustomStepper';
import ParticipantsRequestControls from '../ParticipantsRequestsItemEvaluation/ParticipantsRequestControls';
import { useParams } from 'react-router-dom';
import { useParticipantsCommentsHistory } from 'src/api/participants-requests/hooks';
import GeneratePDF from 'src/components/GeneratePDF';
import { TParticipantCheckingInfo } from 'src/modules/participants-requests/types';
import TableCommentHistory from 'src/components/TableCommentHistory';
import { STEPS } from 'src/common/constants/defConstant';
import { useAddToInnovation } from 'src/api/innovations/hooks';
import { AdminRoles } from 'src/modules/admin/constants';
import { useAuthStore } from 'src/store/useAuthStore';

const FiltrationRequestItemViewAndEvaluation = () => {
  const [active, setActive] = useState(0);
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useParticipantsCommentsHistory(id || '');
  const { mutate: addInnovation } = useAddToInnovation();
  const { user } = useAuthStore();

  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const [checkingInfo, setCheckingInfo] = useState<TParticipantCheckingInfo>({
    'Personal Information': false,
    'Participant Data': false,
    'Innovation Data': false,
    'Impact of Innovation': false 
  });

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
        <CustomStepper active={active} setActive={setActive} labels={STEPS} checkingInfo={checkingInfo} setCheckingInfo={setCheckingInfo} />
      </Flex>
      <Flex gap={16} justify='flex-end'>
        {isAdmin && (<Button onClick={() => addInnovation(id || '')}>To Innovation Page</Button>)}
        <GeneratePDF />
      </Flex>

      <Center>
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <ParticipantsRequestsItemView tabIndex={active} />
          {/* <ParticipantsRequestsItemEvaluation /> */}

          <ParticipantsRequestControls steps={STEPS} active={active} setActive={setActive} checkingInfo={checkingInfo} />

          { data && !isLoading ? (
            <TableCommentHistory data={data} />
          ) : null}
        </Paper>
      </Center>
    </Stack>
  );
};

export default FiltrationRequestItemViewAndEvaluation;
