import { FC } from 'react';

import { LoadingOverlay, Flex, Stack, Button } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useSendFiltrationApprovalEmailQuery } from 'src/api/filtration-request/hooks';

const FiltrationSendEmails: FC<ContextModalProps<{isApproved: boolean}>> = ({
  context,
  id,
  innerProps: { isApproved }
}) => {
  const { mutate: sendEmails, isLoading } = useSendFiltrationApprovalEmailQuery(isApproved);

  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleSend = () => {
    sendEmails()
    context.closeModal(id);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Stack>
        <Flex justify="center" gap={16}>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSend}>
            Send
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default FiltrationSendEmails;
