export const ARBITRATING_REQUEST_KEY = 'arbitrating-request';
export const ARBITRATING_ASSIGN_KEY = 'arbitrating-assign';
export const ARBITRATING_ALL_USERS_KEY = 'arbitrating-all-users';
export const ARBITRATING_APPROVAL_KEY = 'arbitrating-approval';
export const ARBITRATING_INTERVIEWED_KEY = 'arbitrating-interviewed';
export const ARBITRATING_QUALIFIED_INTERVIEW_KEY = 'qualified-for-interview';
export const ARBITRATING_ALL_WINNERS_KEY = 'all-winners';
export const ARBITRATING_TOP_WINNER_KEY = 'top-winners';
export const ARBITRATING_APPROVAL_PERCENTAGES_KEY = 'arbitrating-approval-percentage';
export const ARBITRATING_REQUEST_PERCENTAGES_KEY = 'arbitrating-request-percentage';
export const ARBITRATING_COMMENT_HISTORY_KEY = 'arbitrating-comment-history';
export const ARBITRATING_LATEST_DEGREE_KEY = 'arbitrating-latest-degree';
