import { closeModal, openContextModal } from '@mantine/modals';

import { AdminUser } from 'src/modules/admin/types';

import { Modals } from './contants';
import { TJudgmentUser } from 'src/modules/participants-requests/types';

export const openLoginVerifyModal = ({
  email
}: {
  email: string;
}) => {
  openContextModal({
    modal: Modals.LoginVerify,
    innerProps: { email },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    padding: 30,
    title: 'Enter verify code',
    size: 360,
    trapFocus: false
  });
};

export const closeLoginVerifyModal = () => {
  closeModal(Modals.LoginVerify);
};

export const openAdminChangeStatusModal = ({
  admin
}: {
  admin: AdminUser;
}) => {
  openContextModal({
    modal: Modals.AdminChangeStatus,
    innerProps: { admin },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    title: 'Change admin status',
    size: 350,
    trapFocus: false
  });
};

export const closeAdminChangeStatusModal = () => {
  closeModal(Modals.AdminChangeStatus);
};

export const openAdminAddNewModal = () => {
  openContextModal({
    modal: Modals.AdminAddNew,
    innerProps: {},
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    title: 'Add new Admin',
    size: 500,
    padding: 30,
    trapFocus: false
  });
};

export const closeAdminAddNewModal = () => {
  closeModal(Modals.AdminAddNew);
};

export const openAdminDeleteUserModal = ({
  admin
}: {
  admin: AdminUser;
}) => {
  openContextModal({
    modal: Modals.AdminDeleteUser,
    innerProps: { admin },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    title: 'Are you sure you want to delete this account?',
    size: 500,
    trapFocus: false
  });
};

export const closeAdminDeleteUserModal = () => {
  closeModal(Modals.AdminDeleteUser);
};

export const openFiltrationSendEmailsModal = ({
  isApproved
}: {
  isApproved: boolean;
}) => {
  openContextModal({
    modal: Modals.FiltrationSendEmails,
    innerProps: { isApproved },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    title: `Are you sure you want to send ${isApproved ? 'Approval' : 'Rejected'} Email?`,
    size: 500,
    trapFocus: false
  });
};

export const closeFiltrationSendEmailsModal = () => {
  closeModal(Modals.FiltrationSendEmails);
};

export const openArbitratingAssignModal = ({
  user
}: {
  user: TJudgmentUser;
}) => {
  openContextModal({
    modal: Modals.ArbitratingAssignDelete,
    innerProps: { user },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    title: `Are you sure you want to delete ${user.fullName}?`,
    size: 500,
    padding: 30,
    trapFocus: false
  });
};

export const closeArbitratingAssignModal = () => {
  closeModal(Modals.ArbitratingAssignDelete);
};

export const openNewEmailTemplateModal = ({}: {}) => {
  openContextModal({
    modal: Modals.NewEmailTemplate,
    innerProps: {},
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    title: `Create New Template`,
    size: 500,
    trapFocus: false
  });
};

export const closeNewEmailTemplateModal = () => {
  closeModal(Modals.NewEmailTemplate);
};

export const openDeleteEmailTemplateModal = ({
  templateId,
  title
}: {
  templateId: number,
  title: string | null
}) => {
  openContextModal({
    modal: Modals.DeleteEmailTemplate,
    innerProps: { templateId, title },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    title: `Delete Template`,
    size: 500,
    trapFocus: false
  });
};

export const closeDeleteEmailTemplateModal = () => {
  closeModal(Modals.DeleteEmailTemplate);
};

export const openSendEmailToUsersModal = ({
  templateId
}: {
  templateId: number
}) => {
  openContextModal({
    modal: Modals.SendEmailToUsers,
    innerProps: { templateId },
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    size: 612,
    trapFocus: false
  });
};

export const closeSendEmailToUsersModal = () => {
  closeModal(Modals.SendEmailToUsers);
};

export const openCreateGroupModal = ({}: {}) => {
  openContextModal({
    modal: Modals.CreateGroupModal,
    innerProps: {},
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    padding: 30,
    size: 612,
    trapFocus: false
  });
};

export const closeCreateGroupModal = () => {
  closeModal(Modals.CreateGroupModal);
};

export const openInnovationDeleteModal = (id: string) => {
  openContextModal({
    modal: Modals.InnovationDelete,
    innerProps: id,
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: true,
    title: `Are you sure you want to delete?`,
    size: 500,
    padding: 30,
    trapFocus: false
  });
};

export const closeInnovationDeleteModal = () => {
  closeModal(Modals.InnovationDelete);
};
