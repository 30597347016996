export const tableHeadings = {
  ticketNumber: 'Ticket number',
  title: 'Title',
  ticketType: 'Ticket Type',
  ticketStatus: 'Ticket Status',
  name: 'Name',
  email: 'Email',
  creating_date: 'Date'
};

export enum TicketStatus {
  New = 1,
  UnderReview = 2,
  MissingInfo = 3,
  Closed = 4
}
