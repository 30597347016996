import { FC } from 'react';

import { clsx, InputBase } from '@mantine/core';
import InputMask from 'react-input-mask';

import styles from './MaskedInput.module.scss';
import { MaskedInputProps } from './types';

const MaskedInput: FC<MaskedInputProps> = (props) => (
  <InputBase
    classNames={{
      input: styles.input,
      invalid: styles.invalid,
      error: styles.error,
      icon: clsx(styles.icon, { [styles.iconError]: props.error })
    }}
    component={InputMask}
    {...props}
  />
);

export default MaskedInput;
