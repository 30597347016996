import { FC, useRef } from 'react';

import { Button, Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useAllWinnersQuery, useSendArbitratingWinnerEmailQuery, useSortArbitratingWinnerListtMutation } from 'src/api/arbitrating-requests/hooks';
import { reorderIds } from 'src/common/helpers/dragAndDrop';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { AdminRoles } from 'src/modules/admin/constants';
import { useArbitratingWinnersTableColumns } from 'src/modules/arbitration-winners/hooks';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';
import { useAuthStore } from 'src/store/useAuthStore';

import { useArbitratingRequestFilter } from './hooks';
import styles from '../../components/FinalEmailButton/FinalEmailButton.module.scss';
import { getArbitratingApprovalForExport } from '../ArbitratingRequest/utils';

const ArbitratingWinners: FC = () => {
  const { user } = useAuthStore();
  const tableInstanceRef = useRef<Table<any>>();

  const { data: AllWinners, isLoading: AllWinnersLoading } = useAllWinnersQuery();
  const { mutate: sortWinnerList, isLoading: isSortingWinnerListLoading } = useSortArbitratingWinnerListtMutation();
  const { mutate: sendWinnerEmail } = useSendArbitratingWinnerEmailQuery();

  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;
  
  const columns = useArbitratingWinnersTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const ArbitratingAssignExport = getArbitratingApprovalForExport(AllWinners?.data);
  const requestsFilteredByUserRole = useArbitratingRequestFilter(AllWinners?.data);

  const handleReorder = (sourceIndex: number, destinationIndex: number) => {
    if (!AllWinnersLoading && !AllWinners?.data) {
      return;
    };

    sortWinnerList(reorderIds((AllWinners?.data || []), sourceIndex, destinationIndex));
  };

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Winners</Title>
        <Flex gap={16}>
          {isAdmin ? (
            <Button
              className={`${styles.button} ${styles.approve}`}
              disabled={!(AllWinners && AllWinners?.data.length)}
              onClick={() => sendWinnerEmail()}
            >Winner Email</Button>
          ) : null}
          <ExportButton filename="Arbitrating Winners" data={ArbitratingAssignExport} />
        </Flex>
      </Flex>
      {AllWinnersLoading && ( 
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {requestsFilteredByUserRole ? (
        <DataTable
          columns={columns}
          draggable={true}
          data={AllWinners?.data || []}
          filters={filters}
          tableInstanceRef={tableInstanceRef}
          onDragEnd={handleReorder}
          isLoading={isSortingWinnerListLoading}
        />
      ) : null}
    </Flex>
  );
};

export default ArbitratingWinners;
