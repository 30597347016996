import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { useGendersQuery } from 'src/api/genders/hooks';
import { useNationalitiesQuery } from 'src/api/nationalities/hooks';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import { TTableFilters } from 'src/components/TableFilters';
import { TUser } from 'src/modules/users/types';

import { tableHeadings } from './constants';

export const useUsersTableColumns = () => {
  return useMemo(() => {
    const columnHelper = createColumnHelper<TUser>();

    return [
      columnHelper.accessor('first_name', {
        header: tableHeadings.firstName
      }),
      columnHelper.accessor('second_name', {
        header: tableHeadings.secondName
      }),
      columnHelper.accessor('last_name', {
        header: tableHeadings.lastName
      }),
      columnHelper.accessor('nationality', {
        header: tableHeadings.nationality,
        cell: (cell) => cell.getValue().name,
        filterFn: (row, _columnId, filterValue) => {
          return +filterValue === row.original.nationality.id;
        },
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.nationality?.name || '';
          const valueB = rowB.original.nationality?.name || '';

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      }),
      columnHelper.accessor('gender', {
        header: tableHeadings.gender,
        cell: (cell) => cell.getValue().name,
        filterFn: (row, _columnId, filterValue) => {
          return +filterValue === row.original.gender.id;
        },
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.gender?.name || '';
          const valueB = rowB.original.gender?.name || '';

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        minSize: 110,
        size: 110,
        enableResizing: false
      }),
      columnHelper.accessor('mobile', {
        header: tableHeadings.mobileNumber,
        enableResizing: false
      }),
      columnHelper.accessor('user_identity', {
        header: tableHeadings.identityNumber,
        minSize: 160,
        enableResizing: false
      }),
      columnHelper.accessor('birthday', {
        header: tableHeadings.birthDate,
        cell: (cell) => dayjs(cell.getValue()).format('DD-MM-YYYY'),
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.birthday || '';
          const valueB = rowB.original.birthday || '';
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);

          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        },
        minSize: 120,
        size: 120,
        enableResizing: false
      }),
      columnHelper.accessor('email', {
        header: tableHeadings.email
      })
    ];
  }, []);
};

export const useUsersTableFilters = () => {
  const { data: genders } = useGendersQuery();
  const { data: nationalities } = useNationalitiesQuery();

  const normalizedGenders = useNormalizedItemsData(genders);
  const normalizedNationalities = useNormalizedItemsData(nationalities);

  return useMemo((): TTableFilters<TUser> => [
    {
      id: 'first_name',
      title: tableHeadings.firstName,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'second_name',
      title: tableHeadings.secondName,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'last_name',
      title: tableHeadings.lastName,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'nationality',
      title: tableHeadings.nationality,
      type: 'select',
      data: normalizedNationalities || [],
      defaultValue: null
    },
    {
      id: 'gender',
      title: tableHeadings.gender,
      type: 'select',
      data: normalizedGenders || [],
      defaultValue: null
    },
    {
      id: 'mobile',
      title: tableHeadings.mobileNumber,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'user_identity',
      title: tableHeadings.identityNumber,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'birthday',
      title: tableHeadings.birthDate,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'email',
      title: tableHeadings.email,
      type: 'input',
      defaultValue: ''
    }
  ], [normalizedGenders, normalizedNationalities]);
};
