import { FC, ReactNode } from 'react';

import { Box, Text } from '@mantine/core';

type TProps = {
  label: string;
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const InfoLabel: FC<TProps> = ({ label, children, ...rest }) => (
  <Box {...rest}>
    <Text fz={14} weight={600}>{label}</Text>
    {children}
  </Box>
);

export default InfoLabel;
// NOTE Might be replaced by ContentWithLabel