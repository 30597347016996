import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import {
  TCompetitionPathItem,
  TCompetitionPathItemForm,
  TCompetitionPathsList
} from 'src/modules/competition-paths/types';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';

import {
  getCompetitionPathsList,
  getCompetitionPathById,
  createCompetitionPathsItem,
  updateCompetitionPathsItem,
  deleteCompetitionPathsItem
} from './api';
import { COMPETITION_PATHS_LIST_QUERY_KEY, COMPETITION_PATH_QUERY_KEY } from './constants';
import { TCompetitionPathsItemUpdateRequest } from './types';

export const useCompetitionPathsListQuery = () => {
  return useQuery<TCompetitionPathsList>({
    queryKey: [COMPETITION_PATHS_LIST_QUERY_KEY],
    queryFn: getCompetitionPathsList
  });
};

export const useCompetitionPathByIdQuery = (id: string) => {
  return useQuery<TCompetitionPathItem | null>({
    queryKey: [COMPETITION_PATH_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getCompetitionPathById(id);
      const item = response[0];

      return item || null;
    }
  });
};

export const useCreateCompetitionPathsItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCompetitionPathItemForm>({
    mutationFn: createCompetitionPathsItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITION_PATHS_LIST_QUERY_KEY]);
      router.navigate(Paths.competitionPaths);
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateCompetitionPathsItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCompetitionPathsItemUpdateRequest>({
    mutationFn: updateCompetitionPathsItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITION_PATHS_LIST_QUERY_KEY]);
      await queryClient.invalidateQueries([COMPETITION_PATH_QUERY_KEY]);
      router.navigate(Paths.competitionPaths);
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteCompetitionPathsItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteCompetitionPathsItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITION_PATHS_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};
