import { FC } from 'react';

import { Flex, Stack, Button, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useDeleteEmailTemplateQuery } from 'src/api/email-template/hooks';

const DeleteEmailTemplate: FC<ContextModalProps<{ title: string, templateId: number }>> = ({
  context,
  id,
  innerProps: { title, templateId }
}) => {
  const { mutate: deleteTemplate } = useDeleteEmailTemplateQuery();
  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleDelete = () => {
    deleteTemplate(templateId);
    handleCancel();
  };

  return (
    <Stack>
      <Flex justify='center' align='center' direction='column' gap={16}>
        <Text ta='center'>Are you sure want to delete <strong>“{title ? title : 'this'}”</strong> template?</Text>

        <Flex justify="center" gap={16}>
          <Button variant="outline" onClick={handleCancel}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default DeleteEmailTemplate;
