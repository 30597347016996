import { Center, Flex, Grid, Loader, SimpleGrid, Stack, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';
import ContentWithLabel from 'src/components/ContentWithLabel';

import { ParticipantInfo, ParticipantPercentage } from './Participant';
import { getFullName } from './helpers';

const ParticipantData = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useParticipantsRequestByIdQuery(id || '');

  if (isLoading)
    return (
      <Center py={50}>
        <Loader size="lg" />
      </Center>
    );

  if (!data) return null;

  const { participating_type_id, partcipants_data } = data;

  return (
    <Stack>
      <SimpleGrid cols={2} spacing="xl">
        <ContentWithLabel label="Participating Type">
          {participating_type_id?.name}
        </ContentWithLabel>
        <ContentWithLabel label="Number of Participants">
          {partcipants_data?.length}
        </ContentWithLabel>
      </SimpleGrid>

      <ContentWithLabel label="Participants Information">
        <Grid>
          {partcipants_data.map((participant) => (
            <Grid.Col md={6} xl={4} key={participant.user_identity}>
              <ParticipantInfo {...participant} />
            </Grid.Col>
          ))}
        </Grid>
      </ContentWithLabel>

      <SimpleGrid cols={2} spacing="xl">
        <ContentWithLabel label="Participant Percentage">
          <Grid>
            {partcipants_data.map((participant) => (
              <Grid.Col md={6} xl={8} key={participant.user_identity}>
                <ParticipantPercentage {...participant} />
              </Grid.Col>
            ))}
          </Grid>
        </ContentWithLabel>

        <ContentWithLabel label="Applicant Percentage">
          <Grid>
            <Grid.Col md={6} xl={8}>
              <Flex
                key={`participant_${data.member.user_identity}`}
                justify="space-between"
                className={styles.section}
              >
                <Text weight={500}>{getFullName(data.member)}</Text>
                <Text weight={500}>{data.participating_percentage}%</Text>
              </Flex>
            </Grid.Col>
          </Grid>
        </ContentWithLabel>
      </SimpleGrid>
    </Stack>
  );
};

export default ParticipantData;
