import { FC } from 'react';

import { Flex, Select, Text } from '@mantine/core';

import { TShowRowsProps } from './types';

const ShowRows: FC<TShowRowsProps> = ({ table }) => (
  <Flex align="center" justify="space-between">
    <Text size={14}>Show rows</Text>
    <Select
      size="xs"
      data={['10', '20', '30', '40', '50']}
      value={`${table.getState().pagination.pageSize}`}
      onChange={(value) => table.setPageSize(value ? +value : 10)}
      sx={{ width: 60 }}
    />
  </Flex>
);

export default ShowRows;
