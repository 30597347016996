import { Paper, Table, Title } from '@mantine/core';
import dayjs from 'dayjs';

import { TCommentHistory } from 'src/api/arbitrating-requests/types';
import { STATUS_COLORS } from 'src/common/constants/defConstant';

interface TProps {
  data: TCommentHistory[]
}

const TableCommentHistory = ({ data }: TProps) => {
  return (
    <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
      <Title order={3}>Admins&apos; Comment</Title>
      <Table sx={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Admin Name</th>
            <th>Status</th>
            <th>Comment</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: TCommentHistory, id: number) => (
            <tr key={id}>
              <td>{item.adminName}</td>
              <td style={{color: `${STATUS_COLORS[item.statusId]}`}}>{item.statusName}</td>
              <td>{item.comment}</td>
              <td>{dayjs(item.created).format('DD/MM/YYYY')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Paper>
  );
};

export default TableCommentHistory;
