export const reorderIds = <T extends { id: string; [key: string]: any }>(
  list: T[],
  sourceIndex: number,
  destinationIndex: number
): string[] => {
  const result = list.map((item) => item.id);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};
