import { Center, SimpleGrid, Loader, Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';
import AttachmentButton from 'src/components/AttachmentButton';
import ContentWithLabel from 'src/components/ContentWithLabel';

import styles from './styles.module.scss';

const ImpactOfInnovation = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useParticipantsRequestByIdQuery(id || '');

  if (isLoading)
    return (
      <Center py={50}>
        <Loader size="lg" />
      </Center>
    );

  if (!data) return null;

  return (
    <Stack>
      <ContentWithLabel label="Number of Applications">
        {data.number_of_applications}
      </ContentWithLabel>

      <ContentWithLabel label="Application Data">
        {data.application_data.map((application) => (
          <SimpleGrid
            cols={2}
            spacing="xl"
            mb="md"
            breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
            className={styles.section}
            key={application.application_id}
          >
            <ContentWithLabel label="Application Description">
              {application.description}
            </ContentWithLabel>
            <ContentWithLabel label="Direct impact of the Application">
              {application.impact}
            </ContentWithLabel>
            <ContentWithLabel label="Attachment">
              <AttachmentButton href={application.attachment} />
            </ContentWithLabel>
            <ContentWithLabel label="Economic Impact">
              {application.economic_impact}
            </ContentWithLabel>
            <ContentWithLabel label="Economic Impact Attachment">
              <AttachmentButton href={application.economic_impact_attachment} />
            </ContentWithLabel>
            <ContentWithLabel label="Innovation Completeness">
              {application.completness}
            </ContentWithLabel>
            <ContentWithLabel label="Completeness Attachment">
              <AttachmentButton href={application.completness_attachment} />
            </ContentWithLabel>
            <ContentWithLabel label="Attached Video">
              <AttachmentButton href={application.attached_video} />
            </ContentWithLabel>
          </SimpleGrid>
        ))}
      </ContentWithLabel>
    </Stack>
  );
};

export default ImpactOfInnovation;
