import { useQuery } from '@tanstack/react-query';

import { getInterestedUserList } from './api';
import { INTERESTED_USER_KEY } from './constants';
import { GetInterestedUserListResponse } from './types';

export const useInterestedUserListQuery = () => {
  return useQuery<GetInterestedUserListResponse>({
    queryKey: [INTERESTED_USER_KEY],
    queryFn: getInterestedUserList
  });
};
