import { AdminUser } from 'src/modules/admin/types';

export const getAdminsForExport = (admins: AdminUser[] | undefined) => {
  if (!admins) {
    return [
      { data: [], title: 'Data' },
      { data: [], title: 'Statistics' }
    ];
  }

  const adminsDataSheet = getAdminsData(admins);
  const adminsStatisticsSheet = getAdminsStatistics(admins);

  return [
    { data: adminsDataSheet, title: 'Data' },
    { data: adminsStatisticsSheet, title: 'Statistics' }
  ];
};

const getAdminsData = (admins: AdminUser[]) => {
  const adminsExport = admins?.map(admin => ({
    // 'id': admin.id,
    'Full Name': admin.fullName,
    'Email': admin.email,
    'Mobile Number': admin.mobileNumber,
    'Role': admin.userRole.name,
    'Active Status': admin.activeStatus
  })) ?? [];

  return adminsExport;
};
const getAdminsStatistics = (admins: AdminUser[]) => {
  const activeAdminsLength = admins.filter(({activeStatus}) => activeStatus).length;
  const disabledAdminsLength = admins.filter(({activeStatus}) => !activeStatus).length;
  const superAdminsLength = admins.filter(({userRole: {id}}) => id === 1).length;
  const supervisoryBoardAdminsLength = admins.filter(({userRole: {id}}) => id === 4).length;
  const contentManagerAdminsLength = admins.filter(({userRole: {id}}) => id === 5).length;
  const generalSecretariatAdminsLength = admins.filter(({userRole: {id}}) => id === 7).length;
  const authorizerAdminsLength = admins.filter(({userRole: {id}}) => id === 6).length;
  const judgmentAdminsLength = admins.filter(({userRole: {id}}) => id === 3).length;
  const operationCommitteeAdminsLength = admins.filter(({userRole: {id}}) => id === 2).length;

  return [
    { 'Statistic description': 'Active Admins', 'No.': activeAdminsLength },
    { 'Statistic description': 'Disabled admins', 'No.': disabledAdminsLength },
    { 'Statistic description': 'Super Admins', 'No.': superAdminsLength },
    { 'Statistic description': 'Supervisory Board Admins', 'No.': supervisoryBoardAdminsLength },
    { 'Statistic description': 'Content Manager Admins', 'No.': contentManagerAdminsLength },
    { 'Statistic description': 'General Secretariat Admins', 'No.': generalSecretariatAdminsLength },
    { 'Statistic description': 'Authorizer Admins', 'No.': authorizerAdminsLength },
    { 'Statistic description': 'Judgment Admins', 'No.': judgmentAdminsLength },
    { 'Statistic description': 'Operation Committee Admins', 'No.': operationCommitteeAdminsLength }
  ];
};
