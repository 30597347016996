import { FC } from 'react';

import { useForm } from 'react-hook-form';

import { useCreateCompetitionItemMutation } from 'src/api/competitions/hooks';
import ManageCompetition from 'src/components/ManageCompetition';
import { competitionFormDefaultValues } from 'src/modules/competitions/constants';
import { TCompetitionItemForm } from 'src/modules/competitions/types';

const CompetitionsAddItem: FC = () => {
  const { mutate, isLoading } = useCreateCompetitionItemMutation();

  const form = useForm<TCompetitionItemForm>({
    defaultValues: competitionFormDefaultValues
  });

  const handleItemCreate = (data: TCompetitionItemForm) => {
    mutate(data);
  };

  return (
    <ManageCompetition
      title="Add competition"
      submitText="Create"
      form={form}
      onSubmit={handleItemCreate}
      isLoading={isLoading}
    />
  );
};

export default CompetitionsAddItem;
