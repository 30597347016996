import { Flex, Input } from '@mantine/core';
import styles from './TablePercentInput.module.scss';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import { TArbitratingCollectedData } from 'src/api/arbitrating-requests/types';

interface TProps {
  maxWidth?: boolean;
  maxVal: number;
  placeholder: string;
  tableValue?: TArbitratingCollectedData[];
  total?: boolean;
  totalVal?: number;
  id?: number;
  setTableValue?: Dispatch<SetStateAction<TArbitratingCollectedData[]>>;
  resetValue?: boolean;
  setResetValue?: Dispatch<SetStateAction<boolean>>;
};

const TablePercentInput = ({
  maxWidth = false,
  maxVal,
  placeholder,
  tableValue,
  total = false,
  totalVal,
  id,
  resetValue,
  setTableValue,
  setResetValue
}: TProps) => {
  const [val, setVal] = useState<number | string>((id || id === 0) && tableValue?.length && tableValue[id] ? tableValue[id][id] : '');
  const { data: arbitrationCriteria, isLoading: isArbitrationCriteriaLoading } = useArbitrationCriteriaListQuery();

  const formatNumber = (inputValue: number | string) => {
    if (+inputValue > maxVal) {
      setVal(maxVal);
      return maxVal;
    } else if (isNaN(+inputValue) || +inputValue === 0) {
      setVal('');
      return '';
    } else {
      setVal(inputValue);
      return inputValue;
    }
  };

  const changeValue = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const inputValue = e.target.value;

    if (tableValue && tableValue[id]) {
      tableValue[id] = {[id]: formatNumber(inputValue)};
      setTableValue &&
      setTableValue(tableValue);
    } else {
      setTableValue &&
      tableValue && setTableValue([...tableValue, {[id]: formatNumber(inputValue)}]);
    };
  };

  return (
    <Flex className={`${styles.wrapper} ${maxWidth && styles.tableNumInput}`}>
      {!isArbitrationCriteriaLoading && arbitrationCriteria && (
        <>
          {total ? (
            <div className={styles.totalWrapper}>
              <span className={total ? styles.totalText : ''}>
                {totalVal}%
              </span>
              out of 100%
            </div>
            
          ) : (
            <div className={styles.calculated}>
              <Input
                className={styles.input}
                placeholder={placeholder}
                value={total ? `${totalVal}%` : val}
                onChange={(e) => {
                  if (id || id === 0) {
                    changeValue(e, id);
                    setResetValue && setResetValue(!resetValue);
                  };
                }}
              />
              <span>{maxVal}%</span>
            </div>
          )}
        </>
      )}
    </Flex>
  );
};

export default TablePercentInput;
