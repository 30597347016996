import { FC, useMemo } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  clsx,
  Divider,
  Menu,
  Navbar as MantineNavbar,
  NavLink,
  ScrollArea,
  Stack,
  Text,
  Tooltip
} from '@mantine/core';
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand } from '@tabler/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthStore } from 'src/store/useAuthStore';
import { useLayoutStore } from 'src/store/useLayoutStore';

import { links } from './constants';
import styles from './styles.module.scss';
import { TNavLinkItem } from './types';

const Navbar: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { isNavbarOpen, toggleNavbar } = useLayoutStore();

  const openNavLinks = useMemo(() => {
    const generateLinks = (items: TNavLinkItem[]) => items.map((item, index) => {
      if (!user || !item.roles || !item.roles.includes(user.userRole.id)) {
        return null;
      }

      return (
        <NavLink
          key={`${index}_${item.label}`}
          icon={item.icon}
          label={item.label}
          active={item.active && item.active(pathname)}
          onClick={item.path ? () => navigate(item.path || '/') : undefined}
          classNames={{ root: styles.linkRoot }}
        >
          {item.children ? generateLinks(item.children) : null}
        </NavLink>
      );
    });

    return generateLinks(links);
  }, [navigate, pathname, user]);

  const collapsedNavLinks = useMemo(() => {
    return links.map((item, index) => {
      if (!user || !item.roles || !item.roles.includes(user.userRole.id)) {
        return null;
      }

      return item.children ? (
        <Menu withinPortal position="right-start" trigger="hover" shadow="xl" key={`${index}_${item.label}`}>
          <Menu.Target>
            <ActionIcon
              size={38}
              variant="light"
              color={item.active && item.active(pathname) ? 'blue' : 'dark'}
              className={clsx(styles.actionIcon, { [styles.actionIconActive]: item.active && item.active(pathname) })}
            >
              {item.icon}
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown p={10}>
            <Menu.Label>
              <Text size={14} color="#172b4d" align="center" weight={600}>
                {item.label}
              </Text>
            </Menu.Label>
            <Menu.Divider />

            {item.children.map((child, childIndex) => {
              if (!child.roles || !child.roles.includes(user.userRole.id)) {
                return null;
              }

              return (
                <Menu.Item
                  key={`${childIndex}_${child.label}`}
                  onClick={child.path ? () => navigate(child.path || '/') : undefined}
                  icon={child.icon}
                  color={child.active && child.active(pathname) ? 'blue' : undefined}
                  className={clsx({ [styles.menuItemActive]: child.active && child.active(pathname) })}
                >
                  {child.label}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Tooltip label={item.label} key={`${index}_${item.label}`} position="right" withinPortal>
          <ActionIcon
            size={38}
            variant="light"
            color={item.active && item.active(pathname) ? 'blue' : 'dark'}
            className={clsx(styles.actionIcon, { [styles.actionIconActive]: item.active && item.active(pathname) })}
            onClick={item.path ? () => navigate(item.path || '/') : undefined}
          >
            {item.icon}
          </ActionIcon>
        </Tooltip>
      );
    });
  }, [navigate, pathname, user]);

  return (
    <MantineNavbar width={{ base: isNavbarOpen ? 250 : 60 }} className={styles.navbarRoot}>
      <MantineNavbar.Section
        grow
        component={ScrollArea}
        className={styles.navSection}
      >
        <Stack spacing={5}>
          {isNavbarOpen ? openNavLinks : collapsedNavLinks}
        </Stack>
      </MantineNavbar.Section>

      <MantineNavbar.Section pt={5}>
        <Divider />

        <Box mt={5}>
          {isNavbarOpen ? (
            <Button
              variant="subtle"
              color="dark"
              onClick={toggleNavbar}
              fullWidth
              leftIcon={<IconLayoutSidebarLeftCollapse size={20} color="#172b4d" />}
              h={38}
              className={styles.collapseButton}
            >
              Collapse
            </Button>
          ) : (
            <Tooltip label={'Expand'} position="right" withinPortal>
              <ActionIcon
                size={38}
                color="dark"
                onClick={toggleNavbar}
                className={styles.expandButton}
              >
                <IconLayoutSidebarLeftExpand size={20} color="#172b4d" />
              </ActionIcon>
            </Tooltip>
          )}
        </Box>
      </MantineNavbar.Section>
    </MantineNavbar>
  );
};

export default Navbar;
