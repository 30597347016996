import { SimpleGrid } from '@mantine/core';

import ParticipantsRequestsStatistics from './ParticipantsRequestsStatistics';
import UsersStatistics from './UsersStatistics';

const HomePageStatistics = () => {
  return (
    <SimpleGrid cols={2}>
      <UsersStatistics />
      <ParticipantsRequestsStatistics />
    </SimpleGrid>
  );
};

export default HomePageStatistics;
