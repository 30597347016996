export const FormatPlace = (id: number, lang: string) => {
  if (lang === 'ar') {
    switch (id) {
      case 1:
        return 'الفائز الأول';

      case 2:
        return 'الفائز الثاني';
        
      case 3:
        return 'الفائز الثالث';
      
      case 4:
        return 'الفائز الرابع';

      case 5:
        return 'الفائز الخامس';

      case 6:
        return 'الفائز السادس';

      case 7:
        return 'الفائز السابع';

      case 8:
        return 'الفائز الثامن';

      case 9:
        return 'الفائز التاسع';
    
      default:
        return id + 'الفائز';
    }
  } else {
    switch (id) {
      case 1:
        return '1st';
      
      case 2:
        return '2nd';
        
      case 3:
        return '3rd';
    
      default:
        return id + 'th';
    }
  }
};
