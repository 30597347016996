import { FC, useMemo } from 'react';

import { useCreateGeneralSecretariatItemMutation } from 'src/api/general-secretariat/hooks';
import ManageGeneralSecretariat from 'src/components/ManageGeneralSecretariat';
import { TGeneralSecretariatItemForm } from 'src/modules/general-secretariat/types';

const AddModalContent: FC = () => {
  const { mutate, isLoading } = useCreateGeneralSecretariatItemMutation();

  const defaultValues = useMemo((): TGeneralSecretariatItemForm => ({
    name_en: '',
    name_ar: '',
    position_en: '',
    position_ar: '',
    image: null
  }), []);

  const handleItemCreate = (data: TGeneralSecretariatItemForm) => {
    mutate(data);
  };

  return (
    <ManageGeneralSecretariat
      defaultValues={defaultValues}
      onSubmit={handleItemCreate}
      submitText="Create"
      isLoading={isLoading}
    />
  );
};

export default AddModalContent;
