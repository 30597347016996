import { useQuery } from '@tanstack/react-query';

import { NATIONALITIES_LIST } from 'src/api/nationalities/constants';
import { Nationality } from 'src/modules/nationalities/types';

import { getNationalities } from './api';

export const useNationalitiesQuery = () => {
  return useQuery<Nationality[]>({
    queryKey: [NATIONALITIES_LIST],
    queryFn: async () => getNationalities().then((res) => res.data.data)
  });
};
