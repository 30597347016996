import { FC } from 'react';

import { Box, Stack, Text, TypographyStylesProvider } from '@mantine/core';

import { TFAQItem } from 'src/modules/faq/types';

type Props = {
  item: TFAQItem;
};

const AccordionContent: FC<Props> = ({ item }) => (
  <Stack spacing={30}>
    <Box>
      <Text weight={600}>Question - En</Text>
      <Text size={14}>{item.question_en}</Text>
    </Box>
    <Box>
      <Text weight={600}>Question - Ar</Text>
      <Text size={14}>{item.question_ar}</Text>
    </Box>
    <Box>
      <Text weight={600}>Answer - En</Text>
      <TypographyStylesProvider>
        <Box dangerouslySetInnerHTML={{ __html: item.answer_en }} />
      </TypographyStylesProvider>
    </Box>
    <Box>
      <Text weight={600}>Answer - Ar</Text>
      <TypographyStylesProvider>
        <Box dangerouslySetInnerHTML={{ __html: item.answer_ar }} />
      </TypographyStylesProvider>
    </Box>
  </Stack>
);

export default AccordionContent;
