import { FC, useMemo } from 'react';

import { ActionIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEye, IconEyeOff, IconLock } from '@tabler/icons';

import TextInput from 'src/components/TextInput';

import { TPasswordInputProps } from './types';

const PasswordInput: FC<TPasswordInputProps> = ({
  value,
  onChange,
  error
}) => {
  const [showPassword, { toggle }] = useDisclosure(false);

  const showPasswordButton = useMemo(() => (
    <Tooltip label={`${showPassword ? 'Hide' : 'Show'} password`} withArrow arrowSize={6}>
      <ActionIcon onClick={toggle}>
        {showPassword ? (
          <IconEyeOff size={16} color="black" />
        ) : (
          <IconEye size={16} color="black" />
        )}
      </ActionIcon>
    </Tooltip>
  ), [showPassword, toggle]);

  return (
    <TextInput
      value={value}
      onChange={onChange}
      error={error}
      type={showPassword ? 'text' : 'password'}
      label="Password"
      required
      placeholder="••••••••••••"
      icon={<IconLock size={16} />}
      rightSection={showPasswordButton}
    />
  );
};

export default PasswordInput;
