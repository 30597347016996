import { AdminRoles } from '../admin/constants';
import { RequestStatus } from '../competitions/constants';

export const tableHeadings = {
  rank: 'Rank',
  image: 'Winner Image',
  fullName: 'Winner Name',
  innovationName: 'Innovation Name',
  innovationPath: 'Innovation Path',
  participatingType: 'Participating Type'
};

export enum RequestFiltrationType {
  Individual = 1,
  Group = 2
}

type VisibleRequestStatusesByRole = Partial<{
  [key in AdminRoles]: RequestStatus[];
}>;

export const visibleRequestStatusesByRole: VisibleRequestStatusesByRole = {
  [AdminRoles.OperationCommittee]: [
    RequestStatus.Approved,
    RequestStatus.Rejected,
    RequestStatus.FinalApproved,
    RequestStatus.FinalRejected
  ]
};
