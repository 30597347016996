import { Center, SimpleGrid, Loader } from '@mantine/core';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';
import AttachmentButton from 'src/components/AttachmentButton';
import ContentWithLabel from 'src/components/ContentWithLabel';

import { getFullName } from './helpers';

const PersonalInformation = () => {
  const { id } = useParams();

  const { data, isLoading } = useParticipantsRequestByIdQuery(id || '');

  if (isLoading)
    return (
      <Center py={50}>
        <Loader size="lg" />
      </Center>
    );

  if (!data) return null;

  const { member, city_id, cv, employer, participating_role_id } = data;

  return (
    <SimpleGrid cols={2} spacing="xl" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      <ContentWithLabel label="Full Name">{getFullName(member)}</ContentWithLabel>
      <ContentWithLabel label="ID/Iqama Number">{member.user_identity}</ContentWithLabel>
      <ContentWithLabel label="Nationality">{member.nationality.name}</ContentWithLabel>
      <ContentWithLabel label="Birth Date">
        {member.birthday ? dayjs(member.birthday).format('DD-MM-YYYY') : '-'}
      </ContentWithLabel>
      <ContentWithLabel label="Gender">{member.gender.name}</ContentWithLabel>
      <ContentWithLabel label="Email">{member.email}</ContentWithLabel>
      <ContentWithLabel label="Mobile Number">{member.mobile}</ContentWithLabel>
      <ContentWithLabel label="Employer">{employer}</ContentWithLabel>
      <ContentWithLabel label="City">{city_id?.name}</ContentWithLabel>
      <ContentWithLabel label="Role in the Innovation">
        {participating_role_id?.name}
      </ContentWithLabel>
      <ContentWithLabel label="CV/Resume">
        <AttachmentButton href={cv} />
      </ContentWithLabel>
    </SimpleGrid>
  );
};

export default PersonalInformation;
