import { FC } from 'react';

import { Container, Text, Box, Title, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/router/constants';

import styles from './ErrorPage.module.scss';

const ErrorPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Box className={styles.root}>
      <Container>
        <Text className={styles.label}>500</Text>
        <Title className={styles.title}>Something bad just happened...</Title>
        <Text size="lg" align="center" className={styles.description}>
          Our servers could not handle your request.
        </Text>
        <Group position="center">
          <Button size="md" onClick={() => navigate(Paths.homepage)}>
            Get back to home page
          </Button>
        </Group>
      </Container>
    </Box>
  );
};

export default ErrorPage;
