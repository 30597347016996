import { useMemo } from 'react';

import { Flex, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { useAuthStore } from 'src/store/useAuthStore';

import { tableHeadings } from './constants';
import { TInterestedUser } from 'src/api/interested-user/types';

export const useInterestedUserTableColumns = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TInterestedUser>();

    return [
      columnHelper.accessor('first_name', {
        header: tableHeadings.firstName,
        size: 100
      }),
      columnHelper.accessor('second_name', {
        header: tableHeadings.secondName,
        size: 140
      }),
      columnHelper.accessor('last_name', {
        header: tableHeadings.lastName,
        size: 130
      }),
      columnHelper.accessor('mobile_number', {
        header: tableHeadings.mobileNumber,
        enableResizing: false
      }),
      columnHelper.accessor('email', {
        header: tableHeadings.email
      }),
      columnHelper.accessor('tags', {
        header: tableHeadings.interests,
        size: 120,
        cell: (cell) => {
          return (
            <>
              {cell.row.original.tags.map((item, id) => (
                <Flex key={id} bg='#E8F3FD' style={{'borderRadius': '14px'}} my={2} px={12} py={3} direction='column'>
                  <Text color='#228BE6'>{item}</Text>
                </Flex>
              ))}
            </>
          );
        }
      }),
      columnHelper.accessor('entity', {
        header: tableHeadings.entity,
        size: 100
      }),
      columnHelper.accessor('company_name', {
        header: tableHeadings.company_name,
        size: 120,
        cell: (cell) => cell.row.original.entity_id === 2 && cell.row.original.company_name
      })
    ];
  }, [navigate, user]);
};
