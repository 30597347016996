import { FC, useCallback } from 'react';

import { Button, Center, Loader, Stack, Table, Title } from '@mantine/core';
import { openModal } from '@mantine/modals';

import { useSocialMediaAccountsList } from 'src/api/social-media-accounts/hooks';
import Paper from 'src/components/Paper';
import { TSocialMediaAccount } from 'src/modules/social-media-accounts/types';

import EditModalContent from './EditModalContent';
import styles from './styles.module.scss';

const SocialMediaAccounts: FC = () => {
  const { data, isLoading } = useSocialMediaAccountsList();

  const openEditModal = useCallback((item: TSocialMediaAccount) => openModal({
    title: <Title order={3}>Edit</Title>,
    exitTransitionDuration: 300,
    closeOnClickOutside: true,
    size: 700,
    padding: 30,
    children: <EditModalContent item={item} />
  }), []);

  return (
    <Center>
      <Paper w={700} pos="relative">
        <Stack spacing={20}>
          <Title order={2}>
            Social Media Accounts
          </Title>
          {isLoading ? (
            <Center py={50}>
              <Loader size="lg" />
            </Center>
          ) : null}
          {data ? (
            <Stack>
              <Table sx={{ tableLayout: 'fixed' }}>
                <thead>
                <tr>
                  <th style={{ width: 100 }}>Account</th>
                  <th>Account Link</th>
                  <th style={{ width: 100 }}>Hide</th>
                  <th style={{ width: 100 }}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {item.socialMedia_type.title}
                    </td>
                    <td className={styles.accountLink}>
                      {item.account_link}
                    </td>
                    <td>
                      {item.hide ? 'True' : 'False'}
                    </td>
                    <td>
                      <Button
                        variant="subtle"
                        onClick={() => openEditModal(item)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Stack>
          ) : null}
        </Stack>
      </Paper>
    </Center>
  );
};

export default SocialMediaAccounts;
