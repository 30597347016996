import { FC, useRef, useState } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useArbitratingRequestQuery } from 'src/api/arbitrating-requests/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';

import { getArbitratingApprovalForExport } from './utils';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';
import { useArbitrationRequestTableColumns } from 'src/modules/arbitration-request/hooks';
import ArbitratingStatusTabs from './ArbitratingStatusTabs';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';

const ArbitratingRequest: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();

  const { data: ArbitratingRequest, isLoading: ArbitrationRequestLoading } = useArbitratingRequestQuery();

  const columns = useArbitrationRequestTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const ArbitratingRequestExport = getArbitratingApprovalForExport(ArbitratingRequest?.data);

  const [activeTab, setActiveTab] = useState<'Not Done' | 'Done'>('Not Done');

  const handleTabChange = (tab: 'Not Done' | 'Done') => {
    if (tab) setActiveTab(tab);
  };

  const filterByRequestStatus = activeTab === 'Done'
    ? ( item: TParticipantsRequest) => item?.status_id === 1
    : ( item: TParticipantsRequest) => item?.status_id === 0;

  const activeTabData = !ArbitrationRequestLoading && ArbitratingRequest?.data.filter(filterByRequestStatus);

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Arbitrating Request</Title>
        <ExportButton filename="Arbitrating Approval" data={ArbitratingRequestExport} />
      </Flex>
      {ArbitrationRequestLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      <DataTable
        columns={columns}
        data={activeTabData || []}
        filters={filters}
        tableInstanceRef={tableInstanceRef}
        extraSearchElement={<ArbitratingStatusTabs handleTabChange={handleTabChange} />}
      />
    </Flex>
  );
};

export default ArbitratingRequest;
