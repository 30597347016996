import { useMemo } from 'react';

import { ActionIcon, Grid, Tooltip, Image, Flex, Text } from '@mantine/core';
import { IconChevronDown, IconTrash } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';

import { TInnovation } from 'src/api/innovations/types';
import { useCompetitionPathsListQuery } from 'src/api/competition-paths/hooks';
import logo from 'src/assets/svg/logo-colored.svg';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import { TTableFilters } from 'src/components/TableFilters';
import { useAuthStore } from 'src/store/useAuthStore';
import { useLayoutStore } from 'src/store/useLayoutStore';

import { tableHeadings } from './constants';
import { TInnovationItem } from './types';
import { openInnovationDeleteModal } from 'src/providers/ModalsProvider/helpers';

export const useInnovationsTableColumns = () => {
  const { user } = useAuthStore();
  const { openedInovationItem } = useLayoutStore();

  const handleOpen = (itemID: string) => {
    useLayoutStore.setState((state) => {
      if (openedInovationItem === itemID) {
        return { ...state, openedInovationItem: '' };
      }

      return { ...state, openedInovationItem: itemID };
    });
  };

  return useMemo(() => {
    const columnHelper = createColumnHelper<TInnovationItem>();

    return [
      columnHelper.accessor('request_number', {
        header: tableHeadings.requestNumber,
        maxSize: 115,
        size: 115,
        minSize: 115,
        enableSorting: false,
        enableResizing: false,
        cell: (cell) => cell.row.original.request_number
      }),

      columnHelper.display({
        header: ' ',
        size: 50,
        minSize: 50,
        enableResizing: false,
        enableSorting: false,
        cell: (cell) => (
          <Grid columns={2}>
            <Grid.Col span={1}>
              <Tooltip label="Details" withArrow arrowSize={6}>
                <ActionIcon size="sm" onClick={() => handleOpen(cell.row.original.id)}>
                  <IconChevronDown size={20} style={{transform: openedInovationItem === cell.row.original.id ? 'rotate(180deg)' : 'rotate(0deg)'}} />
                </ActionIcon>
              </Tooltip>
            </Grid.Col>
          </Grid>
        )
      }),

      columnHelper.accessor('image', {
        header: tableHeadings.image,
        size: 90,
        minSize: 90,
        enableSorting: false,
        enableResizing: false,
        cell: (cell) => {
          return (
            <Flex justify="center">
              <Image src={cell.row.original.image || logo} h={60} maw={60} />
            </Flex>
          );
        }
      }),

      columnHelper.accessor('date', {
        header: tableHeadings.date,
        size: 120,
        minSize: 120,
        enableSorting: false,
        enableResizing: false,
        cell: (cell) => cell.row.original.date
      }),

      columnHelper.accessor('innovation_title', {
        header: tableHeadings.innovationName,
        minSize: 160,
        cell: (cell) => {
          return (
            <Text dir='rtl' ta='left'>{cell.row.original.innovation_title}</Text>
          )
        }
      }),

      columnHelper.accessor('innovation_path', {
        header: tableHeadings.innovationPath,
        size: 180
      }),

      columnHelper.accessor('participants_names', {
        header: tableHeadings.participantsName,
        size: 200,
        cell: (cell) => {
          return [cell.row.original.main_participant_name, ...cell.row.original.participants_names]?.map((item, id) => (
            <p key={id}>{`${id + 1}. ${item}`}</p>
          ))
        }
      }),

      columnHelper.accessor('berief_description', {
        header: tableHeadings.innovationDescription,
        size: 175,
        enableResizing: false,
        cell: (cell) => cell.row.original.berief_description || '',
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Grid>
              <Grid.Col span={1}>
                <Tooltip label="Delete" withArrow arrowSize={6}>
                  <ActionIcon
                    size="sm"
                    onClick={() => openInnovationDeleteModal(cell.row.original.id)}
                  >
                    <IconTrash size={20} />
                  </ActionIcon>
                </Tooltip>
              </Grid.Col>
            </Grid>
          );
        }
      })
    ];
  }, [openedInovationItem, user]);
};

export const useInnovationsTableFilters = () => {
  const { data: competitionPathsList } = useCompetitionPathsListQuery();

  const normalizedInnovationPathList = useNormalizedItemsData(
    competitionPathsList?.map((p) => ({ id: p.title_en, name: p.title_en }))
  );

  return useMemo((): TTableFilters<TInnovation> => [
    {
      id: 'innovation_path',
      title: tableHeadings.innovationPath,
      type: 'select',
      data: normalizedInnovationPathList,
      defaultValue: null
    }
  ], [normalizedInnovationPathList]);
};
