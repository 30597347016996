import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TTermsAndConditions } from 'src/modules/terms-and-conditions/types';


import { getTermsAndConditions, updateTermsAndConditions } from './api';
import { TERMS_AND_CONDITIONS_QUERY_KEY } from './constants';

export const useTermsAndConditionsQuery = () => {
  return useQuery<TTermsAndConditions>({
    queryKey: [TERMS_AND_CONDITIONS_QUERY_KEY],
    queryFn: getTermsAndConditions
  });
};

export const useUpdateTermsAndConditionsMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TTermsAndConditions>({
    mutationFn: updateTermsAndConditions,
    onSuccess: async () => {
      await queryClient.invalidateQueries([TERMS_AND_CONDITIONS_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
