import { Flex, Group, Image, Text } from '@mantine/core';
import styles from './AssignAdminUserModal.module.scss';
import { Dispatch, SetStateAction, useState } from 'react';
import SearchInput from '../SearchInput';
import { AdminUser } from 'src/modules/admin/types';
import logo from 'src/assets/svg/logo-colored.svg';
import { useParams } from 'react-router-dom';
import { useArbitratingAssignQuery, useGetAllArbitratingUsersQuery } from 'src/api/arbitrating-requests/hooks';
import { TJudgmentUser, TParticipantsRequest } from 'src/modules/participants-requests/types';
import { useAdminListQuery } from 'src/api/admin/hooks';

interface TProps {
  setOpenedModal: Dispatch<SetStateAction<boolean>>;
  handleRequest?: () => void;
  handleSelectUser: (user: AdminUser) => void;
  selectedUser: AdminUser[];
  top?: boolean;
};

const AssignAdminUserModal = ({
  setOpenedModal,
  handleRequest,
  handleSelectUser,
  selectedUser,
  top = false 
}: TProps) => {
  const { id } = useParams<{ id: string }>();
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: ArbitratingAssign, isLoading: ArbitratingLoading } = useArbitratingAssignQuery();
  const { data: ArbitratingUsers, isLoading: ArbitratingUsersLoading } = useGetAllArbitratingUsersQuery();
  const {data: AdminList, isLoading: AdminListLoading} = useAdminListQuery();

  const ArbitratingAssignedUser = !ArbitratingLoading ? ArbitratingAssign?.data.find((item: TParticipantsRequest) => item.id === id)?.judgment_data : [];

  const usersList = top ? (!ArbitratingUsersLoading || !ArbitratingLoading) && ArbitratingAssignedUser && ArbitratingUsers &&
    ArbitratingUsers?.data?.filter((item: AdminUser) => !ArbitratingAssignedUser?.some(
      (users: TJudgmentUser) => users.fullName === item.fullName && users.email === item.email
    )) : ArbitratingUsers?.data;

  const SuperAdminOnly = !AdminListLoading && AdminList?.data && AdminList?.data.filter((user) => user.userRole.id === 1);


  const filteredUsersList = (SuperAdminOnly && usersList && [...SuperAdminOnly, ...usersList] || [])?.filter(
    (user: AdminUser) => user.fullName.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className={styles.modalOver} onClick={() => setOpenedModal(false)} />
      <Flex className={`${styles.modal} ${top && styles.modalTop}`} mah={400} mih={400} w={470} bg='white' direction='column'>
        <Group className={styles.modalHeader}>
          <Flex justify='space-between' align='center' className={styles.titleWrapper}>
            <h3 className={styles.title}>Assign to <span>{selectedUser.length}</span></h3>
            {handleRequest && (<p onClick={handleRequest}>Done</p>)}
          </Flex>

          <SearchInput
            onDebouncedChange={(value) => setSearchValue(value)}
            placeholder='Search Name' 
          />
        </Group>


        <Flex gap={15} wrap='wrap' className={styles.userList}>
          {usersList && filteredUsersList?.map((user: AdminUser) => (
            <Flex
              key={user.id}
              className={`
                ${styles.userBlock}
                ${selectedUser.find((selected) => selected.id === user.id) && styles.selected}
              `}
              gap={8}
              align='center'
              onClick={() => handleSelectUser(user)}
            >
              <Image className={styles.userImg} src={logo} />
              <Text className={styles.userName}>{user.fullName}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default AssignAdminUserModal;
