import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TVideoItemForm, TVideosList } from 'src/modules/videos/types';

import { TVideoItemUpdateRequest } from './types';

export const getVideosList = async (): Promise<TVideosList> => {
  return privateApi.get('/admin-content/videos').then((res) => res.data.data);
};

export const getVideoById = async (id: string): Promise<TVideosList> => {
  return privateApi.get('/admin-content/videos', {
    params: { id }
  }).then((res) => res.data.data);
};

export const createVideoItem = async (data: TVideoItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/video', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateVideoItem = async (data: TVideoItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/video', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteVideoItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/video', {
    params: { id }
  }).then((res) => res.data);
};
