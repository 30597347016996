import { SelectItem } from '@mantine/core';

export const statusSelectData: SelectItem[] = [
  {
    value: '3',
    label: 'Missing Requirements'
  },
  {
    value: '8',
    label: 'Final Approved'
  },
  {
    value: '9',
    label: 'Final Rejected'
  }
];
