import { AxiosResponse } from 'axios';

import privateApi from 'src/common/configs/private-api';
import publicApi from 'src/common/configs/public-api';
import { SuccessResponse } from 'src/common/types/api';
import { AdminUser } from 'src/modules/admin/types';

import {
  AdminLoginRequest,
  AdminLoginResponse,
  AdminLoginVerifyRequest,
  AdminLoginVerifyResponse,
  GetAdminsListResponse,
  TAdminAddNewRequest,
  TAdminRolesResponse,
  TAdminVerifyRequest,
  TAdminResendRequest,
  UpdateAdminStatusRequest,
  UpdateAdminStatusResponse
} from './types';

export const adminLogin = async (
  data: AdminLoginRequest
): Promise<AxiosResponse<AdminLoginResponse>> => {
  return publicApi.post('/admin/login', data);
};

export const adminLoginVerify = async (
  data: AdminLoginVerifyRequest
): Promise<AxiosResponse<AdminLoginVerifyResponse>> => {
  return publicApi.post('/admin/login/verify', data);
};

export const getAdminProfile = async (): Promise<AxiosResponse<AdminUser>> => {
  return privateApi.get('/admin');
};

export const adminLogout = async (): Promise<AxiosResponse<SuccessResponse>> => {
  return privateApi.post('/admin/logout');
};

export const getAdminList = async (): Promise<AxiosResponse<GetAdminsListResponse>> => {
  return privateApi.get('/admin/get-all', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  });
};

export const updateAdminStatus = async (data: UpdateAdminStatusRequest): Promise<UpdateAdminStatusResponse> => {
  return privateApi.put('/admin/activate-status', data).then((res) => res.data);
};

export const addNewAdmin = async (data: TAdminAddNewRequest): Promise<SuccessResponse> => {
  return privateApi.post('/admin', data);
};

export const adminVerify = async (data: TAdminVerifyRequest): Promise<SuccessResponse> => {
  return privateApi.post('/admin/verify', data);
};

export const getAdminRoles = async (): Promise<TAdminRolesResponse> => {
  return privateApi.get('/admin/role').then((res) => res.data);
};

export const resendVerification = async (data: TAdminResendRequest): Promise<SuccessResponse> => {
  return privateApi.post('/admin/register/resend-verification', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const deleteAdminUser = (user_id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/admin/DeleteAdmin', {
    params: {
      user_id: user_id
    }
  });
};
