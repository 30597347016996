import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { generatePath, useNavigate } from 'react-router-dom';

import TableActionsCell from 'src/components/TableActionsCell';
import { TTableFilters } from 'src/components/TableFilters';
import { Paths } from 'src/router/constants';

import { competitionsTableHeadings as headings } from './constants';
import { TCompetitionItem } from './types';

export const useCompetitionsTableColumns = ({
  onDeleteClick
}: {
  onDeleteClick: (id: string) => void;
}) => {
  const navigate = useNavigate();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TCompetitionItem>();

    return [
      columnHelper.accessor('title_en', {
        header: headings.titleEn
      }),

      columnHelper.accessor('title_ar', {
        header: headings.titleAr
      }),

      columnHelper.accessor('description_en', {
        header: headings.descriptionEn,
        minSize: 170
      }),

      columnHelper.accessor('description_ar', {
        header: headings.descriptionAr,
        minSize: 170
      }),

      columnHelper.accessor('start_date', {
        header: headings.startDate,
        cell: (cell) => dayjs(cell.getValue()).format('DD-MM-YYYY'),
        minSize: 120,
        size: 120,
        enableResizing: false
      }),

      columnHelper.accessor('end_date', {
        header: headings.endDate,
        cell: (cell) => dayjs(cell.getValue()).format('DD-MM-YYYY'),
        minSize: 120,
        size: 120,
        enableResizing: false
      }),

      columnHelper.accessor('location_en', {
        header: headings.locationEn
      }),

      columnHelper.accessor('location_ar', {
        header: headings.locationAr
      }),

      columnHelper.accessor('qualification_en', {
        header: headings.qualificationEn,
        minSize: 170
      }),

      columnHelper.accessor('qualification_ar', {
        header: headings.qualificationAr,
        minSize: 170
      }),

      columnHelper.accessor('isActive', {
        header: headings.isActive,
        size: 120,
        minSize: 120,
        enableResizing: false
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => <TableActionsCell
          actions={['edit', 'delete']}
          onEditClick={() => navigate(generatePath(Paths.competitionsItemEdit, { id: row.original.id }))}
          onDeleteClick={() => onDeleteClick(row.original.id)}
        />
      })
    ];
  }, [navigate, onDeleteClick]);
};

export const useCompetitionsTableFilters = () => {
  return useMemo((): TTableFilters<TCompetitionItem> => [
    {
      id: 'title_en',
      title: headings.titleEn,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'title_ar',
      title: headings.titleAr,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'description_en',
      title: headings.descriptionEn,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'description_ar',
      title: headings.descriptionAr,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'start_date',
      title: headings.startDate,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'end_date',
      title: headings.endDate,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'location_en',
      title: headings.locationEn,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'location_ar',
      title: headings.locationAr,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'qualification_en',
      title: headings.qualificationEn,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'qualification_ar',
      title: headings.qualificationAr,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'isActive',
      title: headings.isActive,
      type: 'input',
      defaultValue: ''
    }
  ], []);
};
