import { FC } from 'react';

import { Controller } from 'react-hook-form';

import FormSection from 'src/components/FormSection';
import Textarea from 'src/components/Textarea';
import TextInput from 'src/components/TextInput';

import { TContentAboutPrizeSectionProps } from '../types';

const Vision: FC<TContentAboutPrizeSectionProps> = ({
  control,
  disabled
}) => (
  <FormSection title="Vision">
    <Controller
      control={control}
      name="vision.title_en"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <TextInput
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Title - En"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="vision.title_ar"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <TextInput
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Title - Ar"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="vision.text_en"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <Textarea
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Text - En"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="vision.text_ar"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <Textarea
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Text - Ar"
          disabled={disabled}
          required
        />
      )}
    />
  </FormSection>
);

export default Vision;
