import { TCompetitionItemForm } from 'src/modules/competitions/types';

export const competitionFormDefaultValues: TCompetitionItemForm = {
  title_en: '',
  title_ar: '',
  description_en: '',
  description_ar: '',
  start_date: null,
  end_date: null,
  location_en: '',
  location_ar: '',
  image: null,
  qualification_en: '',
  qualification_ar: '',
  isActive: null,
  competition_path_ids: []
};

export const competitionsTableHeadings = {
  titleEn: 'Title - En',
  titleAr: 'Title - Ar',
  descriptionEn: 'Description - En',
  descriptionAr: 'Description - Ar',
  startDate: 'Start Date',
  endDate: 'End Date',
  locationEn: 'Location - En',
  locationAr: 'Location - Ar',
  imagePath: 'Image',
  qualificationEn: 'Qualification - En',
  qualificationAr: 'Qualification - Ar',
  isActive: 'Is Active'
};

export enum RequestStatus {
  New = 1,
  Submitted = 2,
  MissingRequirements = 3,
  Approved = 4,
  Rejected = 5,
  Edited = 6,
  UnderReview = 7,
  FinalApproved = 8,
  FinalRejected = 9,
  NotUpdated = 10,
  UnderEvaluation = 11,
  Evaluated = 12,
  EvaluatedApproved = 13,
  QualifiedForInterview = 14,
  Interviewed = 15,
  Winner = 16,
  NotWinner = 17
}

export enum RequestEvaluationStage {
  New = 1,
  Filtering = 2,
  Authorizing = 3,
  Approval = 4,
  Arbitration = 5
}
