import privateApi from 'src/common/configs/private-api';

import { GetInterestedUserListResponse } from './types';

export const getInterestedUserList = async (): Promise<GetInterestedUserListResponse> => {
  return privateApi.get('/interested-user/get-all', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  }).then((res) => res.data);
};
