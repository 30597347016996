import { FC } from 'react';

import { LoadingOverlay, Flex, Stack, Button } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useDeleteAssignAribtratingUserMutation } from 'src/api/arbitrating-requests/hooks';
import { TJudgmentUser } from 'src/modules/participants-requests/types';

const ArbitratingAssignModal: FC<ContextModalProps<{ user: TJudgmentUser }>> = ({
  context,
  id,
  innerProps: { user }
}) => {
  const { mutate: deleteUser, isLoading } = useDeleteAssignAribtratingUserMutation();

  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleDelete = () => {
    deleteUser(user.id);
    context.closeModal(id);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Stack>
        <Flex justify="center" gap={16}>
          <Button variant="outline" onClick={handleCancel}>
            No
          </Button>
          <Button onClick={handleDelete}>
            Yes
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default ArbitratingAssignModal;
