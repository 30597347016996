import { FC } from 'react';

import { Box } from '@mantine/core';

import styles from './TableCell.module.scss';
import { TTableCellProps } from './types';

const TableCell: FC<TTableCellProps> = ({
  children,
  cell
}) => (
  <Box
    className={styles.root}
    sx={{ width: cell.column.getSize() }}
  >
    <Box
      className={styles.content}
    >
      {children}
    </Box>
  </Box>
);

export default TableCell;
