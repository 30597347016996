import { FC, useEffect, useState } from 'react';

import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons';

import TextInput from 'src/components/TextInput';

import { TSearchInputProps } from './types';

const SearchInput: FC<TSearchInputProps> = ({
  onDebouncedChange,
  placeholder
}) => {
  const [value, setValue] = useState('');
  const [debounced] = useDebouncedValue(value, 500);

  useEffect(() => {
    onDebouncedChange(debounced);
  }, [debounced, onDebouncedChange]);

  return (
    <TextInput
      icon={<IconSearch size={16} />}
      value={value}
      placeholder={placeholder}
      onChange={(event) => setValue(event.target.value)}
    />
  );
};

export default SearchInput;
