import { useMemo } from 'react';

import { ActionIcon, Grid, Tooltip, Image, Flex } from '@mantine/core';
import { IconChecklist } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { useCompetitionPathsListQuery } from 'src/api/competition-paths/hooks';
import logo from 'src/assets/svg/logo-colored.svg';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import { TTableFilters } from 'src/components/TableFilters';
import { Paths } from 'src/router/constants';

import { tableHeadings } from './constants';
import { TParticipantsRequest } from '../participants-requests/types';
import { TWinnerItem } from '../arbitration-winners/types';

export const use3TopWinnersTableColumns = () => {
  const navigate = useNavigate();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TWinnerItem>();

    return [
      columnHelper.display({
        header: tableHeadings.rank,
        enableResizing: false,
        maxSize: 110,
        cell: (cell) => cell.row.original.rank_In_Word
      }),

      columnHelper.accessor('winner_image', {
        header: tableHeadings.image,
        minSize: 125,
        size: 125,
        enableSorting: false,
        enableResizing: false,
        cell: (cell) => {
          return (
            <Flex justify="center">
              <Image src={cell.row.original.winner_image || logo} h={60} maw={60} />
            </Flex>
          );
        }
      }),

      columnHelper.display({
        header: tableHeadings.fullName,
        minSize: 220,
        cell: (cell) => {
          const item = cell.row.original;

          return `${item.winner_first_name} ${item.winner_second_name} ${item.winner_last_name}`
        }
      }),

      columnHelper.accessor('innovation_name', {
        header: tableHeadings.innovationName,
        minSize: 160
      }),

      columnHelper.accessor('innovation_path', {
        header: tableHeadings.innovationPath,
        size: 200
      }),

      columnHelper.accessor('participatingType', {
        header: tableHeadings.participatingType,
        size: 175,
        enableResizing: false,
        cell: (cell) => cell.row.original.participatingType || '',
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Grid columns={2}>
              <Grid.Col span={1}>
                <Tooltip label="Details" withArrow arrowSize={6}>
                  <ActionIcon
                    size="sm"
                    onClick={() => {
                      navigate(
                        generatePath(Paths.topWinnersItemDetail, {
                          id: row.original.participatingRequestId
                        })
                      );
                    }}
                  >
                    <IconChecklist size={20} />
                  </ActionIcon>
                </Tooltip>
              </Grid.Col>
            </Grid>
          );
        }
      })
    ];
  }, [navigate]);
};

export const use3TopWinnersTableFilters = () => {
  const { data: competitionPathsList } = useCompetitionPathsListQuery();

  const normalizedInnovationPathList = useNormalizedItemsData(
    competitionPathsList?.map((p) => ({ id: p.title_en, name: p.title_en }))
  );

  return useMemo((): TTableFilters<TParticipantsRequest> => [
    {
      id: 'path',
      title: tableHeadings.innovationPath,
      type: 'select',
      data: normalizedInnovationPathList,
      defaultValue: null
    },
    {
      id: 'participating_type',
      title: tableHeadings.participatingType,
      type: 'input',
      defaultValue: ''
    }  
  ], [normalizedInnovationPathList]);
};
