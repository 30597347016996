import { FC } from 'react';

import { ActionIcon, Box, Flex, TextInput } from '@mantine/core';
import { IconX } from '@tabler/icons';
import ReactPlayer from 'react-player';

import { TVideoUploadProps } from './types';
import styles from './VideoUpload.module.scss';

const VideoUpload: FC<TVideoUploadProps> = ({ value, onChange }) => {
  const clear = () => {
    onChange('');
  };

  return (
    <Box>
      <TextInput value={value} onChange={onChange} label="YouTube link" required />
      {value && (
        <Flex justify="center" className={styles.previewContainer}>
          <ActionIcon
            color="red"
            radius="xl"
            size="sm"
            variant="filled"
            className={styles.deleteIcon}
            onClick={clear}
          >
            <IconX size={16} />
          </ActionIcon>
          <ReactPlayer url={value} width="100%" height={300} volume={0.5} controls />
        </Flex>
      )}
    </Box>
  );
};

export default VideoUpload;
