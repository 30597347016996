import { FC, useRef, useState } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useParticipantsRequestsQuery } from 'src/api/participants-requests/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { RequestStatus } from 'src/modules/competitions/constants';
import {
  useParticipantsRequestsTableColumns,
  useParticipantsRequestsTableFilters
} from 'src/modules/participants-requests/hooks';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';

import { useParticipantsRequestsFilter } from './hooks';
import { EvaluationStageFilter } from './ParticipantRequestFilter';
import RequestStatusTabs from './RequestStatusTabs';
import { getParticipantRequestsForExport } from './utils';

const ParticipantsRequests: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();

  const { data: participantsRequests, isLoading } = useParticipantsRequestsQuery();

  const columns = useParticipantsRequestsTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const [activeTab, setActiveTab] = useState<'new' | 'rest'>('new');

  const handleTabChange = (tab: 'new' | 'rest') => {
    if (tab) setActiveTab(tab);
  };

  const participantsRequestsExport = getParticipantRequestsForExport(participantsRequests?.data);

  const requestsFilteredByUserRole = useParticipantsRequestsFilter(participantsRequests?.data);

  const requests = requestsFilteredByUserRole?.reduce(
    (acc: { newRequests: TParticipantsRequest[]; rest: TParticipantsRequest[] }, request) => {
      if (request.status?.id === RequestStatus.New) {
        acc.newRequests.push(request);
      } else {
        acc.rest.push(request);
      }

      return acc;
    },

    { newRequests: [], rest: [] }
  );

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Participants Requests</Title>
        {activeTab !== 'new' && <EvaluationStageFilter tableInstanceRef={tableInstanceRef} />}
        <ExportButton filename="Participants Requests" data={participantsRequestsExport} />
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {requestsFilteredByUserRole ? (
        <DataTable
          columns={columns}
          data={(activeTab === 'new' ? requests?.newRequests : requests?.rest) || []}
          filters={filters}
          extraSearchElement={<RequestStatusTabs handleTabChange={handleTabChange} />}
          tableInstanceRef={tableInstanceRef}
        />
      ) : null}
    </Flex>
  );
};

export default ParticipantsRequests;
