import { useRef } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useInterestedUserListQuery } from 'src/api/interested-user/hooks';
import DataTable from 'src/components/DataTable';
import { useInterestedUserTableColumns } from 'src/modules/interested-user/hooks';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';


const InterestedUser = () => {
  const tableInstanceRef = useRef<Table<any>>();

  const { data, isLoading } = useInterestedUserListQuery();

  const columns = useInterestedUserTableColumns();
  const filters = useParticipantsRequestsTableFilters();
  
  return (
    <Flex direction='column' h='100%'>
      <Flex align='center' justify='space-between' pb={30}>
        <Title order={2}>Interested Users</Title>
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size='lg' />
        </Center>
      )}
      <DataTable
        columns={columns}
        data={data?.data ? data?.data : []}
        filters={filters}
        tableInstanceRef={tableInstanceRef}
      />
    </Flex>
  );
};

export default InterestedUser;
