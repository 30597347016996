import { FC, ReactNode } from 'react';

import { createEmotionCache, MantineProvider as Provider } from '@mantine/core';

type Props = {
  children: ReactNode;
};

export const cache = createEmotionCache({
  key: 'iami-cp',
  prepend: true
});

const MantineProvider: FC<Props> = ({ children }) => (
  <Provider
    emotionCache={cache}
    theme={{
      fontFamily: 'Inter, sans-serif',
      fontFamilyMonospace: 'Monaco, Courier, monospace',
      headings: { fontFamily: 'Inter, sans-serif' }
    }}
  >
    {children}
  </Provider>
);

export default MantineProvider;
