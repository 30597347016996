import { Button, Checkbox } from '@mantine/core';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import { TParticipantCheckingInfo } from 'src/modules/participants-requests/types';

type Props = {
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
  labels: string[];
  checkingInfo?: TParticipantCheckingInfo;
  setCheckingInfo?: React.Dispatch<React.SetStateAction<any>>;
};

const CustomStepper = ({ active, setActive, labels, checkingInfo, setCheckingInfo }: Props) => {

  useEffect(() => {
    if (checkingInfo && setCheckingInfo) {
      setCheckingInfo({ ...checkingInfo, [labels[active]]: true })
    }
  }, [active])

  return (
    <Button.Group>
      {labels.map((label, index) => (
        <Button
          variant={index === active ? 'filled' : 'default'}
          onClick={() => {
            setActive(index);
          }}
          key={label}
        >
          {label}
          {checkingInfo && (
            <Checkbox checked={checkingInfo[label] === true ? true : false} onChange={() => {}} className={styles.checkbox}></Checkbox>
          )}
        </Button>
      ))}
    </Button.Group>
  );
};

export default CustomStepper;
