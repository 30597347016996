import { FC } from 'react';

import { Button, Flex, LoadingOverlay, Stack, Text } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { useParams } from 'react-router-dom';

import { useUpdateParticipantApprovalStatusMutation } from 'src/api/participants-requests/hooks';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { TParticipantsApprovalData } from 'src/modules/participants-requests/types';


type Props = {
  requestId: string;
  participantInfo: TParticipantsApprovalData;
};

const ResetApprovalModalContent: FC<Props> = ({
  requestId,
  participantInfo
}) => {
  const { id } = useParams<{ id: string }>();

  const {
    mutate: updateApprovalStatus,
    isLoading: isUpdateApprovalStatusLoading
  } = useUpdateParticipantApprovalStatusMutation(id || '');

  const handleReset = () => {
    updateApprovalStatus({
      request_id: requestId,
      email: participantInfo.email || '',
      isAprroved: null,
      approve_file: null
    }, {
      onSuccess: () => {
        showSuccessNotification({ message: 'Reset successfully' });
      }
    });
  };

  return (
    <>
      <LoadingOverlay visible={isUpdateApprovalStatusLoading} />

      <Stack spacing={30}>
        <Text align="center">
          Are you sure you want reset approval status for {participantInfo.email}
        </Text>
        <Flex justify="space-between">
          <Button variant="outline" onClick={() => closeAllModals()}>
            Cancel
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default ResetApprovalModalContent;
