import { ChangeEvent, useMemo } from 'react';

import { ActionIcon, Flex, Grid, Tooltip } from '@mantine/core';
import { IconChecklist } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { STATUS_COLORS } from 'src/common/constants/defConstant';
import AssignUser from 'src/components/AssignUser';
import { RequestStatus } from 'src/modules/competitions/constants';
import { Paths } from 'src/router/constants';
import { useLayoutStore } from 'src/store/useLayoutStore';

import { tableHeadings } from './constants';
import { TJudgmentUser, TParticipantsRequest } from '../participants-requests/types';

export const useArbitrationAssignTableColumns = () => {
  const navigate = useNavigate();
  const { selectedItems } = useLayoutStore();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, requestNumber: string) => {
    const isChecked = event.target.checked;
    useLayoutStore.setState((state) => {
      if (isChecked) {
        return {
          selectedItems: [...state.selectedItems, requestNumber]
        };
      };

      return {
        selectedItems: state.selectedItems.filter((item) => item !== requestNumber)
      };
    });
  };

  return useMemo(() => {
    const columnHelper = createColumnHelper<TParticipantsRequest>();

    return [
      columnHelper.accessor('selected', {
        header: '',
        size: 50,
        minSize: 50,
        enableResizing: false,
        enableSorting: false,
        cell: (cell) => {
          const state = useLayoutStore.getState();

          return (
            <input
              type='checkbox'
              style={{cursor: 'pointer'}}
              onChange={(e) => handleCheckboxChange(e, cell.row.original.id)}
              checked={!!state.selectedItems.find((item) => item === cell.row.original.id) || false}
            />
          );
        }
      }),

      columnHelper.accessor('request_number', {
        header: tableHeadings.requestNumber,
        size: 155,
        enableResizing: false
      }),

      columnHelper.accessor('member.email', {
        header: tableHeadings.email,
        minSize: 160
      }),

      columnHelper.accessor('innovation_name', {
        header: tableHeadings.innovationName,
        minSize: 160
      }),

      columnHelper.accessor('path', {
        header: tableHeadings.innovationPath,
        size: 160
      }),

      columnHelper.accessor('participating_type', {
        header: tableHeadings.participatingType,
        size: 175,
        enableResizing: false,
        cell: (cell) => cell.getValue()?.name || '',
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.participating_type?.name || '';
          const valueB = rowB.original.participating_type?.name || '';

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      }),

      columnHelper.accessor('status', {
        header: tableHeadings.status,
        size: 80,
        cell: (cell) => {
          const key = cell.row.original.status?.id as RequestStatus;

          return <span style={{color: STATUS_COLORS[key]}}>{cell.getValue()?.name}</span>;
        },
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.status?.name;
          const valueB = rowB.original.status?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, columnId, filterValue) => {
          return +filterValue === row.original.status?.id;
        }
      }),

      columnHelper.accessor('evaluation_stage', {
        header: tableHeadings.evaluationStatus,
        size: 70,
        cell: (cell) => cell.getValue()?.name,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.evaluation_stage?.name;
          const valueB = rowB.original.evaluation_stage?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, _columnId, filterValue) => {
          return +filterValue === row.original.evaluation_stage?.id;
        }
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => (
            <Grid columns={2}>
              <Grid.Col span={1}>
                <Tooltip label="Evaluation" withArrow arrowSize={6}>
                  <ActionIcon
                    size="sm"
                    onClick={() => {
                      navigate(
                        generatePath(Paths.arbitratingAssignItemEvaluation, {
                          id: row.original.id
                        })
                      );
                    }}
                  >
                    <IconChecklist size={20} />
                  </ActionIcon>
                </Tooltip>
              </Grid.Col>
            </Grid>
          )
        }
      ),
      columnHelper.accessor('Assign', {
        header: 'Assign',
        size: 160,
        minSize: 160,
        enableResizing: true,
        enableSorting: false,
        cell: (cell) => {
          const JudgmentUsers = cell.row.original?.judgment_data;

          return (
            <Flex direction="column" gap={5}>
              {JudgmentUsers ? JudgmentUsers.map((user: TJudgmentUser) => (
                <AssignUser userName={user.fullName} key={user.id} />
              )) : '-'}
            </Flex>
          );
        }
      })
    ];
  }, [navigate]);
};
