import { useEffect, useState } from 'react';

import { Paper, Flex, Stack, Center, Button } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useArbitratingRequestQuery, useAssignAribtratingRequestPercentageQuery } from 'src/api/arbitrating-requests/hooks';
import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import BackPageButton from 'src/components/BackPageButton';
import GeneratePDF from 'src/components/GeneratePDF';

import ArbitratingRequestControls from './ArbitratingRequestControls';
import CustomStepper from '../ParticipantsRequestsItemView/CustomStepper';
import ArbitratingRequestsItemView from './ArbitratingRequestsItemView';
import { TArbitratingCollectedData, TModelComment } from 'src/api/arbitrating-requests/types';
import CommentHistoryTable from './commentHistoryTable';
import PercentageTable from './PercentageTable';
import { STEPS } from 'src/common/constants/defConstant';
import { useAddToInnovation } from 'src/api/innovations/hooks';
import { useAuthStore } from 'src/store/useAuthStore';
import { AdminRoles } from 'src/modules/admin/constants';

const FiltrationRequestItemViewAndEvaluation = () => {
  const [active, setActive] = useState(0);
  const [resetValue, setResetValue] = useState(false);
  const [model, setModel] = useState<TModelComment[]>([]);
  const [sum, setSum] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();

  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const { data: ArbitratingRequest, isLoading: ArbitrationRequestLoading } = useArbitratingRequestQuery();
  const { mutate: addInnovation } = useAddToInnovation();

  const commentId = !ArbitrationRequestLoading && ArbitratingRequest?.data.find((el) => el.id === id);

  const { data: arbitrationCriteria, isLoading: isarbitrationCriteriaLoading } = useArbitrationCriteriaListQuery();

  const [tableValues, setTableValues] = useState<TArbitratingCollectedData[]>([]);
  const [comments, setComments] = useState<TArbitratingCollectedData[]>([]);

  const maxPercentagesCoefficient = !isarbitrationCriteriaLoading &&
    arbitrationCriteria?.reduce((accumulator: number, item) => {
      return accumulator + item.percentage;
    }, 0);

  useEffect(() => {
    const result = tableValues?.reduce((accumulator: number, currentObj: TArbitratingCollectedData) => {
      const valuesArray = Object.values(currentObj)?.map(Number);
      return accumulator + valuesArray[0];
    }, 0);

    setSum(result);
  }, [tableValues, resetValue]);

  const totalPercentages = maxPercentagesCoefficient ? +sum * (100 / maxPercentagesCoefficient) : 1;
  const isComment = comments?.map((item, id) => {return {[id]: !!(+Object.keys(comments[id])[0] === id && item[id])}});

  const [checkingInfo, setCheckingInfo] = useState({
    'Personal Information': false,
    'Participant Data': false,
    'Innovation Data': false,
    'Impact of Innovation': false
  });

  const isChecingInfo = () => {
    return Object.values(checkingInfo).every((el) => el === true) &&
      tableValues?.length === arbitrationCriteria?.length &&
      comments?.length === arbitrationCriteria?.length &&
      isComment.filter((item, id) => item[id] === false).length === 0;
  };

  useEffect(() => {
    if (isChecingInfo() && !isarbitrationCriteriaLoading && arbitrationCriteria) {
      const modelData = arbitrationCriteria?.map((item, id: number) => {
        return {
          arbitrationCriteria_id: item.id,
          percentage: +tableValues[id][id] || '',
          comment: comments[id][id] || ''
        };
      });

      setModel(modelData);
    };
  }, [comments, tableValues, checkingInfo]);

  const { mutate: AssignPercentage, isLoading: LoadingPercentage } = useAssignAribtratingRequestPercentageQuery(id || '', model);

  const onSubmit = async () => {
    if (!LoadingPercentage) {
      await AssignPercentage();
    };
  };

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
        <CustomStepper
          active={active}
          setActive={setActive}
          labels={STEPS}
          checkingInfo={(commentId && !commentId?.status_id) ? checkingInfo : undefined}
          setCheckingInfo={setCheckingInfo}
        />
      </Flex>
      <Flex gap={16} justify='flex-end'>
        {isAdmin && (<Button onClick={() => addInnovation(id || '')}>To Innovation Page</Button>)}
        <GeneratePDF />
      </Flex>

      <Center>
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <ArbitratingRequestsItemView tabIndex={active} />
          <ArbitratingRequestControls steps={STEPS} active={active} setActive={setActive} />
        </Paper>
      </Center>

      {!ArbitrationRequestLoading && commentId ? (
        <>
          <CommentHistoryTable commentId={commentId?.request_id || ''} />
          <PercentageTable
            tableValues={tableValues}
            setTableValues={setTableValues}
            resetValue={resetValue}
            setResetValue={setResetValue}
            sum={sum}
            totalPercentages={totalPercentages}
            comments={comments}
            setComments={setComments}
            isChecingInfo={isChecingInfo}
            onSubmit={onSubmit}
            isDone={commentId?.status_id || 0}
          />
        </>
      ) : null}
    </Stack>
  );
};

export default FiltrationRequestItemViewAndEvaluation;
