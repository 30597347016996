import { TPhotosItemForm } from './types';

export const photosFormDefaultValues: TPhotosItemForm = {
  summary_en: '',
  summary_ar: '',
  image: null
};

export const photosTableHeadings = {
  image: 'Image',
  modifyDate: 'Modify Date',
  publishDate: 'Publish Date',
  summaryEn: 'Summary - En',
  summaryAr: 'Summary - Ar'
};
