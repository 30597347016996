import { Switch, Tooltip } from '@mantine/core';
import { FC } from 'react';

type TProps = {
  value: any;
  onChange: () => void;
};

const VisibleFilter:FC<TProps> = ({ value, onChange }) => (
  <Tooltip label={value ? 'Hide page' : 'Show page'} withArrow arrowSize={6}>
    <div>
      <Switch checked={value} onChange={onChange} />
    </div>
  </Tooltip>
);

export default VisibleFilter;
