import { FC, useEffect, useState } from 'react';

import { Button, Flex, LoadingOverlay, Stack } from '@mantine/core';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useRegulationsQuery, useRegulationsMutation } from 'src/api/regulations/hooks';
import ContentPageWrapper from 'src/components/ContentPageWrapper';
import Form from 'src/components/Form';
import PDFUpload from 'src/components/PDFUpload';
import { TRegulationsForm } from 'src/modules/regulations/types';

const Regulations: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data, isLoading: isDataLoading } = useRegulationsQuery();
  const { mutate, isLoading: isMutateLoading } = useRegulationsMutation();

  const form = useForm<TRegulationsForm>({
    defaultValues: {
      file_en: null,
      file_ar: null
    }
  });

  useEffect(() => {
    if (data) {
      form.reset({
        file_en: data.file_en_path,
        file_ar: data.file_ar_path
      });
    }
  }, [form, data]);

  const handleSubmit: SubmitHandler<TRegulationsForm> = (data) => {
    mutate(data);
    setIsEditing(false);
  };

  const onEditCancel = () => {
    setIsEditing(false);
    form.reset();
  };

  return (
    <ContentPageWrapper
      title="Regulations"
      isEditing={isEditing}
      onEditEnter={() => setIsEditing(true)}
      onEditLeave={onEditCancel}
    >
      <LoadingOverlay visible={isDataLoading || isMutateLoading} />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="file_en"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <PDFUpload
                value={value}
                onChange={onChange}
                error={error?.message}
                label="File - En"
                disabled={!isEditing}
              />
            )}
          />

          <Controller
            control={form.control}
            name="file_ar"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <PDFUpload
                value={value}
                onChange={onChange}
                error={error?.message}
                label="File - Ar"
                disabled={!isEditing}
              />
            )}
          />

          {isEditing && (
            <Flex justify="flex-end">
              <Button type="submit">
                Save
              </Button>
            </Flex>
          )}
        </Stack>
      </Form>
    </ContentPageWrapper>
  );
};

export default Regulations;
