import { TTicket } from 'src/modules/tickets/types';

export const getTicketsForExport = (tickets: TTicket[] | undefined) => {
  if (!tickets) {
    return [
      { data: [], title: 'Data' },
      { data: [], title: 'Statistics' }
    ];
  }

  const ticketsDataSheet = getTicketsData(tickets);
  const ticketsStatisticsSheet = getTicketsStatistics(tickets);

  return [
    { data: ticketsDataSheet, title: 'Data' },
    { data: ticketsStatisticsSheet, title: 'Statistics' }
  ];
};

const getTicketsData = (tickets: TTicket[]) => {
  const ticketsExport = tickets?.map(ticket => ({
    Title: ticket.title,
    Description: ticket.description,
    'Rate Comment': ticket.rate_comment,
    'Admin Status Comment': ticket.admin_status_comment,
    'Admin Status Edit Name': ticket.admin_status_edit_Name,
    'Created at': ticket.creating_date,
    'Modified at': ticket.modify_date,
    'Created by': ticket.create_by,
    'Rate': ticket.rate,
    'Is Rated': ticket.is_rated,
    'Attachment Path': ticket.attachemt_path,
    'Ticket Type': ticket.tickit_type.name,
    'Status': ticket.ticket_status.name,
    'Ticket Number': ticket.ticket_number,
    Email: ticket.email,
    Name: ticket.name
  })) ?? [];

  return ticketsExport;
};
const getTicketsStatistics = (tickets: TTicket[]) => {
  const inquiryTicketsLength = tickets.filter(({tickit_type}) => tickit_type.name === 'Inquiry').length;
  const supportRequestTicketsLength = tickets.filter(({tickit_type}) => tickit_type.name === 'Support Request').length;
  const closedTicketsLength = tickets.filter(({ticket_status}) => ticket_status.name === 'Closed').length;
  const newTicketsLength = tickets.filter(({ticket_status}) =>
    ticket_status.name === 'New'
  ).length;
  const missingInfoTicketsLength = tickets.filter(({ticket_status}) =>
    ticket_status.name === 'Missing Info'
  ).length;
  const underReviewTicketsLength = tickets.filter(({ticket_status}) =>
    ticket_status.name === 'Under Review'
  ).length;

  return [
    { 'Statistic description': 'Inquiry Tickets', 'No.': inquiryTicketsLength },
    { 'Statistic description': 'Support Request Tickets', 'No.': supportRequestTicketsLength },
    { 'Statistic description': 'Closed', 'No.': closedTicketsLength },
    { 'Statistic description': 'New', 'No.': newTicketsLength },
    { 'Statistic description': 'Missing Info', 'No.': missingInfoTicketsLength },
    { 'Statistic description': 'Under Review', 'No.': underReviewTicketsLength }
  ];
};
