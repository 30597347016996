import { FC } from 'react';

import { Box, Button, Center, LoadingOverlay, Stack, TextInput, Title } from '@mantine/core';
import { Controller, UseFormReturn } from 'react-hook-form';

import Form from 'src/components/Form';
import ImageUpload from 'src/components/ImageUpload';
import Paper from 'src/components/Paper';
import RichTextEditor from 'src/components/RichTextEditor';
import { TCompetitionPathItemForm } from 'src/modules/competition-paths/types';

type TProps = {
  title: string;
  submitText: string;
  onSubmit: (data: TCompetitionPathItemForm) => void;
  form: UseFormReturn<TCompetitionPathItemForm>;
  isLoading: boolean;
};

const ManageCompetitionPaths: FC<TProps> = ({
  title,
  form,
  onSubmit,
  submitText,
  isLoading
}) => (
  <Center>
    <Paper w={700}>
      <Title order={3} mb={30}>
        {title}
      </Title>
      <Box
        component={Form}
        w="100%"
        onSubmit={form.handleSubmit(onSubmit)}
        pos="relative"
      >
        <LoadingOverlay visible={isLoading} />

        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="title_en"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Title - En"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="title_ar"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Title - Ar"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="body_en"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <RichTextEditor
                content={value}
                onUpdate={onChange}
                error={error?.message}
                label="Body - En"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="body_ar"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <RichTextEditor
                content={value}
                onUpdate={onChange}
                error={error?.message}
                label="Body - Ar"
                required
                disabled={isLoading}
                isRtl
              />
            )}
          />

          <Controller
            control={form.control}
            name="image"
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <ImageUpload
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Image"
                disabled={isLoading}
              />
            )}
          />

          <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
            {submitText}
          </Button>
        </Stack>
      </Box>
    </Paper>
  </Center>
);

export default ManageCompetitionPaths;
