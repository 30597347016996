import { FC, ReactNode } from 'react';

import { Stack } from '@mantine/core';

type TProps = {
  children: ReactNode;
  rowHeight?: number;
};

const InfoWrapper: FC<TProps> = ({
  children,
  rowHeight = 30
}) => (
  <Stack sx={{ '& > *': { height: rowHeight } }}>
    {children}
  </Stack>
);

export default InfoWrapper;
