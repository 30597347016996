import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showErrorNotification, showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';

import { addToInnovation, deleteInnovation, getInnovationList, putInnovationData, sortInnovation } from './api';
import { INNOVATION_LIST_KEY } from './constants';
import { TInnovation, TInnovationPutData } from './types';

export const useInnovationListQuery = () => {
  return useQuery<TInnovation[]>({
    queryKey: [INNOVATION_LIST_KEY],
    queryFn: getInnovationList
  });
};

export const usePutInnovationDataQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, TInnovationPutData>({
    mutationFn: putInnovationData,
    onSuccess: async () => {
      await queryClient.invalidateQueries([INNOVATION_LIST_KEY]);
      showSuccessNotification({ message: 'Innovation data changed successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      };
    }
  });
};

export const useDeleteInnovation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteInnovation,
    onSuccess: async () => {
      await queryClient.invalidateQueries([INNOVATION_LIST_KEY]);
      showSuccessNotification({ message: 'Innovation deleted successfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useAddToInnovation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: addToInnovation,
    onSuccess: async () => {
      await queryClient.invalidateQueries([INNOVATION_LIST_KEY]);
      showSuccessNotification({ message: 'Innovation item added successfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useSortInnovation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string[]>({
    mutationFn: sortInnovation,
    onSuccess: async () => {
      await queryClient.invalidateQueries([INNOVATION_LIST_KEY]);
      showSuccessNotification({ message: 'Items sorted successfully' });
    }
  });
};
