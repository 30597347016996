import { FC, useCallback } from 'react';

import { Button, Center, Flex, Loader, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useCompetitionsListQuery, useDeleteCompetitionItemMutation } from 'src/api/competitions/hooks';
import DataTable from 'src/components/DataTable';
import { useCompetitionsTableColumns, useCompetitionsTableFilters } from 'src/modules/competitions/hooks';
import { Paths } from 'src/router/constants';

const Competitions: FC = () => {
  const { data, isLoading: isDataLoading } = useCompetitionsListQuery();
  const { mutate: deleteItem, isLoading: isDeleteItemLoading } = useDeleteCompetitionItemMutation();

  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteItem(id)
  }), [deleteItem]);

  const columns = useCompetitionsTableColumns({
    onDeleteClick: openDeleteModal
  });

  const filters = useCompetitionsTableFilters();

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>
          Competitions
        </Title>
        <Button
          component={Link}
          to={Paths.competitionsAddItem}
          leftIcon={<IconPlus size={18} />}
        >
          Add
        </Button>
      </Flex>

      {isDataLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data ? (
        <DataTable
          columns={columns}
          data={data}
          filters={filters}
          isLoading={isDeleteItemLoading}
        />
      ) : null}
    </Flex>
  );
};

export default Competitions;
