import { Flex, Image, Text } from '@mantine/core';

import logo from 'src/assets/svg/logo-colored.svg';

import styles from './AssignUser.module.scss';

interface TProps {
  userName: string;
};

const AssignUser = ({ userName }: TProps) => {
  return (
    <Flex gap={4} className={styles.wrapper}>
      <Image className={styles.img} src={logo} />
      <Text className={styles.text}>{userName}</Text>
    </Flex>
  );
};

export default AssignUser;
