import { Paper, Flex, Stack, Center, Grid, Box, Text, Button, TextInput, Loader } from '@mantine/core'; 
import { useParams } from 'react-router-dom';

import { usePutWinnerDataQuery, useTopWinnerDataQuery } from 'src/api/arbitrating-requests/hooks';
import logo from 'src/assets/svg/logo-colored.svg';
import BackPageButton from 'src/components/BackPageButton';
import Dropdown from 'src/components/Dropdown';
import { ParticipatingType } from 'src/common/constants/defConstant';
import { FormatPlace } from 'src/common/helpers/formatPlace';

import styles from './TopWinnersDetail.module.scss';
import { useCompetitionPathsListQuery } from 'src/api/competition-paths/hooks';
import { useEffect, useState } from 'react';
import { PutRequestWinnerData } from 'src/api/arbitrating-requests/types';
import ImageUpload from 'src/components/ImageUpload';

const TopWinnersItemDetail = () => {
  const { id } = useParams<{ id: string }>();
  const {data: WinnerData, isLoading: WinnerDataLoading} = useTopWinnerDataQuery();
  const { data: CompetitionPaths, isLoading: CompetitionPathsLoading } = useCompetitionPathsListQuery();
  const { mutate: updateWinnerData } = usePutWinnerDataQuery();
  const selectedItem = !WinnerDataLoading && WinnerData?.find((el) => el.participatingRequestId === id);

  const [fullName, setFullname] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<PutRequestWinnerData>();

  const CompetitionPathsEn = !CompetitionPathsLoading && CompetitionPaths?.map((el) => {return {id: el.id, title: el.title_en}});
  const InnovationID = CompetitionPathsEn && selectedItem && CompetitionPathsEn.find((el: any) => el.title === selectedItem.innovation_path);

  useEffect(() => {
    if (selectedItem) {
      setFullname(`${selectedItem.winner_first_name} ${selectedItem.winner_second_name} ${selectedItem.winner_last_name}`);

      setValue({
        ParticipatingRequestId: selectedItem.participatingRequestId || '',
        InnovationNameAr: selectedItem.innovation_name_ar || '',
        InnovationNameEn: selectedItem.innovation_name_en || '',
        FirstName: selectedItem.winner_first_name,
        SecondName: selectedItem.winner_second_name,
        LastName: selectedItem.winner_last_name,
        InnovationPathId: InnovationID ? InnovationID.id.toString() : selectedItem.innovation_path,
        ParticipatingTypeId: selectedItem.participatingType === 'Group' ? '1' : '2',
        WinnerImage: selectedItem.winner_image || logo
      });
    };
  }, [selectedItem, WinnerDataLoading, loading]);

  useEffect(() => {
    setValue({
      ParticipatingRequestId: selectedItem && selectedItem.participatingRequestId || '',
      InnovationNameAr: value?.InnovationNameAr || '',
      InnovationNameEn: value?.InnovationNameEn || '',
      FirstName: fullName.split(' ')[0] || '',
      SecondName: fullName.split(' ')[1] || '',
      LastName: fullName.split(' ').slice(2).join(' ') || '',
      InnovationPathId: value?.InnovationPathId || '',
      ParticipatingTypeId: value?.ParticipatingTypeId || '',
      WinnerImage: value?.WinnerImage || logo
    });
  }, [fullName]);

  useEffect(() => {
    setTimeout(() => setLoading(true), 0);
  }, []);

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
      </Flex>

      {WinnerDataLoading && CompetitionPathsLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}

      {selectedItem && value && !WinnerDataLoading && !CompetitionPathsLoading && (
        <Center>
          <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <Box mt={24} mb={26} className={styles.wrapper}>
                  <Text fz={14} color="#767676">Rank - En</Text>
                  <Text fz={24}>{selectedItem.rank ? FormatPlace(selectedItem.rank, 'en') : ''}</Text>
                </Box>

                <Box mt={24} mb={26} className={styles.wrapper}>
                  <Text fz={14} color="#767676">Rank - Ar</Text>
                  <Text fz={24}>{selectedItem.rank ? FormatPlace(selectedItem.rank, 'ar') : ''}</Text>
                </Box>

                <Flex w={200} h={200} align='center' justify='center' className={styles.ImgWrapper}>
                  <ImageUpload
                    value={value.WinnerImage}
                    onChange={(img) => {
                      if (img) {
                        setValue({...value, WinnerImage: img });
                      } else {
                        setValue({...value, WinnerImage: null });
                      }
                    }}
                    required
                    additionalStyles={styles.dropzone}
                  />
                </Flex>

                <TextInput
                  w={350} fz={18}
                  className={styles.input}
                  label='Winner Name - Ar'
                  withAsterisk
                  value={fullName}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </Grid.Col>

              <Grid.Col span={1}>
                <TextInput
                  w={350} fz={18}
                  className={styles.input}
                  label='Innovation Name - En'
                  withAsterisk
                  value={value.InnovationNameEn || ''}
                  onChange={(e) => setValue({...value, InnovationNameEn: e.target.value})}
                />

                <TextInput
                  w={350} fz={18}
                  className={styles.input}
                  label='Innovation Name - Ar'
                  withAsterisk
                  value={value.InnovationNameAr || ''}
                  onChange={(e) => setValue({...value, InnovationNameAr: e.target.value})}
                />

                <Box mt={24} mb={26} className={styles.wrapper}>
                  <Text fz={14} color="#767676">Innovation Path</Text>
                  <Text fz={24}>{selectedItem.innovation_path}</Text>
                </Box>

                <Box mt={24} mb={26} className={styles.wrapper}>
                  <Text fz={14} color="#767676">Participating Type</Text>
                  <Text fz={24}>{selectedItem.participatingType}</Text>
                </Box>
              </Grid.Col>
            </Grid>

            <Flex>
              <Button onClick={() => updateWinnerData(value)}>Save</Button>
            </Flex>
          </Paper>
        </Center>
      )}
    </Stack>
  );
};

export default TopWinnersItemDetail;
