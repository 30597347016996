import { useGetArbitratingCommentHistoryQuery } from "src/api/arbitrating-requests/hooks";
import TableCommentHistory from "src/components/TableCommentHistory";

interface TProps {
  commentId: string
}

const CommentHistoryTable = ({ commentId }: TProps) => {
  const { data: commmentHistory, isLoading: iscommmentHistoryLoading } = useGetArbitratingCommentHistoryQuery(commentId || '');
  
  return (
    <>
      {!iscommmentHistoryLoading && commmentHistory?.length ? (
        <TableCommentHistory data={commmentHistory} />
      ) : null}
    </>
  )
};

export default CommentHistoryTable;
