import { FC } from 'react';

import { Button, Group, Input, InputWrapperProps, Text } from '@mantine/core';
import { MIME_TYPES } from '@mantine/dropzone';
import { IconFileText } from '@tabler/icons';

import Dropzone from 'src/components/Dropzone';

import styles from './PDFUpload.module.scss';

type Props = {
  value: string | File | null;
  onChange: (file: string | File | null) => void;
  disabled?: boolean;
} & Pick<InputWrapperProps, 'label' | 'error' | 'required'>;

const PDFUpload: FC<Props> = ({
  value,
  onChange,
  label,
  error,
  required,
  disabled
}) => {
  const handleUpload = (file: File) => {
    onChange(file);
  };

  return (
    <Input.Wrapper
      label={label}
      error={error}
      withAsterisk={required}
      classNames={{ label: styles.label }}
    >
      {value ? (
        <div className={styles.previewRoot}>
          <Group>
            <Text sx={{ wordBreak: 'break-all' }}>
              {typeof value === 'string'
                ? value.slice(value.indexOf('_') + 1)
                : value.name}
            </Text>
            <Button
              component="a"
              href={typeof value === 'string' ? value : URL.createObjectURL(value)}
              target="_blank"
              variant="light"
            >
              View file
            </Button>
            {!disabled && (
              <Button
                variant="light"
                color="red"
                onClick={() => onChange(null)}
              >
                Remove file
              </Button>
            )}
          </Group>
        </div>
      ) : (
        <Dropzone
          onDrop={(files) => handleUpload(files[0])}
          maxFiles={1}
          maxSize={50 * 1000 * 1000}
          disabled={disabled}
          accept={[MIME_TYPES.pdf]}
          title="Drag PDF file here or click to select file"
          subTitle="File should not exceed 50mb, allowed formats: pdf"
          idleIcon={<IconFileText size={50} stroke={1.5} />}
        />
      )}
    </Input.Wrapper>
  );
};

export default PDFUpload;
