import React, { useEffect, useState } from 'react';

import {
  Flex,
  Title,
  Center,
  Paper,
  Loader,
  Group,
  Divider,
  Text,
  Button,
  Image,
  TextInput,
  Select
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';

import { useGetLatestCompetitionQuery, usePutLatestWinnerCompetitionDataQuery } from 'src/api/arbitrating-requests/hooks';
import { useCompetitionPathsListQuery } from 'src/api/competition-paths/hooks';
import bronze from 'src/assets/svg/bronze.svg';
import gold from 'src/assets/svg/gold.svg';
import silver from 'src/assets/svg/silver.svg';
import { WinnerMedalName } from 'src/common/constants/defConstant';
import Dropdown from 'src/components/Dropdown';

import styles from './WinnersContent.module.scss';
import { putRequestLatestWinnerCompetitionData } from 'src/api/arbitrating-requests/types';
import { WinnerCategory } from 'src/common/helpers/winnerCategory';
import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';
import VisibleFilter from 'src/components/VisibleFilter';
import { useSetShowHidePageQuery, useShowHidePageListQuery } from 'src/api/content-management/hooks';

const WinnersContent = () => {
  const [openedItem, setOpenedItem] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState<putRequestLatestWinnerCompetitionData | any>();

  const { data: LatestCompetition, isLoading: LatestCompetitionLoading } = useGetLatestCompetitionQuery();
  const {data: additionalData, isLoading: additionalDataLoading} = useParticipantsRequestByIdQuery(openedItem);
  const { data: CompetitionPaths, isLoading: CompetitionPathsLoading } = useCompetitionPathsListQuery();
  const { data: Content, isLoading: isContent } = useShowHidePageListQuery();
  const { mutate: updateWinnerCompetition } = usePutLatestWinnerCompetitionDataQuery();
  const { mutate: updateShowHidePage } = useSetShowHidePageQuery('Winners in Latest Competition');

  const isPageShow = !isContent && Content && Content.data.find((el) => el.name === 'Winners in Latest Comp');

  const participantsData = !additionalDataLoading && additionalData?.partcipants_data.map((el) => `${el.first_name} ${el.second_name} ${el.last_name}`);
  const selectedItem = !LatestCompetitionLoading && LatestCompetition?.data?.find((el: any) => el?.id === openedItem);
  const CompetitionPathsEn = !CompetitionPathsLoading && CompetitionPaths?.map((el) => {
    return {id: el.id, title: el.title_en};
  });
  const InnovationID = CompetitionPathsEn && selectedItem && CompetitionPathsEn.find((el: any) => el.title === selectedItem.path);

  const openCloseHandler = (id: string) => {
    if (openedItem === id) {
      setOpenedItem('');
    } else {
      setOpenedItem(id);
    };
  };

  useEffect(() => {
    if (selectedItem) {
      setValue({
        ParticipatingRequestId: selectedItem.id,
        FirstName: selectedItem.member.first_name,
        SecondName: selectedItem.member.second_name,
        LastName: selectedItem.member.last_name,
        InnovationNameAr: selectedItem.innovation_name_ar || '',
        InnovationNameEn: selectedItem.innovation_name || '',
        InnovationDescriptionAr: selectedItem.innovation_description_ar || '',
        InnovationDescriptionEn: selectedItem.innovation_description_en || '',
        InnovationPathId: InnovationID ? InnovationID.id.toString() : selectedItem.path,
        ParticipatingTypeId: selectedItem.participating_type.name === 'Group' ? '1' : '2',
        ParticipantsNames: selectedItem.winner_participants_names ||
          (
            participantsData ? [`${selectedItem.member.first_name} ${selectedItem.member.second_name} ${selectedItem.member.last_name}`, ...participantsData] :
            [`${selectedItem.member.first_name} ${selectedItem.member.second_name} ${selectedItem.member.last_name}`]
          ),
        Medal: selectedItem.category_id ? selectedItem.category_id : WinnerCategory(selectedItem.sort)
      });
    };
  }, [selectedItem, openedItem, additionalDataLoading]);

  const updateItem = (value: any) => {
    updateWinnerCompetition(value);
    setEditMode(false);
  }

  return (
    <Flex direction='column' h='100%'>
      <Flex align='center' justify='space-between' pb={30} pr={44}>
        <Title order={2}>Winners in Latest Competition</Title>
        <VisibleFilter
          value={isPageShow && isPageShow.is_show}
          onChange={() => isPageShow && updateShowHidePage({content_id: isPageShow.id, is_show: !isPageShow.is_show})}
        />
      </Flex>

      {LatestCompetitionLoading && (
        <Center py={50}>
          <Loader size='lg' />
        </Center>
      )}

      <Center>
        <Paper w='100%' p='xl' radius='lg' display='grid' maw='100%' sx={{ gap: 32 }}>
          <Flex direction='column' py={18} px={20}>
            {LatestCompetition?.data.map((item: any) => {
              const medal = () => {
                if (item.category_id === 1) return gold;
                if (item.category_id === 2) return silver;
                if (item.category_id === 3) return bronze;
                if (item.category_id >= 4) return null;
                if (item.sort < 4) return gold;
                if (item.sort < 7) return silver;
                if (item.sort > 6) return bronze;
              };

              return (
                <div key={item.id} className={styles.itemWrapper}>
                  <Group className={styles.header} onClick={() => openCloseHandler(item.id)}>
                    <IconChevronDown size={18} className={openedItem === item.id ? styles.arrow : ''} />

                    <Flex justify='space-between' w='100%'>
                      <Group>
                        <p>{item.rankInWord_en}</p>
                        <span
                          className={`${styles.medal} ${
                            item.category_id === 1 ? styles.gold :
                            item.category_id === 2 ? styles.silver :
                            item.category_id === 3 ? styles.bronze :
                            item.category_id === 4 ? styles.motivational :
                            item.category_id === 5 ? styles.notassigned :
                            item.sort < 4 ? styles.gold :
                            item.sort < 7 ? styles.silver : styles.bronze
                          }`}
                        >
                          <Image src={medal()} maw={24} h={24} />
                          {item.category_en || WinnerMedalName.en[WinnerCategory(item.sort) - 1] || 'Motivational Category'}
                        </span>
                      </Group>

                      <Group dir='rtl'>
                        <p>{item.rankInWord_ar}</p>
                        <span
                          className={`${styles.medal} ${
                            item.category_id === 1 ? styles.gold :
                            item.category_id === 2 ? styles.silver :
                            item.category_id === 3 ? styles.bronze :
                            item.category_id === 4 ? styles.motivational :
                            item.category_id === 5 ? styles.notassigned :
                            item.sort < 4 ? styles.gold :
                            item.sort < 7 ? styles.silver : styles.bronze
                          }`}
                          dir='rtl'
                        >
                          <Image src={medal()} maw={24} h={24} />
                          {item.category_ar || WinnerMedalName.ar[WinnerCategory(item.sort) - 1] || 'الفئة التحفيزية'}
                        </span>
                      </Group>
                    </Flex>
                  </Group>

                  {openedItem === item.id && (
                    <>
                      <Divider mx={20} />

                      <Flex justify='center' maw={545} direction='column' mx='auto' my={30} gap={24}>
                        <Flex justify='space-between'>
                          <Flex direction='column'>
                            {editMode ? (
                              <>
                                <Flex gap={4}>
                                  <Text fz={14} color="#767676" fw={500}>Category - En</Text>
                                  <Text color='#FF2929'>*</Text>
                                </Flex>
                                <Select
                                  data={WinnerMedalName.en.filter((el, id) => id !== 4)}
                                  onChange={(val) => {
                                    const selected = WinnerMedalName.en.findIndex((el) => el === val);
                                    setValue({ ...value, Medal: selected + 1 });
                                  }}
                                  value={WinnerMedalName.en.find((el, id) => (id + 1) === value?.Medal)}
                                />
                              </>
                            ) : (
                              <>
                                <Text color='#767676' fz={14} fw={500}>Category - En</Text>
                                <Flex gap={4} align='center'>
                                  {item.category_id !== 4 && (<Image src={medal()} maw={24} h={24} />)}
                                  {item.category_en || WinnerMedalName.en[WinnerCategory(item.sort) - 1]}
                                </Flex>
                              </>
                            )}
                          </Flex>

                          <Flex direction='column' align='flex-end'>
                            {editMode ? (
                              <>
                                <Flex gap={4}>
                                  <Text fz={14} color="#767676" fw={500}>Category - Ar</Text>
                                  <Text color='#FF2929'>*</Text>
                                </Flex>
                                <Select
                                  data={WinnerMedalName.ar.filter((el, id) => id !== 4)}
                                  onChange={(val) => {
                                    const selected = WinnerMedalName.ar.findIndex((el) => el === val);
                                    setValue({ ...value, Medal: selected + 1 });
                                  }}
                                  value={WinnerMedalName.ar.find((el, id) => (id + 1) === value?.Medal)}
                                />
                              </>
                            ) : (
                              <>
                                <Text color='#767676' fz={14} fw={500}>Category - Ar</Text>
                                <Flex gap={4} align='center' dir='rtl'>
                                  {item.category_id !== 4 && (<Image src={medal()} maw={24} h={24} />)}
                                  {item.category_ar || WinnerMedalName.ar[WinnerCategory(item.sort) - 1]}
                                </Flex>
                              </>
                            )}
                          </Flex>
                        </Flex>

                        {item.winner_participants_names?.length || value?.ParticipantsNames.length ? (
                          <>
                            <Divider />
                            <Flex direction='column' gap={4}>
                              <Text color='#767676' fz={14} fw={500}>Participants Names - En</Text>
                              {(item.winner_participants_names || value?.ParticipantsNames).map((name: any, id: number) => (
                                <React.Fragment key={id}>
                                  {editMode ? (
                                    <TextInput
                                      value={name}
                                      onChange={(e) => {
                                        const updatedParticipantsNames = [...(value?.ParticipantsNames || [])];
                                        updatedParticipantsNames[id] = e.target.value;
                                        setValue({ ...value, ParticipantsNames: updatedParticipantsNames });
                                      }}
                                    />
                                  ) : (
                                    <Text>{id + 1}. {name}</Text>
                                  )}
                                </React.Fragment>
                              ))}
                            </Flex>
                          </>
                        ) : null}

                        <Divider />

                        <Flex justify='space-between' gap={20}>
                          <Flex direction='column' className={styles.fullWidth}>
                            {editMode ? (
                              <TextInput
                                label='Innovation Name - En'
                                withAsterisk
                                value={value.InnovationNameEn || ''}
                                onChange={(e) => setValue({ ...value, InnovationNameEn: e.target.value })}
                              />
                            ) : (
                              <>
                                <Text color='#767676' fz={14} fw={500}>Innovation Name - En</Text>
                                <Text>{item.innovation_name}</Text>
                              </>
                            )}
                          </Flex>

                          <Flex direction='column' className={styles.fullWidth}>
                            {editMode ? (
                              <TextInput
                                label='Innovation Name - Ar'
                                withAsterisk
                                value={value.InnovationNameAr || ''}
                                onChange={(e) => setValue({ ...value, InnovationNameAr: e.target.value })}
                              />
                            ) : (
                              <>
                                <Text color='#767676' fz={14} fw={500}>Innovation Name - Ar</Text>
                                <Text>{item.innovation_name_ar}</Text>
                              </>
                            )}
                          </Flex>
                        </Flex>

                        <Divider />

                        <Flex direction='column'>
                          {editMode ? (
                            <Dropdown
                              data={CompetitionPathsEn || []}
                              label='Innovation Path'
                              value={selectedItem ? selectedItem?.path : ''}
                              defaultValue={value}
                              setVal={setValue}
                              full
                              path
                            />
                          ) : (
                            <>
                              <Text color='#767676' fz={14} fw={500}>Innovation Path - En</Text>
                              <Text>{item.path}</Text>
                            </>
                          )}
                        </Flex>

                        <Divider />

                        <Flex direction='column'>
                          <Text color='#767676' fz={14} fw={500}>Innovation Description - En</Text>
                          {editMode ? (
                            <Text
                              className={styles.editableText}
                              contentEditable
                              dangerouslySetInnerHTML={{ __html: item.innovation_description_en || '' }}
                              onKeyUp={(e: any) => setValue({ ...value, InnovationDescriptionEn: e.target.innerText })}
                            />
                          ) : (
                            <Text className={styles.textWrap} w={520} maw={520}>{item.innovation_description_en}</Text>
                          )}
                        </Flex>
                        <Flex direction='column' align='flex-end'>
                          <Text color='#767676' fz={14} fw={500}>Innovation Description - Ar</Text>
                          {editMode ? (
                            <Text
                              className={styles.editableText}
                              contentEditable
                              ta='end'
                              dir='rtl'
                              dangerouslySetInnerHTML={{ __html: item.innovation_description_ar || '' }}
                              onKeyUp={(e: any) => setValue({ ...value, InnovationDescriptionAr: e.target.innerText })}
                            />
                          ) : (
                            <Text className={styles.textWrap} w={520} maw={520}>{item.innovation_description_ar}</Text>
                          )}
                        </Flex>

                        <Divider />

                        {editMode ? (
                          <Flex gap={20}>
                            <Button w="max-content" onClick={() => updateItem(value)}>Save</Button>
                            <Button variant="outline" w="max-content" onClick={() => setEditMode(false)}>Cancel</Button>
                          </Flex>
                        ) : (
                          <Button w="max-content" onClick={() => setEditMode(true)}>Edit</Button>
                        )}
                      </Flex>
                    </>
                  )}
                </div>
              );
            })}
          </Flex>
        </Paper>
      </Center>
    </Flex>
  );
};

export default WinnersContent;
