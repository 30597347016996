import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TCompetitionItemForm, TCompetitionsList } from 'src/modules/competitions/types';

import { TCompetitionItemUpdateRequest } from './types';

export const getCompetitionsList = async (): Promise<TCompetitionsList> => {
  return privateApi.get('/admin-content/competitions').then((res) => res.data.data);
};

export const getCompetitionById = async (id: string): Promise<TCompetitionsList> => {
  return privateApi.get('/admin-content/competitions', {
    params: { id }
  }).then((res) => res.data.data);
};

export const createCompetitionItem = async (data: TCompetitionItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/competition', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateCompetitionItem = async (data: TCompetitionItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/competition', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteCompetitionItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/competition', {
    params: { id }
  }).then((res) => res.data);
};
