import privateApi from 'src/common/configs/private-api';
import { SuccessResponse } from 'src/common/types/api';
import { TEvaluationCriteria } from 'src/modules/evaluation-criteria/types';

export const getEvaluationCriteria = async (): Promise<TEvaluationCriteria> => {
  return privateApi.get('/admin-content/evaluationcriteria').then((res) => res.data.data);
};

export const updateEvaluationCriteria = async (data: TEvaluationCriteria): Promise<SuccessResponse> => {
  return privateApi.put('/evaluation-criteria', data).then((res) => res.data);
};
