import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Select, Text } from '@mantine/core';

import styles from './Dropdown.module.scss';

type TProps = {
  data: string[] | any;
  label: string;
  value: string;
  path?: boolean;
  full?: boolean;
  defaultValue: any;
  setVal: Dispatch<SetStateAction<any>>;
};

const Dropdown:FC<TProps> = ({
  data,
  label,
  value,
  path = false,
  full = false,
  defaultValue,
  setVal
}) => {
  const [selectValue, setSelectValue] = useState<string>(
    value || data.map((el: any) => el.title || el)[0]
  );

  const handleChahge = (val: string | null) => {
    const item = data.findIndex((el: any) => val === el || val === el.title);
    setSelectValue(val || '');

    if (path) {
      setVal({ ...defaultValue, InnovationPathId: data[item].id.toString() });
    } else {
      setVal({ ...defaultValue, ParticipatingTypeId: (item + 1).toString() });
    }
  };

  return (
    <div className={`${styles.wrapper} ${full ? styles.fullWidth : ''}`}>
      <Text fz={14} color='#767676' className={styles.label}>
        {label}
        <Text color='#FF2929'>*</Text>
      </Text>
      <Select
        data={data.map((el: any) => el.title || el)}
        onChange={(val) => handleChahge(val)}
        value={selectValue}
      />
    </div>
  );
};

export default Dropdown;
