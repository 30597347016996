import privateApi from 'src/common/configs/private-api';
import { ItemWithIdAndName, SuccessResponse } from 'src/common/types/api';
import { TTicket } from 'src/modules/tickets/types';

import { TUpdateTicketStatusRequestBody } from './types';

export const getTicketsList = (): Promise<TTicket[]> => {
  return privateApi.get('/ticket', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  }).then((res) => res.data.data);
};

export const getTicketById = (id: string): Promise<TTicket> => {
  return privateApi.get('/ticket', {
    params: { id }
  }).then((res) => res.data.data[0]);
};

export const updateTicketStatus = (body: TUpdateTicketStatusRequestBody): Promise<SuccessResponse> => {
  return privateApi.put('/ticket/adminStatusEdit', body);
};

export const getTicketStatusList = (): Promise<ItemWithIdAndName[]> => {
  return privateApi.get('/ticket-status').then((res) => res.data.data);
};

export const getTicketTypeList = (): Promise<ItemWithIdAndName[]> => {
  return privateApi.get('/ticket-type').then((res) => res.data.data);
};
