import { Center, Flex, Group, Loader, Paper, Title, Text, Button, TextInput, Stack } from "@mantine/core";
import { useEmailTemplateListQuery, usePutWinnerDataQuery } from "src/api/email-template/hooks";

import styles from '../EmailTemplate/EmailTemplate.module.scss';
import { useNavigate, useParams } from "react-router-dom";
import BackPageButton from "src/components/BackPageButton";
import { TUpdateEmailTemplate } from "src/api/email-template/types";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import RichTextEditor from "src/components/RichTextEditor";

const EditEmailTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useEmailTemplateListQuery();
  const { mutate: updateEmailTemplate } = usePutWinnerDataQuery();

  const item = !isLoading && data?.data.find((item) => item.id === +(id || 0));

  const form = useForm<TUpdateEmailTemplate>({
    defaultValues: {
      subject_en: '',
      subject_ar: '',
      content_en: '',
      content_ar: ''
    }
  });

  useEffect(() => {
    if (item) {
      form.reset({
        subject_en: item.subject,
        subject_ar: item.subject,
        content_en: item.body,
        content_ar: item.body
      });
    }
  }, [form, data]);

  const handleSubmit = () => {
    form.handleSubmit((data) => {
      updateEmailTemplate({ id: id || '', ...data });
      navigate(-1)
    })();
  };

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <BackPageButton label="Edit" />
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      <Flex gap={32} justify='center'>
        <Paper w='calc(50% - 16px)' radius='lg' display='grid'>
          <Group bg='#E8F3FD' className={styles.title}>
            <Title size={24} color='#228BE6' fw={400} px={30} py={26}>{item && item.name}</Title>
          </Group>

          <Flex p={30} direction='column' justify='space-between' gap={30}>
            <Stack spacing={30}>
              <Controller
                control={form.control}
                name="subject_en"
                rules={{ required: 'Required' }}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    label="Subject - En"
                    disabled={isLoading}
                    required
                  />
                )}
              />

              <Controller
                control={form.control}
                name="subject_ar"
                rules={{ required: 'Required' }}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    label="Subject - Ar"
                    disabled={isLoading}
                    required
                  />
                )}
              />

              {/* <Controller
                control={form.control}
                name="content_en"
                rules={{ required: 'Required' }}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => (
                  <RichTextEditor
                    onUpdate={onChange}
                    content={value}
                    error={error?.message}
                    label="Body - En"
                  />
                )}
              /> */}

              <Controller
                control={form.control}
                name="content_ar"
                rules={{ required: 'Required' }}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => (
                  <RichTextEditor
                    onUpdate={onChange}
                    content={value}
                    error={error?.message}
                    label="Body - Ar"
                    isRtl
                  />
                )}
              />
            </Stack>

            <Flex gap={20}>
              <Button onClick={handleSubmit}>Save</Button>
              <Button variant='outline' onClick={() => navigate(-1)}>Cancel</Button>
            </Flex>
          </Flex>
        </Paper>
      </Flex>
    </Flex>
  );
};

export default EditEmailTemplate;
