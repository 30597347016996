export const tableHeadings = {
  firstName: 'First name',
  secondName: 'Second name',
  lastName: 'Last name',
  nationality: 'Nationality',
  gender: 'Gender',
  mobileNumber: 'Mobile number',
  identityNumber: 'Identity number',
  email: 'Email',
  birthDate: 'Birth date'
};
