import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TPrivacyPolicy } from 'src/modules/privacy-policy/types';

import { getPrivacyPolicy, updatePrivacyPolicy } from './api';
import { PRIVACY_POLICY_QUERY_KEY } from './constants';

export const usePrivacyPolicyQuery = () => {
  return useQuery<TPrivacyPolicy>({
    queryKey: [PRIVACY_POLICY_QUERY_KEY],
    queryFn: getPrivacyPolicy
  });
};

export const useUpdatePrivacyPolicyMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TPrivacyPolicy>({
    mutationFn: updatePrivacyPolicy,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PRIVACY_POLICY_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
