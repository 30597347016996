import { FC, useMemo } from 'react';

import { useCreateFAQItemMutation } from 'src/api/faq/hooks';
import ManageFAQ from 'src/components/ManageFAQ';
import { TFAQItemForm } from 'src/modules/faq/types';

const AddModalContent: FC = () => {
  const { mutate, isLoading } = useCreateFAQItemMutation();

  const defaultValues = useMemo((): TFAQItemForm => ({
    question_en: '',
    question_ar: '',
    answer_en: '',
    answer_ar: ''
  }), []);

  const handleItemCreate = (data: TFAQItemForm) => {
    mutate(data);
  };

  return (
    <ManageFAQ
      defaultValues={defaultValues}
      onSubmit={handleItemCreate}
      submitText="Create"
      isLoading={isLoading}
    />
  );
};

export default AddModalContent;
