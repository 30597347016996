import { createBrowserRouter, Navigate } from 'react-router-dom';

import App from 'src/App';
import MainLayout from 'src/layouts/MainLayout';
import { AdminRoles } from 'src/modules/admin/constants';
import AboutThePrize from 'src/pages/AboutThePrize';
import Account from 'src/pages/Account';
import Admins from 'src/pages/Admins';
import AdminVerify from 'src/pages/AdminVerify';
import ArbitrationCriteria from 'src/pages/ArbitrationCriteria';
import ArbitratingAssign from 'src/pages/ArbitratingAssign';
import ArbitratingAssignItemViewAndEvaluation from 'src/pages/ArbitratingAssignEvaluation';
import ArbitratingApproval from 'src/pages/ArbitratingApproval';
import ArbitratingApprovalItemViewAndEvaluation from 'src/pages/ArbitratingApprovalEvaluation';
import ArbitratingInterview from 'src/pages/ArbitratingInterview';
import ArbitrationInterviewItemViewAndEvaluation from 'src/pages/ArbitratingInterviewEvaluation';
import ArbitratingRequest from 'src/pages/ArbitratingRequest';
import ArbitratingRequestItemViewAndEvaluation from 'src/pages/ArbitratingRequestEvaluation';
import ArbitratingWinners from 'src/pages/ArbitratingWinners';
import ArbitrationWinnersItemViewAndEvaluation from 'src/pages/ArbitratingWinnersEvaluation';
import CompetitionConditions from 'src/pages/CompetitionConditions';
import CompetitionPaths from 'src/pages/CompetitionPaths';
import CompetitionPathsAddItem from 'src/pages/CompetitionPathsAddItem';
import CompetitionPathsItemEdit from 'src/pages/CompetitionPathsItemEdit';
import Competitions from 'src/pages/Competitions';
import CompetitionsAddItem from 'src/pages/CompetitionsAddItem';
import CompetitionsItemEdit from 'src/pages/CompetitionsItemEdit';
import ContentHomepage from 'src/pages/ContentHomepage';
import EmailTemplate from 'src/pages/EmailTemplate';
import CreateNewTemplate from 'src/pages/CreateNewTemplate';
import ErrorPage from 'src/pages/ErrorPage';
import EvaluationCriteria from 'src/pages/EvaluationCriteria';
import FAQ from 'src/pages/FAQ';
import FiltrationApproval from 'src/pages/FiltrationApproval';
import FiltrationRequest from 'src/pages/FiltrationRequest';
import FiltrationApprovalItemViewAndEvaluation from 'src/pages/FiltrationApprovalEvaluation';
import FiltrationRequestItemViewAndEvaluation from 'src/pages/FiltrationRequestItemEvaluation';
import GeneralSecretariat from 'src/pages/GeneralSecretariat';
import Home from 'src/pages/Home';
import Login from 'src/pages/Login';
import News from 'src/pages/News';
import NewsAddItem from 'src/pages/NewsAddItem';
import NewsItemEdit from 'src/pages/NewsItemEdit';
import ParticipantsRequests from 'src/pages/ParticipantsRequests';
import ParticipantsRequestsItemViewAndEvaluation from 'src/pages/ParticipantsRequestsItemEvaluation';
import ParticipantsRequestsItemParticipantsApproval from 'src/pages/ParticipantsRequestsItemParticipantsApproval';
import Photos from 'src/pages/Photos';
import PhotosAddItem from 'src/pages/PhotosAddItem';
import PhotosItemEdit from 'src/pages/PhotosItemEdit';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import Regulations from 'src/pages/Regulations';
import SocialMediaAccounts from 'src/pages/SocialMediaAccounts';
import SupervisoryBoard from 'src/pages/SupervisoryBoard';
import TermsAndConditions from 'src/pages/TermsAndConditions';
import Tickets from 'src/pages/Tickets';
import TicketsItemView from 'src/pages/TicketsItemView';
import TopWinners from 'src/pages/TopWinners';
import TopWinnersItemDetail from 'src/pages/TopWinnersDetail/TopWinnersDetail';
import Users from 'src/pages/Users';
import Videos from 'src/pages/Videos';
import VideosAddItem from 'src/pages/VideosAddItem';
import VideosItemEdit from 'src/pages/VideosItemEdit';
import WinnersContent from 'src/pages/WinnersContent';
import AdminRoleWrapper from 'src/router/AdminRoleWrapper';
import AuthWrapper from 'src/router/AuthWrapper';
import NoAuthWrapper from 'src/router/NoAuthWrapper';
import EditEmailTemplate from 'src/pages/EditEmailTemplate';
import Innovations from 'src/pages/Innovations';
import InterestedUser from 'src/pages/InterestedUser';

import { Paths } from './constants';

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: Paths.error,
        element: <ErrorPage />
      },

      {
        element: <NoAuthWrapper />,
        children: [
          {
            path: Paths.login,
            element: <Login />
          },
          {
            path: Paths.adminVerify,
            element: <AdminVerify />
          }
        ]
      },

      {
        element: <AuthWrapper />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: Paths.homepage,
                element: <Home />
              },
              {
                path: Paths.account,
                element: <Account />
              },

              {
                element: <AdminRoleWrapper
                  roles={[
                    AdminRoles.SuperAdmin,
                    AdminRoles.OperationCommittee,
                    AdminRoles.GeneralSecretariat
                  ]}
                />,
                children: [
                  {
                    path: Paths.admins,
                    element: <Admins />
                  },
                  {
                    path: Paths.users,
                    element: <Users />
                  },
                  {
                    path: Paths.interestedUser,
                    element: <InterestedUser />
                  },
                  {
                    path: Paths.tickets,
                    element: <Tickets />
                  },
                  {
                    path: Paths.ticketsItemView,
                    element: <TicketsItemView />
                  },
                  {
                    path: Paths.participantsRequestsItemParticipantsApproval,
                    element: <ParticipantsRequestsItemParticipantsApproval />
                  }
                ]
              },

              {
                element: <AdminRoleWrapper
                  roles={[
                    AdminRoles.SuperAdmin,
                    AdminRoles.OperationCommittee,
                    AdminRoles.SupervisoryBoard,
                    AdminRoles.Authorizer,
                    AdminRoles.GeneralSecretariat
                  ]}
                />,
                children: [
                  {
                    path: Paths.participantsRequests,
                    element: <ParticipantsRequests />
                  },
                  {
                    path: Paths.participantsRequestsItemEvaluation,
                    element: (
                      <ParticipantsRequestsItemViewAndEvaluation />
                    )
                  }
                ]
              },

              {
                element: <AdminRoleWrapper
                  roles={[
                    AdminRoles.SuperAdmin,
                    AdminRoles.OperationCommittee,
                    AdminRoles.GeneralSecretariat
                  ]}
                />,
                children: [
                  {
                    path: Paths.filtrationRequest,
                    element: <FiltrationRequest />
                  },
                  {
                    path: Paths.filtrationRequestItemEvaluation,
                    element: <FiltrationRequestItemViewAndEvaluation />
                  },
                  {
                    path: Paths.filtrationApproval,
                    element: <FiltrationApproval />
                  },
                  {
                    path: Paths.filtrationApprovalItemEvaluation,
                    element: <FiltrationApprovalItemViewAndEvaluation />
                  },
                ]
              },

              {
                element: <AdminRoleWrapper
                  roles={[
                    AdminRoles.SuperAdmin,
                    AdminRoles.GeneralSecretariat,
                    AdminRoles.ArbitrationCommittee,
                    AdminRoles.Judgment
                  ]}
                />,
                children: [
                  {
                    path: Paths.arbitratingRequest,
                    element: <ArbitratingRequest />
                  },
                  {
                    path: Paths.arbitratingRequestItemEvaluation,
                    element: <ArbitratingRequestItemViewAndEvaluation />
                  },
                ]
              },

              {
                element: <AdminRoleWrapper
                  roles={[
                    AdminRoles.SuperAdmin,
                    AdminRoles.GeneralSecretariat,
                    AdminRoles.ArbitrationCommittee
                  ]}
                />,
                children: [
                  {
                    path: Paths.arbitratingRequest,
                    element: <ArbitratingRequest />
                  },
                  {
                    path: Paths.arbitratingRequestItemEvaluation,
                    element: <ArbitratingRequestItemViewAndEvaluation />
                  },
                  {
                    path: Paths.arbitratingAssign,
                    element: <ArbitratingAssign />
                  },
                  {
                    path: Paths.arbitratingAssignItemEvaluation,
                    element: <ArbitratingAssignItemViewAndEvaluation />
                  },
                  {
                    path: Paths.arbitratingApproval,
                    element: <ArbitratingApproval />
                  },
                  {
                    path: Paths.arbitratingApprovalItemEvaluation,
                    element: <ArbitratingApprovalItemViewAndEvaluation />
                  },
                  {
                    path: Paths.arbitratingInterview,
                    element: <ArbitratingInterview />
                  },
                  {
                    path: Paths.arbitratingInterviewItemEvaluation,
                    element: <ArbitrationInterviewItemViewAndEvaluation />
                  },
                  {
                    path: Paths.winners,
                    element: <ArbitratingWinners />
                  },
                  {
                    path: Paths.winnersItemEvaluation,
                    element: <ArbitrationWinnersItemViewAndEvaluation />
                  },
                ]
              },

              {
                element: <AdminRoleWrapper
                  roles={[
                    AdminRoles.SuperAdmin,
                    AdminRoles.ContentManager,
                    AdminRoles.GeneralSecretariat
                  ]}
                />,
                children: [
                  {
                    path: Paths.news,
                    element: <News />
                  },
                  {
                    path: Paths.newsAddItem,
                    element: <NewsAddItem />
                  },
                  {
                    path: Paths.newsItemEdit,
                    element: <NewsItemEdit />
                  },
                  {
                    path: Paths.photos,
                    element: <Photos />
                  },
                  {
                    path: Paths.photosAddItem,
                    element: <PhotosAddItem />
                  },
                  {
                    path: Paths.photosItemEdit,
                    element: <PhotosItemEdit />
                  },
                  {
                    path: Paths.videos,
                    element: <Videos />
                  },
                  {
                    path: Paths.videosAddItem,
                    element: <VideosAddItem />
                  },
                  {
                    path: Paths.videosItemEdit,
                    element: <VideosItemEdit />
                  },
                  {
                    path: Paths.termsAndConditions,
                    element: <TermsAndConditions />
                  },
                  {
                    path: Paths.privacyPolicy,
                    element: <PrivacyPolicy />
                  },
                  {
                    path: Paths.faq,
                    element: <FAQ />
                  },
                  {
                    path: Paths.contentHomepage,
                    element: <ContentHomepage />
                  },
                  {
                    path: Paths.aboutThePrize,
                    element: <AboutThePrize />
                  },
                  {
                    path: Paths.supervisoryBoard,
                    element: <SupervisoryBoard />
                  },
                  {
                    path: Paths.generalSecretariat,
                    element: <GeneralSecretariat />
                  },
                  {
                    path: Paths.competitionPaths,
                    element: <CompetitionPaths />
                  },
                  {
                    path: Paths.competitionPathsAddItem,
                    element: <CompetitionPathsAddItem />
                  },
                  {
                    path: Paths.competitionPathsItemEdit,
                    element: <CompetitionPathsItemEdit />
                  },
                  {
                    path: Paths.competitions,
                    element: <Competitions />
                  },
                  {
                    path: Paths.competitionsAddItem,
                    element: <CompetitionsAddItem />
                  },
                  {
                    path: Paths.competitionsItemEdit,
                    element: <CompetitionsItemEdit />
                  },
                  {
                    path: Paths.regulations,
                    element: <Regulations />
                  },
                  {
                    path: Paths.arbitrationCriteria,
                    element: <ArbitrationCriteria />
                  },
                  {
                    path: Paths.competitionConditions,
                    element: <CompetitionConditions />
                  },
                  {
                    path: Paths.socialMediaAccounts,
                    element: <SocialMediaAccounts />
                  },
                  {
                    path: Paths.emailTemplate,
                    element: <EmailTemplate />
                  },
                  {
                    path: Paths.newEmailTemplate,
                    element: <CreateNewTemplate />
                  },
                  {
                    path: Paths.editEmailTemplate,
                    element: <EditEmailTemplate />
                  },
                  {
                    path: Paths.evaluationCriteria,
                    element: <EvaluationCriteria />
                  },
                  {
                    path: Paths.topWinners,
                    element: <TopWinners />
                  },
                  {
                    path: Paths.winnersContent,
                    element: <WinnersContent />
                  },
                  {
                    path: Paths.topWinnersItemDetail,
                    element: <TopWinnersItemDetail />
                  },
                  {
                    path: Paths.innovationsContent,
                    element: <Innovations />
                  }
                ]
              },
              {
                element: <AdminRoleWrapper
                  roles={[ AdminRoles.Judgment ]}
                />,
                children: [
                  {
                    path: Paths.news,
                    element: <News />
                  },
                  {
                    path: Paths.photos,
                    element: <Photos />
                  },
                  {
                    path: Paths.videos,
                    element: <Videos />
                  },
                  {
                    path: Paths.termsAndConditions,
                    element: <TermsAndConditions />
                  },
                  {
                    path: Paths.privacyPolicy,
                    element: <PrivacyPolicy />
                  },
                  {
                    path: Paths.faq,
                    element: <FAQ />
                  },
                  {
                    path: Paths.regulations,
                    element: <Regulations />
                  },
                  {
                    path: Paths.competitionConditions,
                    element: <CompetitionConditions />
                  }
                ]
              },
            ]
          }
        ]
      },

      {
        path: '*',
        element: <Navigate to={Paths.homepage} replace />
      }
    ]
  }
]);

export default router;
