import { FC, useCallback } from 'react';

import { Button, Center, Flex, Loader, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useDeleteNewsItemMutation, useNewsListQuery } from 'src/api/news/hooks';
import DataTable from 'src/components/DataTable';
import { useNewsTableColumns, useNewsTableFilters } from 'src/modules/news/hooks';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';
import { AdminRoles } from 'src/modules/admin/constants';

const News: FC = () => {
  const { user } = useAuthStore();
  const { data, isLoading: isDataLoading } = useNewsListQuery();
  const { mutate: deleteItem, isLoading: isDeleteItemLoading } = useDeleteNewsItemMutation();
  const isJudgment = user?.userRole.id === AdminRoles.Judgment;

  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteItem(id)
  }), [deleteItem]);

  const columns = useNewsTableColumns({
    onDeleteClick: openDeleteModal
  });

  const filters = useNewsTableFilters();

  return (
    <Flex direction="column">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>
          News
        </Title>
        {!isJudgment && (
          <Button
            component={Link}
            to={Paths.newsAddItem}
            leftIcon={<IconPlus size={18} />}
          >
            Add
          </Button>
        )}
      </Flex>
      {isDataLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data ? (
        <DataTable
          data={data}
          columns={columns}
          filters={filters}
          isLoading={isDeleteItemLoading}
        />
      ) : null}
    </Flex>
  );
};

export default News;
