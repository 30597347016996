import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TSocialMediaAccount, TSocialMediaAccountForm } from 'src/modules/social-media-accounts/types';

export const getSocialMediaAccounts = async (): Promise<TSocialMediaAccount[]> => {
  return privateApi.get('/admin-content/socialmediaaccount').then((res) => res.data.data);
};

export const updateSocialMediaAccount = async (data: TSocialMediaAccountForm): Promise<SuccessResponse> => {
  return privateApi.put('/social-account', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};
