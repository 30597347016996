import { useMemo } from 'react';

import { ActionIcon, Grid, Tooltip } from '@mantine/core';
import { IconChecklist, IconEye, IconUserCheck } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { AdminRoles } from 'src/modules/admin/constants';
import { RequestStatus } from 'src/modules/competitions/constants';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import { RequestFiltrationType, tableHeadings } from './constants';
import { TParticipantsRequest } from '../participants-requests/types';

import styles from '../../pages/ArbitratingRequest/ArbitratingRequest.module.scss';

export const useArbitrationRequestTableColumns = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TParticipantsRequest>();

    return [
      columnHelper.accessor('request_number', {
        header: tableHeadings.requestNumber,
        size: 155,
        enableResizing: false
      }),

      columnHelper.accessor('member.email', {
        header: tableHeadings.email,
        minSize: 160
      }),

      columnHelper.accessor('innovation_name', {
        header: tableHeadings.innovationName,
        minSize: 160
      }),

      columnHelper.accessor('path', {
        header: tableHeadings.innovationPath,
        size: 160
      }),

      columnHelper.accessor('participating_type', {
        header: tableHeadings.participatingType,
        size: 175,
        enableResizing: false,
        cell: (cell) => cell.getValue()?.name || '',
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.participating_type?.name || '';
          const valueB = rowB.original.participating_type?.name || '';

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      }),

      columnHelper.accessor('status', {
        header: tableHeadings.status,
        size: 80,
        cell: (cell) => {
          const key = cell.row.original.status_id as number;

          return (
            <span className={key === 1 ? styles.statusDone : styles.statusNotDone}>
              {key === 1 ? 'Done' : 'Not Done'}
            </span>
          );
        },
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.status?.name;
          const valueB = rowB.original.status?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, columnId, filterValue) => {
          return +filterValue === row.original.status?.id;
        }
      }),

      columnHelper.accessor('evaluation_stage', {
        header: tableHeadings.evaluationStatus,
        size: 70,
        cell: (cell) => cell.getValue()?.name,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.evaluation_stage?.name;
          const valueB = rowB.original.evaluation_stage?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, _columnId, filterValue) => {
          return +filterValue === row.original.evaluation_stage?.id;
        }
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <>
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <Tooltip label="Evaluation" withArrow arrowSize={6}>
                    <ActionIcon
                      size="sm"
                      onClick={() => {
                        navigate(
                          generatePath(Paths.arbitratingRequestItemEvaluation, {
                            id: row.original.id
                          })
                        );
                      }}
                    >
                      <IconChecklist size={20} />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              </Grid>
            </>
          );
        }
      })
    ];
  }, [navigate, user]);
};
