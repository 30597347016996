import { FC } from 'react';

import { Center, ScrollArea, Text, Box, Flex, Paper } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import styles from './Table.module.scss';
import TableCellData from './TableCellData';
import TableHeaderGroup from './TableHeaderGroup';
import { TTableProps } from './types';
import { useLayoutStore } from 'src/store/useLayoutStore';

const Table: FC<TTableProps> = ({ table, draggable, additionalComponent, onDragEnd }) => {
  const { openedInovationItem } = useLayoutStore();
  const handleDrop: OnDragEndResponder = ({ source, destination }) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    onDragEnd && onDragEnd(source.index, destination.index);
  };

  return (
    <Box className={styles.table}>
      <ScrollArea scrollbarSize={8}>
        <TableHeaderGroup table={table} draggable={draggable} additionalComponent={additionalComponent} />
        {draggable ? (
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppableList">
              {(droppable) => (
                <Box {...droppable.droppableProps} ref={droppable.innerRef}>
                  <Paper pos="relative">
                    {table.getRowModel().rows.map((row, index) => (
                      <Draggable
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(draggable) => (
                          <Flex
                            align={row.original.id === openedInovationItem ? 'flex-start' : 'center'}
                            ref={draggable.innerRef}
                            {...draggable.draggableProps}
                          >
                            <Box {...draggable.dragHandleProps} mt={row.original.id === openedInovationItem ? 26 : 0}>
                              <IconGripVertical />
                            </Box>
                            <Flex direction='column'>
                              <TableCellData row={row} table={table} key={row.id} />
                              {row.original.id === openedInovationItem && additionalComponent}
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {droppable.placeholder}
                  </Paper>
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Flex direction="column">
            {table.getRowModel().rows.length ? (
              <>
                {table.getRowModel().rows.map((row) => (
                  <>
                    <TableCellData row={row} table={table} key={row.id} />
                    {row.original.id === openedInovationItem && additionalComponent}
                  </>
                ))}
              </>
            ) : (
              <Center>
                <Text py={50} size={16}>
                  No results found
                </Text>
              </Center>
            )}
          </Flex>
        )}
      </ScrollArea>
    </Box>
  );
};

export default Table;
