export const WinnerCategory = (categoryId: number) => {
  switch (categoryId) {
    case 1:
    case 2:
    case 3:
      return 1;
    
    case 4:
    case 5:
    case 6:
      return 2;
      
    case 7:
    case 8:
    case 9:
      return 3;
  
    default:
      return 4;
  };
};
