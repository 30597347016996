import { Button, Flex } from '@mantine/core';
import styles from './AssignUser.module.scss';
import { useLayoutStore } from 'src/store/useLayoutStore';
import { IconUserCheck, IconSquareX } from '@tabler/icons';
import { useState } from 'react';
import { AdminUser } from 'src/modules/admin/types';
import { showWarningNotification } from 'src/common/helpers/notifications';
import AssignUserModal from 'src/components/AssignUserModal';
import { useAssignAribtratingToUserQuery } from 'src/api/arbitrating-requests/hooks';
import { useAuthStore } from 'src/store/useAuthStore';
import { AdminRoles } from 'src/modules/admin/constants';
import AssignAdminUserModal from 'src/components/AssignAdminUserModal';

const AssignUser = () => {  
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AdminUser[]>([]);
  const { selectedItems } = useLayoutStore();
  const { user } = useAuthStore();

  const clearSelectedItems = () => {
    useLayoutStore.setState(() => {
      return { selectedItems: [] };
    });
  };

  const handleSelectUser = (newUser: AdminUser) => {
    if (selectedUser.find((user) => user.id === newUser.id)) {
      setSelectedUser(selectedUser.filter((user) => user.id !== newUser.id));
    } else {
      setSelectedUser([...selectedUser, newUser]);
    }
  };

  const selectedUsersId = selectedUser.map((item) => item.id);
  const { mutate: assignUser, isLoading } = useAssignAribtratingToUserQuery(selectedItems, selectedUsersId);

  const handleRequest = async () => {
    if (selectedUser.length && !isLoading) {
      await assignUser();
      setOpenedModal(false);
      clearSelectedItems();
    } else {
      showWarningNotification(
        {message: 'Select at least one user to assign'}
      );
    }
  };

  return (
    <div className={styles.modalWrapper}>
      <Flex className={styles.wrapper} justify='space-between' w={560} bg='rgba(231, 245, 255, 1)'>
        <Flex align='center' gap={15}>
          <IconSquareX size={24} color='#228be6' cursor='pointer' onClick={clearSelectedItems} />
          <p>{selectedItems.length} Rows selected</p>
        </Flex>
        <Button className={styles.btn} onClick={() => setOpenedModal(!openedModal)}>
          <IconUserCheck size={16} />
          Assign
        </Button>
      </Flex>

      {openedModal && (
        <>
          {user?.userRole.id === AdminRoles.SuperAdmin ? (
            <AssignAdminUserModal
              selectedUser={selectedUser}
              handleSelectUser={handleSelectUser}
              setOpenedModal={setOpenedModal}
              handleRequest={handleRequest}
            />
          ) : (
            <AssignUserModal
              selectedUser={selectedUser}
              handleSelectUser={handleSelectUser}
              setOpenedModal={setOpenedModal}
              handleRequest={handleRequest}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AssignUser;
