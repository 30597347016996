import { FC } from 'react';

import { Box, Group, Image, Stack, Text } from '@mantine/core';

import { TGeneralSecretariatItem } from 'src/modules/general-secretariat/types';

type TProps = {
  item: TGeneralSecretariatItem;
};

const AccordionContent: FC<TProps> = ({ item }) => (
  <Group spacing={40}>
    <Box>
      <Text weight={600}>Image</Text>
      <Image src={item.image_path} width={300} />
    </Box>
    <Stack spacing={30}>
      <Box>
        <Text weight={600}>Name - En</Text>
        <Text size={14}>{item.name_en}</Text>
      </Box>
      <Box>
        <Text weight={600}>Name - Ar</Text>
        <Text size={14}>{item.name_ar}</Text>
      </Box>
      <Box>
        <Text weight={600}>Position - En</Text>
        <Text size={14}>{item.position_en}</Text>
      </Box>
      <Box>
        <Text weight={600}>Position - Ar</Text>
        <Text size={14}>{item.position_ar}</Text>
      </Box>
    </Stack>
  </Group>
);

export default AccordionContent;
