import { Button, Flex } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

import EvaluationControls from './EvaluationControls';
import styles from '../ParticipantsRequestsItemEvaluation/styles.module.scss';
import { useFiltrationApprovalQuery } from 'src/api/filtration-request/hooks';
import { useParams } from 'react-router-dom';
import { RequestStatus } from 'src/modules/competitions/constants';
import { AdminRoles } from 'src/modules/admin/constants';
import { useAuthStore } from 'src/store/useAuthStore';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';

type Props = {
  steps: string[];
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
};

const FiltrationApprovalControls = ({ steps, active, setActive }: Props) => {
  const [, scrollTo] = useWindowScroll();
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();
  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin ||
    user?.userRole.id === AdminRoles.GeneralSecretariat;

  const { data: filtrationApproval, isLoading } = useFiltrationApprovalQuery();

  const selectedItem = !isLoading && filtrationApproval.data
    .find((item: TParticipantsRequest) => item.id === id).status.id;

  const isShowControls = !isLoading &&
    (selectedItem === RequestStatus.Approved || selectedItem === RequestStatus.Rejected) &&
    isAdmin;

  const nextStep = () => {
    setActive((current) => (current < steps.length - 1 ? current + 1 : current));

    scrollTo({ y: 0 });
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));

    scrollTo({ y: 0 });
  };

  return (
    <Flex justify="space-between">
      <Button
        disabled={active === 0}
        leftIcon={<IconChevronLeft />}
        variant="subtle"
        className={styles.paginationButton}
        onClick={prevStep}
      >
        Prev
      </Button>

      {!isLoading && isShowControls && (
        <EvaluationControls />
      )}

      <Button
        disabled={active === 3}
        rightIcon={<IconChevronRight />}
        variant="subtle"
        className={styles.paginationButton}
        onClick={nextStep}
      >
        Next
      </Button>
    </Flex>
  );
};

export default FiltrationApprovalControls;
