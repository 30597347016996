import axios, { AxiosError } from 'axios';

import { API_BASE_URL } from 'src/common/constants/api';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';

const publicApi = axios.create({
  baseURL: API_BASE_URL
});


publicApi.interceptors.request.use((config) => ({
  ...config,
  headers: {
    'Accept-Language': 'en',
    'x-api-key': process.env.REACT_APP_API_KEY || ''
  }
}));

publicApi.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<any>) => {
    if (!error.isAxiosError) {
      throw 'Unknown error';
    }

    if (error.response?.status === 500) {
      router.navigate(Paths.error);

      throw {
        type: 'Server error',
        status: 500,
        message: 'Server error'
      };
    }

    if (error.response?.status === 422) {
      throw {
        type: 'Validation error',
        status: 422,
        errors: error.response.data?.errors
      };
    }

    throw 'Uncaught error';
  }
);

export default publicApi;
