import { AdminRoles } from '../admin/constants';
import { RequestStatus } from '../competitions/constants';

export const tableHeadings = {
  image: 'Image',
  date: 'Date',
  innovationName: 'Innovation Name',
  innovationPath: 'Innovation Path',
  participantsName: 'Participants Name',
  innovationDescription: 'Innovation Description',
  requestNumber: 'Request Number'
};

export enum RequestFiltrationType {
  Individual = 1,
  Group = 2
}

type VisibleRequestStatusesByRole = Partial<{
  [key in AdminRoles]: RequestStatus[];
}>;

export const visibleRequestStatusesByRole: VisibleRequestStatusesByRole = {
  [AdminRoles.OperationCommittee]: [
    RequestStatus.Approved,
    RequestStatus.Rejected,
    RequestStatus.FinalApproved,
    RequestStatus.FinalRejected
  ]
};
