export enum AdminRoles {
  SuperAdmin = 1,
  OperationCommittee = 2,
  Judgment = 3,
  SupervisoryBoard = 4,
  ContentManager = 5,
  Authorizer = 6,
  GeneralSecretariat = 7, // TODO same as SuperAdmin
  ArbitrationCommittee = 8
}
