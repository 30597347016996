import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TAboutPrize, TAboutPrizeForm } from 'src/modules/about-prize/types';

import { getAboutPrize, updateAboutPrize } from './api';
import { ABOUT_PRIZE_QUERY_KEY } from './constants';

export const useAboutPrizeQuery = () => {
  return useQuery<TAboutPrize>({
    queryKey: [ABOUT_PRIZE_QUERY_KEY],
    queryFn: getAboutPrize
  });
};

export const useUpdateAboutPrizeMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TAboutPrizeForm>({
    mutationFn: updateAboutPrize,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ABOUT_PRIZE_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
