import privateApi from 'src/common/configs/private-api';
import { SuccessResponse } from 'src/common/types/api';
import { TTermsAndConditions } from 'src/modules/terms-and-conditions/types';

export const getTermsAndConditions = async (): Promise<TTermsAndConditions> => {
  return privateApi.get('/admin-content/terms').then((res) => res.data.data);
};

export const updateTermsAndConditions = async (data: TTermsAndConditions): Promise<SuccessResponse> => {
  return privateApi.put('/terms', data).then((res) => res.data);
};
