import { SelectItem } from '@mantine/core';

export const statusSelectData: SelectItem[] = [
  {
    value: '3',
    label: 'Missing Requirements'
  },
  {
    value: '4',
    label: 'Approved'
  },
  {
    value: '5',
    label: 'Rejected'
  }
];
