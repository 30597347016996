import { useMutation, useQuery } from '@tanstack/react-query';
import { getShowHidePageList, setShowHidePage } from './api';
import { SHOW_HIDE_LIST_KEY } from './constants';
import { TPutShowHide, TShowHideList } from './types';
import queryClient from 'src/common/configs/query-client';
import { showErrorNotification, showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';

export const useShowHidePageListQuery = () => {
  return useQuery<TShowHideList>({
    queryKey: [SHOW_HIDE_LIST_KEY],
    queryFn: getShowHidePageList
  });
};

export const useSetShowHidePageQuery = (page: string) => {
  return useMutation<SuccessResponse, ErrorResponse, TPutShowHide>({
    mutationFn: setShowHidePage,
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries([SHOW_HIDE_LIST_KEY]);
      showSuccessNotification({ message: `${page} page ${variables.is_show ? 'show' : 'hide'} successfully` });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      };
    }
  });
};
