import { FC, useEffect, useState } from 'react';

import { Button, Flex, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { usePrivacyPolicyQuery, useUpdatePrivacyPolicyMutation } from 'src/api/privacy-policy/hooks';
import ContentPageWrapper from 'src/components/ContentPageWrapper';
import Form from 'src/components/Form';
import RichTextEditor from 'src/components/RichTextEditor';
import { TPrivacyPolicy } from 'src/modules/privacy-policy/types';
import { TTermsAndConditions } from 'src/modules/terms-and-conditions/types';

const PrivacyPolicy: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data, isLoading: isDataLoading } = usePrivacyPolicyQuery();
  const { mutate, isLoading: isMutateLoading } = useUpdatePrivacyPolicyMutation();

  const form = useForm<TPrivacyPolicy>({
    defaultValues: {
      title_en: '',
      title_ar: '',
      text_en: '',
      text_ar: ''
    }
  });

  useEffect(() => {
    if (data) {
      form.reset(data);
    }
  }, [form, data]);

  const onEditCancel = () => {
    setIsEditing(false);
    form.reset();
  };

  const handleSubmit: SubmitHandler<TTermsAndConditions> = (data) => {
    mutate(data);
    setIsEditing(false);
  };

  return (
    <ContentPageWrapper
      title="Privacy Policy"
      isEditing={isEditing}
      onEditEnter={() => setIsEditing(true)}
      onEditLeave={onEditCancel}
    >
      <LoadingOverlay visible={isDataLoading || isMutateLoading} />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="title_en"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Title - En"
                disabled={!isEditing}
              />
            )}
          />

          <Controller
            control={form.control}
            name="title_ar"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Title - Ar"
                disabled={!isEditing}
              />
            )}
          />

          <Controller
            control={form.control}
            name="text_en"
            rules={{ required: 'Required' }}
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => (
              <RichTextEditor
                onUpdate={onChange}
                content={value}
                label="Text - En"
                error={error?.message}
                disabled={!isEditing}
              />
            )}
          />

          <Controller
            control={form.control}
            name="text_ar"
            rules={{ required: 'Required' }}
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => (
              <RichTextEditor
                onUpdate={onChange}
                content={value}
                label="Text - Ar"
                error={error?.message}
                disabled={!isEditing}
                isRtl
              />
            )}
          />

          {isEditing && (
            <Flex justify="flex-end">
              <Button type="submit">
                Save
              </Button>
            </Flex>
          )}
        </Stack>
      </Form>
    </ContentPageWrapper>
  );
};

export default PrivacyPolicy;
