import { FC, useEffect, useRef } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useArbitratingAssignQuery } from 'src/api/arbitrating-requests/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';

import { useArbitratingRequestFilter } from './hooks';
import { getArbitratingApprovalForExport } from './utils';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';
import { useArbitrationAssignTableColumns } from 'src/modules/arbitration-assign/hooks';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';
import { useLayoutStore } from 'src/store/useLayoutStore';

const ArbitratingAssign: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();
  const { selectedItems } = useLayoutStore();

  const { data: ArbitratingAssign, isLoading: ArbitratingLoading } = useArbitratingAssignQuery();

  const columns = useArbitrationAssignTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const ArbitratingAssignExport = getArbitratingApprovalForExport(ArbitratingAssign?.data);
  const requestsFilteredByUserRole = useArbitratingRequestFilter(ArbitratingAssign?.data);

  useEffect(() => {
    useLayoutStore.setState((state) => ({
      ...state,
      selectedItems: [],
    }));
  }, []);

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Assign</Title>
        <ExportButton filename="Arbitrating Approval" data={ArbitratingAssignExport} />
      </Flex>
      {ArbitratingLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {requestsFilteredByUserRole ? (
        <DataTable
          columns={columns}
          data={
            ArbitratingAssign?.data
              .filter((item: TParticipantsRequest) => item.evaluation_stage?.id === 5)
            || []
          }
          filters={filters}
          tableInstanceRef={tableInstanceRef}
          selectedItemBlock
        />
      ) : null}
    </Flex>
  );
};

export default ArbitratingAssign;
