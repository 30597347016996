import { useEffect, useState } from 'react';

import { Button, Divider, Flex, Text, TextInput } from '@mantine/core';

import { useCompetitionPathsListQuery } from 'src/api/competition-paths/hooks';
import logo from 'src/assets/svg/logo-colored.svg';
import { useLayoutStore } from 'src/store/useLayoutStore';

import styles from '../../pages/WinnersContent/WinnersContent.module.scss';
import imgStyles from '../../pages/TopWinnersDetail/TopWinnersDetail.module.scss'
import Dropdown from '../Dropdown';
import { useInnovationListQuery, usePutInnovationDataQuery } from 'src/api/innovations/hooks';
import { TInnovationPutData } from 'src/api/innovations/types';
import ImageUpload from '../ImageUpload';

const InnovationsEditableData = () => {
  const [editMode, setEditMode] = useState(false);
  const { openedInovationItem } = useLayoutStore();
  const [allParticipants, setAllParticipants] = useState<string[]>();
  const [value, setValue] = useState<TInnovationPutData>({
    ParticipatingRequestId: '',
    InnovationNameAr: '',
    InnovationNameEn: '',
    InnovationDescriptionAr: '',
    InnovationDescriptionEn: '',
    InnovationPathId: '',
    InnovationImage: null,
    InnovationDate: null,
  });
  const { data, isLoading } = useInnovationListQuery();
  const { data: CompetitionPaths, isLoading: CompetitionPathsLoading } = useCompetitionPathsListQuery();
  const { mutate: updateInnovationData } = usePutInnovationDataQuery();

  const item = !isLoading && data?.find((item) => item.id === openedInovationItem);
  const CompetitionPathsEn = !CompetitionPathsLoading && CompetitionPaths?.map((el) => {
    return {id: el.id, title: el.title_en};
  });
  const InnovationID = CompetitionPathsEn && item && CompetitionPathsEn.find((el: any) => el.title === item.innovation_path);

  useEffect(() => {
    if (item) {
      setAllParticipants([item.main_participant_name, ...item.participants_names]);

      setValue({
        ParticipatingRequestId: item.id,
        InnovationNameAr: item.innovation_title_name_ar,
        InnovationNameEn: item.innovation_title_name_en,
        InnovationDescriptionAr: item.berief_description_ar,
        InnovationDescriptionEn: item.berief_description_en,
        InnovationPathId: InnovationID ? InnovationID.id.toString() : item?.innovation_path,
        InnovationImage: item.image || logo,
        InnovationDate: item.date,
      });
    };
  }, [item, openedInovationItem]);

  return (
    <Flex pt={20} align='center' direction='column'>
      {!isLoading && item && (
        <>
          <Flex justify='center' w={545} maw={545} direction='column' mx='auto' my={30} gap={24}>
            <Flex direction='column' mx='auto' w='max-content'>
              <Text color='#767676' fz={14} fw={500}>Image</Text>
              <Flex w={200} h={200} align='center' justify='center' className={imgStyles.ImgWrapper}>
                  <ImageUpload
                    value={value.InnovationImage}
                    onChange={(img) => {
                      if (img) {
                        setValue({...value, InnovationImage: img });
                      } else {
                        setValue({...value, InnovationImage: null });
                      }
                    }}
                    required
                    disabled={!editMode}
                    additionalStyles={imgStyles.dropzone}
                  />
                </Flex>
            </Flex>

            {[item.main_participant_name, ...item.participants_names].length ? (
              <>
                <Divider />
                <Flex direction='column'>
                  {editMode ? (
                    <Flex gap={4} direction='column'>
                      <Text color='#767676' fz={14} fw={500}>Participants Names</Text>
                      {[item.main_participant_name, ...item.participants_names].map((name, id) => (
                        <TextInput
                          key={id}
                          value={allParticipants ? allParticipants[id] : name}
                          onChange={(e) => {
                            const updatedParticipantsNames = allParticipants || [];
                            updatedParticipantsNames[id] = e.target.value;
                            setAllParticipants([...updatedParticipantsNames]);
                          }}
                        />
                      ))}
                    </Flex>
                  ) : (
                    <>
                      <Text color='#767676' fz={14} fw={500}>Participants Names</Text>
                      {[item.main_participant_name, ...item.participants_names].map((name, id) => (
                        <Text key={id}>{id + 1}. {name}</Text>
                      ))}
                    </>
                  )}
                </Flex>
              </>
            ) : null}

            <Divider />

            <Flex direction='column'>
              <Text color='#767676' fz={14} fw={500}>Innovation Date</Text>
              <Text>{value.InnovationDate}</Text>
            </Flex>

            <Flex justify='space-between' gap={20}>
              <Flex direction='column' className={styles.fullWidth}>
                {editMode ? (
                  <TextInput
                    label='Innovation Name - En'
                    withAsterisk
                    value={value.InnovationNameEn}
                    onChange={(e) => setValue({ ...value, InnovationNameEn: e.target.value })}
                  />
                ) : (
                  <>
                    <Text color='#767676' fz={14} fw={500}>Innovation Name - En</Text>
                    <Text>{value.InnovationNameEn}</Text>
                  </>
                )}
              </Flex>

              <Flex direction='column' className={styles.fullWidth}>
                {editMode ? (
                  <TextInput
                    label='Innovation Name - Ar'
                    withAsterisk
                    value={value.InnovationNameAr}
                    onChange={(e) => setValue({ ...value, InnovationNameAr: e.target.value })}
                  />
                ) : (
                  <>
                    <Text color='#767676' fz={14} fw={500}>Innovation Name - Ar</Text>
                    <Text>{value.InnovationNameAr}</Text>
                  </>
                )}
              </Flex>
            </Flex>

            <Divider />

            <Flex direction='column'>
              {editMode ? (
                <Dropdown
                  data={CompetitionPathsEn || []}
                  label='Innovation Path'
                  value={item ? item?.innovation_path : ''}
                  defaultValue={value}
                  setVal={setValue}
                  full
                  path
                />
              ) : (
                <>
                  <Text color='#767676' fz={14} fw={500}>Innovation Path - En</Text>
                  <Text>{item?.innovation_path}</Text>
                </>
              )}
            </Flex>

            <Divider />

            <Flex direction='column'>
              <Text color='#767676' fz={14} fw={500}>Innovation Description - En</Text>
              {editMode ? (
                <Text
                  w={545}
                  className={styles.editableText}
                  contentEditable
                  dangerouslySetInnerHTML={{ __html: item?.berief_description_en || '' }}
                  onKeyUp={(e: any) => setValue({ ...value, InnovationDescriptionEn: e.target.innerText })}
                />
              ) : (
                <Text className={styles.textWrap} w={545} maw='100%'>{item?.berief_description_en}</Text>
              )}
            </Flex>
            <Flex direction='column' align='flex-end'>
              <Text color='#767676' fz={14} fw={500}>Innovation Description - Ar</Text>
              {editMode ? (
                <Text
                  w={545}
                  className={styles.editableText}
                  contentEditable
                  dir='rtl'
                  dangerouslySetInnerHTML={{ __html: item?.berief_description_ar || '' }}
                  onKeyUp={(e: any) => setValue({ ...value, InnovationDescriptionAr: e.target.innerText })}
                />
              ) : (
                <Text className={styles.textWrap} dir='rtl' w={545} maw={545}>{item?.berief_description_ar}</Text>
              )}
            </Flex>

            <Divider />

            {editMode ? (
              <Flex gap={20}>
                <Button
                  w="max-content"
                  onClick={() => {
                    updateInnovationData(value);
                    setEditMode(false);
                  }}
                >Save</Button>
                <Button variant="outline" w="max-content" onClick={() => setEditMode(false)}>Cancel</Button>
              </Flex>
            ) : (
              <Flex justify='space-between'>
                <Button w="max-content" onClick={() => setEditMode(true)}>Edit</Button>
              </Flex>
            )}
          </Flex>

          <Divider orientation='vertical' h={1} bg='#dee2e6' />
        </>
      )}
    </Flex>
  );
};

export default InnovationsEditableData;
