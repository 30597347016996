import { FC, useMemo } from 'react';

import { Button, Flex, Group, Select, Stack } from '@mantine/core';
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';

import { useParticipantsRequestsFieldListQuery } from 'src/api/participants-requests/hooks';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import TextInput from 'src/components/TextInput';
import { TFieldComment } from 'src/modules/participants-requests/types';

type Props = {
  control: Control<any>;
};

const CommentsForm: FC<Props> = ({ control }) => {
  const { data: requestFields } = useParticipantsRequestsFieldListQuery();
  const normalizedFields = useNormalizedItemsData(requestFields, {}, true);

  const selectedFields = useWatch({
    control: control,
    name: 'fields'
  });

  const fieldList = useMemo(() => {
    return normalizedFields.map((item) => ({
      ...item,
      disabled: selectedFields.some((field: TFieldComment) => field.field_id === item.value)
    }));
  }, [normalizedFields, selectedFields]);


  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control: control,
    name: 'fields'
  });

  const handleRemoveField = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <Stack>
      {fields.map((item, index) => (
        <Flex key={item.id} gap={20}>
          <Group sx={{ flexGrow: 1 }} grow align="flex-start">
            <Controller
              control={control}
              name={`fields.${index}.field_id`}
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  data={fieldList}
                  label="Field"
                  required
                  clearable
                />
              )}
            />

            <Controller
              control={control}
              name={`fields.${index}.comment`}
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Comment"
                  required
                />
              )}
            />
          </Group>

          <Button
            variant="subtle"
            color="red"
            onClick={() => handleRemoveField(index)}
            mt={25}
          >
            Remove
          </Button>
        </Flex>
      ))}
      <Button
        variant="subtle"
        fullWidth
        onClick={() => append({ field_id: '', comment: '' })}
      >
        Add comment
      </Button>
    </Stack>
  );
};

export default CommentsForm;
