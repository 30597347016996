import { FC, useState } from 'react';

import { Avatar, clsx, Menu, Text } from '@mantine/core';
import { IconChevronDown, IconSettings, IconUser, IconLogin } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useAdminLogoutMutation } from 'src/api/admin/hooks';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import styles from './styles.module.scss';

const UserMenu: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useAuthStore();
  const { mutate: logout } = useAdminLogoutMutation();

  return (
    <Menu
      shadow="md"
      width={200}
      opened={isOpen}
      withinPortal={true}
      onChange={setIsOpen}
      position="bottom-end"
    >
      <Menu.Target>
        <button className={styles.root}>
          <Avatar radius="xl" size={30}>
            <IconUser size={20} />
          </Avatar>
          <Text size="sm" weight={500} align="start">
            {user?.fullName}
          </Text>
          <IconChevronDown size={18} className={clsx(styles.icon, { [styles.open]: isOpen })} />
        </button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Application</Menu.Label>
        <Menu.Item
          component={Link}
          to={Paths.account}
          icon={<IconSettings size={16} />}
        >
          Account settings
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          color="red"
          icon={<IconLogin size={16} />}
          onClick={() => logout()}
        >
          Sign out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserMenu;
