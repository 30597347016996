import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { THomeInfo, THomeInfoForm } from 'src/modules/homeinfo/types';

import { getHomepageInfo, updateHomeInfo } from './api';
import { HOME_INFO_QUERY_KEY } from './constants';

export const useHomeInfoQuery = () => {
  return useQuery<THomeInfo>({
    queryKey: [HOME_INFO_QUERY_KEY],
    queryFn: getHomepageInfo
  });
};

export const useUpdateHomeInfoMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, THomeInfoForm>({
    mutationFn: updateHomeInfo,
    onSuccess: async () => {
      await queryClient.invalidateQueries([HOME_INFO_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
