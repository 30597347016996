import { FC } from 'react';

import { Box, Button, LoadingOverlay, Stack } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

import Form from 'src/components/Form';
import TextInput from 'src/components/TextInput';
import { TFAQItemForm } from 'src/modules/faq/types';

import RichTextEditor from '../RichTextEditor';

type TProps = {
  submitText: string;
  onSubmit: (data: TFAQItemForm) => void;
  defaultValues: TFAQItemForm;
  isLoading: boolean;
};

const ManageFAQ: FC<TProps> = ({
  defaultValues,
  submitText,
  onSubmit,
  isLoading
}) => {
  const form = useForm({ defaultValues });

  return (
    <Box
      component={Form}
      w="100%"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <LoadingOverlay visible={isLoading} />

      <Stack spacing={30}>
        <Controller
          control={form.control}
          name="question_en"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Question - En"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="question_ar"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Question - Ar"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="answer_en"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <RichTextEditor
              content={value}
              onUpdate={onChange}
              error={error?.message}
              label="Answer - En"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="answer_ar"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <RichTextEditor
              content={value}
              onUpdate={onChange}
              error={error?.message}
              label="Answer - Ar"
              disabled={isLoading}
              required
              isRtl
            />
          )}
        />

        <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
          {submitText}
        </Button>
      </Stack>
    </Box>
  );
};

export default ManageFAQ;
