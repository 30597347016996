import { FC, useEffect, useRef, useState } from 'react';

import { Button, Center, Flex, Group, Loader, Title } from '@mantine/core';
import { IconBrandSpacehey } from '@tabler/icons';
import { Table } from '@tanstack/react-table';

import {
  useAllWinnersQuery,
  useAssignInterviewedToWinnersQuery,
  useInterviewedQuery,
  useQualifiedForInterviewQuery,
  useSendArbitratingInterviewEmailQuery
} from 'src/api/arbitrating-requests/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { useArbitratingInterviewTableColumns, useArbitratingInterviewTableScoreColumns } from 'src/modules/arbitration-interview/hooks';
import { RequestStatus } from 'src/modules/competitions/constants';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';
import { useLayoutStore } from 'src/store/useLayoutStore';

import ArbitratingStatusTabs from './ArbitratingStatusTabs';
import { useArbitratingRequestFilter } from './hooks';
import styles from '../../components/FinalEmailButton/FinalEmailButton.module.scss';
import { getArbitratingApprovalForExport } from '../ArbitratingRequest/utils';

const ArbitratingInterview: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();
  const { selectedInterviewItems } = useLayoutStore();
  const [activeTab, setActiveTab] = useState<'Interviewed' | 'QualifiedForInterview'>('QualifiedForInterview');

  const { data: QualifiedForInterview, isLoading: QualifiedForInterviewLoading } = useQualifiedForInterviewQuery();
  const { data: Interviewed, isLoading: InterviewedLoading } = useInterviewedQuery();
  const { data: ArbitrationWinners, isLoading: ArbitrationWinnersLoading } = useAllWinnersQuery();
  const { mutate: AssignToWinners } = useAssignInterviewedToWinnersQuery(selectedInterviewItems);
  const { mutate: interviewEmail } = useSendArbitratingInterviewEmailQuery();

  const isDataLoaded = !QualifiedForInterviewLoading && !InterviewedLoading && !ArbitrationWinnersLoading;
  const Data = isDataLoaded && QualifiedForInterview && Interviewed && ArbitrationWinners &&
  [...QualifiedForInterview.data, ...Interviewed.data, ...ArbitrationWinners.data];

  const filteredData = (Data || []).sort((a: TParticipantsRequest, b: TParticipantsRequest) => {
      if (a.total_avg_criteria && b.total_avg_criteria) {
        return +b.total_avg_criteria - +a.total_avg_criteria;
      } else return 0;
    });
  
  const scoreColumns = useArbitratingInterviewTableScoreColumns();
  const columns = useArbitratingInterviewTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const ArbitratingAssignExport = getArbitratingApprovalForExport(Data || []);
  const requestsFilteredByUserRole = useArbitratingRequestFilter(Data || []);

  const handleTabChange = (tab: 'Interviewed' | 'QualifiedForInterview') => {
    if (tab) setActiveTab(tab);
  };

  const filterByRequestStatus = activeTab === 'QualifiedForInterview'
    ? ( item: TParticipantsRequest) => item?.status?.id === RequestStatus.QualifiedForInterview
    : ( item: TParticipantsRequest) => item?.status?.id === RequestStatus.Interviewed || item?.status?.id === RequestStatus.NotWinner;

  const activeTabData = isDataLoaded && (filteredData || [])?.filter(filterByRequestStatus);

  const clearApprovalItems = () => {
    useLayoutStore.setState((state) => ({
      ...state,
      selectedInterviewItems: []
    }));
  };

  useEffect(() => {
    clearApprovalItems();
  }, []);

  const AssignInterviewedToWinners = async () => {
    await AssignToWinners();
    await clearApprovalItems();
  };

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Interview</Title>
        <Group display="flex">
          {activeTab === 'Interviewed' ? (
            <Button
              disabled={selectedInterviewItems.length === 0}
              leftIcon={<IconBrandSpacehey />}
              onClick={AssignInterviewedToWinners}
            >
              Winner
            </Button>
          ) : (
            <Button
              className={`${styles.button} ${styles.approve}`}
              disabled={!(QualifiedForInterview && QualifiedForInterview.data.length)}
              onClick={() => interviewEmail()}
            >Interview Email</Button>
          )}
          <ExportButton filename="Arbitrating Interview" data={ArbitratingAssignExport} />
        </Group>
      </Flex>
      {!isDataLoaded && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {requestsFilteredByUserRole ? (
        <DataTable
          columns={activeTab === 'Interviewed' ? scoreColumns : columns}
          data={activeTabData || []}
          filters={filters}
          tableInstanceRef={tableInstanceRef}
          extraSearchElement={<ArbitratingStatusTabs handleTabChange={handleTabChange} />}
        />
      ) : null}
    </Flex>
  );
};

export default ArbitratingInterview;
