import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { THomeInfo, THomeInfoForm } from 'src/modules/homeinfo/types';

export const getHomepageInfo = async (): Promise<THomeInfo> => {
  return privateApi.get('/admin-content/homeinfo').then((res) => res.data.data);
};

export const updateHomeInfo = async (data: THomeInfoForm): Promise<SuccessResponse> => {
  return privateApi.put('/homeinfo', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};
