import { FC } from 'react';

import { Button, Center, Flex, Loader, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons';

import { useAdminListQuery } from 'src/api/admin/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { useAdminsTableColumns, useAdminTableFilters } from 'src/modules/admin/hooks';
import { openAdminAddNewModal } from 'src/providers/ModalsProvider/helpers';

import { getAdminsForExport } from './utils';

const Admins: FC = () => {
  const { data: adminList, isLoading } = useAdminListQuery();

  const columns = useAdminsTableColumns();
  const filters = useAdminTableFilters();
  
  const exportedData = getAdminsForExport(adminList?.data);
  return (
    <Flex direction="column">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>
          Admins
        </Title>
        <Flex gap={10}>
          <ExportButton filename='Admins' data={exportedData} />
          <Button
            leftIcon={<IconPlus size={18} />}
            onClick={openAdminAddNewModal}
          >
            Add
          </Button>
        </Flex>
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {adminList ? (
        <DataTable
          data={adminList.data}
          columns={columns}
          filters={filters}
        />
      ) : null}
    </Flex>
  );
};

export default Admins;
