import { Box, Center, Loader, Text } from '@mantine/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie
} from 'recharts';

import { useUsersListQuery } from 'src/api/users/hooks';

import { groupUsersByProperty } from '../Users/utils';
import { COLORS, mapForChart, renderColoredCells } from './helpers';

const UsersStatistics = () => {
  const { data: usersList, isLoading: isUsersListLoading } = useUsersListQuery();

  const groupedByNationality = groupUsersByProperty(usersList?.data || [], 'nationality');
  const groupedByGender = groupUsersByProperty(usersList?.data || [], 'gender');

  const nationalityData = mapForChart(groupedByNationality).map((a) => ({
    name: a.description,
    amount: a.amount
  }));

  const genderData = [
    {
      Male: groupedByGender.Male?.length,
      Female: groupedByGender.Female?.length
    }
  ];

  return (
    <Box>
      <Text size={28} weight={700} align="center">
        Users{' '}
        <Text component="span" color="dimmed">
          (Total: {usersList?.data.length || '?'})
        </Text>
      </Text>
      {isUsersListLoading ? (
        <Center py={40}>
          <Loader size="lg" />
        </Center>
      ) : (
        <>
          <Box h={300} mb={40}>
            <Text size="lg" align="center" weight={600}>
              Nationality
            </Text>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={500} height={300}>
                <Pie data={nationalityData} outerRadius={100} dataKey="amount">
                  {renderColoredCells(nationalityData)}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box>
            <Text size="lg" align="center" weight={600}>
              Gender
            </Text>
            <ResponsiveContainer width="100%" height={60}>
              <BarChart
                layout="vertical"
                data={genderData}
                margin={{
                  left: 20,
                  right: 20
                }}
              >
                <XAxis hide type="number" tick={{ fontSize: 12 }} />
                <YAxis hide type="category" dataKey="description" width={180} />
                <Tooltip />
                <Bar stackId="a" dataKey="Male" fill={COLORS[1]} />
                <Bar stackId="a" dataKey="Female" fill={COLORS[6]} />
                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UsersStatistics;
