import { useQuery } from '@tanstack/react-query';

import { GENDERS_LIST } from 'src/api/genders/constants';
import { Gender } from 'src/modules/genders/types';

import { getGenders } from './api';

export const useGendersQuery = () => {
  return useQuery<Gender[]>({
    queryKey: [GENDERS_LIST],
    queryFn: async () => getGenders().then((res) => res.data.data)
  });
};
