import { FC } from 'react';

import { Box, Stack, Text } from '@mantine/core';

import { TFormSectionProps } from './types';

const FormSection: FC<TFormSectionProps> = ({
  children,
  title
}) => (
  <Box>
    <Text weight={600} size={18} align="center">
      {title}
    </Text>
    <Stack spacing={20}>
      {children}
    </Stack>
  </Box>
);

export default FormSection;
