import { useMemo } from 'react';

import { TParticipantsRequest } from 'src/modules/participants-requests/types';
import { isSuperAdmin } from 'src/modules/users/utils';
import { useAuthStore } from 'src/store/useAuthStore';

import { createCanUserSeeRequest } from './utils';

export const useFiltrationRequestFilter = (filtrations?: TParticipantsRequest[]) => {
  const { user } = useAuthStore();

  const requestsFilteredByUserRole = useMemo(() => {
    if (!user?.userRole.id) return [];

    if (isSuperAdmin(user)) {
      return filtrations;
    }

    const canUserSeeRequest = createCanUserSeeRequest(user);

    return filtrations?.filter(canUserSeeRequest);
  }, [filtrations, user]);

  return requestsFilteredByUserRole;
};
