import { FC } from 'react';

import { Center, Title, Text, Badge } from '@mantine/core';

import Paper from 'src/components/Paper';
import { renderAdminStatus } from 'src/modules/admin/helpers';
import { useAuthStore } from 'src/store/useAuthStore';

import InfoLabel from './InfoLabel';
import InfoWrapper from './InfoWrapper';

const Account: FC = () => {
  const { user } = useAuthStore();

  return (
    <Center>
      <Paper w={700}>
        <Title order={3} mb={30}>Account settings</Title>
        {user ? (
          <InfoWrapper>
            <InfoLabel label="Full name">
              <Text size={14}>{user.fullName}</Text>
            </InfoLabel>
            <InfoLabel label="Email">
              <Text size={14}>{user.email}</Text>
            </InfoLabel>
            <InfoLabel label="Mobile number">
              <Text size={14}>{user.mobileNumber}</Text>
            </InfoLabel>
            <InfoLabel label="User role">
              <Badge variant="outline">{user.userRole.name}</Badge>
            </InfoLabel>
            <InfoLabel label="Activation status">
              {renderAdminStatus(user.activeStatus)}
            </InfoLabel>
          </InfoWrapper>
        ) : null}
      </Paper>
    </Center>
  );
};

export default Account;
