import styles from '../GeneratePDF.module.scss'
import logo from 'src/assets/svg/logo-colored.svg';
import { getFullName } from 'src/pages/ParticipantsRequestsItemView/helpers';
import { Flex, Grid, Image, Text } from '@mantine/core';
import { ParticipantInfo } from 'src/pages/ParticipantsRequestsItemView/Participant';

interface TProps {
  data: any
}

const ParticipantData = ({ data }: TProps) => {
  const { participating_type_id, partcipants_data } = data;

  return (
    <div className={styles.page}>
      <div className={styles.image}><Image src={logo} width={120} height={120} fit="contain" /></div>
      <div className={styles.divider} />

      <div className={styles.content}>
        <div className={styles.title}>
          <h3>Participant Data</h3>
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Participating Type</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{participating_type_id?.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Number of Participants</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{partcipants_data?.length}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Participants Information</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>
              <Grid>
                {partcipants_data.map((participant: any, id: number) => (
                  <>
                    <h5><b>Participants Number - #{id + 1}</b></h5>
                    <Grid.Col md={6} xl={12} key={participant.user_identity}>
                      <ParticipantInfo {...participant} />
                    </Grid.Col>
                  </>
                ))}
              </Grid>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Applicant Name</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{getFullName(data.member)}</div>
          </div>
          <Grid className={styles.border}>
            <Grid.Col md={6} xl={11}>
              <Flex
                key={`participant_${data.member.user_identity}`}
                justify="space-between"
                className={styles.section}
              >
                <Text weight={500}>{getFullName(data.member)}</Text>
                <Text weight={500}>{data.participating_percentage}%</Text>
              </Flex>
            </Grid.Col>
          </Grid>
        </div>
      </div>

      <h3>page-2</h3>
    </div>
  )
}

export default ParticipantData;