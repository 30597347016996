import { FC, useEffect, useState } from 'react';

import { Box, Button, Center, LoadingOverlay, MultiSelect, Select, Stack, TextInput, Title } from '@mantine/core';
import { Controller, UseFormReturn } from 'react-hook-form';

import { useCompetitionPathsListQuery } from 'src/api/competition-paths/hooks';
import DatePicker from 'src/components/DatePicker';
import Form from 'src/components/Form';
import ImageUpload from 'src/components/ImageUpload';
import Paper from 'src/components/Paper';
import RichTextEditor from 'src/components/RichTextEditor';
import Textarea from 'src/components/Textarea';
import { normalizeCompetitionPathsForMultiSelect } from 'src/modules/competition-paths/helpers';
import { TCompetitionItem, TCompetitionItemForm } from 'src/modules/competitions/types';
import { useAuthStore } from 'src/store/useAuthStore';
import { AdminRoles } from 'src/modules/admin/constants';

type TProps = {
  title: string;
  submitText: string;
  data?: TCompetitionItem | null | undefined;
  onSubmit: (data: TCompetitionItemForm) => void;
  form: UseFormReturn<TCompetitionItemForm>;
  isLoading: boolean;
};

const ManageCompetition: FC<TProps> = ({
  title,
  submitText,
  onSubmit,
  form,
  data,
  isLoading
}) => {
  const { user } = useAuthStore();
  const isAdmin =
    user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.ContentManager
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const { data: competitionPaths, isLoading: isCompetitionPathsLoading } = useCompetitionPathsListQuery();
  const [showDate, setShowDate] = useState<boolean>(isAdmin && !!data?.start_date && !!data?.end_date);

  const isDisabled = isLoading || isCompetitionPathsLoading;

  useEffect(() => {
    if (data && showDate === false && data?.start_date && data?.end_date) {
      form.setValue('start_date', null);
      form.setValue('end_date', null);
    }
  }, [showDate, data, isLoading])
 
  return (
    <Center>
      <Paper w={700}>
        <Title order={3} mb={30}>
          {title}
        </Title>
        <Box
          component={Form}
          w="100%"
          onSubmit={form.handleSubmit(onSubmit)}
          pos="relative"
        >
          <LoadingOverlay visible={isDisabled} />

          <Stack spacing={30}>
            <Controller
              control={form.control}
              name="title_en"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Title - En"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="title_ar"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Title - Ar"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="description_en"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Textarea
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Description - En"
                  disabled={isDisabled}
                  required
                />
              )}
            />

            <Controller
              control={form.control}
              name="description_ar"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Textarea
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Description - Ar"
                  disabled={isDisabled}
                  required
                />
              )}
            />

            {isAdmin && (
              <Button onClick={(e) => {
                e.preventDefault();
                setShowDate(!showDate)
              }}>
                {showDate ? 'Disable date' : 'Enable date'}
              </Button>
            )}

            {showDate ? (
              <>
                <Controller
                  control={form.control}
                  name="start_date"
                  rules={{ required: 'Required' }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      label="Start Date"
                      disabled={isDisabled}
                      required
                    />
                  )}
                />

                <Controller
                  control={form.control}
                  name="end_date"
                  rules={{ required: 'Required' }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      label="End Date"
                      disabled={isDisabled}
                      required
                    />
                  )}
                />
              </>
            ) : null}


            <Controller
              control={form.control}
              name="location_en"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Location - En"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="location_ar"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Location - Ar"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="image"
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Image"
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="qualification_en"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <RichTextEditor
                  content={value}
                  onUpdate={onChange}
                  error={error?.message}
                  label="Qualification - En"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="qualification_ar"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <RichTextEditor
                  content={value}
                  onUpdate={onChange}
                  error={error?.message}
                  label="Qualification - Ar"
                  required
                  disabled={isDisabled}
                  isRtl
                />
              )}
            />

            <Controller
              control={form.control}
              name="isActive"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  data={[{ value: 'true', label: 'True' }, { value: 'false', label: 'False' }]}
                  label="Is Active"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Controller
              control={form.control}
              name="competition_path_ids"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <MultiSelect
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  data={competitionPaths ? normalizeCompetitionPathsForMultiSelect(competitionPaths) : []}
                  label="Competition Paths"
                  dropdownPosition="top"
                  required
                  disabled={isDisabled}
                />
              )}
            />

            <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
              {submitText}
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Center>
  );
};

export default ManageCompetition;
