import { RequestStatus } from 'src/modules/competitions/constants';

export const STATUS_COLORS: Record<RequestStatus, string> = {
  [RequestStatus.New]: 'gray',
  [RequestStatus.Submitted]: 'blue',
  [RequestStatus.MissingRequirements]: 'orange',
  [RequestStatus.Approved]: '#3b3',
  [RequestStatus.Rejected]: 'red',
  [RequestStatus.Edited]: '#ff78f1',
  [RequestStatus.UnderReview]: 'purple',
  [RequestStatus.FinalApproved]: '#009933',
  [RequestStatus.FinalRejected]: 'darkgray',
  [RequestStatus.NotUpdated]: '#6600cc',
  [RequestStatus.UnderEvaluation]: '#F1CB00',
  [RequestStatus.Evaluated]: '#AC00FF',
  [RequestStatus.EvaluatedApproved]: '#22C4E6',
  [RequestStatus.QualifiedForInterview]: 'gray',
  [RequestStatus.Interviewed]: 'gray',
  [RequestStatus.Winner]: 'gray',
  [RequestStatus.NotWinner]: 'gray'
};

export const STEPS = [
  'Personal Information',
  'Participant Data',
  'Innovation Data',
  'Impact of Innovation'
];

export const ParticipatingType = {
  En: [ 'Individual', 'Group' ],
  Ar: [ 'فرد', 'مجموعة' ]
};

export const WinnerMedalName = {
  'en': ['Gold Category', 'Silver Category', 'Bronze Category', 'Motivational Category', 'Not Assigned'],
  'ar': ['الفئة الذهبية', 'الفئة الفضية', 'الفئة البرونزية', 'الفئة التحفيزية', 'غير محدد']
};
