import { Button, Center, Divider, Flex, Group, Loader, Paper, Text, Title } from '@mantine/core';

import { useEmailTemplateListQuery } from 'src/api/email-template/hooks';

import styles from './EmailTemplate.module.scss';
import { openDeleteEmailTemplateModal, openNewEmailTemplateModal, openSendEmailToUsersModal } from 'src/providers/ModalsProvider/helpers';
import { generatePath, useNavigate } from 'react-router-dom';
import { Paths } from 'src/router/constants';

const EmailTemplate = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useEmailTemplateListQuery();
  const regex = /\((.*?)\)/g;

  const highlightedText = (text: string) => {
    return text.replace(regex, (match, group) => `<span style="color: #9747FF; font-weight: 600">(${group})</span>`);
  };

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Email Template</Title>
        <Button onClick={openNewEmailTemplateModal}>New Template</Button>
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
        <Flex gap={32} wrap='wrap'>
          {!isLoading && data?.data.map((item) => (
            <Paper key={item.id} w='calc(50% - 16px)' radius='lg'>
              <Group bg='#E8F3FD' className={styles.title}>
                <Title order={3} size={24} color='#228BE6' fw={400} px={30} py={26}>{item.name}</Title>
              </Group>

              <Flex direction='column' justify='space-between' h='calc(100% - 85.6px)'>
                <Flex p={30} direction='column' justify='space-between' gap={30}>
                  {/* <Flex direction='column' gap={24}>
                    <Text className={styles.bodyEn} dangerouslySetInnerHTML={{ __html: highlightedText(item.body) }} />
                  </Flex>
                  <Divider orientation='horizontal' variant='solid' color='#E4E4E4' h={1} /> */}

                  <Flex direction='column' gap={24}>
                    <Text className={styles.bodyAr} dangerouslySetInnerHTML={{ __html: highlightedText(item.body) }}  />
                  </Flex>
                  <Divider orientation='horizontal' variant='solid' color='#E4E4E4' />
                </Flex>

                <Flex gap={20} px={30} pb={30}>
                  {item.is_sendable && (
                    <Button onClick={() => openSendEmailToUsersModal({templateId: item.id})}>Send</Button>
                  )}
                  {item.is_editable && (
                    <Button
                      variant='outline'
                      onClick={() => {
                        navigate(
                          generatePath(Paths.editEmailTemplate, { id: item.id.toString() })
                        );
                      }}
                    >Edit</Button>
                  )}
                  {item.is_deletable && (
                    <Button
                      variant='outline'
                      color='red'
                      onClick={() => openDeleteEmailTemplateModal({templateId: item.id, title: item.name})}
                    >
                      Delete
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Paper>
          ))}
      </Flex>
    </Flex>
  );
};

export default EmailTemplate;
