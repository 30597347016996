import { useEffect, useState } from 'react';

import { Paper, Flex, Stack, Center, Table, Button, Textarea, Title } from '@mantine/core';

import styles from './ArbitratingApprovalEvaluation.module.scss';

import BackPageButton from 'src/components/BackPageButton';

import ParticipantsRequestsItemView from '../ParticipantsRequestsItemView';
import CustomStepper from '../ParticipantsRequestsItemView/CustomStepper';
import ArbitratingRequestControls from './ArbitratingApprovalControls';
import { useParams } from 'react-router-dom';
import GeneratePDF from 'src/components/GeneratePDF';
import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import TablePercentInput from 'src/components/TablePercentInput';
import { useArbitratingApprovalQuery, useAssignAribtratingApprovalPercentageQuery, useGetAllPercentagesQuery } from 'src/api/arbitrating-requests/hooks';
import { RequestStatus } from 'src/modules/competitions/constants';
import { TArbitratingCollectedData, TArbitratingJudgmentData, TArbitratingJudgmentTotalData, TModel } from 'src/api/arbitrating-requests/types';
import { useGetArbitratingCommentHistoryQuery } from 'src/api/arbitrating-requests/hooks';
import { useAuthStore } from 'src/store/useAuthStore';
import TableCommentHistory from 'src/components/TableCommentHistory';
import { STEPS } from 'src/common/constants/defConstant';
import { useAddToInnovation } from 'src/api/innovations/hooks';
import { AdminRoles } from 'src/modules/admin/constants';

const ArbitrationApprovalItemViewAndEvaluation = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();

  const [active, setActive] = useState(0);
  const [sum, setSum] = useState<number>(0);
  const [resetValue, setResetValue] = useState(false);
  const [model, setModel] = useState<TModel[]>([]);
  const [tableValues, setTableValues] = useState<TArbitratingCollectedData[]>([]);
  const [comment, setComment] = useState<string>('');

  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const { data: ArbitrationApproval, isLoading: ArbitrationApprovalLoading } = useArbitratingApprovalQuery();
  const ArbitrationItemStatus = !ArbitrationApprovalLoading &&
    ArbitrationApproval?.data.find((el) => el.id === id)?.status?.id;

  const isStatusApproved = ArbitrationItemStatus === RequestStatus.EvaluatedApproved ||
    ArbitrationItemStatus === RequestStatus.QualifiedForInterview;
  
  const { data: arbitrationCriteria, isLoading: isArbitrationCriteriaLoading } = useArbitrationCriteriaListQuery();
  const { data: arbitrationPercentage, isLoading: isArbitrationPercentageLoading } = useGetAllPercentagesQuery(id || '');
  const { mutate: AssignPercentage, isLoading: LoadingPercentage } = useAssignAribtratingApprovalPercentageQuery(id || '', comment, model);
  const { data: commmentHistory, isLoading: iscommmentHistoryLoading } = useGetArbitratingCommentHistoryQuery(id || '');
  const { mutate: addInnovation } = useAddToInnovation();

  const maxPercentagesCoefficient = !isArbitrationCriteriaLoading &&
    arbitrationCriteria?.reduce((accumulator: number, item) => {
      return accumulator + item.percentage
    }, 0)

  useEffect(() => {
    const result = tableValues?.reduce((accumulator: number, currentObj: TArbitratingCollectedData) => {
      const valuesArray = Object.values(currentObj)?.map(Number);
      return accumulator + valuesArray[0];
    }, 0);

    setSum(result)
  }, [tableValues, resetValue])

  const totalPercentages = maxPercentagesCoefficient ? +sum * (100 / maxPercentagesCoefficient) : 1;
  const isTableDataReady = !isArbitrationPercentageLoading && arbitrationPercentage && arbitrationPercentage.total_data.length && tableValues.length;
  const showAvgCriteria = isStatusApproved && !isArbitrationPercentageLoading && arbitrationPercentage;

  const [checkingInfo, setCheckingInfo] = useState({
    'Personal Information': false,
    'Participant Data': false,
    'Innovation Data': false,
    'Impact of Innovation': false
  });

  const isChecingInfo = () => {
    return Object.values(checkingInfo).every((el) => el === true) &&
      tableValues?.length === arbitrationCriteria?.length &&
      comment?.length;
  };

  useEffect(() => {
    if (isChecingInfo() && !isArbitrationCriteriaLoading && arbitrationCriteria) {
      const modelData = arbitrationCriteria?.map((item, id: number) => {
        return {
          arbitrationCriteria_id: item.id,
          percentage: +tableValues[id][id] || ''
        };
      });

      setModel(modelData);
    };
  }, [comment, tableValues, checkingInfo]);

  const onApprove = () => {
    !LoadingPercentage && AssignPercentage();
  };

  useEffect(() => {
    if (!isArbitrationPercentageLoading && arbitrationPercentage) {
      const formattedData = arbitrationPercentage?.total_data?.map(
        (total: TArbitratingJudgmentTotalData, id: number) => {
          return { [id]: total.avg_criteria };
        }
      );

      setTableValues(formattedData);
    }
  }, [isArbitrationPercentageLoading])

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
        <CustomStepper
          active={active}
          setActive={setActive}
          labels={STEPS}
          checkingInfo={!isStatusApproved ? checkingInfo : undefined}
          setCheckingInfo={setCheckingInfo}
        />
      </Flex>
      <Flex gap={16} justify='flex-end'>
        {isAdmin && (<Button onClick={() => addInnovation(id || '')}>To Innovation Page</Button>)}
        <GeneratePDF />
      </Flex>

      <Center>
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <ParticipantsRequestsItemView tabIndex={active} />
          <ArbitratingRequestControls steps={STEPS} active={active} setActive={setActive} />
        </Paper>
      </Center>

      {!iscommmentHistoryLoading && commmentHistory?.length ? (
        <TableCommentHistory data={commmentHistory} />
      ) : null}

      {!isArbitrationPercentageLoading && arbitrationPercentage && (
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <Title order={3}>Percentage of Arbitration Criteria</Title>
          <Stack>
            <Table sx={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>Committee Name</th>
                  {arbitrationCriteria?.map((item) => (
                    <th key={item.id} className={styles.tableComments}>{item.title}</th>
                  ))}
                  <th className={styles.tableComments}>Total</th>
                </tr>
              </thead>
              <tbody>
                {!isArbitrationPercentageLoading && arbitrationPercentage?.judgment_data?.map((item: TArbitratingJudgmentData) => (
                  <>
                    <tr key={item.criteriaId}>
                      <td>{item.fullName}</td>
                      {arbitrationCriteria?.map((percentage, id) => (
                        <td key={percentage.id} className={styles.tableData}>
                          <div>{item?.judgment_Evaluation[id]?.judgmentPercentage || '-'}</div>
                        </td>
                      ))}
                      <td className={styles.tableData}>
                        <div>{item?.total_Percentage}%</div>
                      </td>
                    </tr>
                    <tr key={item.criteriaId}>
                      <td>Comments</td>
                      {arbitrationCriteria?.map((percentage, id) => (
                        <td key={percentage.id} className={styles.tableComments}>
                          <Textarea
                            autosize
                            maxRows={6}
                            disabled
                            className={styles.textArea}
                            value={item?.judgment_Evaluation[id]?.comment || '-'}
                          />
                        </td>
                      ))}
                      <td></td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td>{user?.fullName}</td>
                  {!isStatusApproved && isTableDataReady && arbitrationCriteria?.map((td, id) => (
                    <td key={td.id}>
                      <TablePercentInput
                        maxVal={td.percentage}
                        placeholder={`eg ${td.percentage}%`}
                        id={id}
                        tableValue={tableValues || ''}
                        setTableValue={setTableValues}
                        resetValue={resetValue}
                        setResetValue={setResetValue}
                      />
                    </td>
                  ))}
                  {showAvgCriteria && arbitrationPercentage?.total_data?.map((total: TArbitratingJudgmentTotalData, id: number) => (
                    <td key={total.criteriaId} className={styles.tableData}><div>{total.avg_criteria}</div></td>
                  ))}
                  <td>
                    <TablePercentInput
                      maxVal={100}
                      placeholder='eg 20%'
                      total
                      totalVal={sum ? +totalPercentages.toFixed(2) : 0}
                    />
                  </td>
                </tr>
                {!isStatusApproved ? (
                  <tr>
                    <td>Final Comment</td>
                    <td colSpan={arbitrationCriteria ? arbitrationCriteria?.length : 1}>
                      <Textarea
                        autosize
                        placeholder='Enter comment'
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                      />
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </Stack>

          {!isStatusApproved ? (
            <Button w="max-content" disabled={!isChecingInfo()} onClick={onApprove}>Approve</Button>
          ) : null}
        </Paper>
      )}
    </Stack>
  );
};

export default ArbitrationApprovalItemViewAndEvaluation;
