import { FC } from 'react';

import { Tabs } from '@mantine/core';
import { IconFile, IconFilePlus } from '@tabler/icons';

interface Props {
  handleTabChange: (tab: 'Done' | 'Not Done') => void;
}

const ArbitratingStatusTabs: FC<Props> = ({ handleTabChange }) => {
  return (
    <Tabs variant="outline" defaultValue={'Not Done'} onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="Not Done" icon={<IconFile size="0.8rem" />}>
          Not Done
        </Tabs.Tab>
        <Tabs.Tab value="Done" icon={<IconFilePlus color="green" size="0.8rem" />}>
          Done
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default ArbitratingStatusTabs;
