import { FC } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';

import { useUsersListQuery } from 'src/api/users/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { useUsersTableColumns, useUsersTableFilters } from 'src/modules/users/hooks';

import { getUsersDataForExport } from './utils';


const Users: FC = () => {
  const { data: usersList, isLoading } = useUsersListQuery();

  const columns = useUsersTableColumns();
  const filters = useUsersTableFilters();

  const usersExport = getUsersDataForExport(usersList?.data);

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>
          Users
        </Title>
        <ExportButton filename='Users' data={usersExport} />
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {usersList ? (
        <DataTable
          columns={columns}
          data={usersList.data}
          filters={filters}
        />
      ) : null}
    </Flex>
  );
};

export default Users;
