import { FC, useState } from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Button, Checkbox, Flex, Text, Stack, Image, TextInput, Title } from '@mantine/core';
import styles from '../SendEmailToUsers/SendEmailToUsers.module.scss';
import logo from 'src/assets/svg/logo-colored.svg';
import { IconSearch } from '@tabler/icons';
import { useAddEmailTemplateGroupsQuery } from 'src/api/email-template/hooks';
import { TUser } from 'src/modules/users/types';
import { useUsersListQuery } from 'src/api/users/hooks';
import { useLayoutStore } from 'src/store/useLayoutStore';

export type TCreatedGroups = {
  group_name_en: string;
  group_name_ar: string;
  UserList: string[];
};

const CreateGroupModal: FC<ContextModalProps<{}>> = ({
  context,
  id,
  innerProps: {}
}) => {
  const { selectedEmailTemplateTab } = useLayoutStore();
  const [selectedUsers, setSelestedUsers] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const { data: Users, isLoading: isUsersLoading } = useUsersListQuery();
  const { mutate: createGroup } = useAddEmailTemplateGroupsQuery();

  const filteredUsersList = !isUsersLoading && (Users?.data || [])?.filter(
    (user: TUser) => `${user.first_name} ${user.second_name} ${user.last_name}`.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const addUser = (id: string) => {
    if (selectedUsers.find((userId) => userId === id)) {
      setSelestedUsers(selectedUsers.filter((userId) => userId !== id))
    } else {
      setSelestedUsers([...selectedUsers, id])
    }
  };

  const selectAll = () => {
    filteredUsersList && setSelestedUsers(filteredUsersList.map((el) => el.user_id))
  };

  const handleCreateGroups = (newGroup: TCreatedGroups) => {
    createGroup(newGroup)
    setSearch('');
    setGroupName('');
    setSelestedUsers([]);
    context.closeModal(id);

    useLayoutStore.setState((state) => {
      return { ...state, selectedEmailTemplateTab: 'Group' };
    });
  };

  const handleCancel = () => {
    context.closeModal(id);

    useLayoutStore.setState((state) => {
      return { ...state, selectedEmailTemplateTab: 'Group' };
    });
  };

  return (
    <Stack>
      <Title pos='absolute' top={30} fz={24}>Create Group</Title>
      <Flex justify='center' direction='column' gap={16}>
        <Flex justify='center' gap={20} direction='column'>
          <TextInput
            w='100%'
            label='Group Name'
            withAsterisk
            required
            onChange={(e) => setGroupName(e.target.value)}
            value={groupName}
            placeholder='Enter group name'
          />
          <TextInput
            w='100%'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder='Search user'
            icon={<IconSearch size={20} />}
          />
        </Flex>

        <Flex direction='column'>
          <Flex direction='column' className={styles.usersBlock}>
            <Flex py={12} px={20} justify='space-between' align='center' className={styles.header}>
              <Checkbox
                label='Select All'
                onClick={selectAll}
                checked={selectedUsers.length === (filteredUsersList || []).length}
              />
              {selectedUsers.length ? (
                <Text color='gray'>
                  ({selectedUsers.length} User{selectedUsers.length > 1 && 's'} Selected)
                </Text>
              ) : null}
              <Button unstyled fz={16} onClick={() => setSelestedUsers([])}>
                Clear Selection
              </Button>
            </Flex>

            <Flex wrap='wrap' gap={30} py={20} pl={20} pr={34} className={styles.itemsWrapper}>
              {(filteredUsersList || []).map((item) => (
                <div className={styles.item} onClick={() => addUser(item.user_id)} key={item.user_id}>
                  <Checkbox checked={!!selectedUsers.find((i) => i === item.user_id)} />
                  <div className={styles.itemInfo}>
                    <Image className={styles.userImg} src={logo} />
                    <Text fz={14}>{`${item.first_name} ${item.second_name} ${item.last_name}`}</Text>
                  </div>
                </div>
              ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex justify='center' gap={16}>
          <Button
            onClick={() => {
              handleCreateGroups({
                group_name_en: groupName,
                group_name_ar: groupName,
                UserList: selectedUsers
              })
            }}
            disabled={groupName.length === 0 || selectedUsers.length === 0}
          >
            Create Group
          </Button>
          <Button variant='outline' onClick={handleCancel}>Cancel</Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default CreateGroupModal;
