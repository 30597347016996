import {
  IconBriefcase,
  IconBroadcast,
  IconChartBar,
  IconClipboardList,
  IconGavel,
  IconHome,
  IconHomeShield,
  IconId,
  IconLayoutList,
  IconLetterCase,
  IconListDetails,
  IconLock,
  IconMessage2,
  IconMessageCircle,
  IconMilitaryAward,
  IconNews,
  IconNotes,
  IconPhoto,
  IconRoute,
  IconScale,
  IconShare,
  IconTournament,
  IconUsers,
  IconVersions,
  IconVideo,
  IconFilter,
  IconQuestionMark,
  IconCheck,
  IconTag,
  IconTags,
  IconUserCheck,
  IconBook2,
  IconBrandSpacehey,
  IconBrandPrisma,
  IconTrophy,
  IconMail,
  IconRecharging
} from '@tabler/icons';

import { TNavLinkItem } from 'src/components/Navbar/types';
import { AdminRoles } from 'src/modules/admin/constants';
import { Paths } from 'src/router/constants';

export const links: TNavLinkItem[] = [
  {
    path: Paths.homepage,
    icon: <IconHome size={16} />,
    label: 'Homepage',
    active: (path) => path === Paths.homepage,
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.OperationCommittee,
      AdminRoles.Judgment,
      AdminRoles.SupervisoryBoard,
      AdminRoles.ContentManager,
      AdminRoles.Authorizer,
      AdminRoles.GeneralSecretariat
    ]
  },
  {
    path: Paths.admins,
    icon: <IconId size={16} />,
    label: 'Admins',
    active: (path) => path.startsWith(Paths.admins),
    roles: [AdminRoles.SuperAdmin]
  },
  {
    path: Paths.users,
    icon: <IconUsers size={16} />,
    label: 'Users',
    active: (path) => path.startsWith(Paths.users),
    roles: [AdminRoles.SuperAdmin, AdminRoles.GeneralSecretariat]
  },
  {
    path: Paths.interestedUser,
    icon: <IconUsers size={16} />,
    label: 'Interested Users',
    active: (path) => path.startsWith(Paths.interestedUser),
    roles: [AdminRoles.SuperAdmin, AdminRoles.GeneralSecretariat]
  },
  {
    icon: <IconTournament size={16} />,
    label: 'Competition',
    active: (path) => (
      path.startsWith(Paths.competitions)
      || path.startsWith(Paths.participantsRequests)
      || path.startsWith(Paths.competitionPaths)
      || path.startsWith(Paths.arbitrationCriteria)
    ),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.SupervisoryBoard,
      AdminRoles.ContentManager,
      AdminRoles.Authorizer,
      AdminRoles.GeneralSecretariat
    ],
    children: [
      {
        path: Paths.competitions,
        icon: <IconLayoutList size={16} />,
        label: 'Competitions List',
        active: (path) => path.startsWith(Paths.competitions),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.participantsRequests,
        icon: <IconListDetails size={16} />,
        label: 'Participants Requests',
        active: (path) => path.startsWith(Paths.participantsRequests),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.OperationCommittee,
          AdminRoles.SupervisoryBoard,
          AdminRoles.Authorizer,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.competitionPaths,
        icon: <IconRoute size={16} />,
        label: 'Competition Paths',
        active: (path) => path.startsWith(Paths.competitionPaths),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.arbitrationCriteria,
        icon: <IconChartBar size={16} />,
        label: 'Arbitration Criteria',
        active: (path) => path.startsWith(Paths.arbitrationCriteria),
        roles: [ AdminRoles.SuperAdmin, AdminRoles.GeneralSecretariat ]
      }
    ]
  },
  {
    icon: <IconFilter size={16} />,
    label: 'Filtration',
    active: (path) => path.startsWith(Paths.filtration),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.OperationCommittee,
      AdminRoles.GeneralSecretariat
    ],
    children: [
      {
        path: Paths.filtrationRequest,
        icon: <IconQuestionMark size={16} />,
        label: 'Filtration Request',
        active: (path) => path.startsWith(Paths.filtrationRequest),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.OperationCommittee,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.filtrationApproval,
        icon: <IconCheck size={16} />,
        label: 'Filtration Approval',
        active: (path) => path.startsWith(Paths.filtrationApproval),
        roles: [ AdminRoles.SuperAdmin, AdminRoles.GeneralSecretariat ]
      }
    ]
  },
  {
    icon: <IconTag size={16} />,
    label: 'Arbitrating',
    active: (path) => path.startsWith(Paths.arbitrating),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.GeneralSecretariat,
      AdminRoles.Judgment,
      AdminRoles.ArbitrationCommittee
    ],
    children: [
      {
        path: Paths.arbitratingAssign,
        icon: <IconUserCheck size={16} />,
        label: 'Assign',
        active: (path) => path.startsWith(Paths.arbitratingAssign),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.GeneralSecretariat,
          AdminRoles.ArbitrationCommittee
        ]
      },
      {
        path: Paths.arbitratingRequest,
        icon: <IconTags size={16} />,
        label: 'Arbitrating Request',
        active: (path) => path.startsWith(Paths.arbitratingRequest),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.GeneralSecretariat,
          AdminRoles.Judgment,
          AdminRoles.ArbitrationCommittee
        ]
      },
      {
        path: Paths.arbitratingApproval,
        icon: <IconBook2 size={16} />,
        label: 'Arbitrating Approval',
        active: (path) => path.startsWith(Paths.arbitratingApproval),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.GeneralSecretariat,
          AdminRoles.ArbitrationCommittee
        ]
      },
      {
        path: Paths.arbitratingInterview,
        icon: <IconBrandSpacehey size={16} />,
        label: 'Interview',
        active: (path) => path.startsWith(Paths.arbitratingInterview),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.GeneralSecretariat,
          AdminRoles.ArbitrationCommittee
        ]
      }
    ]
  },
  {
    path: Paths.winners,
    icon: <IconBrandPrisma size={16} />,
    label: 'Winners',
    active: (path) => path.startsWith(Paths.winners),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.GeneralSecretariat,
      AdminRoles.ArbitrationCommittee
    ]
  },
  {
    icon: <IconBroadcast size={16} />,
    label: 'Media Center',
    active: (path) => (
      path.startsWith(Paths.news)
      || path.startsWith(Paths.photos)
      || path.startsWith(Paths.videos)
    ),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.ContentManager,
      AdminRoles.GeneralSecretariat
    ],
    children: [
      {
        path: Paths.news,
        icon: <IconNews size={16} />,
        label: 'News',
        active: (path) => path.startsWith(Paths.news),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.photos,
        icon: <IconPhoto size={16} />,
        label: 'Photos',
        active: (path) => path.startsWith(Paths.photos),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.videos,
        icon: <IconVideo size={16} />,
        label: 'Videos',
        active: (path) => path.startsWith(Paths.videos),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      }
    ]
  },
  {
    icon: <IconHomeShield size={16} />,
    label: 'General',
    active: (path) => (
      path.startsWith(Paths.termsAndConditions)
      || path.startsWith(Paths.privacyPolicy)
      || path.startsWith(Paths.faq)
      || path.startsWith(Paths.regulations)
      || path.startsWith(Paths.competitionConditions)
    ),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.ContentManager,
      AdminRoles.GeneralSecretariat
    ],
    children: [
      {
        path: Paths.termsAndConditions,
        icon: <IconNotes size={16} />,
        label: 'Terms & Conditions',
        active: (path) => path.startsWith(Paths.termsAndConditions),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.privacyPolicy,
        icon: <IconLock size={16} />,
        label: 'Privacy Policy',
        active: (path) => path.startsWith(Paths.privacyPolicy),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.faq,
        icon: <IconMessageCircle size={16} />,
        label: 'FAQ',
        active: (path) => path.startsWith(Paths.faq),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.regulations,
        icon: <IconScale size={16} />,
        label: 'Regulations',
        active: (path) => path.startsWith(Paths.regulations),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.competitionConditions,
        icon: <IconVersions size={16} />,
        label: 'Competition Conditions',
        active: (path) => path.startsWith(Paths.competitionConditions),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      }
    ]
  },
  {
    icon: <IconLetterCase size={16} />,
    label: 'Content',
    active: (path) => path.startsWith(Paths.content),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.ContentManager,
      AdminRoles.GeneralSecretariat
    ],
    children: [
      {
        path: Paths.contentHomepage,
        icon: <IconHome size={16} />,
        label: 'Homepage',
        active: (path) => path.startsWith(Paths.contentHomepage),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.OperationCommittee,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.topWinners,
        icon: <IconTrophy size={16} />,
        label: '3 Top Winners',
        active: (path) => path.startsWith(Paths.topWinners),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.aboutThePrize,
        icon: <IconMilitaryAward size={16} />,
        label: 'About the Prize',
        active: (path) => path.startsWith(Paths.aboutThePrize),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.evaluationCriteria,
        icon: <IconClipboardList size={16} />,
        label: 'Evaluation Criteria',
        active: (path) => path.startsWith(Paths.evaluationCriteria),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.supervisoryBoard,
        icon: <IconBriefcase size={16} />,
        label: 'Supervisory Board',
        active: (path) => path.startsWith(Paths.supervisoryBoard),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.generalSecretariat,
        icon: <IconGavel size={16} />,
        label: 'General Secretariat',
        active: (path) => path.startsWith(Paths.generalSecretariat),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.winnersContent,
        icon: <IconTrophy size={16} />,
        label: 'Winners in Latest Comp',
        active: (path) => path.startsWith(Paths.winnersContent),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.innovationsContent,
        icon: <IconRecharging size={16} />,
        label: 'Innovations',
        active: (path) => path.startsWith(Paths.innovationsContent),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.socialMediaAccounts,
        icon: <IconShare size={16} />,
        label: 'Social Media Accounts',
        active: (path) => path.startsWith(Paths.socialMediaAccounts),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      },
      {
        path: Paths.emailTemplate,
        icon: <IconMail size={16} />,
        label: 'Email Template',
        active: (path) => path.startsWith(Paths.emailTemplate),
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.ContentManager,
          AdminRoles.GeneralSecretariat
        ]
      }
    ]
  },
  {
    path: Paths.tickets,
    icon: <IconMessage2 size={16} />,
    label: 'Tickets',
    active: (path) => path.startsWith(Paths.tickets),
    roles: [
      AdminRoles.SuperAdmin,
      AdminRoles.GeneralSecretariat
    ]
  }
];
