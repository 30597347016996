import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TSupervisoryBoardItemForm, TSupervisoryBoardList } from 'src/modules/supervisory-board/types';

import {
  createSupervisoryBoardItem,
  deleteSupervisoryBoardItem,
  getSupervisoryBoardList, sortSupervisoryBoardList,
  updateSupervisoryBoardItem
} from './api';
import { SUPERVISORY_BOARD_LIST_QUERY_KEY } from './constants';
import { TSupervisoryBoardItemUpdateRequest } from './types';

export const useSupervisoryBoardListQuery = () => {
  return useQuery<TSupervisoryBoardList>({
    queryKey: [SUPERVISORY_BOARD_LIST_QUERY_KEY],
    queryFn: getSupervisoryBoardList
  });
};

export const useCreateSupervisoryBoardItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TSupervisoryBoardItemForm>({
    mutationFn: createSupervisoryBoardItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([SUPERVISORY_BOARD_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateSupervisoryBoardItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TSupervisoryBoardItemUpdateRequest>({
    mutationFn: updateSupervisoryBoardItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([SUPERVISORY_BOARD_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteSupervisoryBoardItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteSupervisoryBoardItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([SUPERVISORY_BOARD_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};

export const useSortSupervisoryBoardListMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string[]>({
    mutationFn: sortSupervisoryBoardList,
    onSuccess: async () => {
      await queryClient.invalidateQueries([SUPERVISORY_BOARD_LIST_QUERY_KEY]);
      showSuccessNotification({ message: 'Items sorted successfully' });
    }
  });
};
