import { FC } from 'react';

import { Box, Button, Center, LoadingOverlay, Stack, TextInput, Title } from '@mantine/core';
import { Controller, UseFormReturn } from 'react-hook-form';

import Form from 'src/components/Form';
import ImageUpload from 'src/components/ImageUpload';
import Paper from 'src/components/Paper';
import VideoUpload from 'src/components/VideoUpload';
import { TVideoItemForm } from 'src/modules/videos/types';

type TProps = {
  title: string;
  submitText: string;
  onSubmit: (data: TVideoItemForm) => void;
  form: UseFormReturn<TVideoItemForm>;
  isLoading: boolean;
};

const ManageVideo: FC<TProps> = ({ title, submitText, onSubmit, form, isLoading }) => (
  <Center>
    <Paper w={700}>
      <Title order={3} mb={30}>
        {title}
      </Title>
      <Box component={Form} w="100%" onSubmit={form.handleSubmit(onSubmit)} pos="relative">
        <LoadingOverlay visible={isLoading} />

        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="summary_en"
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Summary - En"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="summary_ar"
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Summary - Ar"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="video"
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange } }) => (
              <VideoUpload value={value} onChange={onChange} />
            )}
          />

          <Controller
            control={form.control}
            name="cover_image"
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <ImageUpload
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Cover Image"
                required
                disabled={isLoading}
              />
            )}
          />

          <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
            {submitText}
          </Button>
        </Stack>
      </Box>
    </Paper>
  </Center>
);

export default ManageVideo;
