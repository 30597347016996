import { FC, useMemo } from 'react';

import { Box, Button, LoadingOverlay, NumberInput, Stack } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import Form from 'src/components/Form';
import TextInput from 'src/components/TextInput';
import { TArbitrationCriteriaItem, TArbitrationCriteriaItemForm } from 'src/modules/arbitration-criteria/types';

type TProps = {
  submitText: string;
  onSubmit: (data: TArbitrationCriteriaItemForm) => void;
  defaultValues: TArbitrationCriteriaItemForm;
  isLoading: boolean;
  itemData?: TArbitrationCriteriaItem;
};

const ManageArbitrationCriteria: FC<TProps> = ({
  submitText,
  onSubmit,
  defaultValues,
  isLoading,
  itemData
}) => {
  const { data } = useArbitrationCriteriaListQuery();
  const form = useForm({ defaultValues });

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }

    return itemData ? data.filter((item) => item.title !== itemData.title) : data;
  }, [data, itemData]);

  return (
    <Box
      component={Form}
      w="100%"
      onSubmit={form.handleSubmit(onSubmit)}
      pos="relative"
    >
      <LoadingOverlay visible={isLoading} />

      <Stack spacing={30}>
        <Controller
          control={form.control}
          name="title"
          rules={{
            required: 'Required',
            validate: (value) => {
              if (!data) {
                return true;
              }

              const isTitleUnique = filteredData.every((item) => item.title !== value);

              if (!isTitleUnique) {
                return 'Title must be unique';
              }

              return true;
            }
          }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Title"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="percentage"
          rules={{
            required: 'Required',
            min: {
              value: 1,
              message: 'Percentage must be greater than 0'
            },
            max: {
              value: 100,
              message: 'Percentage must be less than 100'
            },
            validate: (value) => {
              if (!data) {
                return true;
              }

              const totalPercentage = filteredData.reduce((acc, item) => acc + item.percentage, 0);

              if (totalPercentage + value > 100) {
                return 'Total percentage must be less than 100';
              }

              return true;
            }
          }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <NumberInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Percentage"
              disabled={isLoading}
              required
            />
          )}
        />

        <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
          {submitText}
        </Button>
      </Stack>
    </Box>
  );
};

export default ManageArbitrationCriteria;
