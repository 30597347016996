import { showNotification } from '@mantine/notifications';
import { IconCheck, IconAlertTriangle, IconBan } from '@tabler/icons';

export const showSuccessNotification = ({
  message,
  autoClose = true
}: {
  message: string;
  autoClose?: boolean | number;
}) => {
  showNotification({
    message,
    autoClose,
    color: 'teal',
    icon: <IconCheck size={20} />
  });
};

export const showWarningNotification = ({
  message,
  autoClose = true
}: {
  message: string;
  autoClose?: boolean | number;
}) => {
  showNotification({
    message,
    autoClose,
    color: 'yellow',
    icon: <IconAlertTriangle size={20} />
  });
};

export const showErrorNotification = ({
  message,
  autoClose = true
}: {
  message: string;
  autoClose?: boolean | number;
}) => {
  showNotification({
    message,
    autoClose,
    color: 'red',
    icon: <IconBan size={20} />
  });
};
