import { FC, Fragment, useMemo, useState } from 'react';

import { Button, Drawer, Flex, ActionIcon, Indicator, Tooltip } from '@mantine/core';
import { IconFilter } from '@tabler/icons';
import { ColumnFilter } from '@tanstack/react-table';
import { SubmitHandler, useForm } from 'react-hook-form';

import Form from 'src/components/Form';
import { renderTableFilterByType } from 'src/components/TableFilters/helpers';

import styles from './TableFilters.module.scss';
import { TTableFiltersProps } from './types';

const TableFilters: FC<TTableFiltersProps> = ({
  table,
  filters
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const shownFilters = useMemo(() => {
    return filters.filter(({ id }) => table.getColumn(id)?.getCanFilter());
  }, [table, filters]);

  const defaultValues = useMemo((): Record<string, any> => {
    return shownFilters.reduce((result, item) => ({
      ...result,
      [item.id]: item.defaultValue
    }), {});
  }, [shownFilters]);

  const filtersCount = useMemo(() => {
    return table.options.state.columnFilters?.length.toString();
  }, [table.options.state]);

  const form = useForm({ defaultValues });

  const handleSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    const prepareValues = Object.entries(data).reduce((acc, [key, value]) => {
      if (value || value === false || value === 0) {
        acc.push({ id: key, value });
      }

      return acc;
    }, [] as ColumnFilter[]);
    table.setColumnFilters(prepareValues);
  };

  const handleReset = () => {
    form.reset();
    table.setColumnFilters([]);
  };

  const handleClose = () => {
    form.handleSubmit(handleSubmit)();
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip label="Filters" withArrow arrowSize={6}>
        <ActionIcon onClick={() => setIsOpen((open) => !open)}>
          <Indicator label={filtersCount} showZero={false} dot={false} inline size={16}>
            <IconFilter size={20} color="#172b4d" />
          </Indicator>
        </ActionIcon>
      </Tooltip>

      <Drawer
        opened={isOpen}
        onClose={handleClose}
        position="right"
        title="Filters"
        size={500}
        overlayBlur={0.4}
        overlayColor="rgba(9, 30, 66, 0.54)"
        padding="xl"
      >
        <Form className={styles.form} onSubmit={form.handleSubmit(handleSubmit)}>
          {shownFilters.map((filter) => (
            <Fragment key={filter.id}>
              {renderTableFilterByType(filter, form)}
            </Fragment>
          ))}
          <Flex align="center" justify="space-between" mt={20}>
            <Button
              color="red"
              variant="light"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button type="submit">Apply</Button>
          </Flex>
        </Form>
      </Drawer>
    </>
  );
};

export default TableFilters;
