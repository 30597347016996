import { FC, useMemo } from 'react';

import { useUpdateArbitrationCriteriaMutation } from 'src/api/arbitration-criteria/hooks';
import ManageArbitrationCriteria from 'src/components/ManageArbitrationCriteria';
import { TArbitrationCriteriaItem, TArbitrationCriteriaItemForm } from 'src/modules/arbitration-criteria/types';

type TProps = {
  item: TArbitrationCriteriaItem;
};

const EditModalContent: FC<TProps> = ({ item }) => {
  const { mutate, isLoading } = useUpdateArbitrationCriteriaMutation();

  const defaultValues = useMemo((): TArbitrationCriteriaItemForm => ({
    title: item.title,
    percentage: item.percentage
  }), [item]);

  const handleItemUpdate = (data: TArbitrationCriteriaItemForm) => {
    mutate({ id: item.id, ...data });
  };

  return (
    <ManageArbitrationCriteria
      defaultValues={defaultValues}
      onSubmit={handleItemUpdate}
      submitText="Save"
      isLoading={isLoading}
      itemData={item}
    />
  );
};

export default EditModalContent;
