import { Button, Group } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';
import { RequestStatus } from 'src/modules/competitions/constants';

import { openUpdateRequestStatusModal } from './helpers';

const EvaluationControls = () => {
  const { id } = useParams<{ id: string }>();

  const { data: request } = useParticipantsRequestByIdQuery(id || '');

  const getModalOpener = (requestStatus: RequestStatus) => () => {
    if (!request) return;

    openUpdateRequestStatusModal(request.id, request?.evaluation_stage.id, requestStatus);
  };

  if (request?.status.id === RequestStatus.New) {
    return null;
  }

  return (
    <Group>
      <Button
        variant="filled"
        onClick={getModalOpener(RequestStatus.FinalApproved)}
      >
        Final Approved
      </Button>
      <Button
        variant="outline"
        onClick={getModalOpener(RequestStatus.MissingRequirements)}
      >
        Missing Requirement
      </Button>
      <Button
        variant="filled"
        color="red"
        onClick={getModalOpener(RequestStatus.FinalRejected)}
      >
        Final Rejected
      </Button>
    </Group>
  );
};

export default EvaluationControls;
