import { Center, List, Loader, SimpleGrid } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useArbitratingRequestQuery } from 'src/api/arbitrating-requests/hooks';

import AttachmentButton from 'src/components/AttachmentButton';
import ContentWithLabel from 'src/components/ContentWithLabel';

const InnovationData = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useArbitratingRequestQuery();
  const Data: any = data?.data.find((item) => item.id === id);

  if (isLoading)
    return (
      <Center py={50}>
        <Loader size="lg" />
      </Center>
    );

  if (!data) return null;

  const { innovation_data, path } = Data;

  return (
    <SimpleGrid cols={2} spacing="xl" breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
      <ContentWithLabel label="Innovation Name">
        {innovation_data?.innovation_name}
      </ContentWithLabel>
      <ContentWithLabel label="Innovation Type">
        {innovation_data?.innovation_type?.length ? (
          <List listStyleType={innovation_data?.innovation_type?.length === 1 ? 'none' : 'initial'}>
            {innovation_data.innovation_type.map((item: any) => (
              <List.Item key={item.id}>{item.name}</List.Item>
            ))}
          </List>
        ) : (
          '-'
        )}
      </ContentWithLabel>
      <ContentWithLabel label="Field of Innovation">{path.name}</ContentWithLabel>
      {/* <ContentWithLabel label="Your Role In Innovation">
        {data?.participating_role_id?.name}
      </ContentWithLabel> */}
      <ContentWithLabel label="Description of Innovation">
        {innovation_data?.description}
      </ContentWithLabel>
      <ContentWithLabel label="Supportive Attachments">
        <AttachmentButton href={innovation_data?.innovation_attachment} />
      </ContentWithLabel>
    </SimpleGrid>
  );
};

export default InnovationData;
