import privateApi from 'src/common/configs/private-api';

import { TCreateEmailTemplate, TEmailTemplate, TGroupListData, TPutUpdateEmailTemplate, TSendToGroup, TSendToUser } from './types';
import { SuccessResponse } from 'src/common/types/api';
import { TCreatedGroups } from 'src/providers/ModalsProvider/CreateGroupModal/CreateGroupModal';

export const getEmailTemplateList = async (): Promise<TEmailTemplate> => {
  return privateApi.get('/email-template').then((res) => res.data);
};

export const getEmailTemplateItem = async (id: string): Promise<any> => {
  return privateApi.get(`/email-template?Id=${id}`).then((res) => res.data);
};

export const updateEmailTemplate = async (data: TPutUpdateEmailTemplate): Promise<SuccessResponse> => {
  return privateApi.put('/email-template', data).then((res) => res.data);
};

export const deleteEmailTemplate = async (id: number): Promise<SuccessResponse> => {
  return privateApi.delete(`/email-template?templateId=${id}`).then((res) => res.data);
};

export const sendEmailTemplate = async (data: TSendToUser): Promise<SuccessResponse> => {
  return privateApi.post('/email-template/send_to_user', data).then((res) => res.data);
};

export const addNewEmailTemplate = async (data: TCreateEmailTemplate): Promise<SuccessResponse> => {
  return privateApi.post('/email-template', data).then((res) => res.data);
};

export const getEmailTemplateGroups = async (): Promise<TGroupListData> => {
  return privateApi.get('/group').then((res) => res.data);
};

export const addEmailTemplateGroups = async (data: TCreatedGroups): Promise<SuccessResponse> => {
  return privateApi.post('/group', data).then((res) => res.data);
};

export const sendEmailTemplateGroups = async (data: TSendToGroup): Promise<SuccessResponse> => {
  return privateApi.post('/email-template/send_to_group', data).then((res) => res.data);
};

export const deleteEmailTemplateGroup = async (group_id: number): Promise<SuccessResponse> => {
  return privateApi.delete(`/group?group_id=${group_id}`).then((res) => res.data);
};
