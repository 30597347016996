import { FC } from 'react';

import { Drawer, Switch, Stack } from '@mantine/core';

import { TColumnVisibilityProps } from './types';

const ColumnVisibility: FC<TColumnVisibilityProps> = ({
  isOpen,
  onClose,
  table
}) => (
  <Drawer
    opened={isOpen}
    onClose={onClose}
    position="right"
    title="Column Visibility"
    size={300}
    overlayBlur={0.4}
    overlayColor="rgba(9, 30, 66, 0.54)"
    padding="xl"
  >
    <Stack spacing={10}>
      {table.getAllLeafColumns().map((column) => (
        <Switch
          key={column.id}
          onLabel="ON"
          offLabel="OFF"
          size="sm"
          label={column.columnDef.header as string}
          checked={column.getIsVisible()}
          onChange={column.getToggleVisibilityHandler()}
        />
      ))}
    </Stack>
  </Drawer>
);

export default ColumnVisibility;
