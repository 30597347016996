import { Button, Group } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';
import { RequestStatus } from 'src/modules/competitions/constants';

import { openUpdateRequestStatusModal } from './helpers';

type Props = {
  checkingInfo?: any
}

const EvaluationControls = ({ checkingInfo }: Props) => {
  const { id } = useParams<{ id: string }>();

  const filtrationRequestCheck =
    checkingInfo ?
      !(checkingInfo['Personal Information']
      && checkingInfo['Participant Data']
      && checkingInfo['Innovation Data']
      && checkingInfo['Impact of Innovation'])
    : false;

  const { data: request } = useParticipantsRequestByIdQuery(id || '');

  const getModalOpener = (requestStatus: RequestStatus) => () => {
    if (!request) return;

    openUpdateRequestStatusModal(request.id, request?.evaluation_stage.id, requestStatus);
  };

  if (request?.status.id === RequestStatus.New) {
    return null;
  }

  return (
    <Group>
      <Button
        variant="filled"
        onClick={getModalOpener(RequestStatus.Approved)}
        disabled={checkingInfo ? filtrationRequestCheck : false}
      >
        Approved
      </Button>
      <Button
        variant="outline"
        onClick={getModalOpener(RequestStatus.MissingRequirements)}
      >
        Missing Requirement
      </Button>
      <Button
        variant="filled"
        color="red"
        onClick={getModalOpener(RequestStatus.Rejected)}
        disabled={checkingInfo ? filtrationRequestCheck : false}
      >
        Rejected
      </Button>
    </Group>
  );
};

export default EvaluationControls;
