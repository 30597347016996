import { FC } from 'react';

import { Tabs } from '@mantine/core';
import { IconFile, IconFilePlus } from '@tabler/icons';

interface Props {
  handleTabChange: (tab: 'Evaluated Approved' | 'Evaluated') => void;
}

const ArbitratingStatusTabs: FC<Props> = ({ handleTabChange }) => {
  return (
    <Tabs variant="outline" defaultValue={'Evaluated'} onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="Evaluated" icon={<IconFile size="0.8rem" />}>
          Evaluated
        </Tabs.Tab>
        <Tabs.Tab value="Evaluated Approved" icon={<IconFilePlus color="green" size="0.8rem" />}>
          Evaluated Approved
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default ArbitratingStatusTabs;
