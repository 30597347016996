import { FC, useEffect } from 'react';

import { Button, Flex, LoadingOverlay, Select, Stack } from '@mantine/core';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useUpdateParticipantApprovalStatusMutation } from 'src/api/participants-requests/hooks';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import Form from 'src/components/Form';
import PDFUpload from 'src/components/PDFUpload';
import { TParticipantApprovalForm, TParticipantsApprovalData } from 'src/modules/participants-requests/types';

type Props = {
  requestId: string;
  participantInfo: TParticipantsApprovalData;
};

const ApprovalModalContent: FC<Props> = ({
  requestId,
  participantInfo
}) => {
  const { id } = useParams<{ id: string }>();

  const { mutate, isLoading } = useUpdateParticipantApprovalStatusMutation(id || '');

  const form = useForm<TParticipantApprovalForm>({
    defaultValues: { isAprroved: null, approve_file: null }
  });

  const isApproved = useWatch({
    control: form.control,
    name: 'isAprroved'
  });

  useEffect(() => {
    if (isApproved !== 'true') {
      form.setValue('approve_file', null);
    }
    form.clearErrors('approve_file');
  }, [isApproved, form]);

  const handleSubmit: SubmitHandler<TParticipantApprovalForm> = (data) => {
    const isApproved = data.isAprroved === 'true';

    mutate({
      request_id: requestId,
      email: participantInfo.email || '',
      approve_file: data.approve_file,
      isAprroved: isApproved
    }, {
      onSuccess: () => {
        showSuccessNotification({ message: isApproved ? 'Approve successfully' : 'Updated successfully' });
      }
    });
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="isAprroved"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                error={error?.message}
                data={[{ value: 'true', label: 'Approved' }, { value: 'false', label: 'Rejected' }]}
                label="Approve status"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="approve_file"
            rules={{ required: isApproved === 'true' ? 'Required' : false }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <PDFUpload
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Approve file"
                disabled={isLoading || isApproved === 'false'}
              />
            )}
          />
        </Stack>

        <Flex justify="flex-end" mt={40}>
          <Button type="submit">
            Submit
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default ApprovalModalContent;
