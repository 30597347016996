import { FC, ReactNode } from 'react';

import { Center, clsx, Flex, Text } from '@mantine/core';
import { Dropzone as MantineDropzone, DropzoneProps } from '@mantine/dropzone';
import { IconUpload, IconX } from '@tabler/icons';

import styles from './Dropzone.module.scss';

type Props = {
  title: string;
  subTitle: string;
  idleIcon: ReactNode;
} & Pick<DropzoneProps, 'onDrop' | 'maxFiles' | 'maxSize' | 'disabled' | 'accept'>;

const Dropzone: FC<Props> = ({
  onDrop,
  maxFiles,
  maxSize,
  disabled,
  accept,
  title,
  subTitle,
  idleIcon
}) => (
  <MantineDropzone
    onDrop={onDrop}
    maxFiles={maxFiles}
    maxSize={maxSize}
    disabled={disabled}
    accept={accept}
    className={clsx(styles.dropzoneRoot, {
      [styles.dropzoneDisabled]: disabled
    })}
  >
    <Center sx={{ height: 220, pointerEvents: 'none' }}>
      <Flex align="center" justify="center" gap={15}>
        <MantineDropzone.Accept>
          <IconUpload size={50} stroke={1.5} />
        </MantineDropzone.Accept>
        <MantineDropzone.Reject>
          <IconX size={50} stroke={1.5} />
        </MantineDropzone.Reject>
        <MantineDropzone.Idle>
          {idleIcon}
        </MantineDropzone.Idle>
        <div>
          <Text size="xl">
            {title}
          </Text>
          <Text size="sm" color="dimmed">
            {subTitle}
          </Text>
        </div>
      </Flex>
    </Center>
  </MantineDropzone>
);

export default Dropzone;
