import { FC, Fragment } from 'react';

import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons';

import { TTableActionsCellProps } from './types';

const TableActionsCell: FC<TTableActionsCellProps> = ({
  actions,
  onEditClick,
  onDeleteClick
}) => (
  <Flex justify="center" gap={10}>
    {actions.map((item, index) => (
      <Fragment key={index}>
        {item === 'edit' && (
          <Tooltip label="Edit">
            <ActionIcon size="sm" onClick={onEditClick}>
              <IconEdit color="#868e96" />
            </ActionIcon>
          </Tooltip>
        )}
        {item === 'delete' && (
          <Tooltip label="Delete">
            <ActionIcon size="sm" onClick={onDeleteClick}>
              <IconTrash color="#ff6b6b" />
            </ActionIcon>
          </Tooltip>
        )}
      </Fragment>
    ))}
  </Flex>
);

export default TableActionsCell;
