import React, { useEffect, useState } from 'react';

import { Paper, Flex, Stack, Center, Table, Title, Textarea, Button } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { useAllWinnersQuery, useGetAllPercentagesQuery, useGetArbitratingCommentHistoryQuery } from 'src/api/arbitrating-requests/hooks';
import { TArbitratingCollectedData, TArbitratingJudgmentData, TArbitratingJudgmentTotalData } from 'src/api/arbitrating-requests/types';
import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import { STEPS } from 'src/common/constants/defConstant';
import BackPageButton from 'src/components/BackPageButton';
import GeneratePDF from 'src/components/GeneratePDF';
import InterviewScoreTable from 'src/components/InterviewScoreTable';
import TableCommentHistory from 'src/components/TableCommentHistory';
import TablePercentInput from 'src/components/TablePercentInput';
import { RequestStatus } from 'src/modules/competitions/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import ArbitratingRequestControls from './ArbitratingWinnersControls';
import styles from '../ArbitratingApprovalEvaluation/ArbitratingApprovalEvaluation.module.scss';
import ParticipantsRequestsItemView from '../ParticipantsRequestsItemView';
import CustomStepper from '../ParticipantsRequestsItemView/CustomStepper';
import { useAddToInnovation } from 'src/api/innovations/hooks';
import { AdminRoles } from 'src/modules/admin/constants';

const ArbitratingWinnersItemViewAndEvaluation = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();

  const [active, setActive] = useState(0);
  const [sum, setSum] = useState<number>(0);
  const [tableValues, setTableValues] = useState<TArbitratingCollectedData[]>([]);
  const [comment, setComment] = useState<string>('');
  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const { data: ArbitrationWinners, isLoading: ArbitrationWinnersLoading } = useAllWinnersQuery();
  const { data: arbitrationCriteria, isLoading: isArbitrationCriteriaLoading } = useArbitrationCriteriaListQuery();
  const { data: arbitrationPercentage, isLoading: isArbitrationPercentageLoading } = useGetAllPercentagesQuery(id || '');
  const { data: commmentHistory, isLoading: iscommmentHistoryLoading } = useGetArbitratingCommentHistoryQuery(id || '');
  const { mutate: addInnovation } = useAddToInnovation();

  const interviewedComment = !iscommmentHistoryLoading && commmentHistory?.find((comment) => {
    return comment.statusId === RequestStatus.Interviewed;
  })?.comment;

  const selectedItem = !ArbitrationWinnersLoading &&
    ArbitrationWinners?.data.find((item) => item.id === id);

  const maxPercentagesCoefficient = !isArbitrationCriteriaLoading &&
    arbitrationCriteria?.reduce((accumulator: number, item) => {
      return accumulator + item.percentage;
    }, 0);

  useEffect(() => {
    const result = tableValues?.reduce((accumulator: number, currentObj: TArbitratingCollectedData) => {
      const valuesArray = Object.values(currentObj)?.map(Number);
      return accumulator + valuesArray[0];
    }, 0);

    setSum(result);
  }, [tableValues]);

  const totalPercentages = maxPercentagesCoefficient ? +sum * (100 / maxPercentagesCoefficient) : 1;
  const showAvgCriteria = !isArbitrationPercentageLoading && arbitrationPercentage;

  useEffect(() => {
    if (!isArbitrationPercentageLoading && arbitrationPercentage) {
      const formattedData = arbitrationPercentage?.total_data?.map(
        (total: TArbitratingJudgmentTotalData, id: number) => {
          return { [id]: total.avg_criteria };
        }
      );

      setTableValues(formattedData);
    }
  }, [isArbitrationPercentageLoading, arbitrationPercentage]);

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
        <CustomStepper
          active={active}
          setActive={setActive}
          labels={STEPS}
        />
      </Flex>
      <Flex gap={16} justify='flex-end'>
        {isAdmin && (<Button onClick={() => addInnovation(id || '')}>To Innovation Page</Button>)}
        <GeneratePDF />
      </Flex>

      <Center>
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <ParticipantsRequestsItemView tabIndex={active} />
          <ArbitratingRequestControls steps={STEPS} active={active} setActive={setActive} />
        </Paper>
      </Center>

      {!iscommmentHistoryLoading && commmentHistory?.length ? (
        <TableCommentHistory data={commmentHistory} />
      ) : null}

      {!isArbitrationPercentageLoading && arbitrationPercentage && (
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <Title order={3}>Percentage of Arbitration Criteria</Title>
          <Stack>
            <Table sx={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>Committee Name</th>
                  {arbitrationCriteria?.map((item) => (
                    <th key={item.id} className={styles.tableComments}>{item.title}</th>
                  ))}
                  <th className={styles.tableComments}>Total</th>
                </tr>
              </thead>
              <tbody>
                {!isArbitrationPercentageLoading && arbitrationPercentage?.judgment_data?.map((item: TArbitratingJudgmentData, id) => (
                  <React.Fragment key={id}>
                    <tr>
                      <td>{item.fullName} {item.criteriaId}</td>
                      {arbitrationCriteria?.map((percentage, id) => (
                        <td key={percentage.id} className={styles.tableData}>
                          <div>{item?.judgment_Evaluation[id]?.judgmentPercentage || '-'}</div>
                        </td>
                      ))}
                      <td className={styles.tableData}>
                        <div>{item?.total_Percentage}%</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Comments</td>
                      {arbitrationCriteria?.map((percentage, id) => (
                        <td key={percentage.id} className={styles.tableComments}>
                          <Textarea
                            autosize
                            maxRows={6}
                            disabled
                            className={styles.textArea}
                            value={item?.judgment_Evaluation[id]?.comment || '-'}
                          />
                        </td>
                      ))}
                      <td></td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <td>{user?.fullName}</td>
                  {showAvgCriteria && arbitrationPercentage?.total_data?.map((total: TArbitratingJudgmentTotalData) => (
                    <td key={total.criteriaId} className={styles.tableData}><div>{total.avg_criteria}</div></td>
                  ))}
                  <td>
                    <TablePercentInput
                      maxVal={100}
                      placeholder='eg 20%'
                      total
                      totalVal={sum ? +totalPercentages.toFixed(2) : 0}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Stack>
        </Paper>
      )}

      {selectedItem && (
        <InterviewScoreTable
          selectedItem={selectedItem?.degree || ''}
          interviewedComment={interviewedComment || ''}
          isStatusApproved={true}
          setSum={setSum}
          comment={comment}
          setComment={setComment}
          id={id || ''}
          isChecingInfo={() => false}
          AssignQualifiedForInterviewToInterviewed={() => null}
        />
      )}
    </Stack>
  );
};

export default ArbitratingWinnersItemViewAndEvaluation;
