import {
  GetParticipantsRequestsList,
  TFilteringStageUpdateStatus,
  TGenerateParticipantApprovalPDFPayload,
  TParticipantApprovalRequestBody
} from 'src/api/participants-requests/types';
import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import {
  TParticipantRequestEvaluationStage,
  TParticipantRequestField,
  TParticipantRequestStatus,
  TParticipantRequestTimelineItem,
  TParticipantsApprovalInfo,
  TParticipantsRequestFullInfo
} from 'src/modules/participants-requests/types';

import { TCommentHistory } from '../arbitrating-requests/types';

export const getParticipantsRequestsList = (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/admin/participants-requests', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  }).then((res) => res.data);
};

export const getParticipantsRequestById = (requestId: string): Promise<TParticipantsRequestFullInfo> => {
  return privateApi.get('/admin/request', { params: { requestId } }).then((res) => res.data);
};

export const getParticipantsRequestsStatusList = (): Promise<TParticipantRequestStatus[]> => {
  return privateApi.get('/request-status').then((res) => res.data.data);
};

export const getParticipantsRequestsEvaluationStageList = (): Promise<TParticipantRequestEvaluationStage[]> => {
  return privateApi.get('/request-evaluation-stages').then((res) => res.data.data);
};

export const getParticipantsRequestsFieldList = (): Promise<TParticipantRequestField[]> => {
  return privateApi.get('/request-field').then((res) => res.data.data);
};

export const updateParticipantRequest = (data: TFilteringStageUpdateStatus): Promise<SuccessResponse> => {
  return privateApi.put('/admin/update-request', data).then((res) => res.data);
};

export const getParticipantRequestApprovalInfo = (requestId: string): Promise<TParticipantsApprovalInfo> => {
  return privateApi.get('/admin/requestApproves', { params: { requestId } }).then((res) => res.data);
};

export const updateParticipantApproveStatus = async (data: TParticipantApprovalRequestBody): Promise<SuccessResponse> => {
  return privateApi.put('/admin/participantAprrove', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const getParticipantRequestTimeline = (requestId: string): Promise<TParticipantRequestTimelineItem[]> => {
  return privateApi.get(`/admin/request/status`, { params: { requestId } }).then(res => res.data);
};

export const generateParticipantApprovalPDF = (payload: TGenerateParticipantApprovalPDFPayload): Promise<SuccessResponse> => {
  return privateApi
    .get(`/admin/letter`, {
      params: {
        request_number: payload.requestNumber,
        email: payload.email
      },
      headers: {
        'Accept-Language': payload.language
      }
    })
    .then(res => res.data);
};

export const getParticipantsCommentsHistory = (requestId: string): Promise<TCommentHistory[]> => {
  return privateApi.get('/admin/commentHistory', { params: { requestId } }).then((res) => res.data);
};
