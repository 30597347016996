import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showErrorNotification, showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TWinnerItem } from 'src/modules/arbitration-winners/types';

import {
  AssignApprovalToInterview,
  AssignAribtratingApprovalPercentage,
  AssignAribtratingRequestPercentage,
  AssignAribtratingToUser,
  AssignInterviewedToWinners,
  AssignQualifiedForInterviewToInterviewed,
  DeleteAssignAribtratingUser,
  GetAllArbitratingRequestPercentage,
  GetAllArbitratingUsers,
  GetAllAssignAribtratingApprovalPercentage,
  getAllWinnersList,
  getArbitratingApprovalList,
  getArbitratingAssignList,
  getArbitratingCommentHistory,
  getArbitratingRequestList,
  getInterviewedList,
  getLatestCompetitionList,
  getLatestInterviewDegree,
  getQualifiedForInterviewList,
  getTopWinnerData,
  putLatestWinnerCompetitionData,
  putWinnerData,
  sendArbitratingInterviewEmails,
  sendArbitratingWinnerEmails,
  sortArbitratingWinnerList
} from './api';
import {
  ARBITRATING_ALL_USERS_KEY,
  ARBITRATING_ALL_WINNERS_KEY,
  ARBITRATING_APPROVAL_KEY,
  ARBITRATING_APPROVAL_PERCENTAGES_KEY,
  ARBITRATING_ASSIGN_KEY,
  ARBITRATING_COMMENT_HISTORY_KEY,
  ARBITRATING_INTERVIEWED_KEY,
  ARBITRATING_LATEST_DEGREE_KEY,
  ARBITRATING_QUALIFIED_INTERVIEW_KEY,
  ARBITRATING_REQUEST_KEY,
  ARBITRATING_REQUEST_PERCENTAGES_KEY,
  ARBITRATING_TOP_WINNER_KEY
} from './constants';
import { LatestInterviewDegree, PutRequestWinnerData, TArbitratingPercentage, TAssignQualifiedForInterviewToInterviewed, TCommentHistory, TLatestCompetitionListData, TModel, putRequestLatestWinnerCompetitionData } from './types';
import { GetAdminsListResponse } from '../admin/types';
import { GetParticipantsRequestsList } from '../participants-requests/types';

export const useArbitratingRequestQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [ARBITRATING_REQUEST_KEY],
    queryFn: getArbitratingRequestList
  });
};

export const useGetAllArbitratingUsersQuery = () => {
  return useQuery<GetAdminsListResponse>({
    queryKey: [ARBITRATING_ALL_USERS_KEY],
    queryFn: () => GetAllArbitratingUsers().then((res) => res.data)
  });
};

export const useArbitratingAssignQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [ARBITRATING_ASSIGN_KEY],
    queryFn: getArbitratingAssignList
  });
};

export const useArbitratingApprovalQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [ARBITRATING_APPROVAL_KEY],
    queryFn: getArbitratingApprovalList
  });
};

export const useQualifiedForInterviewQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [ARBITRATING_QUALIFIED_INTERVIEW_KEY],
    queryFn: getQualifiedForInterviewList
  });
};

export const useInterviewedQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [ARBITRATING_INTERVIEWED_KEY],
    queryFn: getInterviewedList
  });
};

export const useAllWinnersQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [ARBITRATING_ALL_WINNERS_KEY],
    queryFn: getAllWinnersList
  });
};

export const useTopWinnerDataQuery = () => {
  return useQuery<TWinnerItem[]>({
    queryKey: [ARBITRATING_TOP_WINNER_KEY],
    queryFn: getTopWinnerData
  });
};

export const useGetLatestCompetitionQuery = () => {
  return useQuery<TLatestCompetitionListData>({
    queryKey: [ARBITRATING_ALL_USERS_KEY],
    queryFn: getLatestCompetitionList
  });
};

export const usePutWinnerDataQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, PutRequestWinnerData>({
    mutationFn: putWinnerData,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_TOP_WINNER_KEY]);
      showSuccessNotification({ message: 'Winner data changed successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const usePutLatestWinnerCompetitionDataQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse, putRequestLatestWinnerCompetitionData>({
    mutationFn: putLatestWinnerCompetitionData,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_TOP_WINNER_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_ALL_USERS_KEY]);
      showSuccessNotification({ message: 'Winner data changed successfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useGetLatestInterviewDegreeQuery = (participating_request_id: string) => {
  return useQuery<LatestInterviewDegree>({
    queryKey: [ARBITRATING_LATEST_DEGREE_KEY, participating_request_id],
    queryFn: () => getLatestInterviewDegree(participating_request_id)
  });
};

export const useGetAllPercentagesQuery = (id: string) => {
  return useQuery<TArbitratingPercentage>({
    queryKey: [ARBITRATING_APPROVAL_PERCENTAGES_KEY, id],
    queryFn: () => GetAllAssignAribtratingApprovalPercentage(id)
  });
};

export const useGetAllRequestPercentagesQuery = (id: string) => {
  return useQuery<TArbitratingPercentage>({
    queryKey: [ARBITRATING_REQUEST_PERCENTAGES_KEY, id],
    queryFn: () => GetAllArbitratingRequestPercentage(id)
  });
};

export const useAssignAribtratingToUserQuery = (participating_request_id: string[], user_id: string[]) => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: () => AssignAribtratingToUser(participating_request_id, user_id),
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_ASSIGN_KEY]);
      showSuccessNotification({ message: 'Assigned succesfully' });
    },
    onError: (err: ErrorResponse) => {
      if (err.status === 422) {
        showErrorNotification({ message: err.errors[0].error });
      } else {
        showErrorNotification({ message: 'Something went wrong' });
      }
    }
  });
};

export const useAssignAribtratingRequestPercentageQuery = (participantAssignUser_id: string, model: TModel[]) => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: () => AssignAribtratingRequestPercentage(participantAssignUser_id, model),
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_REQUEST_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_REQUEST_PERCENTAGES_KEY, participantAssignUser_id]);
      showSuccessNotification({ message: 'Percentage set succesfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useAssignAribtratingApprovalPercentageQuery = (participating_request_id: string, final_comment: string, model: TModel[]) => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: () => AssignAribtratingApprovalPercentage(participating_request_id, final_comment, model),
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_APPROVAL_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_APPROVAL_PERCENTAGES_KEY, participating_request_id]);
      showSuccessNotification({ message: 'Percentage set succesfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useDeleteAssignAribtratingUserMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: DeleteAssignAribtratingUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_ASSIGN_KEY]);
      showSuccessNotification({ message: 'User deleted successfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useAssignApprovalToInterviewQuery = (participating_request_id: string[]) => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: () => AssignApprovalToInterview(participating_request_id),
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_APPROVAL_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_QUALIFIED_INTERVIEW_KEY]);
      showSuccessNotification(
        { message: `Succesfully assigned ${participating_request_id.length > 1 ? 'items' : 'item'} to Interview` }
      );
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useAssignQualifiedForInterviewToInterviewedQuery = (data: TAssignQualifiedForInterviewToInterviewed, requestId: string) => {
  return useMutation<SuccessResponse, ErrorResponse, TAssignQualifiedForInterviewToInterviewed>({
    mutationFn: AssignQualifiedForInterviewToInterviewed,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_QUALIFIED_INTERVIEW_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_INTERVIEWED_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_COMMENT_HISTORY_KEY, requestId]);
      await queryClient.invalidateQueries([ARBITRATING_APPROVAL_PERCENTAGES_KEY, data.participating_request_id]);
      showSuccessNotification({ message: 'Degree set succesfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useAssignInterviewedToWinnersQuery = (ParticipatingRequestId: string[]) => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: () => AssignInterviewedToWinners(ParticipatingRequestId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_INTERVIEWED_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_ALL_WINNERS_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_TOP_WINNER_KEY]);
      showSuccessNotification(
        { message: `Succesfully assigned ${ParticipatingRequestId.length > 1 ? 'items' : 'item'} to Winners` }
      );
    },
    onError: () => {
      showErrorNotification({ message: 'Something went wrong' });
    }
  });
};

export const useGetArbitratingCommentHistoryQuery = (requestId: string) => {
  return useQuery<TCommentHistory[]>({
    queryKey: [ARBITRATING_COMMENT_HISTORY_KEY, requestId],
    queryFn: () => getArbitratingCommentHistory(requestId)
  });
};

export const useSendArbitratingInterviewEmailQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: sendArbitratingInterviewEmails,
    onSuccess: () => {
      showSuccessNotification({ message: 'Emails send succesfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Try to resend email' });
    }
  });
};

export const useSendArbitratingWinnerEmailQuery = () => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: sendArbitratingWinnerEmails,
    onSuccess: () => {
      showSuccessNotification({ message: 'Emails send succesfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Try to resend email' });
    }
  });
};

export const useSortArbitratingWinnerListtMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string[]>({
    mutationFn: sortArbitratingWinnerList,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ARBITRATING_ALL_WINNERS_KEY]);
      await queryClient.invalidateQueries([ARBITRATING_TOP_WINNER_KEY]);
      showSuccessNotification({ message: 'Items sorted successfully' });
    }
  });
};
