import { FC } from 'react';

import { Controller } from 'react-hook-form';

import FormSection from 'src/components/FormSection';
import ImageUpload from 'src/components/ImageUpload';
import Textarea from 'src/components/Textarea';
import TextInput from 'src/components/TextInput';

import { TContentHomepageSectionProps } from '../types';

const PrinceMessage: FC<TContentHomepageSectionProps> = ({
  control,
  disabled
}) => (
  <FormSection title="Prince message">
    <Controller
      control={control}
      name="crownPrinceMessage.title_en"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <TextInput
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Title - En"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="crownPrinceMessage.title_ar"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <TextInput
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Title - Ar"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="crownPrinceMessage.message_en"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <Textarea
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Message - En"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="crownPrinceMessage.message_ar"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <Textarea
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Message - Ar"
          disabled={disabled}
          required
        />
      )}
    />

    <Controller
      control={control}
      name="crownPrinceMessage.image"
      rules={{ required: 'Required' }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <ImageUpload
          value={value}
          onChange={onChange}
          error={error?.message}
          label="Image"
          required
          disabled={disabled}
        />
      )}
    />
  </FormSection>
);

export default PrinceMessage;
