import { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useCompetitionPathByIdQuery, useUpdateCompetitionPathsItemMutation } from 'src/api/competition-paths/hooks';
import ManageCompetitionPaths from 'src/components/ManageCompetitionPaths';
import { competitionPathFormDefaultValues } from 'src/modules/competition-paths/constants';
import { TCompetitionPathItemForm } from 'src/modules/competition-paths/types';
import { Paths } from 'src/router/constants';

const CompetitionPathsItemEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, isLoading: isCompetitionPathLoading } = useCompetitionPathByIdQuery(id || '');
  const { mutate, isLoading: isUpdateCompetitionPathLoading } = useUpdateCompetitionPathsItemMutation();

  useEffect(() => {
    if (data === null) {
      navigate(Paths.competitions);
    }
  }, [data, navigate]);

  const handleItemUpdate = (data: TCompetitionPathItemForm) => {
    mutate({ id: id || '', ...data });
  };

  const form = useForm<TCompetitionPathItemForm>({
    defaultValues: competitionPathFormDefaultValues
  });

  useEffect(() => {
    if (data) {
      form.reset({
        title_en: data.title_en,
        title_ar: data.title_ar,
        body_en: data.body_en,
        body_ar: data.body_ar,
        image: data.image_path
      });
    }
  }, [form, data]);

  return (
    <ManageCompetitionPaths
      form={form}
      onSubmit={handleItemUpdate}
      submitText="Save"
      title="Edit Competition Path"
      isLoading={isCompetitionPathLoading || isUpdateCompetitionPathLoading}
    />
  );
};

export default CompetitionPathsItemEdit;
