import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TGeneralSecretariatItemForm, TGeneralSecretariatList } from 'src/modules/general-secretariat/types';

import {
  createGeneralSecretariatItem,
  deleteGeneralSecretariatItem,
  getGeneralSecretariatList, sortGeneralSecretariatList,
  updateGeneralSecretariatItem
} from './api';
import { GENERAL_SECRETARIAT_LIST_QUERY_KEY } from './constants';
import { TGeneralSecretariatItemUpdateRequest } from './types';

export const useGeneralSecretariatListQuery = () => {
  return useQuery<TGeneralSecretariatList>({
    queryKey: [GENERAL_SECRETARIAT_LIST_QUERY_KEY],
    queryFn: getGeneralSecretariatList
  });
};

export const useCreateGeneralSecretariatItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TGeneralSecretariatItemForm>({
    mutationFn: createGeneralSecretariatItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([GENERAL_SECRETARIAT_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateGeneralSecretariatItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TGeneralSecretariatItemUpdateRequest>({
    mutationFn: updateGeneralSecretariatItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([GENERAL_SECRETARIAT_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteGeneralSecretariatItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteGeneralSecretariatItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([GENERAL_SECRETARIAT_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};

export const useSortGeneralSecretariatListMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string[]>({
    mutationFn: sortGeneralSecretariatList,
    onSuccess: async () => {
      await queryClient.invalidateQueries([GENERAL_SECRETARIAT_LIST_QUERY_KEY]);
      showSuccessNotification({ message: 'Items sorted successfully' });
    }
  });
};
