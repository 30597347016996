import { FC, useEffect, useMemo, useState } from 'react';

import { Center, Loader, Text, Stack, Flex, Button, Image } from '@mantine/core';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAdminResendVerification, useAdminVerifyMutation } from 'src/api/admin/hooks';
import logo from 'src/assets/svg/logo-row.svg';
import { Paths } from 'src/router/constants';

const AdminVerify: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isVerifiedSuccesfully, setIsVerifiedSuccesfully] = useState<boolean>(true);

  const searchData = useMemo(() => ({
    email: searchParams.get('email') || '',
    token: searchParams.get('token') || ''
  }), [searchParams]);

  const email = useMemo(() => ({
    email: searchParams.get('email') || ''
  }), [searchParams]);

  const { mutateAsync, isLoading } = useAdminVerifyMutation();
  const { mutate } = useAdminResendVerification();

  useEffect(() => {
    mutateAsync(searchData).catch((err) => {
      if (err) setIsVerifiedSuccesfully(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex mih="100vh">
      <Center sx={{ flexGrow: 1 }}>
        {isLoading ? (
          <Loader size={90} />
        ) : (
          <Stack align="center" spacing={20} pos="relative">
            <Image
              pos="absolute"
              sx={{ top: '-130px' }}
              src={logo}
              width={400}
              height={100}
              withPlaceholder
              fit="contain"
              mb={30}
            />
            <Text size={56} weight={700} lh={1.2}>
              {isVerifiedSuccesfully ? 'Verified successfully' : 'Code expired'}
            </Text>
            <Text size={20} lh={1.2} mb={20}>
              {isVerifiedSuccesfully
                ? 'You can now safely log in to IAMI Control Panel'
                : 'You can try to resend verification email'
              }
            </Text>
            {isVerifiedSuccesfully ? (
              <Button size="lg" onClick={() => navigate(Paths.login)}>
                Go to login page
              </Button>
            ) : (
              <Button size="lg" onClick={() => mutate(email)}>
                Resend verification email
              </Button>
            )}
          </Stack>
        )}
      </Center>
    </Flex>
  );
};

export default AdminVerify;
