import { Image } from '@mantine/core';
import logo from 'src/assets/svg/logo-colored.svg';
import styles from '../GeneratePDF.module.scss'
import AttachmentButton from '../AttachmentButton';

interface TProps {
  data: any
}

const InnovationData = ({ data }: TProps) => {
  const { innovation_data, path } = data;

  return (
    <div className={styles.page}>
      <div className={styles.image}><Image src={logo} width={120} height={120} fit="contain" /></div>
      <div className={styles.divider} />

      <div className={styles.content}>
        <div className={styles.title}>
          <h3>Innovation Data</h3>
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Innovation Name</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{innovation_data?.innovation_name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Innovation Type</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>
              {innovation_data?.innovation_type?.length ? (
                <>
                  {innovation_data.innovation_type.map((item: any) => (
                    <p key={item.id}>{item.name}{'  '}</p>
                  ))}
                </>
              ) : '-'}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Field of Innovation</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{path.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Innovation Description</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}><p>{innovation_data?.description}</p></div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Your Role in Innovation</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{data?.participating_role_id?.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />The Biography</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>
              <AttachmentButton href={innovation_data?.innovation_attachment} />
            </div>
          </div>
        </div>
      </div>

      <h3>page-3</h3>
    </div>
  )
}

export default InnovationData;