import { FC } from 'react';

import { Button, Center, Flex, Stack, Title } from '@mantine/core';

import Paper from 'src/components/Paper';

import { TContentPageWrapper } from './types';

const ContentPageWrapper: FC<TContentPageWrapper> = ({
  children,
  title,
  isEditing,
  onEditEnter,
  onEditLeave
}) => (
  <Center>
    <Paper w={700} pos="relative">
      <Stack spacing={20}>
        <Flex align="center" justify="space-between">
          <Title order={2}>
            {title}
          </Title>
          {isEditing ? (
            <Button
              variant="outline"
              onClick={onEditLeave}
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant="filled"
              onClick={onEditEnter}
            >
              Edit
            </Button>
          )}
        </Flex>
        {children}
      </Stack>
    </Paper>
  </Center>
);

export default ContentPageWrapper;
