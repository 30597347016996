import { FC } from 'react';

import { Button, LoadingOverlay, Select, Stack } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

import { useGenerateParticipantApprovalPDFMutation } from 'src/api/participants-requests/hooks';
import Form from 'src/components/Form';

type Props = {
  requestNumber: string;
  email: string;
};

const GeneratePDFModalContent: FC<Props> = ({
  requestNumber,
  email
}) => {
  const { mutateAsync: generatePDF, isLoading: isGeneratePDFLoading } = useGenerateParticipantApprovalPDFMutation();

  const form = useForm<{ language: string | null }>({
    defaultValues: { language: null }
  });

  const handleSubmit = async ({ language }: { language: string | null }) => {
    if (language) {
      const response = await generatePDF({ language, requestNumber, email });

      if (response.data) {
        window.open(response.data, '_blank');
      }
    }
  };

  return (
    <>
      <LoadingOverlay visible={isGeneratePDFLoading} />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="language"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                error={error?.message}
                data={[{ value: 'ar-SA', label: 'Arabic' }, { value: 'en', label: 'English' }]}
                label="Select language"
                required
                disabled={isGeneratePDFLoading}
              />
            )}
          />

          <Button type="submit" disabled={isGeneratePDFLoading}>
            Generate
          </Button>
        </Stack>
      </Form>
    </>
  );
};

export default GeneratePDFModalContent;
