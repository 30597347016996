import { useCallback, useMemo } from 'react';

import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { IconEye, IconStatusChange } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { useTicketStatusListQuery, useTicketTypeListQuery } from 'src/api/tickets/hooks';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import { TTableFilters } from 'src/components/TableFilters';
import { Paths } from 'src/router/constants';

import { tableHeadings } from './constants';
import TicketUpdateStatusModalContent from './TicketUpdateStatusModalContent';
import { TTicket } from './types';

export const useTicketsTableColumns = () => {
  const navigate = useNavigate();

  const openUpdateModal = useCallback((ticket: TTicket) => openModal({
    title: 'Update ticket status',
    size: 400,
    padding: 30,
    closeOnClickOutside: false,
    centered: true,
    children: <TicketUpdateStatusModalContent ticket={ticket} />
  }), []);

  return useMemo(() => {
    const columnHelper = createColumnHelper<TTicket>();

    return [
      columnHelper.accessor('ticket_number', {
        header: tableHeadings.ticketNumber,
        minSize: 170,
        enableResizing: false
      }),

      columnHelper.accessor('name', {
        header: tableHeadings.name,
        minSize: 170,
        enableResizing: false
      }),

      columnHelper.accessor('email', {
        header: tableHeadings.email,
        minSize: 225,
        enableResizing: false
      }),

      columnHelper.accessor('title', {
        header: tableHeadings.title
      }),

      columnHelper.accessor('tickit_type', {
        header: tableHeadings.ticketType,
        cell: (cell) => cell.getValue()?.name || '',
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.tickit_type.name;
          const valueB = rowB.original.tickit_type.name;

          if (valueA < valueB)
            return -1;
          if (valueA > valueB)
            return 1;
          return 0;
        },
        filterFn: (row, columnId, filterValue) => {
          return +filterValue === row.original.tickit_type.id;
        }
      }),

      columnHelper.accessor('ticket_status', {
        header: tableHeadings.ticketStatus,
        cell: (cell) => cell.getValue()?.name || '',
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.ticket_status.name;
          const valueB = rowB.original.ticket_status.name;

          if (valueA < valueB)
            return -1;
          if (valueA > valueB)
            return 1;
          return 0;
        },
        filterFn: (row, columnId, filterValue) => {
          return +filterValue === row.original.ticket_status.id;
        }
      }),

      columnHelper.accessor('creating_date', {
        header: tableHeadings.creating_date,
        minSize: 170,
        enableResizing: false,
        cell: (cell) => new Date(cell.getValue()).toDateString()
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="center" align="center" gap={10}>
            <Tooltip label="View" withArrow arrowSize={6}>
              <ActionIcon
                size="sm"
                onClick={() => navigate(generatePath(Paths.ticketsItemView, { id: row.original.id }))}
              >
                <IconEye color="#868e96" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Update status" withArrow arrowSize={6}>
              <ActionIcon
                size="sm"
                onClick={() => openUpdateModal(row.original)}
              >
                <IconStatusChange color="#868e96" />
              </ActionIcon>
            </Tooltip>
          </Flex>
        )
      })
    ];
  }, [navigate, openUpdateModal]);
};

export const useTicketsTableFilters = () => {
  const { data: ticketStatusList } = useTicketStatusListQuery();
  const { data: ticketTypeList } = useTicketTypeListQuery();

  const normalizedTicketStatusList = useNormalizedItemsData(ticketStatusList);
  const normalizedTicketTypeList = useNormalizedItemsData(ticketTypeList);

  return useMemo((): TTableFilters<TTicket> => [
    {
      id: 'ticket_number',
      title: tableHeadings.ticketNumber,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'title',
      title: tableHeadings.title,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'tickit_type',
      title: tableHeadings.ticketType,
      type: 'select',
      data: normalizedTicketTypeList || [],
      defaultValue: null
    },
    {
      id: 'ticket_status',
      title: tableHeadings.ticketStatus,
      type: 'select',
      data: normalizedTicketStatusList || [],
      defaultValue: null
    }
  ], [normalizedTicketStatusList, normalizedTicketTypeList]);
};
