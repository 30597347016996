import { FC } from 'react';

import { LoadingOverlay, Flex, Stack, Button } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useDeleteAdminUser } from 'src/api/admin/hooks';
import { AdminUser } from 'src/modules/admin/types';

const AdminDeleteUserModal: FC<ContextModalProps<{ admin: AdminUser }>> = ({
  context,
  id,
  innerProps: { admin }
}) => {
  const { mutate: deleteUser, isLoading } = useDeleteAdminUser();

  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleDelete = () => {
    deleteUser(admin.id);
    context.closeModal(id);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Stack>
        <Flex justify="center" gap={16}>
          <Button variant="outline" onClick={handleCancel}>
            No
          </Button>
          <Button onClick={handleDelete}>
            Yes
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default AdminDeleteUserModal;
