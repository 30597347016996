import { FC, useCallback } from 'react';

import { Button, Center, Flex, Group, Loader, Stack, Table, Text, Title } from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';

import {
  useArbitrationCriteriaListQuery,
  useDeleteArbitrationCriteriaMutation
} from 'src/api/arbitration-criteria/hooks';
import Paper from 'src/components/Paper';
import { TArbitrationCriteriaItem } from 'src/modules/arbitration-criteria/types';

import AddModalContent from './AddModalContent';
import EditModalContent from './EditModalContent';

const ArbitrationCriteria: FC = () => {
  const { data, isLoading: isDataLoading } = useArbitrationCriteriaListQuery();
  const { mutateAsync: deleteItem } = useDeleteArbitrationCriteriaMutation();

  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    closeOnConfirm: false,
    onConfirm: async () => {
      await deleteItem(id);
    }
  }), [deleteItem]);

  const openEditModal = useCallback((item: TArbitrationCriteriaItem) => openModal({
    title: <Title order={3}>Edit</Title>,
    exitTransitionDuration: 300,
    closeOnClickOutside: true,
    size: 500,
    padding: 30,
    children: <EditModalContent item={item} />
  }), []);

  const openAddModal = useCallback(() => openModal({
    title: <Title order={3}>Add</Title>,
    closeOnClickOutside: true,
    size: 500,
    padding: 30,
    children: <AddModalContent />
  }), []);

  return (
    <Center>
      <Paper w={700} pos="relative">
        <Stack spacing={20}>
          <Flex align="center" justify="space-between">
            <Title order={2}>
              Arbitration Criteria
            </Title>

            <Button
              leftIcon={<IconPlus size={18} />}
              onClick={openAddModal}
            >
              Add
            </Button>
          </Flex>
          {isDataLoading ? (
            <Center py={50}>
              <Loader size="lg" />
            </Center>
          ) : null}
          {data ? (
            <Stack>
              <Table sx={{ tableLayout: 'fixed' }}>
                <thead>
                <tr>
                  <th>Title</th>
                  <th>Percentage</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td>{item.percentage}%</td>
                    <td>
                      <Group>
                        <Button
                          variant="subtle"
                          onClick={() => openEditModal(item)}
                        >
                          Edit
                        </Button>
                        <Button
                          color="red"
                          variant="subtle"
                          onClick={() => openDeleteModal(item.id)}
                        >
                          Delete
                        </Button>
                      </Group>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Stack>
          ) : null}
        </Stack>
      </Paper>
    </Center>
  );
};

export default ArbitrationCriteria;
