import { useState } from 'react';

import { Button } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useParticipantsRequestsEvaluationStageListQuery } from 'src/api/participants-requests/hooks';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';

type Props = {
  tableInstanceRef: React.MutableRefObject<Table<any> | undefined>;
};

export const EvaluationStageFilter = ({ tableInstanceRef }: Props) => {
  const { data } = useParticipantsRequestsEvaluationStageListQuery();
  const evaluationStatusList = useNormalizedItemsData(data);

  const [active, setActive] = useState('0');

  const setFilter = (id: string) => {
    setActive(id);
    tableInstanceRef.current?.setColumnFilters([{ id: 'evaluation_stage', value: id }]);
  };

  const resetFilter = () => {
    tableInstanceRef.current?.setColumnFilters([]);
  };

  return (
    <Button.Group>
      <Button variant={active === '0' ? 'filled' : 'default'} onClick={resetFilter}>
        All
      </Button>
      {evaluationStatusList
        .filter(({ value }) => !['1', '3', '4'].includes(value)) // filter out 'New' | 'Autorizing' | 'Approval'
        .map(({ label, value }) => (
          <Button
            variant={value === active ? 'filled' : 'default'}
            onClick={() => setFilter(value)}
            key={value}
          >
            {label}
          </Button>
        ))}
    </Button.Group>
  );
};
