export type NormalizedItem = {
  value: string;
  label: string;
  ar?: boolean;
};

export const useNormalizedItemsData = <T extends Record<string, any>>(
  items: T[] | undefined,
  config?: {
    valueKey?: keyof T;
    labelKey?: keyof T;
  },
  ar = false
): NormalizedItem[] => {
  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => ({
    value: item[config?.valueKey || 'id'].toString(),
    label: item[config?.labelKey || (ar ? 'nameArabic' : 'name')]
  }));
};
