export enum Paths {
  homepage = '/',
  error = '/error',
  login = '/login',
  account = '/account',
  admins = '/admins',
  users = '/users',
  interestedUser = '/interested-user',
  participantsRequests = '/participants-requests',
  participantsRequestsItemView = '/participants-requests/:id/view',
  participantsRequestsItemEvaluation = '/participants-requests/:id/evaluation',
  participantsRequestsItemParticipantsApproval = '/participants-requests/:id/participants-approval',
  news = '/media-center/news',
  newsAddItem = '/media-center/news/add',
  newsItemEdit = '/media-center/news/:id/edit',
  photos = '/media-center/photos',
  photosAddItem = '/media-center/photos/add',
  photosItemEdit = '/media-center/photos/:id/edit',
  videos = '/media-center/videos',
  videosAddItem = '/media-center/videos/add',
  videosItemEdit = '/media-center/videos/:id/edit',
  termsAndConditions = '/general/terms-and-conditions',
  privacyPolicy = '/general/privacy-policy',
  faq = '/general/faq',
  content = '/content',
  contentHomepage = '/content/homepage',
  aboutThePrize = '/content/about-the-prize',
  supervisoryBoard = '/content/supervisory-board',
  generalSecretariat = '/content/general-secretariat',
  winnersContent = '/content/winners',
  innovationsContent = '/content/innovations',
  competitionPaths = '/competition-paths',
  competitionPathsAddItem = '/competition-paths/add',
  competitionPathsItemEdit = '/competition-paths/:id/edit',
  competitions = '/competitions',
  competitionsAddItem = '/competitions/add',
  competitionsItemEdit = '/competitions/:id/edit',
  regulations = '/regulations',
  adminVerify = '/admin-verify',
  tickets = '/tickets',
  ticketsItemView = '/tickets/:id/view',
  topWinners = '/content/top-winners',
  topWinnersItemDetail = '/content/top-winners/:id/detail',
  arbitrationCriteria = '/arbitration-criteria',
  competitionConditions = '/competition-conditions',
  socialMediaAccounts = '/content/social-media-accounts',
  evaluationCriteria = '/content/evaluation-criteria',
  emailTemplate = '/content/email-template',
  newEmailTemplate = '/content/email-template/new',
  editEmailTemplate = '/content/email-template/:id/edit',
  filtration = '/filtration',
  filtrationRequest = '/filtration/request',
  filtrationRequestItemEvaluation = '/filtration/requests/:id/evaluation',
  filtrationApproval = '/filtration/approval',
  filtrationApprovalItemEvaluation = '/filtration/approval/:id/evaluation',
  arbitrating = '/arbitrating',
  arbitratingAssign = '/arbitrating/assign',
  arbitratingAssignItemEvaluation = '/arbitrating/assign/:id/evaluation',
  arbitratingApproval = '/arbitrating/approval',
  arbitratingApprovalItemEvaluation = '/arbitrating/approval/:id/evaluation',
  arbitratingRequest = '/arbitrating/request',
  arbitratingRequestItemEvaluation = '/arbitrating/request/:id/evaluation',
  arbitratingInterview = '/arbitrating/interview',
  arbitratingInterviewItemEvaluation = '/arbitrating/interview/:id/evaluation',
  winners = '/winners',
  winnersItemEvaluation = '/winners/:id/evaluation'
}
