import { useEffect, useRef, useState } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import DataTable from 'src/components/DataTable';
import InnovationsEditableData from 'src/components/InnovationsEditableData';
import { useInnovationsTableColumns, useInnovationsTableFilters } from 'src/modules/innovations/hooks';
import { useLayoutStore } from 'src/store/useLayoutStore';
import { useInnovationListQuery, useSortInnovation } from 'src/api/innovations/hooks';
import { reorderIds } from 'src/common/helpers/dragAndDrop';
import VisibleFilter from 'src/components/VisibleFilter';
import { useSetShowHidePageQuery, useShowHidePageListQuery } from 'src/api/content-management/hooks';

const Innovations = () => {
  const { openedInovationItem } = useLayoutStore();
  const tableInstanceRef = useRef<Table<any>>();

  const { data, isLoading } = useInnovationListQuery();
  const { data: Content, isLoading: isContent } = useShowHidePageListQuery();
  const { mutate: reorderInnovations, isLoading: reorderInnovationsLoading } = useSortInnovation();
  const { mutate: updateShowHidePage } = useSetShowHidePageQuery('Innovations');

  const columns = useInnovationsTableColumns();
  const filters = useInnovationsTableFilters();
  const isPageShow = !isContent && Content && Content.data.find((el) => el.name === 'Innovations');

  useEffect(() => {
    useLayoutStore.setState(() => {
      return { openedInovationItem: '' };
    });
  }, []);

  const handleReorder = (sourceIndex: number, destinationIndex: number) => {
    if (!isLoading && !data) {
      return;
    };

    reorderInnovations(reorderIds((data || []), sourceIndex, destinationIndex));
  };
  
  return (
    <Flex direction='column' h='100%'>
      <Flex align='center' justify='space-between' pb={30}>
        <Title order={2}>Innovations</Title>
      </Flex>
      {isLoading && isContent && (
        <Center py={50}>
          <Loader size='lg' />
        </Center>
      )}
      <DataTable
        columns={columns}
        draggable={true}
        data={data || []}
        filters={filters}
        onDragEnd={handleReorder}
        isLoading={reorderInnovationsLoading}
        tableInstanceRef={tableInstanceRef}
        extraFilterElement={
          <VisibleFilter
            value={isPageShow && isPageShow.is_show}
            onChange={() => isPageShow && updateShowHidePage({content_id: isPageShow.id, is_show: !isPageShow.is_show})}
          />
        }
        additionalComponent={<InnovationsEditableData />}
      />
    </Flex>
  );
};

export default Innovations;
