import styles from '../GeneratePDF.module.scss'
import { Image } from '@mantine/core';
import logo from 'src/assets/svg/logo-colored.svg';
import { getFullName } from 'src/pages/ParticipantsRequestsItemView/helpers';
import dayjs from 'dayjs';
import AttachmentButton from '../AttachmentButton';

interface TProps {
  data: any
}

const PersonalInformation = ({ data }: TProps) => {
  const { member, city_id, cv, employer, participating_role_id } = data;

  return (
    <div className={styles.page}>
      <div className={styles.image}><Image src={logo} width={120} height={120} fit="contain" /></div>
      <div className={styles.divider} />

      <div className={styles.content}>
        <div className={styles.title}>
          <h3>Personal Information</h3>
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Full Name</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{getFullName(member)}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Nationality</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{member.nationality.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Gender</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{member.gender.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Mobile Number</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{member.mobile}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />City</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{city_id?.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />ID/iqama Number</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{member.user_identity}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Birth Date</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{member.birthday ? dayjs(member.birthday).format('DD-MM-YYYY') : '-'}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Email</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{member.email}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Employer</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{employer}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Role in the Innovation</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{participating_role_id?.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />CV/Resume</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>
              <AttachmentButton href={cv} />
            </div>
          </div>
        </div>
      </div>

      <h3>page-1</h3>
    </div>
  )
}

export default PersonalInformation;
