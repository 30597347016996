import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TSocialMediaAccount, TSocialMediaAccountForm } from 'src/modules/social-media-accounts/types';

import { getSocialMediaAccounts, updateSocialMediaAccount } from './api';
import { SOCIAL_MEDIA_ACCOUNTS_LIST } from './constants';

export const useSocialMediaAccountsList = () => {
  return useQuery<TSocialMediaAccount[]>({
    queryKey: [SOCIAL_MEDIA_ACCOUNTS_LIST],
    queryFn: getSocialMediaAccounts
  });
};

export const useUpdateSocialMediaAccountMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TSocialMediaAccountForm>({
    mutationFn: updateSocialMediaAccount,
    onSuccess: async () => {
      await queryClient.invalidateQueries([SOCIAL_MEDIA_ACCOUNTS_LIST]);
      closeAllModals();
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};
