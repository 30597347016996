import React, { useMemo } from 'react';

import { ActionIcon, Grid, Tooltip } from '@mantine/core';
import { IconChecklist } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { STATUS_COLORS } from 'src/common/constants/defConstant';
import { RequestStatus } from 'src/modules/competitions/constants';
import { Paths } from 'src/router/constants';

import { tableHeadings } from './constants';
import { TParticipantsRequest } from '../participants-requests/types';

export const useArbitratingWinnersTableColumns = () => {
  const navigate = useNavigate();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TParticipantsRequest>();

    return [
      columnHelper.display({
        header: 'Rank',
        size: 50,
        maxSize: 50,
        enableResizing: false,
        cell: (cell) => cell.row.index + 1
      }),

      columnHelper.accessor('request_number', {
        header: tableHeadings.requestNumber,
        size: 155,
        enableResizing: false
      }),

      columnHelper.accessor('member.email', {
        header: tableHeadings.email,
        minSize: 160
      }),

      columnHelper.accessor('innovation_name', {
        header: tableHeadings.innovationName,
        minSize: 160,
        cell: (cell) => cell.row.original.innovation_name_ar || cell.row.original.innovation_name
      }),

      columnHelper.accessor('path', {
        header: tableHeadings.innovationPath,
        size: 160
      }),

      columnHelper.accessor('participating_type', {
        header: tableHeadings.participatingType,
        size: 175,
        enableResizing: false,
        cell: (cell) => cell.getValue()?.name || '',
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.participating_type?.name || '';
          const valueB = rowB.original.participating_type?.name || '';

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      }),

      columnHelper.accessor('arbitrating_score', {
        header: tableHeadings.arbitrating_score,
        size: 160,
        cell: (cell) => {
          const validScores = cell.row.original.judgment_data && cell.row.original.judgment_data.filter((item) => item.final_score);
          const firstScore = validScores && validScores[0];
          return validScores && firstScore && firstScore.final_score ? (
            <React.Fragment key={firstScore.id}>
              <p>{(+firstScore.final_score).toFixed(2)}%</p>
            </React.Fragment>
          ) : null;
        }
      }),

      columnHelper.accessor('interview_score', {
        header: tableHeadings.interview_score,
        size: 160,
        cell: (cell) => cell.row.original.degree &&
          (+cell.row.original.degree).toFixed(2) + '%'
      }),

      columnHelper.accessor('status', {
        header: tableHeadings.status,
        size: 80,
        cell: (cell) => {
          const key = cell.row.original.status?.id as RequestStatus;

          return <span style={{color: STATUS_COLORS[key]}}>{cell.getValue()?.name}</span>;
        },
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.status?.name;
          const valueB = rowB.original.status?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, columnId, filterValue) => {
          return +filterValue === row.original.status?.id;
        }
      }),

      columnHelper.accessor('evaluation_stage', {
        header: tableHeadings.evaluationStatus,
        size: 70,
        cell: (cell) => (cell.getValue()?.name),
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.evaluation_stage?.name;
          const valueB = rowB.original.evaluation_stage?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, _columnId, filterValue) => {
          return +filterValue === row.original.evaluation_stage?.id;
        }
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <>
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <Tooltip label="Evaluation" withArrow arrowSize={6}>
                    <ActionIcon
                      size="sm"
                      onClick={() => {
                        navigate(
                          generatePath(Paths.winnersItemEvaluation, {
                            id: row.original.id
                          })
                        );
                      }}
                    >
                      <IconChecklist size={20} />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              </Grid>
            </>
          );
        }
      })
    ];
  }, [navigate]);
};
