import { useMemo } from 'react';

import { ActionIcon, Grid, Tooltip } from '@mantine/core';
import { IconChecklist, IconEye, IconUserCheck } from '@tabler/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { AdminRoles } from 'src/modules/admin/constants';
import { RequestStatus } from 'src/modules/competitions/constants';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import { RequestFiltrationType, tableHeadings } from './constants';
import { TParticipantsRequest } from '../participants-requests/types';
import { STATUS_COLORS } from 'src/common/constants/defConstant';

export const useFiltrationApprovalTableColumns = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TParticipantsRequest>();

    return [
      columnHelper.accessor('request_number', {
        header: tableHeadings.requestNumber,
        size: 155,
        enableResizing: false
      }),

      columnHelper.accessor('user_filtering', {
        header: tableHeadings.operationCommittee,
        minSize: 270
      }),

      columnHelper.accessor('member.email', {
        header: tableHeadings.email,
        minSize: 160
      }),

      columnHelper.accessor('innovation_name', {
        header: tableHeadings.innovationName,
        minSize: 160
      }),

      columnHelper.accessor('path', {
        header: tableHeadings.innovationPath,
        size: 160
      }),

      columnHelper.accessor('participating_type', {
        header: tableHeadings.participatingType,
        size: 175,
        enableResizing: false,
        cell: (cell) => cell.getValue()?.name || '',
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.participating_type?.name || '';
          const valueB = rowB.original.participating_type?.name || '';

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      }),

      columnHelper.accessor('status', {
        header: tableHeadings.status,
        size: 80,
        cell: (cell) => {
          const key = cell.row.original.status?.id as RequestStatus;

          return <span style={{color: STATUS_COLORS[key]}}>{cell.getValue()?.name}</span>;
        },
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.status?.name;
          const valueB = rowB.original.status?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, columnId, filterValue) => {
          return +filterValue === row.original.status?.id;
        }
      }),

      columnHelper.accessor('evaluation_stage', {
        header: tableHeadings.evaluationStatus,
        size: 70,
        cell: (cell) => cell.getValue()?.name,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.evaluation_stage?.name;
          const valueB = rowB.original.evaluation_stage?.name;

          if (!valueA || !valueB) {
            return 0;
          }

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        },
        filterFn: (row, _columnId, filterValue) => {
          return +filterValue === row.original.evaluation_stage?.id;
        }
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => {
          const isStatusNew = row.original.status?.id === RequestStatus.New;
          const isGroupFiltrationType = row.original.participating_type?.id === RequestFiltrationType.Group;
          const isUserSuperAdmin = user?.userRole.id === AdminRoles.SuperAdmin || user?.userRole.id === AdminRoles.GeneralSecretariat;
          const isUserOperationCometee = user?.userRole.id === AdminRoles.OperationCommittee;
          const isRequestDone = row.original.status?.id === RequestStatus.FinalApproved || row.original.status?.id === RequestStatus.FinalRejected;

          return (
            <>
              <Grid columns={2}>
                {isUserSuperAdmin && (
                  <Grid.Col span={1}>
                    {(!isStatusNew || isUserSuperAdmin || isUserOperationCometee) && (
                      <Tooltip label="Evaluation" withArrow arrowSize={6}>
                        <ActionIcon
                          size="sm"
                          onClick={() => {
                            navigate(
                              generatePath(Paths.filtrationApprovalItemEvaluation, {
                                id: row.original.id
                              })
                            );
                          }}
                        >
                          {isStatusNew ? <IconEye size={20} /> : <IconChecklist size={20} />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Grid.Col>
                )}
                {!isRequestDone ? (
                  <Grid.Col span={1}>
                    {isGroupFiltrationType && !isStatusNew && (isUserSuperAdmin || isUserOperationCometee) ? (
                      <Tooltip label="Participant Approval" withArrow arrowSize={6}>
                        <ActionIcon
                          size="sm"
                          onClick={() =>
                            navigate(
                              generatePath(Paths.participantsRequestsItemParticipantsApproval, {
                                id: row.original.id
                              })
                            )
                          }
                        >
                          <IconUserCheck size={20} />
                        </ActionIcon>
                      </Tooltip>
                    ) : null}
                  </Grid.Col>
                ) : null}
              </Grid>
            </>
          );
        }
      })
    ];
  }, [navigate, user]);
};
