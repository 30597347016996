import { FC } from 'react';

import { Tabs } from '@mantine/core';
import { IconTicket, IconSquareRoundedX } from '@tabler/icons';

import { TTicketsStatusTabsProps } from 'src/modules/tickets/types';

const TicketsStatusTabs: FC<TTicketsStatusTabsProps> = ({ handleTabChange }) => {
  return (
    <Tabs variant="outline" defaultValue={'open'} onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="open" icon={<IconTicket size="0.8rem" />}>
          Open
        </Tabs.Tab>
        <Tabs.Tab value="closed" icon={<IconSquareRoundedX color="crimson" size="0.8rem" />}>
          Closed
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default TicketsStatusTabs;
