import { Badge } from '@mantine/core';

export const renderAdminStatus = (status: boolean) => {
  return status ? (
    <Badge variant="filled" color="teal">
      Active
    </Badge>
  ) : (
    <Badge variant="outline" color="gray">
      Disabled
    </Badge>
  );
};
