import { FC } from 'react';

import { Box, Flex, Text } from '@mantine/core';
import { flexRender } from '@tanstack/react-table';

import styles from '../Table.module.scss';
import TableHeaderCell from '../TableHeaderCell';
import { TTableProps } from '../types';

const TableHeaderGroup: FC<TTableProps> = ({ table, draggable, additionalComponent }) => {
  return (
    <Box ml={draggable ? 24 : 0}>
      {table.getHeaderGroups().map((headerGroup) => (
        <Flex key={headerGroup.id} className={styles.tableHeaders}>
          {headerGroup.headers.map((header) => (
            <>
              {draggable && header.index === 0 ? (
                <Text py={10} fw={600} mr={additionalComponent ? 0 : 14}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())
                  }
                </Text>
              ) : (
                <TableHeaderCell key={header.id} table={table} header={header}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())
                  }
                </TableHeaderCell>
              )}
            </>
          ))}
        </Flex>
      ))}
    </Box>
  );
};

export default TableHeaderGroup;
