import { FC, ReactNode } from 'react';

import { clsx, Flex, Box } from '@mantine/core';
import { IconSelector, IconChevronUp, IconChevronDown } from '@tabler/icons';

import styles from './TableHeaderCell.module.scss';
import { TTableHeaderCellProps, TSortingDirection } from './types';

const sortingIcons: Record<TSortingDirection, ReactNode> = {
  unset: <IconSelector size={14} color="#172b4d" />,
  asc: <IconChevronDown size={14} color="#172b4d" />,
  desc: <IconChevronUp size={14} color="#172b4d" />
};

const TableHeaderCell: FC<TTableHeaderCellProps> = ({
  children,
  table,
  header
}) => {
  const sorting = header.column.getIsSorted() || 'unset';
  const canSort = header.column.getCanSort();
  const canResize = header.column.getCanResize();
  const resizingColumnId = table.getState().columnSizingInfo.isResizingColumn;

  return (
    <Flex
      align="center"
      justify="space-between"
      className={clsx(styles.root, {
        [styles.canSort]: canSort
      })}
      sx={{ width: header.getSize() }}
      onMouseUp={canSort && !resizingColumnId ? header.column.getToggleSortingHandler() : undefined}
    >
      {children}
      {canSort && sortingIcons[sorting]}
      {canResize ? (
        <Box
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={clsx(styles.resizer, {
            [styles.resizerActive]: header.column.getIsResizing(),
            [styles.resizerIdle]: resizingColumnId && resizingColumnId !== header.id
          })}
        />
      ) : (
        <Box className={styles.separator} />
      )}
    </Flex>
  );
};

export default TableHeaderCell;
