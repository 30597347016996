import { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useCompetitionByIdQuery, useUpdateCompetitionItemMutation } from 'src/api/competitions/hooks';
import ManageCompetition from 'src/components/ManageCompetition';
import { competitionFormDefaultValues } from 'src/modules/competitions/constants';
import { TCompetitionItemForm } from 'src/modules/competitions/types';
import { Paths } from 'src/router/constants';

const CompetitionsItemEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, isLoading: isDataLoading } = useCompetitionByIdQuery(id || '');
  const { mutate, isLoading: isUpdateCompetitionLoading } = useUpdateCompetitionItemMutation();

  useEffect(() => {
    if (data === null) {
      navigate(Paths.competitions);
    }
  }, [data, navigate]);

  const handleItemUpdate = (data: TCompetitionItemForm) => {
    mutate({ id: id || '', ...data });
  };

  const form = useForm<TCompetitionItemForm>({
    defaultValues: competitionFormDefaultValues
  });

  useEffect(() => {
    if (data) {
      form.reset({
        title_en: data.title_en,
        title_ar: data.title_ar,
        description_en: data.description_en,
        description_ar: data.description_ar,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
        location_en: data.location_en,
        location_ar: data.location_ar,
        image: data.image_path,
        qualification_en: data.qualification_en,
        qualification_ar: data.qualification_ar,
        isActive: data.isActive ? 'true' : 'false',
        competition_path_ids: data.paths.map((item) => item.id.toString())
      });
    }
  }, [form, data]);

  return (
    <ManageCompetition
      title="Edit competition"
      submitText="Save"
      form={form}
      data={data}
      onSubmit={handleItemUpdate}
      isLoading={isDataLoading || isUpdateCompetitionLoading}
    />
  );
};

export default CompetitionsItemEdit;
