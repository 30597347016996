import { TUser } from 'src/modules/users/types';

export const getUsersDataForExport = (users: TUser[] | undefined) => {
  if (!users) {
    return [
      { data: [], title: 'Data' },
      { data: [], title: 'Statistics' }
    ];
  }
  const usersDataSheet = users?.map(mapUserToExport);
  const usersStatisticsSheet = getUsersStatisticsSheet(users);

  return [
    { data: usersDataSheet, title: 'Data' },
    { data: usersStatisticsSheet, title: 'Statistics' }
  ];
};

const mapUserToExport = ({ preferedPath, ...user }: TUser) => {
  return {
    'First Name': user.first_name,
    'Second Name': user.second_name,
    'Last Name': user.last_name,
    Gender: user.gender.name,
    Nationality: user.nationality.name,
    Mobile: user.mobile,
    'User Identity': user.user_identity,
    Birthday: user.birthday,
    Email: user.email,
    'Has Active Compitions': user.has_active_compitions
  };
};

export const groupUsersByProperty = (users: TUser[], property: 'gender' | 'nationality') => {
  const initial = {} as Record<string, TUser[]>;

  return users.reduce((acc, user) => {
    const key = user[property].name;
    const grouped = acc[key] ? [...acc[key], user] : [user];

    return { ...acc, [key]: grouped };
  }, initial);
};

export const getUsersStatistics = (users: TUser[]) => {
  const groupedByNationality = groupUsersByProperty(users, 'nationality');
  const saudiUsers = groupedByNationality['Saudi'];

  const groupedByGender = groupUsersByProperty(users, 'gender');
  const femaleRegisteredUsers = groupedByGender['Female'];
  const maleRegisteredUsers = groupedByGender['Male'];

  return [
    { description: 'Registered', amount: users.length },
    { description: 'Saudi Registered', amount: saudiUsers.length },
    { description: 'Non-Saudi Registered', amount: users.length - saudiUsers.length },
    { description: 'Female Registered', amount: femaleRegisteredUsers.length },
    { description: 'Male Registered', amount: maleRegisteredUsers.length }
  ];
};

export const getUsersStatisticsSheet = (users: TUser[]) => {
  const usersStatistics = getUsersStatistics(users);

  const usersStatisticsSheet = usersStatistics.map((stat) => ({
    'Statistic description': `${stat.description} Users`,
    'No.': stat.amount
  }));

  return usersStatisticsSheet;
};
