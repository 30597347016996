import { FC, useMemo } from 'react';

import { useUpdateSupervisoryBoardItemMutation } from 'src/api/supervisory-board/hooks';
import ManageSupervisoryBoard from 'src/components/ManageSupervisoryBoard';
import { TSupervisoryBoardItem, TSupervisoryBoardItemForm } from 'src/modules/supervisory-board/types';

type TProps = {
  item: TSupervisoryBoardItem;
};

const EditModalContent: FC<TProps> = ({ item }) => {
  const { mutate, isLoading } = useUpdateSupervisoryBoardItemMutation();

  const defaultValues = useMemo((): TSupervisoryBoardItemForm => {
    return {
      name_en: item.name_en,
      name_ar: item.name_ar,
      position_en: item.position_en,
      position_ar: item.position_ar,
      image: item.imagepath
    };
  }, [item]);

  const handleItemUpdate = (data: TSupervisoryBoardItemForm) => {
    mutate({ id: item.id, ...data });
  };

  return (
    <ManageSupervisoryBoard
      defaultValues={defaultValues}
      onSubmit={handleItemUpdate}
      submitText="Save"
      isLoading={isLoading}
    />
  );
};

export default EditModalContent;
