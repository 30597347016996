import { FC, useMemo } from 'react';

import { useUpdateSocialMediaAccountMutation } from 'src/api/social-media-accounts/hooks';
import ManageSocialMediaAccount from 'src/components/ManageSocialMediaAccounts';
import { TSocialMediaAccount, TSocialMediaAccountForm } from 'src/modules/social-media-accounts/types';

type TProps = {
  item: TSocialMediaAccount;
};

const EditModalContent: FC<TProps> = ({ item }) => {
  const { mutate, isLoading } = useUpdateSocialMediaAccountMutation();

  const defaultValues = useMemo((): TSocialMediaAccountForm => ({
    account_link: item.account_link,
    hide: item.hide
  }), [item]);

  const handleItemUpdate = (data: TSocialMediaAccountForm) => {
   mutate({ id: item.id, ...data });
  };

  return (
    <ManageSocialMediaAccount
      defaultValues={defaultValues}
      onSubmit={handleItemUpdate}
      submitText="Save"
      isLoading={isLoading}
    />
  );
};

export default EditModalContent;
