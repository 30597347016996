import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { generatePath, useNavigate } from 'react-router-dom';

import TableActionsCell from 'src/components/TableActionsCell';
import { TTableFilters } from 'src/components/TableFilters';
import { Paths } from 'src/router/constants';

import { photosTableHeadings as headings } from './constants';
import { TPhotosItem } from './types';

export const usePhotosTableColumns = ({
  onDeleteClick
}: {
  onDeleteClick: (id: string) => void;
}) => {
  const navigate = useNavigate();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TPhotosItem>();

    return [
      columnHelper.accessor('summary_en', {
        header: headings.summaryEn
      }),

      columnHelper.accessor('summary_ar', {
        header: headings.summaryAr
      }),

      columnHelper.accessor('publishing_date', {
        header: headings.publishDate,
        cell: (cell) => dayjs(cell.getValue()).format('DD-MM-YYYY'),
        enableResizing: false
      }),

      columnHelper.accessor('modify_date', {
        header: headings.modifyDate,
        cell: (cell) => cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : undefined,
        enableResizing: false
      }),

      columnHelper.accessor('image_path', {
        header: headings.image
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => <TableActionsCell
          actions={['edit', 'delete']}
          onEditClick={() => navigate(generatePath(Paths.photosItemEdit, { id: row.original.id }))}
          onDeleteClick={() => onDeleteClick(row.original.id)}
        />
      })
    ];
  }, [navigate, onDeleteClick]);
};

export const usePhotosTableFilters = () => {
  return useMemo((): TTableFilters<TPhotosItem> => [
    {
      id: 'summary_en',
      title: headings.summaryEn,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'summary_ar',
      title: headings.summaryAr,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'publishing_date',
      title: headings.publishDate,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'modify_date',
      title: headings.modifyDate,
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'image_path',
      title: headings.image,
      type: 'input',
      defaultValue: ''
    }
  ], []);
};
