import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TNewsItemForm, TNewsList } from 'src/modules/news/types';

import { TNewsItemUpdateRequest } from './types';

export const getNewsList = async (): Promise<TNewsList> => {
  return privateApi.get('/admin-content/news').then((res) => res.data.data);
};

export const getNewsById = async (id: string): Promise<TNewsList> => {
  return privateApi.get('/admin-content/news', {
    params: { id }
  }).then((res) => res.data.data);
};

export const createNewsItem = async (data: TNewsItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/news', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateNewsItem = async (data: TNewsItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/news', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteNewsItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/news', {
    params: { id }
  }).then((res) => res.data);
};
