import { FC, useState } from 'react';

import { Box, Button, Image, LoadingOverlay, Stack, Text } from '@mantine/core';
import { IconAt } from '@tabler/icons';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useAdminLoginMutation } from 'src/api/admin/hooks';
import logo from 'src/assets/svg/logo-row.svg';
import loginBg from 'src/assets/loginBg2x.png';
import loginBgTop from 'src/assets/svg/login.svg';
import { emailRegExp } from 'src/common/constants/regExp';
import { parseApiErrors } from 'src/common/helpers/api';
import { TLoginForm } from 'src/common/types/auth';
import Form from 'src/components/Form';
import PasswordInput from 'src/components/PasswordInput';
import TextInput from 'src/components/TextInput';

import styles from './styles.module.scss';

const Login: FC = () => {
  const [formError, setFormError] = useState<string>();

  const form = useForm<TLoginForm>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { mutate: adminLogin, isLoading: isAdminLoginLoading } = useAdminLoginMutation({
    onError: (error) => parseApiErrors(error, {
      form: form,
      onGlobalError: (message) => setFormError(message)
    })
  });

  const handleSubmit: SubmitHandler<TLoginForm> = async (data) => {
    adminLogin(data);
  };

  return (
    <div className={styles.wrapper}>
      <Image src={loginBg} className={styles.bgImage} />
      <Image src={loginBgTop} className={styles.loginBgTop} />

      <Box className={styles.paper} pos="relative">
        <LoadingOverlay visible={isAdminLoginLoading} />

        <Stack align="center" spacing={20} mb={20}>
          <Image src={logo} width={300} height={58} withPlaceholder fit="contain" />
          {formError ? (
            <Text fz={14} color="#c92a2a">
              {formError}
            </Text>
          ) : null}
        </Stack>

        <Form className={styles.form} onSubmit={form.handleSubmit(handleSubmit)}>
          <Stack spacing={40}>
            <Controller
              control={form.control}
              name="email"
              rules={{
                required: 'Required',
                pattern: { value: emailRegExp, message: 'Invalid email format' }
              }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  icon={<IconAt size={16} />}
                  label="Email"
                  placeholder="email@example.com"
                  type="email"
                  required
                />
              )}
            />

            <Controller
              control={form.control}
              name="password"
              rules={{
                required: 'Required',
                minLength: { value: 8, message: 'Minimum password length is 8 characters' }
              }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <PasswordInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Button color="dark" type="submit" size="md">
              Log In
            </Button>
          </Stack>
        </Form>
      </Box>
    </div>
  );
};

export default Login;
