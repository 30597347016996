import { FC } from 'react';

import { useForm } from 'react-hook-form';

import { useCreateVideoItemMutation } from 'src/api/videos/hooks';
import ManageVideo from 'src/components/ManageVideo';
import { videosFormDefaultValues } from 'src/modules/videos/constants';
import { TVideoItemForm } from 'src/modules/videos/types';

const VideosAddItem: FC = () => {
  const { mutate, isLoading } = useCreateVideoItemMutation();

  const form = useForm<TVideoItemForm>({
    defaultValues: videosFormDefaultValues
  });

  const handleItemCreate = (data: TVideoItemForm) => {
    mutate(data);
  };

  return (
    <ManageVideo
      title="Add video"
      submitText="Create"
      form={form}
      onSubmit={handleItemCreate}
      isLoading={isLoading}
    />
  );
};

export default VideosAddItem;
