import { Flex, Stack, Text } from '@mantine/core';

import ContentWithLabel from 'src/components/ContentWithLabel';
import { TParticipantsRequestFullInfo } from 'src/modules/participants-requests/types';

import { getFullName } from './helpers';
import styles from './styles.module.scss';

type Props = TParticipantsRequestFullInfo['partcipants_data'][number];

export const ParticipantInfo = (props: Props) => {
  return (
    <Stack key={`participant_${props.user_identity}`} className={styles.section}>
      <ContentWithLabel label="Full Name">{getFullName(props)}</ContentWithLabel>
      <ContentWithLabel label="ID/Iqama Number">{props.user_identity}</ContentWithLabel>
      <ContentWithLabel label="Nationality">{props.nationality_id.name}</ContentWithLabel>
      <ContentWithLabel label="Email">{props.email}</ContentWithLabel>
    </Stack>
  );
};

export const ParticipantPercentage = (props: Props) => {
  return (
    <Flex
      key={`participant_${props.user_identity}`}
      justify="space-between"
      className={styles.section}
    >
      <Text weight={500}>{getFullName(props)}</Text>
      <Text weight={500}>{props.participation_percentage}%</Text>
    </Flex>
  );
};
