import { FC } from 'react';

import { Box, Button, Flex, LoadingOverlay, Select, Stack } from '@mantine/core';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { useUpdateParticipantRequestMutation } from 'src/api/participants-requests/hooks';
import Form from 'src/components/Form';
import Textarea from 'src/components/Textarea';
import { RequestEvaluationStage, RequestStatus } from 'src/modules/competitions/constants';
import { TParticipantRequestStageForm } from 'src/modules/participants-requests/types';

import { statusSelectData } from './constants';
import CommentsForm from '../CommentsForm';

type Props = {
  requestId: string;
  evaluationStageId: RequestEvaluationStage;
  requestStatus?: RequestStatus;
};

const UpdateRequestStatus: FC<Props> = ({
  requestId,
  evaluationStageId,
  requestStatus
}) => {
  const { mutate: updateRequest, isLoading } = useUpdateParticipantRequestMutation(requestId);

  const form = useForm<TParticipantRequestStageForm>({
    defaultValues: {
      status: requestStatus?.toString(),
      fields: [{ field_id: '', comment: '' }],
      generalComment: ''
    }
  });

  const status = useWatch({
    control: form.control,
    name: 'status'
  });

  const handleSubmit: SubmitHandler<TParticipantRequestStageForm> = (data) => {
    const info = {
      requestId,
      statusId: data.status ? +data.status : undefined,
      evaluation_stage_id: evaluationStageId
    };

    if (data.status === '3') {
      updateRequest({ ...info, RequestFieldComment: data.fields });
    }

    if (data.status === '4' || data.status === '5') {
      updateRequest({ ...info, comment: data.generalComment });
    }
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={40}>
          <Box>
            <Controller
              control={form.control}
              name="status"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Select
                  data={statusSelectData}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Status"
                  required
                  sx={{ maxWidth: 300 }}
                />
              )}
            />
          </Box>

          {status === '3' && (
            <CommentsForm control={form.control} />
          )}

          {status === '4' || status === '5' ? (
            <Controller
              control={form.control}
              name="generalComment"
              rules={{ required: 'Required' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Textarea
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label="Comment"
                  required
                  minRows={4}
                />
              )}
            />
          ) : null}

          <Flex justify="flex-end">
            <Button type="submit">
              Submit
            </Button>
          </Flex>
        </Stack>
      </Form>
    </>
  );
};

export default UpdateRequestStatus;
