import { FC, useCallback } from 'react';

import { Button, Center, Flex, Loader, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useDeleteVideoItemMutation, useVideosListQuery } from 'src/api/videos/hooks';
import DataTable from 'src/components/DataTable';
import { useVideosTableColumns, useVideosTableFilters } from 'src/modules/videos/hooks';
import { Paths } from 'src/router/constants';
import { AdminRoles } from 'src/modules/admin/constants';
import { useAuthStore } from 'src/store/useAuthStore';

const Videos: FC = () => {
  const { user } = useAuthStore();
  const { data, isLoading: isDataLoading } = useVideosListQuery();
  const { mutate: deleteItem, isLoading: isDeleteItemLoading } = useDeleteVideoItemMutation();
  const isJudgment = user?.userRole.id === AdminRoles.Judgment;


  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteItem(id)
  }), [deleteItem]);

  const columns = useVideosTableColumns({
    onDeleteClick: openDeleteModal
  });

  const filters = useVideosTableFilters();

  return (
    <Flex direction="column">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>
          Videos
        </Title>
        {!isJudgment && (
          <Button
            component={Link}
            to={Paths.videosAddItem}
            leftIcon={<IconPlus size={18} />}
          >
            Add
          </Button>
        )}
      </Flex>

      {isDataLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data ? (
        <DataTable
          data={data}
          columns={columns}
          filters={filters}
          isLoading={isDeleteItemLoading}
        />
      ) : null}
    </Flex>
  );
};

export default Videos;
