import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TCompetitionPathItemForm, TCompetitionPathsList } from 'src/modules/competition-paths/types';

import { TCompetitionPathsItemUpdateRequest } from './types';

export const getCompetitionPathsList = async (): Promise<TCompetitionPathsList> => {
  return privateApi.get('/admin-content/competitionpaths').then((res) => res.data.data);
};

export const getCompetitionPathById = async (id: string): Promise<TCompetitionPathsList> => {
  return privateApi.get('/admin-content/competitionpaths', {
    params: { id }
  }).then((res) => res.data.data);
};

export const createCompetitionPathsItem = async (data: TCompetitionPathItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/competition-path', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateCompetitionPathsItem = async (data: TCompetitionPathsItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/competition-path', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteCompetitionPathsItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/competition-path', {
    params: { id }
  }).then((res) => res.data);
};
