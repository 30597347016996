import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TCompetitionConditions } from 'src/modules/competition-conditions/types';

import { getCompetitionConditions, updateCompetitionConditions } from './api';
import { COMPETITION_CONDITIONS_QUERY_KEY } from './constants';

export const useCompetitionConditionsQuery = () => {
  return useQuery<TCompetitionConditions>({
    queryKey: [COMPETITION_CONDITIONS_QUERY_KEY],
    queryFn: getCompetitionConditions
  });
};

export const useUpdateCompetitionConditionsMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCompetitionConditions>({
    mutationFn: updateCompetitionConditions,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITION_CONDITIONS_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
