import { FC, useState } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';

import { useTicketsListQuery } from 'src/api/tickets/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { TicketStatus } from 'src/modules/tickets/constants';
import { useTicketsTableColumns, useTicketsTableFilters } from 'src/modules/tickets/hooks';
import { TTicketGroupByStatus, TTicketGroupLists } from 'src/modules/tickets/types';

import TicketsStatusTabs from './TicketsStatusTabs';
import { getTicketsForExport } from './utils';

const Tickets: FC = () => {
  const { data: tickets, isLoading } = useTicketsListQuery();

  const columns = useTicketsTableColumns();
  const filters = useTicketsTableFilters();

  const ticketsExport = getTicketsForExport(tickets);

  const initialTicketGroups = { open: [], closed: [] };

  const ticketGroupLists =
    tickets?.reduce((acc: TTicketGroupLists, ticket) => {
      if (ticket.ticket_status.id === TicketStatus.Closed) {
        acc.closed.push(ticket);
      } else {
        acc.open.push(ticket);
      }

      return acc;
    }, initialTicketGroups) || initialTicketGroups;

  const [activeTab, setActiveTab] = useState<TTicketGroupByStatus>('open');

  const handleTabChange = (tab: TTicketGroupByStatus) => {
    if (tab) setActiveTab(tab);
  };

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Tickets</Title>
        <ExportButton filename="Tickets" data={ticketsExport} />
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {tickets ? (
        <DataTable
          columns={columns}
          data={activeTab === 'open' ? ticketGroupLists.open : ticketGroupLists.closed}
          filters={filters}
          extraSearchElement={<TicketsStatusTabs handleTabChange={handleTabChange} />}
        />
      ) : null}
    </Flex>
  );
};

export default Tickets;
