import { AxiosResponse } from 'axios';

import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TWinnerItem } from 'src/modules/arbitration-winners/types';
import { RequestStatus } from 'src/modules/competitions/constants';

import { LatestInterviewDegree, PutRequestWinnerData, TArbitratingPercentage, TAssignQualifiedForInterviewToInterviewed, TCommentHistory, TLatestCompetitionListData, TModel, putRequestLatestWinnerCompetitionData } from './types';
import { GetAdminsListResponse } from '../admin/types';
import { GetParticipantsRequestsList } from '../participants-requests/types';

export const getArbitratingAssignList = async (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/AssignToUser/participants-requests', {
    params: {
      page_size: 99999,
      page_number: 1,
      'request_status[0]': RequestStatus.FinalApproved,
      'request_status[1]': RequestStatus.UnderEvaluation,
      'request_status[2]': RequestStatus.Evaluated,
      'request_status[3]': RequestStatus.EvaluatedApproved
    }
  }).then((res) => res.data);
};

export const getArbitratingApprovalList = async (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/AssignToUser/participants-requests', {
    params: {
      page_size: 99999,
      page_number: 1,
      'request_status[0]': RequestStatus.Evaluated,
      'request_status[1]': RequestStatus.EvaluatedApproved,
      'request_status[2]': RequestStatus.QualifiedForInterview
    }
  }).then((res) => res.data);
};

export const getArbitratingRequestList = async (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/AssignToUser/participants-requests-admin', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  }).then((res) => res.data);
};

export const getQualifiedForInterviewList = async (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/AssignToUser/participants-requests', {
    params: {
      page_size: 99999,
      page_number: 1,
      'request_status[0]': RequestStatus.QualifiedForInterview
    }
  }).then((res) => res.data);
};

export const getInterviewedList = async (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/ParticipatingInterview/participants-degree', {
    params: {
      page_size: 99999,
      page_number: 1,
      'request_status[0]': RequestStatus.Interviewed,
      'request_status[1]': RequestStatus.NotWinner
    }
  }).then((res) => res.data);
};

export const getLatestCompetitionList = async (): Promise<TLatestCompetitionListData> => {
  return privateApi.get('/winner/latest-competition', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  }).then((res) => res.data);
};

export const getLatestInterviewDegree = async (participating_request_id: string): Promise<LatestInterviewDegree> => {
  return privateApi.get('/ParticipatingInterview/latest_interview_degree', {
    params: {
      participating_request_id
    }
  }).then((res) => res.data);
};

export const getAllWinnersList = async (): Promise<GetParticipantsRequestsList> => {
  return privateApi.get('/winner').then((res) => res.data);
};

export const getTopWinnerData = async (): Promise<TWinnerItem[]> => {
  return privateApi.get('/winner/top').then((res) => res.data);
};

export const putLatestWinnerCompetitionData = async (data: putRequestLatestWinnerCompetitionData): Promise<SuccessResponse> => {
  return privateApi.put('/winner/Manage', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const putWinnerData = async (data: PutRequestWinnerData): Promise<SuccessResponse> => {
  return privateApi.put('/winner', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const AssignAribtratingToUser = (participating_request_id: string[], user_id: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/AssignToUser', {
    participating_request_id: participating_request_id,
    user_id: user_id
  });
};

export const DeleteAssignAribtratingUser = (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/AssignToUser', {
    params: {id}
  });
};

export const AssignAribtratingRequestPercentage = (participantAssignUser_id: string, model: TModel[]): Promise<SuccessResponse> => {
  return privateApi.post('/ParticipatingPercentage', {
    participantAssignUser_id: participantAssignUser_id,
    model: model
  });
};

export const AssignAribtratingApprovalPercentage = (participating_request_id: string, final_comment: string, model: TModel[]): Promise<SuccessResponse> => {
  return privateApi.post('/ParticipatingPercentage/AddByArbitrationCommittee', {
    participating_request_id: participating_request_id,
    final_comment: final_comment,
    model: model
  });
};

export const AssignApprovalToInterview = (participating_request_id: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/ParticipatingInterview', {
    participating_request_id: participating_request_id
  });
};

export const AssignInterviewedToWinners = (ParticipatingRequestId: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/winner', {
    ParticipatingRequestId: ParticipatingRequestId
  });
};

export const AssignQualifiedForInterviewToInterviewed = (data: TAssignQualifiedForInterviewToInterviewed): Promise<SuccessResponse> => {
  return privateApi.post('/ParticipatingInterview/add-degree', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const GetAllAssignAribtratingApprovalPercentage = async (id: string): Promise<TArbitratingPercentage> => {
  return privateApi.get('/ParticipatingPercentage', {
    params: {id}
  }).then((res) => res.data);
};

export const GetAllArbitratingRequestPercentage = async (id: string): Promise<TArbitratingPercentage> => {
  return privateApi.get('/ParticipatingPercentage/GetDataPercentage', {
    params: {id}
  }).then((res) => res.data);
};

export const GetAllArbitratingUsers = (): Promise<AxiosResponse<GetAdminsListResponse>> => {
  return privateApi.get('/AssignToUser/get-Judgment', {
    params: {
      params: {
        page_size: 99999,
        page_number: 1
      }
    }
  });
};

export const getArbitratingCommentHistory = async (requestId: string): Promise<TCommentHistory[]> => {
  return privateApi.get('/admin/commentHistory', {
    params: {
      requestId: requestId
    }
  }).then(res => res.data);
};

export const sendArbitratingInterviewEmails = async (): Promise<SuccessResponse> => {
  return privateApi.post('/ParticipatingInterview/email').then(res => res.data);
};

export const sendArbitratingWinnerEmails = async (): Promise<SuccessResponse> => {
  return privateApi.post('/winner/email').then(res => res.data);
};

export const sortArbitratingWinnerList = async (data: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/winner/sort', data).then((res) => res.data);
};
