import { FC, useEffect, useRef, useState } from 'react';

import { Button, Center, Flex, Group, Loader, Title } from '@mantine/core';
import { IconBrandSpacehey } from '@tabler/icons';
import { Table } from '@tanstack/react-table';

import ArbitratingStatusTabs from './ArbitratingStatusTabs';

import { useArbitratingApprovalQuery, useAssignApprovalToInterviewQuery } from 'src/api/arbitrating-requests/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';
import { useArbitrationApprovalTableColumns } from 'src/modules/arbitration-approval/hooks';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';
import { RequestStatus } from 'src/modules/competitions/constants';
import { useLayoutStore } from 'src/store/useLayoutStore';

import { getArbitratingApprovalForExport } from './utils';

const ArbitratingApproval: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();
  const { selectedApprovalItems } = useLayoutStore();
  const [activeTab, setActiveTab] = useState<'Evaluated' | 'Evaluated Approved'>('Evaluated');

  const { mutate: AssignToInterview } = useAssignApprovalToInterviewQuery(selectedApprovalItems);
  const { data: ArbitrationApproval, isLoading: ArbitrationApprovalLoading } = useArbitratingApprovalQuery();
  const filteredData = !ArbitrationApprovalLoading && ArbitrationApproval &&
    ArbitrationApproval?.data?.sort((a: TParticipantsRequest, b: TParticipantsRequest) => {
      if (a.total_avg_criteria && b.total_avg_criteria) {
        return +b.total_avg_criteria - +a.total_avg_criteria
      } else return 0;
    });

  const columns = useArbitrationApprovalTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const ArbitratingRequestExport = getArbitratingApprovalForExport(ArbitrationApproval?.data);

  const handleTabChange = (tab: 'Evaluated' | 'Evaluated Approved') => {
    if (tab) setActiveTab(tab);
  };

  const filterByRequestStatus = activeTab === 'Evaluated Approved'
    ? ( item: TParticipantsRequest) => item?.status?.id === RequestStatus.EvaluatedApproved
      || item?.status?.id === RequestStatus.QualifiedForInterview
    : ( item: TParticipantsRequest) => item?.status?.id === RequestStatus.Evaluated;

  const activeTabData = !ArbitrationApprovalLoading && (filteredData || [])?.filter(filterByRequestStatus);

  const clearApprovalItems = () => {
    useLayoutStore.setState((state) => ({
      ...state,
      selectedApprovalItems: []
    }));
  };

  useEffect(() => {
    clearApprovalItems();
  }, []);

  const AssignItemsToInterview = async () => {
    await AssignToInterview();
    await clearApprovalItems();      
  };

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Arbitrating Approval</Title>
        <Group display="flex">
          {activeTab === 'Evaluated Approved' && (
            <Button
              disabled={selectedApprovalItems.length === 0}
              leftIcon={<IconBrandSpacehey />}
              onClick={AssignItemsToInterview}
            >
              Interview
            </Button>
          )}
          <ExportButton filename="Arbitrating Approval" data={ArbitratingRequestExport} />
        </Group>
      </Flex>
      {ArbitrationApprovalLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      <DataTable
        columns={columns}
        data={activeTabData || []}
        filters={filters}
        tableInstanceRef={tableInstanceRef}
        extraSearchElement={<ArbitratingStatusTabs handleTabChange={handleTabChange} />}
      />
    </Flex>
  );
};

export default ArbitratingApproval;
