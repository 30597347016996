import { Flex, Select, Text } from '@mantine/core';
import { Controller, UseFormReturn } from 'react-hook-form';

import styles from 'src/components/TableFilters/TableFilters.module.scss';
import { TTableFilter } from 'src/components/TableFilters/types';
import TextInput from 'src/components/TextInput';

export const renderTableFilterByType = (filter: TTableFilter<Record<string, any>>, form: UseFormReturn) => {
  switch (filter.type) {
    case 'input': {
      return (
        <Flex align="center">
          <Text w={150} size={14}>{filter.title}</Text>
          <div className={styles.inputWrapper}>
            <Controller
              control={form.control}
              name={filter.id}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </Flex>
      );
    }

    case 'select': {
      return (
        <Flex align="center">
          <Text w={150} size={14}>{filter.title}</Text>
          <div className={styles.inputWrapper}>
            <Controller
              control={form.control}
              name={filter.id}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  data={filter.data}
                  clearable
                  searchable
                />
              )}
            />
          </div>
        </Flex>
      );
    }

    default: {
      return null;
    }
  }
};
