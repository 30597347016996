import { FC, useEffect, useState } from 'react';

import { Button, Flex, LoadingOverlay, Stack } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useAboutPrizeQuery, useUpdateAboutPrizeMutation } from 'src/api/about-prize/hooks';
import ContentPageWrapper from 'src/components/ContentPageWrapper';
import Form from 'src/components/Form';
import { TAboutPrizeForm } from 'src/modules/about-prize/types';

import Brief from './Brief';
import Goals from './Goals';
import Vision from './Vision';

const AboutThePrize: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data, isLoading: isDataLoading } = useAboutPrizeQuery();
  const { mutate, isLoading: isMutateLoading } = useUpdateAboutPrizeMutation();

  const form = useForm<TAboutPrizeForm>({
    defaultValues: {
      brief: {
        title_en: '',
        title_ar: '',
        text_en: '',
        text_ar: '',
        image: ''
      },
      vision: {
        title_en: '',
        title_ar: '',
        text_en: '',
        text_ar: ''
      },
      goals: {
        title_en: '',
        title_ar: '',
        text_en: '',
        text_ar: '',
        image: ''
      }
    }
  });

  useEffect(() => {
    if (data) {
      form.reset({
        brief: {
          title_en: data.brief.title_en,
          title_ar: data.brief.title_ar,
          text_en: data.brief.text_en,
          text_ar: data.brief.text_ar,
          image: data.brief.image_path
        },
        vision: data.vision,
        goals: {
          title_en: data.goals.title_en,
          title_ar: data.goals.title_ar,
          text_en: data.goals.text_en,
          text_ar: data.goals.text_ar,
          image: data.goals.image_path
        }
      });
    }
  }, [form, data]);

  const onEditCancel = () => {
    setIsEditing(false);
    form.reset();
  };

  const onSubmit: SubmitHandler<TAboutPrizeForm> = (data) => {
    mutate(data);
    setIsEditing(false);
  };

  return (
    <ContentPageWrapper
      title="About the Prize"
      isEditing={isEditing}
      onEditEnter={() => setIsEditing(true)}
      onEditLeave={onEditCancel}
    >
      <LoadingOverlay visible={isDataLoading || isMutateLoading} />

      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={50}>
          <Brief control={form.control} disabled={!isEditing} />
          <Vision control={form.control} disabled={!isEditing} />
          <Goals control={form.control} disabled={!isEditing} />
        </Stack>

        {isEditing && (
          <Flex justify="flex-end" mt={30}>
            <Button type="submit">
              Save
            </Button>
          </Flex>
        )}
      </Form>
    </ContentPageWrapper>
  );
};

export default AboutThePrize;
