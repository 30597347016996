import { FC, useState } from 'react';

import { Button, LoadingOverlay, Select, Stack, Text } from '@mantine/core';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useTicketStatusListQuery, useUpdateTicketStatusMutation } from 'src/api/tickets/hooks';
import { parseApiErrors } from 'src/common/helpers/api';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import Form from 'src/components/Form';
import Textarea from 'src/components/Textarea/Textarea';
import { AdminRoles } from 'src/modules/admin/constants';
import { TTicket, TUpdateTicketStatusForm } from 'src/modules/tickets/types';
import { useAuthStore } from 'src/store/useAuthStore';

import { TicketStatus } from '../constants';

type Props = {
  ticket: TTicket;
};

const TicketUpdateStatusModalContent: FC<Props> = ({ ticket }) => {
  const { user } = useAuthStore();
  console.log('is_rated:', ticket.is_rated, ticket.ticket_status)
  const [formError, setFormError] = useState<string>();

  const { data: ticketStatusList, isLoading: isTicketStatusListLoading } = useTicketStatusListQuery();
  const { mutate: update, isLoading: isUpdateLoading } = useUpdateTicketStatusMutation({
    onError: (error) =>
      parseApiErrors(error, {
        form,
        onGlobalError: setFormError
      })
  });

  const normalizedTicketStatusList = useNormalizedItemsData(ticketStatusList);

  const form = useForm<TUpdateTicketStatusForm>({
    defaultValues: {
      admin_status_comment: ticket.admin_status_comment || '',
      ticket_status_id: ticket.ticket_status.id.toString()
    }
  });

  const handleUpdate: SubmitHandler<TUpdateTicketStatusForm> = (data) => {
    update({
      id: ticket.id,
      admin_status_comment: data.admin_status_comment,
      ticket_status_id: +data.ticket_status_id
    });
  };

  const isLoading = isTicketStatusListLoading || isUpdateLoading;

  const checkIfStatusInputEnabled = () => {
    if (ticket.ticket_status.id !== TicketStatus.Closed) {
      return true;
    }

    const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin || user?.userRole.id === AdminRoles.GeneralSecretariat;

    if (isAdmin) {
      return !ticket.is_rated;
    }

    return false;
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Form onSubmit={form.handleSubmit(handleUpdate)}>
        {formError ? (
          <Text fz={14} color="#c92a2a" align="center" mb={10}>
            {formError}
          </Text>
        ) : null}

        <Stack>
          <Controller
            control={form.control}
            name="ticket_status_id"
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                value={value}
                onChange={onChange}
                error={error?.message}
                data={normalizedTicketStatusList}
                disabled={!checkIfStatusInputEnabled()}
                label="Status"
                maxDropdownHeight={140}
                required
              />
            )}
          />

          <Controller
            control={form.control}
            name="admin_status_comment"
            rules={{ required: 'Required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Comment"
                dir="auto"
                minRows={2}
                maxRows={8}
                autosize
                required
              />
            )}
          />

          <Button type="submit" sx={{ alignSelf: 'flex-end' }} mt={15}>
            Submit
          </Button>
        </Stack>
      </Form>
    </>
  );
};

export default TicketUpdateStatusModalContent;
