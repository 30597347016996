import { FC, useEffect, useState } from 'react';

import { Button, Flex, LoadingOverlay, Stack } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useHomeInfoQuery, useUpdateHomeInfoMutation } from 'src/api/homeinfo/hooks';
import ContentPageWrapper from 'src/components/ContentPageWrapper';
import Form from 'src/components/Form';
import { THomeInfoForm } from 'src/modules/homeinfo/types';

import GovernorMessage from './GovernorMessage';
import KingMessage from './KingMessage';
import PrinceMessage from './PrinceMessage';

const ContentHomepage: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data, isLoading: isDataLoading } = useHomeInfoQuery();
  const { mutate, isLoading: isMutateLoading } = useUpdateHomeInfoMutation();

  const form = useForm<THomeInfoForm>({
    defaultValues: {
      kingMessage: {
        title_en: '',
        title_ar: '',
        message_en: '',
        message_ar: '',
        image: ''
      },
      crownPrinceMessage: {
        title_en: '',
        title_ar: '',
        message_en: '',
        message_ar: '',
        image: ''
      },
      governorOfGAMIMessage: {
        title_en: '',
        title_ar: '',
        message_en: '',
        message_ar: '',
        image: ''
      }
    }
  });

  useEffect(() => {
    if (data) {
      form.reset({
        kingMessage: {
          title_en: data.kingMessage.title_en,
          title_ar: data.kingMessage.title_ar,
          message_en: data.kingMessage.message_en,
          message_ar: data.kingMessage.message_ar,
          image: data.kingMessage.image_path
        },
        crownPrinceMessage: {
          title_en: data.crownPrinceMessage.title_en,
          title_ar: data.crownPrinceMessage.title_ar,
          message_en: data.crownPrinceMessage.message_en,
          message_ar: data.crownPrinceMessage.message_ar,
          image: data.crownPrinceMessage.image_path
        },
        governorOfGAMIMessage: {
          title_en: data.governorOfGAMIMessage.title_en,
          title_ar: data.governorOfGAMIMessage.title_ar,
          message_en: data.governorOfGAMIMessage.message_en,
          message_ar: data.governorOfGAMIMessage.message_ar,
          image: data.governorOfGAMIMessage.image_path
        }
      });
    }
  }, [form, data]);

  const onEditCancel = () => {
    setIsEditing(false);
    form.reset();
  };

  const onSubmit: SubmitHandler<THomeInfoForm> = (data) => {
    mutate(data);
    setIsEditing(false);
  };

  return (
    <ContentPageWrapper
      title="Homepage"
      isEditing={isEditing}
      onEditEnter={() => setIsEditing(true)}
      onEditLeave={onEditCancel}
    >
      <LoadingOverlay visible={isDataLoading || isMutateLoading} />

      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={50}>
          <KingMessage control={form.control} disabled={!isEditing} />
          <PrinceMessage control={form.control} disabled={!isEditing} />
          <GovernorMessage control={form.control} disabled={!isEditing} />
        </Stack>

        {isEditing && (
          <Flex justify="flex-end" mt={30}>
            <Button type="submit">
              Save
            </Button>
          </Flex>
        )}
      </Form>
    </ContentPageWrapper>
  );
};

export default ContentHomepage;
