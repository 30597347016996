import { FC, ReactNode } from 'react';

import { Box, Text } from '@mantine/core';

type TProps = {
  label: string;
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const ContentWithLabel: FC<TProps> = ({ label, children, ...rest }) => (
  <Box {...rest}>
    <Text fz={14} color="#767676">
      {label}
    </Text>
    <Text fz={14} weight={500}>
      {children && children?.toString().trim() ? children : '-'}
    </Text>
  </Box>
);

export default ContentWithLabel;
// NOTE Might replace InfoLabel
