import { FC, useEffect } from 'react';

import { clsx, Input, InputBaseProps } from '@mantine/core';
import { Link, RichTextEditor as MantineEditor } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';

import { Indent } from './extensions/indent';
import styles from './RichTextEditor.module.scss';

type Props = {
  onUpdate: (html: string) => void;
  content?: string;
  isRtl?: boolean;
  hasColor?: boolean;
  placeholder?: string;
} & Pick<InputBaseProps, 'label' | 'required' | 'error' | 'disabled'>;

const RichTextEditor: FC<Props> = ({
  onUpdate,
  placeholder,
  content,
  hasColor = false,
  label,
  required,
  disabled,
  error,
  isRtl = false
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({ placeholder: placeholder || 'Start filling content' }),
      Indent,
      Color,
      TextStyle
    ],
    onUpdate: onUpdate ? ({ editor }) => onUpdate(editor.getText() ? editor.getHTML() : '') : undefined,
    content: ''
  });

  useEffect(() => {
    if (!editor || !content) {
      return;
    }
    const { from, to } = editor.state.selection;
    editor.commands.setContent(content, false, {
      preserveWhitespace: 'full'
    });
    editor.commands.setTextSelection({ from, to });
  }, [editor, content]);

  useEffect(() => {
    editor?.setEditable(!disabled);
  }, [editor, disabled]);

  return (
    <Input.Wrapper
      label={label}
      required={required}
      error={error}
      classNames={{
        error: styles.error
      }}
    >
      <MantineEditor
        editor={editor}
        classNames={{
          root: clsx(styles.root, { [styles.error]: error }),
          content: clsx(styles.content, { [styles.disabled]: disabled }),
          controlsGroup: styles.controlsGroup,
          control: styles.control
        }}
        dir={isRtl ? 'rtl' : 'ltr'}
      >
        <MantineEditor.Toolbar
          sticky
          stickyOffset={60}
          display={disabled ? 'none' : undefined}
        >
          <MantineEditor.ControlsGroup>
            <MantineEditor.Bold />
            <MantineEditor.Italic />
            <MantineEditor.Underline />
            <MantineEditor.Strikethrough />
            <MantineEditor.ClearFormatting />
            <MantineEditor.Highlight />
          </MantineEditor.ControlsGroup>

          <MantineEditor.ControlsGroup>
            <MantineEditor.H1 />
            <MantineEditor.H2 />
            <MantineEditor.H3 />
            <MantineEditor.H4 />
          </MantineEditor.ControlsGroup>

          <MantineEditor.ControlsGroup>
            <MantineEditor.Blockquote />
            <MantineEditor.Hr />
            <MantineEditor.BulletList />
            <MantineEditor.OrderedList />
          </MantineEditor.ControlsGroup>

          <MantineEditor.ControlsGroup>
            <MantineEditor.Link />
            <MantineEditor.Unlink />
          </MantineEditor.ControlsGroup>

          <MantineEditor.ControlsGroup>
            <MantineEditor.AlignLeft />
            <MantineEditor.AlignCenter />
            <MantineEditor.AlignJustify />
            <MantineEditor.AlignRight />
          </MantineEditor.ControlsGroup>

          <MantineEditor.ControlsGroup>
          {hasColor && (
            <>
              <MantineEditor.Color color="#000000" />
              <MantineEditor.Color color="#9747FF" />
              <MantineEditor.Color color="#6ECA98" />
            </>
            )}
          </MantineEditor.ControlsGroup>
        </MantineEditor.Toolbar>

        <MantineEditor.Content />
      </MantineEditor>
    </Input.Wrapper>
  );
};

export default RichTextEditor;
