import { FC } from 'react';

import { LoadingOverlay, Flex, Stack, Button } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useDeleteInnovation } from 'src/api/innovations/hooks';

const InnovationDelete: FC<ContextModalProps> = ({
  context,
  id,
  innerProps
}: any) => {
  const { mutate: deleteInnovation, isLoading } = useDeleteInnovation();

  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleDelete = () => {
    deleteInnovation(innerProps);
    context.closeModal(id);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Stack>
        <Flex justify="center" gap={16}>
          <Button variant="outline" onClick={handleCancel}>
            No
          </Button>
          <Button onClick={handleDelete}>
            Yes
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default InnovationDelete;
