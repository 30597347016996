import { FC, useMemo } from 'react';

import { useCreateArbitrationCriteriaMutation } from 'src/api/arbitration-criteria/hooks';
import ManageArbitrationCriteria from 'src/components/ManageArbitrationCriteria';
import { TArbitrationCriteriaItemForm } from 'src/modules/arbitration-criteria/types';

const AddModalContent: FC = () => {
  const { mutate, isLoading } = useCreateArbitrationCriteriaMutation();

  const defaultValues = useMemo((): TArbitrationCriteriaItemForm => ({
    title: '',
    percentage: 0
  }), []);

  const handleItemCreate = (data: TArbitrationCriteriaItemForm) => {
    mutate(data);
  };

  return (
    <ManageArbitrationCriteria
      defaultValues={defaultValues}
      onSubmit={handleItemCreate}
      submitText="Create"
      isLoading={isLoading}
    />
  );
};

export default AddModalContent;
