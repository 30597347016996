import { FC } from 'react';

import { Flex, Group } from '@mantine/core';

import SearchInput from 'src/components/SearchInput';
import TableFilters from 'src/components/TableFilters';
import TableSettings from 'src/components/TableSettings';

import { TTableHeaderProps } from './types';
import { useLayoutStore } from 'src/store/useLayoutStore';

import AssignUser from '../AssignUser';

const TableHeader: FC<TTableHeaderProps> = ({
  table,
  filters,
  extraSearchElement: tabs,
  extraFilterElement,
  selectedItemBlock
}) => {
  const { selectedItems } = useLayoutStore();

  return (
    <Group position="apart">
      <Group>
        {selectedItems.length && selectedItemBlock && (
          <AssignUser />
        )}
        <Flex direction="row" gap={10}>
          <SearchInput
            onDebouncedChange={(value) => table.setGlobalFilter(value)}
            placeholder="Search all columns"
          />
          {tabs}
        </Flex>
      </Group>

      <Group spacing={10}>
        {extraFilterElement}
        <TableFilters table={table} filters={filters} />
        <TableSettings table={table} />
      </Group>
    </Group>
  );
};

export default TableHeader;
