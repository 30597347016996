import { Box, Center, Loader, Text } from '@mantine/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie
} from 'recharts';

import { useParticipantsRequestsQuery } from 'src/api/participants-requests/hooks';
import { RequestParticipatingType } from 'src/modules/participants-requests/constants';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';

import { groupRequestsByProperty } from '../ParticipantsRequests/utils';
import { COLORS, mapForChart, renderColoredCells } from './helpers';

const ParticipantsRequestsStatistics = () => {
  const { data: participantsRequests, isLoading: isParticipantsRequestsLoading } =
    useParticipantsRequestsQuery();

  const byStatus = groupRequestsByProperty(participantsRequests?.data || [], 'status');

  const COLORS_BY_STATUS: Record<string, string> = {
    New: 'gray',
    Submitted: 'blue',
    MissingRequirements: 'orange',
    Approved: '#3b3',
    Rejected: 'red',
    Edited: '#ff78f1',
    UnderReview: 'purple',
    FinalApproved: '#009933',
    FinalRejected: 'darkgray',
    NotUpdated: '#6600cc',
    UnderEvaluation: '#F1CB00',
    Evaluated: '#AC00FF',
    EvaluatedApproved: '#22C4E6'
  };

  const statusData = mapForChart(byStatus)
    .map((a) => ({
      name: a.description,
      amount: a.amount
    }))
    .map((a, i) => {
      const color = COLORS_BY_STATUS[a.name] || COLORS.slice(2)[i % COLORS.length];

      return { ...a, fill: color };
    });

  const byParticipatingType: Record<keyof typeof RequestParticipatingType, TParticipantsRequest[]> =
    groupRequestsByProperty(participantsRequests?.data || [], 'participating_type');

  const participatingTypeData = [
    {
      Individual: byParticipatingType.Individual?.length || 0,
      Group: byParticipatingType.Group?.length || 0
    }
  ];

  const byEvaluationStage = groupRequestsByProperty(
    participantsRequests?.data || [],
    'evaluation_stage'
  );

  const evaluationStageData = mapForChart(byEvaluationStage);

  return (
    <Box>
      <Text size={28} weight={700} align="center">
        Participants Requests{' '}
        <Text component="span" color="dimmed">
          (Total: {participantsRequests?.data.length || '?'})
        </Text>
      </Text>

      {isParticipantsRequestsLoading ? (
        <Center py={40}>
          <Loader size="lg" />
        </Center>
      ) : (
        <>
          <Box mb={40}>
            <Text size="lg" align="center" weight={600}>
              Status
            </Text>
            <ResponsiveContainer width="100%" height={240}>
              <PieChart width={500} height={300}>
                <Pie data={statusData} outerRadius={80} dataKey="amount"></Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box mb={40}>
            <Text size="lg" align="center" weight={600}>
              Evaluation
            </Text>
            <ResponsiveContainer width="100%" height={180}>
              <BarChart
                layout="vertical"
                width={500}
                height={300}
                data={evaluationStageData}
                margin={{
                  left: -60
                }}
              >
                <XAxis hide type="number" />
                <YAxis type="category" dataKey="description" tick={{ fontSize: 14 }} width={160} />
                <Tooltip />

                <Bar dataKey="amount">{renderColoredCells(evaluationStageData)}</Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          <Box mb={40}>
            <Text size="lg" align="center" weight={600}>
              Participating
            </Text>
            <ResponsiveContainer width="100%" height={60}>
              <BarChart
                layout="vertical"
                data={participatingTypeData}
                margin={{
                  left: 20,
                  right: 20
                }}
              >
                <XAxis hide type="number" tick={{ fontSize: 12 }} />
                <YAxis hide type="category" dataKey="description" width={180} />
                <Tooltip />
                <Bar stackId="a" dataKey="Individual" fill={COLORS[0]} />
                <Bar stackId="a" dataKey="Group" fill={COLORS[1]} />
                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ParticipantsRequestsStatistics;
