import { FC } from 'react';

import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';

import Header from 'src/components/Header';
import Navbar from 'src/components/Navbar';

import styles from './styles.module.scss';

const MainLayout: FC = () => {

  return (
    <AppShell
      padding={30}
      header={<Header />}
      navbar={<Navbar />}
      classNames={{ main: styles.main }}
    >
      <Outlet />
    </AppShell>
  );
};

export default MainLayout;
