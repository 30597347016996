import { TPutShowHide, TShowHideList } from './types';

import privateApi from 'src/common/configs/private-api';

export const getShowHidePageList = async (): Promise<TShowHideList> => {
  return privateApi.get('/content-management').then((res) => res.data);
};

export const setShowHidePage = async (data: TPutShowHide): Promise<any> => {
  return privateApi.put('/content-management', data).then((res) => res.data);
};
