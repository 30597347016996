import { AdminRoles } from '../admin/constants';
import { RequestStatus } from '../competitions/constants';

export const tableHeadings = {
  requestNumber: 'Request number',
  innovationName: 'Innovation Name',
  operationCommittee: 'Member of Operation Committee',
  email: 'Applicant Email',
  innovationType: 'Innovation Type',
  innovationPath: 'Innovation Path',
  participatingType: 'Participating Type',
  status: 'Status',
  evaluationStatus: 'Evaluation',
  interview_score: 'Interview Score',
  arbitrating_score: 'Arbitrating Score'
};

export enum RequestFiltrationType {
  Individual = 1,
  Group = 2
}

type VisibleRequestStatusesByRole = Partial<{
  [key in AdminRoles]: RequestStatus[];
}>;

export const visibleRequestStatusesByRole: VisibleRequestStatusesByRole = {
  // AdminRoles.SuperAdmin can see all of them
  [AdminRoles.OperationCommittee]: [
    RequestStatus.Approved,
    RequestStatus.Rejected,
    RequestStatus.FinalApproved,
    RequestStatus.FinalRejected
  ]
  // AdminRoles.GeneralSecretariat can see all of them
};
