import { Button, Checkbox, Flex, Image, Text } from '@mantine/core'
import { FC, useEffect, useState } from 'react';

import styles from '../SendEmailToUsers.module.scss';
import logo from 'src/assets/svg/logo-colored.svg';
import React from 'react';
import {
  useDeleteEmailTemplateGroupQuery,
  useSendEmailTemplateGroupsQuery,
  useSendEmailTemplateQuery
} from 'src/api/email-template/hooks';
import { TGroupItem } from 'src/api/email-template/types';
import { TUser } from 'src/modules/users/types';

type TProps = {
  isGroupTab: boolean;
  filteredUsersList: TUser[];
  filteredGroupsList: TGroupItem[];
  handleCancel: () => void;
  templateId: number;
};

const UsersBlock:FC<TProps> = ({ isGroupTab, filteredUsersList, filteredGroupsList, templateId, handleCancel }) => {
  const [selectedUsers, setSelestedUsers] = useState<string[]>([]);
  const [selectedGroups, setSelestedGroups] = useState<number[]>([]);
  const { mutate: deleteGroup } = useDeleteEmailTemplateGroupQuery();
  const { mutate: userEmail } = useSendEmailTemplateQuery();
  const { mutate: groupEmail } = useSendEmailTemplateGroupsQuery();

  const addUser = (id: string) => {
    if (selectedUsers.find((userId) => userId === id)) {
      setSelestedUsers(selectedUsers.filter((userId) => userId !== id));
    } else {
      setSelestedUsers([...selectedUsers, id]);
    }
  };

  const addGroups = (item: number) => {
    if (selectedGroups.find((i) => i === item)) {
      setSelestedGroups(selectedGroups.filter((i) => i !== item));
    } else {
      setSelestedGroups([...selectedGroups, item]);
    }
  };

  const selectAllUsers = () => {
    filteredUsersList && setSelestedUsers(filteredUsersList.map((el) => el.user_id));
  };

  const selectAllGroups = () => {
    filteredGroupsList && setSelestedGroups(filteredGroupsList.map((el) => el.group_id));
  };

  const handleSendEmail = () => {
    if (isGroupTab) {
      groupEmail({
        group_list_id: selectedGroups,
        email_template_id: templateId
      });
    } else {
      userEmail({
        user_list_id: selectedUsers,
        email_template_id: templateId
      });
    }

    handleCancel();
  };

  useEffect(() => {
    setSelestedGroups([]);
    setSelestedUsers([]);
  }, [isGroupTab]);

  return (
    <>
      <Flex direction='column'>
        {isGroupTab && (
          <Flex gap={12}>
            {/* TODO */}
          </Flex>
        )}

        <Flex direction='column' className={styles.usersBlock}>
          <Flex py={12} px={20} justify='space-between' align='center' className={styles.header}>
            {isGroupTab ? (
              <>
                <Checkbox
                  label='Select All'
                  onClick={selectAllGroups}
                  checked={selectedGroups.length === filteredGroupsList.length}
                />
                {selectedGroups.length ? (
                  <Text color='gray'>
                    ({selectedGroups.length} Group{selectedUsers.length > 1 && 's'} Selected)
                  </Text>
                ) : null}
                <Button unstyled fz={16} onClick={() => setSelestedGroups([])}>
                  Clear Selection
                </Button>
              </>
            ) : (
              <>
                <Checkbox
                  label='Select All'
                  onClick={selectAllUsers}
                  checked={selectedUsers.length === filteredUsersList.length}
                />
                {selectedUsers.length ? (
                  <Text color='gray'>
                    ({selectedUsers.length} User{selectedUsers.length > 1 && 's'} Selected)
                  </Text>
                ) : null}
                <Button unstyled fz={16} onClick={() => setSelestedUsers([])}>
                  Clear Selection
                </Button>
              </>
            )}
          </Flex>

          <Flex wrap='wrap' gap={30} py={20} pl={20} pr={34} className={styles.itemsWrapper}>
            {isGroupTab ? (
              <React.Fragment>
                {filteredGroupsList.map((item: TGroupItem, id: number) => (
                  <div
                    className={`${styles.item} ${!!selectedGroups.find((i) => i === item.group_id) && styles.selected}`}
                    onClick={() => addGroups(item.group_id)}
                    key={id}
                  >
                    <Checkbox checked={!!selectedGroups.find((i) => i === item.group_id)} />
                    <div className={`${styles.itemInfo} ${!!selectedGroups.find((i) => i === item.group_id) && styles.selected}`}>
                      <Flex
                        className={`${styles.groupItem} ${!!selectedGroups.find((i) => i === item.group_id) && styles.selected}`}
                        align='center'
                        justify='center'
                      >
                        +{item.user_count}
                      </Flex>
                      <Text fz={14}>{item.group_name}</Text>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {filteredUsersList.map((item) => (
                  <div className={styles.item} onClick={() => addUser(item.user_id)} key={item.user_id}>
                    <Checkbox checked={!!selectedUsers.find((i) => i === item.user_id)} />
                    <div className={`${styles.itemInfo} ${!!selectedUsers.find((i) => i === item.user_id) && styles.selected}`}>
                      <Image className={styles.userImg} src={logo} />
                      <Text fz={14}>{`${item.first_name} ${item.second_name} ${item.last_name}`}</Text>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex justify='center' gap={16}>
        <Button onClick={handleSendEmail}>Send</Button>
        <Button variant='outline' onClick={handleCancel}>Cancel</Button>
        {isGroupTab && (
          <Button variant='outline' color='red' onClick={() => deleteGroup(selectedGroups[0])}>Delete</Button>
        )}
    </Flex>
  </>
  );
};

export default UsersBlock;
