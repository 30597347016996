import { Button, Flex, Group, Stack, Tabs, TextInput, Title } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { FC, useState } from 'react';
import styles from './SendEmailToUsers.module.scss';
import UsersBlock from './UsersBlock/UsersBlock';
import { openCreateGroupModal } from '../helpers';
import { IconSearch } from '@tabler/icons';
import { useGetEmailTemplateGroupsQuery } from 'src/api/email-template/hooks';
import { TGroupItem } from 'src/api/email-template/types';
import { useUsersListQuery } from 'src/api/users/hooks';
import { TUser } from 'src/modules/users/types';
import { useLayoutStore } from 'src/store/useLayoutStore';

const SendEmailToUsers: FC<ContextModalProps<{templateId: number}>> = ({
  context,
  id,
  innerProps: { templateId }
}) => {
  const { selectedEmailTemplateTab } = useLayoutStore();
  const [search, setSearch] = useState<string>('');
  const { data: Users, isLoading: isUsersLoading } = useUsersListQuery();
  const { data: Groups, isLoading: isGroupsLoading } = useGetEmailTemplateGroupsQuery();

  const filteredUsersList = !isUsersLoading && (Users?.data || [])?.filter(
    (user: TUser) => `${user.first_name} ${user.second_name} ${user.last_name}`.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const filteredGroupsList = !isGroupsLoading && (Groups?.data || [])?.filter(
    (group: TGroupItem) => group.group_name.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const handleCancel = () => {
    context.closeModal(id);
  };

  const handleTabChange = (tab: 'Users' | 'Group') => {
    useLayoutStore.setState((state) => {
      return { ...state, selectedEmailTemplateTab: tab };
    });
  };

  return (
    <Stack>
      <Title pos='absolute' top={30} fz={24}>Send Email to {selectedEmailTemplateTab}</Title>
      <Flex justify='center' direction='column' gap={16}>
        <Group align='center' position='center'>
          <Tabs
            variant='default'
            defaultValue={selectedEmailTemplateTab}
            onTabChange={handleTabChange}
            className={styles.tabs}
          >
            <Tabs.List>
              <Tabs.Tab value='Users'>Users</Tabs.Tab>
              <Tabs.Tab value='Group'>Group</Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </Group>
        <Flex justify='center' gap={20}>
          <TextInput
            w='100%'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={`Search ${selectedEmailTemplateTab === 'Group' ? 'group' : 'user'}`}
            icon={<IconSearch size={20} />}
          />
          {selectedEmailTemplateTab === 'Group' && (
            <Button onClick={openCreateGroupModal}>Create Group</Button>
          )}
        </Flex>

        <UsersBlock
          isGroupTab={selectedEmailTemplateTab === 'Group'}
          filteredUsersList={filteredUsersList || []}
          filteredGroupsList={filteredGroupsList || []}
          handleCancel={handleCancel}
          templateId={templateId}
        />
      </Flex>
    </Stack>
  );
};

export default SendEmailToUsers;
