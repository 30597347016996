import { FC, useImperativeHandle, useState } from 'react';

import { LoadingOverlay, Stack } from '@mantine/core';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  VisibilityState,
  ColumnFiltersState
} from '@tanstack/react-table';

import Paper from 'src/components/Paper';
import Table from 'src/components/Table';

import styles from './DataTable.module.scss';
import { fuzzyFilter } from './helpers';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import { TDataTableProps } from './types';

const DataTable: FC<TDataTableProps> = ({
  data,
  columns,
  filters,
  draggable = false,
  additionalComponent,
  initialVisibilityState,
  initialFiltersState,
  isLoading = false,
  extraSearchElement,
  extraFilterElement,
  tableInstanceRef,
  selectedItemBlock,
  onDragEnd
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialVisibilityState || {}
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialFiltersState || []);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    filterFns: { fuzzy: fuzzyFilter },
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      globalFilter
    },
    columnResizeMode: 'onChange',
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    defaultColumn: {
      minSize: 120,
      maxSize: 600
    }
  });

  useImperativeHandle(tableInstanceRef, () => table);

  return (
    <Paper className={styles.root} pos="relative">
      <LoadingOverlay visible={isLoading} />

      <Stack spacing={25}>
        <TableHeader
          table={table}
          filters={filters}
          extraSearchElement={extraSearchElement}
          extraFilterElement={extraFilterElement}
          selectedItemBlock={selectedItemBlock}
        />
        <Table table={table} draggable={draggable} additionalComponent={additionalComponent} onDragEnd={onDragEnd} />
        <TableFooter table={table} />
      </Stack>
    </Paper>
  );
};

export default DataTable;
