import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TVideoItem, TVideoItemForm, TVideosList } from 'src/modules/videos/types';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';

import {
  getVideosList,
  getVideoById,
  createVideoItem,
  updateVideoItem,
  deleteVideoItem
} from './api';
import { VIDEOS_LIST_QUERY_KEY, VIDEOS_ITEM_QUERY_KEY } from './constants';
import { TVideoItemUpdateRequest } from './types';

export const useVideosListQuery = () => {
  return useQuery<TVideosList>({
    queryKey: [VIDEOS_LIST_QUERY_KEY],
    queryFn: getVideosList
  });
};

export const useVideoByIdQuery = (id: string) => {
  return useQuery<TVideoItem | null>({
    queryKey: [VIDEOS_ITEM_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getVideoById(id);
      const item = response[0];

      return item || null;
    }
  });
};

export const useCreateVideoItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TVideoItemForm>({
    mutationFn: createVideoItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([VIDEOS_LIST_QUERY_KEY]);
      router.navigate(Paths.videos);
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateVideoItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TVideoItemUpdateRequest>({
    mutationFn: updateVideoItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([VIDEOS_LIST_QUERY_KEY]);
      await queryClient.invalidateQueries([VIDEOS_ITEM_QUERY_KEY]);
      router.navigate(Paths.videos);
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteVideoItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteVideoItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([VIDEOS_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};
