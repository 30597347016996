import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { AdminUser } from 'src/modules/admin/types';

type AuthState = {
  isLoggedIn: boolean;
  user: AdminUser | null;
  setUser: (user: AdminUser) => void;
  removeUser: () => void;
};

export const useAuthStore = create<AuthState>()(devtools((set) => ({
  isLoggedIn: false,
  user: null,

  setUser: (user: AdminUser) => set(() => ({
    user,
    isLoggedIn: true
  })),

  removeUser: () => set(() => ({
    user: null,
    isLoggedIn: false
  }))
})));
