import { Flex } from '@mantine/core';
import { Row, Table, flexRender } from '@tanstack/react-table';

import styles from '../Table.module.scss';
import TableCell from '../TableCell';

interface TProps {
  row: Row<any>;
  table: Table<any>;
};

const TableCellData = ({ row, table }: TProps ) => {
  return (
    <Flex className={styles.tableRow}>
      {row.getVisibleCells().map((cell) => (
        <TableCell key={cell.id} table={table} cell={cell}>
          {flexRender(
            cell.column.columnDef.cell,
            cell.getContext()
          )}
        </TableCell>
      ))}
    </Flex>
  );
};

export default TableCellData;
