import { FC, useCallback } from 'react';

import { Button, Center, Flex, Loader, Stack, Text, Title } from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';

import {
  useGeneralSecretariatListQuery,
  useDeleteGeneralSecretariatItemMutation,
  useSortGeneralSecretariatListMutation
} from 'src/api/general-secretariat/hooks';
import { reorderIds } from 'src/common/helpers/dragAndDrop';
import DataAccordionList from 'src/components/DataAccordionList';
import { TGeneralSecretariatItem } from 'src/modules/general-secretariat/types';

import AccordionContent from './AccordionContent';
import AddModalContent from './AddModalContent';
import EditModalContent from './EditModalContent';

const GeneralSecretariat: FC = () => {
  const { data, isLoading: isDataLoading } = useGeneralSecretariatListQuery();
  const { mutate: deleteItem, isLoading: isDeleteItemLoading } = useDeleteGeneralSecretariatItemMutation();
  const { mutate: sortItems, isLoading: isSortingItemsLoading } = useSortGeneralSecretariatListMutation();

  const openEditModal = useCallback((item: TGeneralSecretariatItem) => openModal({
    title: <Title order={3}>Edit</Title>,
    exitTransitionDuration: 300,
    size: 700,
    padding: 30,
    children: <EditModalContent item={item} />
  }), []);

  const openAddModal = useCallback(() => openModal({
    title: <Title order={3}>Add</Title>,
    size: 700,
    padding: 30,
    children: <AddModalContent />
  }), []);

  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteItem(id)
  }), [deleteItem]);

  const handleReorder = (sourceIndex: number, destinationIndex: number) => {
    if (!data) {
      return;
    }

    sortItems(reorderIds(data, sourceIndex, destinationIndex));
  };

  return (
    <Stack spacing={30}>
      <Flex align="center" justify="space-between">
        <Title order={2}>
          General Secretariat
        </Title>
        <Button
          onClick={openAddModal}
          leftIcon={<IconPlus size={18} />}
        >
          Add
        </Button>
      </Flex>

      {isDataLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data ? (
        <DataAccordionList
          data={data}
          onItemEditClick={(item) => openEditModal(item)}
          onItemDeleteClick={(item) => openDeleteModal(item.id)}
          titleRenderer={(item) => item.name_en}
          contentRenderer={(item) => <AccordionContent item={item} />}
          isLoading={isDeleteItemLoading || isSortingItemsLoading}
          onDragEnd={handleReorder}
        />
      ) : null}
    </Stack>
  );
};

export default GeneralSecretariat;
