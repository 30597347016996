import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TRegulations, TRegulationsForm } from 'src/modules/regulations/types';

export const getRegulations = async (): Promise<TRegulations> => {
  return privateApi.get('/regulation').then((res) => res.data.data);
};

export const updateRegulations = async (data: TRegulationsForm): Promise<SuccessResponse> => {
  return privateApi.put('/regulation', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};
