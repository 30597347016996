import { FC } from 'react';

import { useForm } from 'react-hook-form';

import { useCreateCompetitionPathsItemMutation } from 'src/api/competition-paths/hooks';
import ManageCompetitionPaths from 'src/components/ManageCompetitionPaths';
import { competitionPathFormDefaultValues } from 'src/modules/competition-paths/constants';
import { TCompetitionPathItemForm } from 'src/modules/competition-paths/types';

const CompetitionPathsAddItem: FC = () => {
  const { mutate, isLoading } = useCreateCompetitionPathsItemMutation();

  const form = useForm<TCompetitionPathItemForm>({
    defaultValues: competitionPathFormDefaultValues
  });

  const handleItemCreate = (data: TCompetitionPathItemForm) => {
    mutate(data);
  };

  return (
    <ManageCompetitionPaths
      form={form}
      onSubmit={handleItemCreate}
      submitText="Create"
      title="Add Competition Path"
      isLoading={isLoading}
    />
  );
};

export default CompetitionPathsAddItem;
