import { FC } from 'react';

import { Box, Button, LoadingOverlay, Stack } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

import Form from 'src/components/Form';
import ImageUpload from 'src/components/ImageUpload';
import TextInput from 'src/components/TextInput';
import { TGeneralSecretariatItemForm } from 'src/modules/general-secretariat/types';

type TProps = {
  submitText: string;
  onSubmit: (data: TGeneralSecretariatItemForm) => void;
  defaultValues: TGeneralSecretariatItemForm;
  isLoading: boolean;
};

const ManageGeneralSecretariat: FC<TProps> = ({
  submitText,
  onSubmit,
  defaultValues,
  isLoading
}) => {
  const form = useForm({ defaultValues });

  return (
    <Box
      component={Form}
      w="100%"
      onSubmit={form.handleSubmit(onSubmit)}
      pos="relative"
    >
      <LoadingOverlay visible={isLoading} />

      <Stack spacing={30}>
        <Controller
          control={form.control}
          name="name_en"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Name - En"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="name_ar"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Name - Ar"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="position_en"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Position - En"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="position_ar"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Position - Ar"
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="image"
          rules={{ required: 'Required' }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <ImageUpload
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Image"
              required
              disabled={isLoading}
            />
          )}
        />

        <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
          {submitText}
        </Button>
      </Stack>
    </Box>
  );
};

export default ManageGeneralSecretariat;
