import { FC, useRef } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useFiltrationRequestQuery } from 'src/api/filtration-request/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import {
  useFiltrationRequestTableColumns,
  useFiltrationRequestTableFilters
} from 'src/modules/filtration-request/hooks';

import { useFiltrationRequestFilter } from './hooks';
import { getFiltrationRequestForExport } from './utils';

const FiltrationRequest: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();

  const { data: filtrationRequest, isLoading } = useFiltrationRequestQuery();

  const columns = useFiltrationRequestTableColumns();
  const filters = useFiltrationRequestTableFilters();

  const filtrationRequestExport = getFiltrationRequestForExport(filtrationRequest?.data);
  const requestsFilteredByUserRole = useFiltrationRequestFilter(filtrationRequest?.data);

  return (
    <Flex direction="column" h="100%">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>Filtration Request</Title>
        <ExportButton filename="Filtration Request" data={filtrationRequestExport} />
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {requestsFilteredByUserRole ? (
        <DataTable
          columns={columns}
          data={filtrationRequest?.data || []}
          filters={filters}
          tableInstanceRef={tableInstanceRef}
        />
      ) : null}
    </Flex>
  );
};

export default FiltrationRequest;
