import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import TableActionsCell from 'src/components/TableActionsCell';
import { TTableFilters } from 'src/components/TableFilters';
import { Paths } from 'src/router/constants';

import { TCompetitionPathItem } from './types';

export const useCompetitionPathsTableColumns = ({
  onDeleteClick
}: {
  onDeleteClick: (id: string) => void;
}) => {
  const navigate = useNavigate();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TCompetitionPathItem>();

    return [
      columnHelper.accessor('title_en', {
        header: 'Title - En',
        size: 300
      }),

      columnHelper.accessor('title_ar', {
        header: 'Title - Ar',
        size: 300
      }),

      columnHelper.accessor('body_en', {
        header: 'Body - En'
      }),

      columnHelper.accessor('body_ar', {
        header: 'Body - Ar'
      }),

      columnHelper.accessor('image_path', {
        header: 'Image'
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        cell: ({ row }) => <TableActionsCell
          actions={['edit', 'delete']}
          onEditClick={() => navigate(
            generatePath(Paths.competitionPathsItemEdit, { id: row.original.id.toString() })
          )}
          onDeleteClick={() => onDeleteClick(row.original.id.toString())}
        />
      })
    ];
  }, [navigate, onDeleteClick]);
};

export const useCompetitionPathsTableFilters = () => {
  return useMemo((): TTableFilters<TCompetitionPathItem> => [
    {
      id: 'title_en',
      title: 'Title - En',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'title_ar',
      title: 'Title - Ar',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'body_en',
      title: 'Body - En',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'body_ar',
      title: 'Body - Ar',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'image_path',
      title: 'Image',
      type: 'input',
      defaultValue: ''
    }
  ], []);
};
