import { Button, Paper, Stack, Table, Textarea, Title } from '@mantine/core';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAllRequestPercentagesQuery } from 'src/api/arbitrating-requests/hooks';
import { TArbitratingCollectedData } from 'src/api/arbitrating-requests/types';
import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import TablePercentInput from 'src/components/TablePercentInput';
import styles from '../ArbitratingApprovalEvaluation/ArbitratingApprovalEvaluation.module.scss';

interface TProps {
  tableValues: TArbitratingCollectedData[];
  setTableValues: Dispatch<SetStateAction<TArbitratingCollectedData[]>>;
  resetValue: boolean;
  setResetValue: Dispatch<SetStateAction<boolean>>;
  sum: number;
  totalPercentages: number;
  comments: TArbitratingCollectedData[];
  setComments: Dispatch<SetStateAction<TArbitratingCollectedData[]>>;
  isChecingInfo: () => boolean;
  onSubmit: () => void;
  isDone: number;
};

const PercentageTable = ({
  tableValues,
  setTableValues,
  resetValue,
  setResetValue,
  sum,
  totalPercentages,
  comments,
  setComments,
  isChecingInfo,
  onSubmit,
  isDone
}: TProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: RequestPercentages, isLoading: isRequestPercentagesLoading } = useGetAllRequestPercentagesQuery(id || '');
  const { data: arbitrationCriteria, isLoading: isarbitrationCriteriaLoading } = useArbitrationCriteriaListQuery();

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>, id: number) => {
    if (comments[id]) {
      comments[id][id] = e.target.value;
      setComments([...comments]);
    } else {
      setComments([...comments, { [id]: e.target.value }]);
    }

    return;
  };

  return (
    <Paper w='100%' p='xl' radius='lg' display='grid' sx={{ gap: 32 }}>
      <Title order={3}>Percentage of Arbitration Criteria</Title>
      {!isarbitrationCriteriaLoading ? (
        <Stack>
          <Table sx={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                {arbitrationCriteria?.map((th) => (
                  <th key={th.id} className={styles.tableComments}>{th.title}</th>
                ))}
                <th className={styles.tableComments}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {!isDone ? arbitrationCriteria?.map((td, id) => (
                  <td key={td.id}>
                    <TablePercentInput
                      maxVal={td.percentage}
                      placeholder={`eg ${td.percentage}%`}
                      id={id}
                      tableValue={tableValues || ''}
                      setTableValue={setTableValues}
                      resetValue={resetValue}
                      setResetValue={setResetValue}
                    />
                  </td>
                )) : (
                  <>
                  {!isRequestPercentagesLoading && RequestPercentages?.judgment_Evaluation && RequestPercentages?.judgment_Evaluation.map((item) => (
                    <td key={item.criteriaId} className={styles.tableData}>
                      <div>{item.judgmentPercentage}%</div>
                    </td>
                  ))}
                  </>
                )}
                <td className={styles.tableData}>
                  {!isDone ? (
                    <TablePercentInput
                      maxVal={100}
                      placeholder=''
                      total
                      totalVal={ sum ? +totalPercentages.toFixed(2) : 0 }
                    />
                  ) : (
                    <div>
                      <p>{RequestPercentages?.total_Percentage}%</p>
                      <span>out of 100%</span>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                {!isDone ? arbitrationCriteria?.map((item, id) => (
                  <td key={item.id}>
                    <Textarea
                      autosize
                      maxRows={6}
                      placeholder='Enter comments'
                      onChange={(e) => handleCommentChange(e, id)}
                      value={comments[id] ? comments[id][id] : ''}
                    />
                  </td>
                )) : (
                  <>
                    {!isRequestPercentagesLoading && RequestPercentages?.judgment_Evaluation && RequestPercentages?.judgment_Evaluation.map((item) => (
                      <td key={item.criteriaId}>
                        <Textarea
                          autosize
                          maxRows={6}
                          disabled
                          className={styles.textArea}
                          value={item.comment || '-'}
                        />
                      </td>
                    ))}
                  </>
                )}
              </tr>
            </tbody>
          </Table>
        </Stack>
      ) : null}

      {!isDone && <Button w='max-content' disabled={!isChecingInfo()} onClick={onSubmit}>Submit</Button>}
    </Paper>
  );
};

export default PercentageTable;
