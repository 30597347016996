import { ChangeEvent, FC, useEffect } from 'react';

import { Flex, Stack, Button, TextInput, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { Link } from 'react-router-dom';
import { Paths } from 'src/router/constants';
import { useLayoutStore } from 'src/store/useLayoutStore';

const NewEmailTemplate: FC<ContextModalProps<{}>> = ({
  context,
  id,
  innerProps: {}
}) => {
  const { newEmailTemplate } = useLayoutStore();

  useEffect(() => {
    useLayoutStore.setState(() => {
      return { newEmailTemplate: '' }
    });
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    useLayoutStore.setState((state) => {
      return {
        newEmailTemplate: value
      };
    });
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return (
    <Stack>
      <Flex justify='center' direction='column' gap={16}>
        <Flex justify='center' direction='column'>
        <Text fz={14} color="#767676">
          Template Name
          <Text color='#FF2929' display='inline'>*</Text>
        </Text>
          <TextInput
            onChange={(e) => handleInputChange(e)}
            value={newEmailTemplate}
            placeholder='Enter template name'
          />
        </Flex>

        <Flex justify="center" gap={16}>
          <Button
            onClick={handleCancel}
            disabled={!newEmailTemplate}
            component={Link}
            to={Paths.newEmailTemplate}
          >
            Next
          </Button>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default NewEmailTemplate;
