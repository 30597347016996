import { FC, useState } from 'react';

import { Center, LoadingOverlay, Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import AuthCode from 'react-auth-code-input';

import { useAdminLoginVerifyMutation } from 'src/api/admin/hooks';
import { parseApiErrors } from 'src/common/helpers/api';

import styles from './LoginVerifyModal.module.scss';

const LoginVerifyModal: FC<ContextModalProps<{ email: string }>> = ({
  innerProps,
  id,
  context
}) => {
  const [formError, setFormError] = useState<string>();

  const { mutate: loginVerify, isLoading: isLoginVerifyLoading } = useAdminLoginVerifyMutation({
    onError: (error) => parseApiErrors(error, {
      onGlobalError: (message) => setFormError(message)
    })
  });


  const handleAuthCodeChange = (value: string) => {
    if (value.length !== 4) {
      return;
    }
    loginVerify({
      email: innerProps.email,
      code: value
    });

    context.closeModal(id);
  };

  return (
    <>
      <LoadingOverlay visible={isLoginVerifyLoading} />

      <Stack>
        {formError ? (
          <Text fz={14} color="#c92a2a" align="center">
            {formError}
          </Text>
        ) : null}

        <Center>
          <AuthCode
            autoFocus
            allowedCharacters="numeric"
            containerClassName={styles.authCodeWrapper}
            inputClassName={styles.authCodeInput}
            length={4}
            onChange={handleAuthCodeChange}
          />
        </Center>
      </Stack>
    </>
  );
};

export default LoginVerifyModal;
