import privateApi from 'src/common/configs/private-api';
import { SuccessResponse } from 'src/common/types/api';
import { TPrivacyPolicy } from 'src/modules/privacy-policy/types';

export const getPrivacyPolicy = async (): Promise<TPrivacyPolicy> => {
  return privateApi.get('/admin-content/privacyandpolicy').then((res) => res.data.data);
};

export const updatePrivacyPolicy = async (data: TPrivacyPolicy): Promise<SuccessResponse> => {
  return privateApi.put('/privacy', data).then((res) => res.data);
};
