import { AdminUser } from 'src/modules/admin/types';
import { RequestStatus } from 'src/modules/competitions/constants';
import { RequestFiltrationType } from 'src/modules/filtration-request/constants';
import { visibleRequestStatusesByRole } from 'src/modules/filtration-request/constants';
import { TParticipantsRequest } from 'src/modules/participants-requests/types';

export const getArbitratingApprovalForExport = (
  arbitrating: TParticipantsRequest[] | undefined
) => {
  if (!arbitrating) {
    return [
      { data: [], title: 'Data' },
      { data: [], title: 'Statistics' }
    ];
  }

  const arbitratingDataSheet = getArbitratingData(arbitrating);
  const arbitratingStatisticsSheet = getArbitratingStatisticsSheet(arbitrating);

  return [
    { data: arbitratingDataSheet, title: 'Data' },
    { data: arbitratingStatisticsSheet, title: 'Statistics' }
  ];
};

const getArbitratingData = (arbitrating: TParticipantsRequest[]) => {
  const arbitratingExport =
    arbitrating?.map((p) => ({
      // id: p.id,
      'Request Number': p.request_number,
      'Email': p.member.email,
      'Innovation Name': p.innovation_name,
      Evaluation: p.evaluation_stage?.name,
      'Participating Type': p.participating_type?.name,
      Status: p.status?.name,
      Path: p.path
    })) ?? [];

  return arbitratingExport;
};

export const groupRequestsByProperty = (
  requests: TParticipantsRequest[],
  property: 'status' | 'participating_type' | 'evaluation_stage'
) => {
  const initial: Record<string, TParticipantsRequest[]> = {};

  return requests.reduce((acc, request) => {
    const key = request[property]?.name;
    if (!key) return acc;

    const grouped = acc[key] ? [...acc[key], request] : [request];
    return { ...acc, [key]: grouped };
  }, initial);
};

export const getArbitratingStatistics = (requests: TParticipantsRequest[]) => {
  const byStatus = groupRequestsByProperty(requests, 'status');

  const byParticipatingType: Record<keyof typeof RequestFiltrationType, TParticipantsRequest[]> =
    groupRequestsByProperty(requests, 'participating_type');

  const byEvaluationStage = groupRequestsByProperty(requests, 'evaluation_stage');

  return [
    { description: 'New Status', amount: byStatus['New']?.length },
    { description: 'Submitted Status', amount: byStatus['Submitted']?.length },
    { description: 'Missing Status', amount: byStatus['Missing Requirements']?.length },
    { description: 'Approved Status', amount: byStatus['Approved']?.length },
    { description: 'Rejected Status', amount: byStatus['Rejected']?.length },
    { description: 'Edited Status', amount: byStatus['Edited']?.length },
    { description: 'Under Review Status', amount: byStatus['Under Review']?.length },

    { description: 'Individual', amount: byParticipatingType['Individual']?.length },
    { description: 'Group', amount: byParticipatingType['Group']?.length },

    { description: 'New Evaluation', amount: byEvaluationStage['New']?.length },
    { description: 'Filtering Evaluation', amount: byEvaluationStage['Filtering']?.length },
    { description: 'Authorizing Evaluation', amount: byEvaluationStage['Authorizing']?.length },
    { description: 'Approval Evaluation', amount: byEvaluationStage['Approval']?.length },
    { description: 'Arbitration Evaluation', amount: byEvaluationStage['Arbitrating']?.length }
  ];
};

export const getArbitratingStatisticsSheet = (requests: TParticipantsRequest[]) => {
  const participantsStatistics = getArbitratingStatistics(requests);

  const usersStatisticsSheet = participantsStatistics.map((stat) => ({
    'Statistic description': `${stat.description} Requests`,
    'No.': stat.amount
  }));

  return usersStatisticsSheet;
};
export const createCanUserSeeRequest =
  ({ userRole }: AdminUser) =>
  ({ status }: TParticipantsRequest) => {
    if (!status?.id) return false;

    if (status?.id === RequestStatus.New || status?.id === RequestStatus.UnderReview) {
      return false;
    }

    const statusesOfVisibleRequests = visibleRequestStatusesByRole[userRole.id];

    return statusesOfVisibleRequests?.includes(status?.id);
  };
