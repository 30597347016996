import { Image, SimpleGrid } from '@mantine/core';
import logo from 'src/assets/svg/logo-colored.svg';
import styles from '../GeneratePDF.module.scss'
import AttachmentButton from '../AttachmentButton';
import ContentWithLabel from '../../ContentWithLabel';

interface TProps {
  data: any
}

const ImpactOfInnovation = ({ data }: TProps) => {
  return (
    <div className={styles.page}>
      <div className={styles.image}><Image src={logo} width={120} height={120} fit="contain" /></div>
      <div className={styles.divider} />

      <div className={styles.content}>
        <div className={styles.title}>
          <h3>Impact of Innovation</h3>
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Number of Applications</p>
              <span>-</span>
            </div>
            <div className={styles.itemInfo}>{data.number_of_applications}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <p><div />Application Data</p>
              <span>-</span>
            </div>
          </div>
            {data.application_data.map((application: any) => (
              <SimpleGrid
                cols={2}
                spacing="xl"
                mb="md"
                breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                className={styles.section}
                key={application.application_id}
              >
                <ContentWithLabel label="Application Description">
                  {application.description}
                </ContentWithLabel>
                <ContentWithLabel label="Direct impact of the Application">
                  {application.impact}
                </ContentWithLabel>
                <ContentWithLabel label="Attachment">
                  <AttachmentButton href={application.attachment} />
                </ContentWithLabel>
                <ContentWithLabel label="Economic Impact">
                  {application.economic_impact}
                </ContentWithLabel>
                <ContentWithLabel label="Economic Impact Attachment">
                  <AttachmentButton href={application.economic_impact_attachment} />
                </ContentWithLabel>
                <ContentWithLabel label="Innovation Completeness">
                  {application.completness}
                </ContentWithLabel>
                <ContentWithLabel label="Completeness Attachment">
                  <AttachmentButton href={application.completness_attachment} />
                </ContentWithLabel>
                <ContentWithLabel label="Attached Video">
                  <AttachmentButton href={application.attached_video} />
                </ContentWithLabel>
              </SimpleGrid>
            ))}
        </div>
      </div>

      <h3>page-4</h3>
    </div>
  )
}

export default ImpactOfInnovation;