import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import {
  TParticipantRequestEvaluationStage,
  TParticipantRequestStatus,
  TParticipantRequestTimelineItem,
  TParticipantsApprovalInfo,
  TParticipantsRequestFullInfo
} from 'src/modules/participants-requests/types';

import {
  generateParticipantApprovalPDF,
  getParticipantRequestApprovalInfo,
  getParticipantRequestTimeline,
  getParticipantsRequestById,
  getParticipantsRequestsEvaluationStageList,
  getParticipantsRequestsFieldList,
  getParticipantsRequestsList,
  getParticipantsRequestsStatusList,
  updateParticipantApproveStatus,
  updateParticipantRequest,
  getParticipantsCommentsHistory
} from './api';
import {
  PARTICIPANT_REQUEST_APPROVAL_QUERY_KEY,
  PARTICIPANT_REQUEST_COMMENTS_KEY,
  PARTICIPANT_REQUEST_DETAILS_QUERY_KEY,
  PARTICIPANT_REQUEST_TIMELINE_QUERY_KEY,
  PARTICIPANTS_REQUESTS_EVALUATION_STAGE_QUERY_KEY,
  PARTICIPANTS_REQUESTS_FIELDS_QUERY_KEY,
  PARTICIPANTS_REQUESTS_KEY,
  PARTICIPANTS_REQUESTS_STATUS_QUERY_KEY
} from './constants';
import {
  GetParticipantsRequestsList,
  TFilteringStageUpdateStatus,
  TGenerateParticipantApprovalPDFPayload,
  TParticipantApprovalRequestBody
} from './types';
import { TCommentHistory } from '../arbitrating-requests/types';

export const useParticipantsRequestsQuery = () => {
  return useQuery<GetParticipantsRequestsList>({
    queryKey: [PARTICIPANTS_REQUESTS_KEY],
    queryFn: getParticipantsRequestsList
  });
};

export const useParticipantsRequestByIdQuery = (requestId: string) => {
  return useQuery<TParticipantsRequestFullInfo>({
    queryKey: [PARTICIPANT_REQUEST_DETAILS_QUERY_KEY, requestId],
    queryFn: () => getParticipantsRequestById(requestId)
  });
};

export const useParticipantsRequestsStatusListQuery = () => {
  return useQuery<TParticipantRequestStatus[]>({
    queryKey: [PARTICIPANTS_REQUESTS_STATUS_QUERY_KEY],
    queryFn: getParticipantsRequestsStatusList
  });
};

export const useParticipantsRequestsEvaluationStageListQuery = () => {
  return useQuery<TParticipantRequestEvaluationStage[]>({
    queryKey: [PARTICIPANTS_REQUESTS_EVALUATION_STAGE_QUERY_KEY],
    queryFn: getParticipantsRequestsEvaluationStageList
  });
};

export const useParticipantsRequestsFieldListQuery = () => {
  return useQuery<TParticipantRequestEvaluationStage[]>({
    queryKey: [PARTICIPANTS_REQUESTS_FIELDS_QUERY_KEY],
    queryFn: getParticipantsRequestsFieldList
  });
};

export const useParticipantRequestTimelineQuery = (requestId: string) => {
  return useQuery<TParticipantRequestTimelineItem[]>({
    queryKey: [PARTICIPANT_REQUEST_TIMELINE_QUERY_KEY, requestId],
    queryFn: () => getParticipantRequestTimeline(requestId)
  });
};

export const useUpdateParticipantRequestMutation = (requestId: string) => {
  return useMutation<SuccessResponse, ErrorResponse, TFilteringStageUpdateStatus>({
    mutationFn: updateParticipantRequest,
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      showSuccessNotification({ message: 'Updated successfully' });
      closeAllModals();
    }
  });
};

export const useParticipantRequestApprovesQuery = (requestId: string) => {
  return useQuery<TParticipantsApprovalInfo>({
    queryKey: [PARTICIPANT_REQUEST_APPROVAL_QUERY_KEY, requestId],
    queryFn: () => getParticipantRequestApprovalInfo(requestId)
  });
};

export const useUpdateParticipantApprovalStatusMutation = (requestId: string) => {
  return useMutation<SuccessResponse, ErrorResponse, TParticipantApprovalRequestBody>({
    mutationFn: updateParticipantApproveStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PARTICIPANT_REQUEST_APPROVAL_QUERY_KEY, requestId]);
      closeAllModals();
    }
  });
};

export const useGenerateParticipantApprovalPDFMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TGenerateParticipantApprovalPDFPayload>({
    mutationFn: generateParticipantApprovalPDF,
    onSuccess: async () => {
      showSuccessNotification({ message: 'PDF generated successfully' });
    }
  });
};

export const useParticipantsCommentsHistory = (requestId: string) => {
  return useQuery<TCommentHistory[]>({
    queryKey: [PARTICIPANT_REQUEST_COMMENTS_KEY, requestId],
    queryFn: () => getParticipantsCommentsHistory(requestId)
  });
};
