import { FC } from 'react';

import { clsx } from '@mantine/core';
import { Textarea as MantineTextarea } from '@mantine/core';

import styles from './Textarea.module.scss';
import { TTextareaProps } from './types';

const Textarea: FC<TTextareaProps> = ({
  value,
  onChange,
  label,
  error,
  placeholder,
  required,
  disabled,
  className,
  minRows = 6,
  maxRows,
  dir,
  autosize
}) => (
  <MantineTextarea
    value={value}
    onChange={onChange}
    label={label}
    error={error}
    placeholder={placeholder}
    withAsterisk={required}
    disabled={disabled}
    className={className}
    minRows={minRows}
    maxRows={maxRows}
    autosize={autosize}
    dir={dir}
    classNames={{
      input: styles.input,
      invalid: styles.invalid,
      error: styles.error,
      icon: clsx(styles.icon, { [styles.iconError]: error })
    }}
  />
);

export default Textarea;
