import { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useUpdateVideoItemMutation, useVideoByIdQuery } from 'src/api/videos/hooks';
import ManageVideo from 'src/components/ManageVideo';
import { videosFormDefaultValues } from 'src/modules/videos/constants';
import { TVideoItemForm } from 'src/modules/videos/types';
import { Paths } from 'src/router/constants';

const VideosItemEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, isLoading: isDataLoading } = useVideoByIdQuery(id || '');
  const { mutate, isLoading: isUpdateNewsLoading } = useUpdateVideoItemMutation();

  useEffect(() => {
    if (data === null) {
      navigate(Paths.videos);
    }
  }, [data, navigate]);

  const handleItemUpdate = (data: TVideoItemForm) => {
    mutate({ id: id || '', ...data });
  };

  const form = useForm<TVideoItemForm>({
    defaultValues: videosFormDefaultValues
  });

  useEffect(() => {
    if (data) {
      form.reset({
        summary_en: data.summary_en,
        summary_ar: data.summary_ar,
        video: data.video_path,
        cover_image: data.cover_image_path
      });
    }
  }, [form, data]);

  return (
    <ManageVideo
      title="Edit video"
      submitText="Save"
      form={form}
      onSubmit={handleItemUpdate}
      isLoading={isDataLoading || isUpdateNewsLoading}
    />
  );
};

export default VideosItemEdit;
