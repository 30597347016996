import { Flex, Title } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

import { TBackPageButton } from './types';

const BackPageButton = ({ label, to }: TBackPageButton) => {
  const navigate = useNavigate();

  const goBack = () => {
    to ? navigate(to) : navigate(-1);
  };

  return (
    <Flex onClick={goBack} mb={30} w="fit-content" sx={{ cursor: 'pointer' }}>
      <IconChevronLeft size={32} />
      <Title order={2} w="auto">
        {label}
      </Title>
    </Flex>
  );
};

export default BackPageButton;
