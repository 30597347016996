import { TVideoItemForm } from './types';

export const videosFormDefaultValues: TVideoItemForm = {
  summary_en: '',
  summary_ar: '',
  video: '',
  cover_image: null
};

export const videosTableHeadings = {
  modifyDate: 'Modify Date',
  publishDate: 'Publish Date',
  summaryEn: 'Summary - En',
  summaryAr: 'Summary - Ar',
  youtubeLink: 'YouTube link',
  coverImage: 'Cover Image'
};
