import { FC } from 'react';

import { Header as MantineHeader, Flex, Image } from '@mantine/core';

import logo from 'src/assets/svg/logo-colored.svg';

import UserMenu from './UserMenu';

const Header: FC = () => (
  <MantineHeader height={61}>
    <Flex h="100%" px={20} justify="space-between" align="center">
      <Image src={logo} width={40} height={40} fit="contain" />
      <UserMenu />
    </Flex>
  </MantineHeader>
);

export default Header;
