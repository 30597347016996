import privateApi from 'src/common/configs/private-api';
import { SuccessResponse } from 'src/common/types/api';
import { TFAQItemForm, TFAQList } from 'src/modules/faq/types';

import { TFAQItemUpdateRequest } from './types';

export const getFAQList = async (): Promise<TFAQList> => {
  return privateApi.get('/admin-content/faq').then((res) => res.data.data);
};

export const createFAQItem = async (data: TFAQItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/faq', data).then((res) => res.data);
};

export const updateFAQItem = async (data: TFAQItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/faq', data).then((res) => res.data);
};

export const deleteFAQItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/faq', {
    params: { id }
  }).then((res) => res.data);
};

export const sortFAQList = async (data: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/faq/sort', data).then((res) => res.data);
};
