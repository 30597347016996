import { FC } from 'react';

import { useForm } from 'react-hook-form';

import { useCreatePhotosItemMutation } from 'src/api/photos/hooks';
import ManagePhoto from 'src/components/ManagePhoto';
import { photosFormDefaultValues } from 'src/modules/photos/constants';
import { TPhotosItemForm } from 'src/modules/photos/types';

const PhotosAddItem: FC = () => {
  const { mutate, isLoading } = useCreatePhotosItemMutation();

  const form = useForm<TPhotosItemForm>({
    defaultValues: photosFormDefaultValues
  });

  const handleItemCreate = (data: TPhotosItemForm) => {
    mutate(data);
  };

  return (
    <ManagePhoto
      title="Add photo"
      submitText="Create"
      form={form}
      onSubmit={handleItemCreate}
      isLoading={isLoading}
    />
  );
};

export default PhotosAddItem;
