import { useQuery } from '@tanstack/react-query';

import { getUsersList } from './api';
import { USERS_LIST } from './constants';
import { GetUsersListResponse } from './types';

export const useUsersListQuery = () => {
  return useQuery<GetUsersListResponse>({
    queryKey: [USERS_LIST],
    queryFn: () => getUsersList().then((res) => res.data)
  });
};
