import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TCompetitionItem, TCompetitionItemForm, TCompetitionsList } from 'src/modules/competitions/types';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';

import {
  getCompetitionsList,
  getCompetitionById,
  createCompetitionItem,
  updateCompetitionItem,
  deleteCompetitionItem
} from './api';
import { COMPETITION_QUERY_KEY, COMPETITIONS_LIST_QUERY_KEY } from './constants';
import { TCompetitionItemUpdateRequest } from './types';

export const useCompetitionsListQuery = () => {
  return useQuery<TCompetitionsList>({
    queryKey: [COMPETITIONS_LIST_QUERY_KEY],
    queryFn: getCompetitionsList
  });
};

export const useCompetitionByIdQuery = (id: string) => {
  return useQuery<TCompetitionItem | null>({
    queryKey: [COMPETITION_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getCompetitionById(id);
      const item = response[0];

      return item || null;
    }
  });
};

export const useCreateCompetitionItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCompetitionItemForm>({
    mutationFn: createCompetitionItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITIONS_LIST_QUERY_KEY]);
      router.navigate(Paths.competitions);
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateCompetitionItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TCompetitionItemUpdateRequest>({
    mutationFn: updateCompetitionItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITIONS_LIST_QUERY_KEY]);
      await queryClient.invalidateQueries([COMPETITION_QUERY_KEY]);
      router.navigate(Paths.competitions);
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteCompetitionItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteCompetitionItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([COMPETITIONS_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};
