import { FC, useMemo } from 'react';

import { useUpdateFAQItemMutation } from 'src/api/faq/hooks';
import ManageFAQ from 'src/components/ManageFAQ';
import { TFAQItem, TFAQItemForm } from 'src/modules/faq/types';

type TProps = {
  item: TFAQItem;
};

const EditModalContent: FC<TProps> = ({ item }) => {
  const { mutate, isLoading } = useUpdateFAQItemMutation();

  const defaultValues = useMemo((): TFAQItemForm => {
    return {
      question_en: item.question_en,
      question_ar: item.question_ar,
      answer_en: item.answer_en,
      answer_ar: item.answer_ar
    };
  }, [item]);

  const handleItemUpdate = (data: TFAQItemForm) => {
    mutate({ id: item.id, ...data });
  };

  return (
    <ManageFAQ
      defaultValues={defaultValues}
      onSubmit={handleItemUpdate}
      submitText="Save"
      isLoading={isLoading}
    />
  );
};

export default EditModalContent;
