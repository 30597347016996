import { FC } from 'react';

import { Tabs } from '@mantine/core';
import { IconFile, IconFilePlus } from '@tabler/icons';

interface Props {
  handleTabChange: (tab: 'new' | 'rest') => void;
}

const RequestStatusTabs: FC<Props> = ({ handleTabChange }) => {
  return (
    <Tabs variant="outline" defaultValue={'new'} onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="new" icon={<IconFilePlus color="green" size="0.8rem" />}>
          New
        </Tabs.Tab>
        <Tabs.Tab value="rest" icon={<IconFile size="0.8rem" />}>
          Submitted
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default RequestStatusTabs;
