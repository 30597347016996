import { TNewsItemForm } from 'src/modules/news/types';

export const newsFormDefaultValues: TNewsItemForm = {
  summary_en: '',
  summary_ar: '',
  news_body_en: '',
  news_body_ar: '',
  image: null
};

export const newsTableHeadings = {
  image: 'Image',
  modifyDate: 'Modify Date',
  publishDate: 'Publish Date',
  summaryEn: 'Summary - En',
  summaryAr: 'Summary - Ar',
  bodyEn: 'Body - En',
  bodyAr: 'Body - Ar'
};
