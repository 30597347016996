import { FC } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { AdminRoles } from 'src/modules/admin/constants';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

type Props = {
  roles: AdminRoles[];
};

const AdminRoleWrapper: FC<Props> = ({
  roles
}) => {
  const { user } = useAuthStore();

  if (!user || !roles.includes(user.userRole.id)) {
    return <Navigate to={Paths.homepage} replace />;
  }

  return <Outlet />;
};

export default AdminRoleWrapper;
