import { FC, useCallback } from 'react';

import { Button, Center, Flex, Loader, Stack, Text, Title } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

import { useCompetitionPathsListQuery, useDeleteCompetitionPathsItemMutation } from 'src/api/competition-paths/hooks';
import DataTable from 'src/components/DataTable';
import { useCompetitionPathsTableColumns, useCompetitionPathsTableFilters } from 'src/modules/competition-paths/hooks';
import { Paths } from 'src/router/constants';

const CompetitionPaths: FC = () => {
  const navigate = useNavigate();

  const { data, isLoading: isDataLoading } = useCompetitionPathsListQuery();
  const { mutate: deleteItem, isLoading: isDeleteItemLoading } = useDeleteCompetitionPathsItemMutation();

  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteItem(id)
  }), [deleteItem]);

  const columns = useCompetitionPathsTableColumns({
    onDeleteClick: openDeleteModal
  });

  const filters = useCompetitionPathsTableFilters();

  return (
    <Stack spacing={30}>
      <Flex align="center" justify="space-between">
        <Title order={2}>
          Competition Paths
        </Title>
        <Button
          onClick={() => navigate(Paths.competitionPathsAddItem)}
          leftIcon={<IconPlus size={18} />}
        >
          Add
        </Button>
      </Flex>

      {isDataLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data ? (
        <DataTable
          columns={columns}
          data={data}
          filters={filters}
          isLoading={isDeleteItemLoading}
        />
      ) : null}
    </Stack>
  );
};

export default CompetitionPaths;
