import { Cell } from 'recharts';

export const COLORS = [
  '#ff3e25',
  '#2365f2',
  '#6cd357',
  '#4cb8ba',
  '#ffb55a',
  '#9876e9',
  '#e65c85',
  '#52c5b2'
];

export const mapForChart = (groupedData: Record<string, unknown[]>) =>
  Object.entries(groupedData).map(([description, groupedUsers]) => ({
    description,
    amount: groupedUsers.length || 0
  }));

export const renderColoredCells = (values: unknown[]) =>
  values.map((_, index) => {
    const color = COLORS[index % COLORS.length];

    return <Cell key={`cell-${index}`} fill={color} />;
  });
