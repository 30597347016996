import {saveAs} from 'file-saver';
import {utils, write} from 'xlsx-js-style';

export type Worksheet = {
  data: any[];
  title: string;
};

export const exportDataAsExcel = (worksheets: Worksheet[], fileName: string) => {
  const workbook = getWorkbookFromSheets(worksheets);
  const workbookBinary = write(workbook, {
    bookType: 'xlsx',
    type: 'binary'
  });
  const octetStream = getOctetStreamFromWorkBook(workbookBinary);
  const blobData = new Blob([octetStream], {
    type: 'application/octet-stream'
  });
  saveAs(blobData, fileName + '.xlsx');
};

const getWorkbookFromSheets = (worksheets: Worksheet[]) => {
  const workbook = utils.book_new();

  worksheets.forEach(worksheetData => {
    const headings = Object.keys(worksheetData.data[0]).map(key => ({
      v: key,
      s: {
        fill: {
          fgColor: {
            rgb: 'D3D3D3'
          }
        },
        font: {
          bold: true
        }
      }
    }));
    const worksheet = utils.aoa_to_sheet([headings]);
    utils.sheet_add_json(worksheet, worksheetData.data, {origin: 1, skipHeader: true});
    utils.book_append_sheet(workbook, worksheet, worksheetData.title);
  });

  return workbook;
};

const getOctetStreamFromWorkBook = (workbookBinary: string) => {
  const buffer = new ArrayBuffer(workbookBinary.length); // convert s to arrayBuffer
  const view = new Uint8Array(buffer);  // create uint8array as viewer

  for (let i=0; i < workbookBinary.length; i++) {
    view[i] = workbookBinary.charCodeAt(i) & 0xFF; // convert to octet
  }
  
  return buffer;
};