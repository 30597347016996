import { FC, ReactNode } from 'react';

import { Flex, Text } from '@mantine/core';

type TProps = {
  label: string;
  children: ReactNode;
};

const InfoLabel: FC<TProps> = ({ label, children }) => (
  <Flex align="center">
    <Text miw={200}>{label}</Text>
    {children}
  </Flex>
);

export default InfoLabel;
