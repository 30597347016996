import { TSupervisoryBoardItemUpdateRequest } from 'src/api/supervisory-board/types';
import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TSupervisoryBoardItemForm, TSupervisoryBoardList } from 'src/modules/supervisory-board/types';

export const getSupervisoryBoardList = async (): Promise<TSupervisoryBoardList> => {
  return privateApi.get('/admin-content/supervisoryboard').then((res) => res.data.data);
};

export const createSupervisoryBoardItem = async (data: TSupervisoryBoardItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/supervisory-board', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateSupervisoryBoardItem = async (data: TSupervisoryBoardItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/supervisory-board', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteSupervisoryBoardItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/supervisory-board', {
    params: { id }
  }).then((res) => res.data);
};

export const sortSupervisoryBoardList = async (data: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/supervisory-board/sort', data).then((res) => res.data);
};
