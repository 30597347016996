import { Accordion, ActionIcon, Box, Flex, LoadingOverlay, Menu, Group } from '@mantine/core';
import { IconDots, IconEdit, IconGripVertical, IconTrash } from '@tabler/icons';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import Paper from 'src/components/Paper';

import styles from './DataAccordionList.module.scss';
import { TDataAccordionListProps } from './types';

const DataAccordionList = <T extends { id: string; [key: string]: any }>({
  data,
  titleRenderer,
  contentRenderer,
  onItemEditClick,
  onItemDeleteClick,
  isLoading = false,
  onDragEnd
}: TDataAccordionListProps<T>) => {

  const handleDrop: OnDragEndResponder = ({ source, destination }) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    onDragEnd(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId="droppableList">
        {(droppable) => (
          <Box {...droppable.droppableProps} ref={droppable.innerRef}>
            <Paper pos="relative" className={styles.paper}>
              <LoadingOverlay visible={isLoading} />

              <Accordion
                variant="separated"
                multiple
                chevronPosition="left"
              >
                {data.map((item, index) => (
                  <Draggable
                    key={`accordion_${item.id}`}
                    draggableId={`accordion_${item.id}`}
                    index={index}
                  >
                    {(draggable) => (
                      <Group
                        ref={draggable.innerRef}
                        {...draggable.draggableProps}
                        mb={20}
                      >
                        <Box
                          {...draggable.dragHandleProps}
                        >
                          <IconGripVertical />
                        </Box>

                        <Accordion.Item
                          value={`accordion_${item.id}`}
                          className={styles.accordion}
                        >
                          <Flex align="center" pr={10}>
                            <Accordion.Control>
                              {titleRenderer(item)}
                            </Accordion.Control>
                            <Menu
                              shadow="md"
                              width={120}
                              withinPortal
                              position="bottom-end"
                              offset={2}
                              trigger="hover"
                            >
                              <Menu.Target>
                                <ActionIcon color="dark" radius="xl">
                                  <IconDots size={16} />
                                </ActionIcon>
                              </Menu.Target>
                              <Menu.Dropdown>
                                <Menu.Item
                                  icon={<IconEdit size={14} />}
                                  onClick={() => onItemEditClick(item)}
                                >
                                  Edit
                                </Menu.Item>
                                <Menu.Item
                                  color="red"
                                  icon={<IconTrash size={14} />}
                                  onClick={() => onItemDeleteClick(item)}
                                >
                                  Delete
                                </Menu.Item>
                              </Menu.Dropdown>
                            </Menu>
                          </Flex>
                          <Accordion.Panel>
                            {contentRenderer(item)}
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Group>
                    )}
                  </Draggable>
                ))}
                {droppable.placeholder}
              </Accordion>
            </Paper>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DataAccordionList;
