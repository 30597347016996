import { FC } from 'react';

import { Accordion, Box, Center, Loader, Stack, Text, Title } from '@mantine/core';
import { clsx } from '@mantine/core';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useTicketByIdQuery } from 'src/api/tickets/hooks';
import InfoLabel from 'src/components/InfoLabel';
import Paper from 'src/components/Paper';

import styles from './TicketsItemView.module.scss';

const TicketsItemView: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useTicketByIdQuery(id || '');

  return (
    <Center>
      <Paper w={700}>
        <Title order={3} mb={30}>
          View Ticket
        </Title>
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {data ? (
          <Stack sx={{ overflow: 'hidden' }}>
            <Box className={clsx(styles.border)}>
              <InfoLabel label="Ticket number">
                <Text size={14}>{data.ticket_number}</Text>
              </InfoLabel>
              {data.name && <InfoLabel label="Name">
                <Text size={14}>{data.name}</Text>
              </InfoLabel>}
              {data.email && <InfoLabel label="Email">
                <Text size={14}>{data.email}</Text>
              </InfoLabel>}
              <InfoLabel label="Title">
                <Text size={14}>{data.title}</Text>
              </InfoLabel>
              <InfoLabel label="Ticket Type">
                <Text size={14}>{data.tickit_type.name || '-'}</Text>
              </InfoLabel>
              <InfoLabel label="Ticket Status">
                <Text size={14}>{data.ticket_status.name || '-'}</Text>
              </InfoLabel>
              <InfoLabel label="Description">
                <Text size={14}>{data.description || '-'}</Text>
              </InfoLabel>
              <InfoLabel label="Rate">
                <Text size={14}>{data.rate || '-'}</Text>
              </InfoLabel>
              <InfoLabel label="Creating Date">
                <Text size={14}>
                  {data.creating_date ? dayjs(data.creating_date).format('DD-MM-YYYY') : '-'}
                </Text>
              </InfoLabel>
              <InfoLabel label="Modifying Date">
                <Text size={14}>
                  {data.modify_date ? dayjs(data.modify_date).format('DD-MM-YYYY') : '-'}
                </Text>
              </InfoLabel>
            <InfoLabel label="Attached file">
              {data.attachemt_path && data.attachemt_path !== 'file not selected' ? (
                <Text
                  component="a"
                  target="_blank"
                  href={data.attachemt_path}
                  size={14}
                  color="blue"
                  underline
                >
                  Open in new tab
                </Text>
              ) : (
                <Text size={14}>-</Text>
              )}
            </InfoLabel>
            </Box>
            <InfoLabel className={clsx(styles.border)} label="Admin comments">
              <Accordion>
                <Accordion.Item value="member_info">
                  <Accordion.Control>
                    View comments
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack>
                      <InfoLabel label="Admin Name">
                        <Text size={14}>{data.admin_status_edit_Name || '-'}</Text>
                      </InfoLabel>
                      <InfoLabel label="Admin Comment">
                        <Text size={14}>{data.admin_status_comment || '-'}</Text>
                      </InfoLabel>
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </InfoLabel>
          </Stack>
        ) : null}
      </Paper>
    </Center>
  );
};

export default TicketsItemView;
