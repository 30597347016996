import { FC, useState } from 'react';

import { ActionIcon, Menu, Tooltip } from '@mantine/core';
import { IconAdjustmentsHorizontal } from '@tabler/icons';

import ColumnVisibility from './ColumnVisibility';
import ShowRows from './ShowRows';
import { TTableSettingsProps } from './types';

const TableSettings: FC<TTableSettingsProps> = ({ table }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isColumnVisibilityOpen, setIsColumnVisibilityOpen] = useState<boolean>(false);

  return (
    <>
      <Menu
        shadow="md"
        width={200}
        opened={isOpen}
        onChange={setIsOpen}
        withinPortal
        position="bottom-end"
      >
        <Menu.Target>
          <Tooltip label="Settings" withArrow arrowSize={6}>
            <ActionIcon>
              <IconAdjustmentsHorizontal size={20} color="#172b4d" />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>
            Settings
          </Menu.Label>
          <Menu.Item closeMenuOnClick={false}>
            <ShowRows table={table} />
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={() => setIsColumnVisibilityOpen(true)}>
            Column Visibility
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <ColumnVisibility
        isOpen={isColumnVisibilityOpen}
        onClose={() => setIsColumnVisibilityOpen(false)}
        table={table}
      />
    </>
  );
};

export default TableSettings;
