import privateApi from 'src/common/configs/private-api';
import { RequestStatus } from 'src/modules/competitions/constants';

export const getFiltrationRequestList = (): Promise<any> => {
  return privateApi.get('/admin/participants-requestsByFilter', {
    params: {
      page_size: 99999,
      page_number: 1,
      'request_status[0]': RequestStatus.MissingRequirements,
      'request_status[1]': RequestStatus.Edited,
      'request_status[2]': RequestStatus.UnderReview,
      'request_status[3]': RequestStatus.NotUpdated
    }
  }).then((res) => res.data);
};

export const getFiltrationApprovalList = (): Promise<any> => {
  return privateApi.get('/admin/participants-requestsByFilter', {
    params: {
      page_size: 99999,
      page_number: 1,
      'request_status[0]': RequestStatus.Approved,
      'request_status[1]': RequestStatus.Rejected,
      'request_status[2]': RequestStatus.FinalApproved,
      'request_status[3]': RequestStatus.FinalRejected
    }
  }).then((res) => res.data);
};

export const sendFiltrationApprovalEmails = (IsApprove: boolean): Promise<any> => {
  return privateApi.post('/admin/finalResultSendEmail', {
    IsApprove: IsApprove,
  });
};
