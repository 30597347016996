import { FC } from 'react';

import { DatePicker as MantineDatePicker, DatePickerProps } from '@mantine/dates';

const DatePicker: FC<DatePickerProps> = ({
  onChange,
  ...restProps
}) => (
  <MantineDatePicker
    dropdownPosition="bottom-start"
    inputFormat="YYYY-DD-MM"
    onChange={(value) => {
      if (!onChange) {
        return;
      }

      if (!value) {
        return null;
      }

      const offset = value.getTimezoneOffset();
      const newDate = new Date(value.getTime() - (offset * 60 * 1000));

      onChange(newDate);
    }}
    {...restProps}
  />
);


export default DatePicker;
