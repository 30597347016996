import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TFAQItemForm, TFAQList } from 'src/modules/faq/types';

import {
  getFAQList,
  createFAQItem,
  updateFAQItem,
  deleteFAQItem, sortFAQList
} from './api';
import { FAQ_LIST_QUERY_KEY } from './constants';
import { TFAQItemUpdateRequest } from './types';

export const useFAQListQuery = () => {
  return useQuery<TFAQList>({
    queryKey: [FAQ_LIST_QUERY_KEY],
    queryFn: getFAQList
  });
};

export const useCreateFAQItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TFAQItemForm>({
    mutationFn: createFAQItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FAQ_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdateFAQItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TFAQItemUpdateRequest>({
    mutationFn: updateFAQItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FAQ_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeleteFAQItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteFAQItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FAQ_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};

export const useSortFAQListMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string[]>({
    mutationFn: sortFAQList,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FAQ_LIST_QUERY_KEY]);
      showSuccessNotification({ message: 'Items sorted successfully' });
    }
  });
};
