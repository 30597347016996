import { useMemo } from 'react';

import { ActionIcon, Badge, Center, Grid, Tooltip } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { IconTrash } from '@tabler/icons';

import { TTableFilters } from 'src/components/TableFilters';
import { AdminRoles } from 'src/modules/admin/constants';
import { AdminUser } from 'src/modules/admin/types';
import { openAdminChangeStatusModal, openAdminDeleteUserModal } from 'src/providers/ModalsProvider/helpers';
import { useAuthStore } from 'src/store/useAuthStore';

import { renderAdminStatus } from './helpers';

export const useAdminsTableColumns = () => {
  const { user } = useAuthStore();

  return useMemo(() => {
    const columnHelper = createColumnHelper<AdminUser>();

    return [
      columnHelper.accessor('fullName', {
        header: 'Full name'
      }),

      columnHelper.accessor('email', {
        header: 'Email'
      }),

      columnHelper.accessor('mobileNumber', {
        header: 'Mobile number',
        size: 170,
        enableResizing: false
      }),

      columnHelper.accessor('userRole', {
        header: 'User role',
        enableResizing: false,
        size: 190,
        cell: (cell) => (
          <Center>
            <Badge>{cell.getValue().name}</Badge>
          </Center>
        )
      }),

      columnHelper.accessor('activeStatus', {
        header: 'Status',
        enableResizing: false,
        size: 110,
        minSize: 110,
        cell: (cell) => {
          if (
            user?.id === cell.row.original.id
            || cell.row.original.userRole.id === AdminRoles.SuperAdmin
            || cell.row.original.userRole.id === AdminRoles.GeneralSecretariat
          ) {
            return null;
          }
          
          return (
            <Center
              onClick={() => openAdminChangeStatusModal({ admin: cell.row.original })}
              style={{ cursor: 'pointer' }}
            >
              {renderAdminStatus(cell.getValue())}
            </Center>
          );
        },
        filterFn: (row, columnId, filterValue) => {
          const value = filterValue === 'true';
          return row.original.activeStatus === value;
        }
      }),

      columnHelper.display({
        header: 'Actions',
        size: 80,
        minSize: 80,
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => {
          const isUserSuperAdmin = user?.userRole.id === AdminRoles.SuperAdmin;
          const userRoleAdmin = row.original.userRole.id === AdminRoles.SuperAdmin;

          return (
            <>
              {!userRoleAdmin && (
                <Grid>
                  {isUserSuperAdmin && (
                    <Grid.Col span={1}>
                        <Tooltip label="Delete" withArrow arrowSize={6}>
                          <ActionIcon
                            size="sm"
                            onClick={() => openAdminDeleteUserModal({admin: row.original})}
                          >
                            <IconTrash size={20} />
                          </ActionIcon>
                        </Tooltip>
                    </Grid.Col>
                  )}
                </Grid>
              )}
            </>
          );
        }
      })
    ];
  }, [user]);
};

export const useAdminTableFilters = () => {
  return useMemo((): TTableFilters<AdminUser> => [
    {
      id: 'fullName',
      title: 'Full name',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'email',
      title: 'Email',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'mobileNumber',
      title: 'Mobile number',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'userRole',
      title: 'User role',
      type: 'input',
      defaultValue: ''
    },
    {
      id: 'activeStatus',
      title: 'Status',
      type: 'select',
      data: [
        {
          value: 'true',
          label: 'Active'
        },
        {
          value: 'false',
          label: 'Disabled'
        }
      ],
      defaultValue: null
    }
  ], []);
};
