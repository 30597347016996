import { useState } from 'react';
import styles from './ArbitratingAssignEvaluation.module.scss';
import { Paper, Flex, Stack, Center, Text, Table, Title, Button } from '@mantine/core';

import BackPageButton from 'src/components/BackPageButton';

import ParticipantsRequestsItemView from '../ParticipantsRequestsItemView';
import CustomStepper from '../ParticipantsRequestsItemView/CustomStepper';
import ArbitratingRequestControls from './ArbitratingAssignControls';
import { IconTrash } from "@tabler/icons";
import { useParams } from 'react-router-dom';
import GeneratePDF from 'src/components/GeneratePDF';
import { AdminUser } from 'src/modules/admin/types';
import AssignUserModal from 'src/components/AssignUserModal';
import { showWarningNotification } from 'src/common/helpers/notifications';
import { useArbitratingAssignQuery, useAssignAribtratingToUserQuery } from 'src/api/arbitrating-requests/hooks';
import { TJudgmentUser, TParticipantsRequest } from 'src/modules/participants-requests/types';
import { useGetArbitratingCommentHistoryQuery } from 'src/api/arbitrating-requests/hooks';
import { openArbitratingAssignModal } from 'src/providers/ModalsProvider/helpers';
import TableCommentHistory from 'src/components/TableCommentHistory';
import { STEPS } from 'src/common/constants/defConstant';
import { useAddToInnovation } from 'src/api/innovations/hooks';
import { useAuthStore } from 'src/store/useAuthStore';
import { AdminRoles } from 'src/modules/admin/constants';
import AssignAdminUserModal from 'src/components/AssignAdminUserModal';

const ArbitrationAssignItemViewAndEvaluation = () => {
  const [active, setActive] = useState(0);
  const { user } = useAuthStore();
  const { id } = useParams<{ id: string }>();
  const { data: ArbitratingAssign, isLoading: ArbitratingLoading } = useArbitratingAssignQuery();
  const { data: commmentHistory, isLoading: isCommentHistoryLoading } = useGetArbitratingCommentHistoryQuery(id || '');
  const { mutate: addInnovation } = useAddToInnovation();

  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AdminUser[]>([]);

  const ArbitratingAssignedUser = !ArbitratingLoading ? ArbitratingAssign?.data?.find((item: TParticipantsRequest) => item.id === id)?.judgment_data : [];

  const handleSelectUser = (newUser: AdminUser) => {
    if (selectedUser.find((user) => user.id === newUser.id)) {
      setSelectedUser(selectedUser.filter((user) => user.id !== newUser.id))
    } else {
      setSelectedUser([...selectedUser, newUser])
    }
  };

  const selectedUsersId = selectedUser.map((item) => item.id);
  const { mutate: assignUser, isLoading: AssignUserLoading } = useAssignAribtratingToUserQuery([id || ''], selectedUsersId);

  const handleRequest = async () => {
    if (!AssignUserLoading && selectedUser.length) {
      await assignUser();
      setSelectedUser([]);
      setOpenedModal(false);
    } else {
      showWarningNotification(
        {message: 'Select at least one user to assign'}
      )
    }
  };

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
        <CustomStepper active={active} setActive={setActive} labels={STEPS} />
      </Flex>
      <Flex gap={16} justify='flex-end'>
        {isAdmin && (<Button onClick={() => addInnovation(id || '')}>To Innovation Page</Button>)}
        <GeneratePDF />
      </Flex>

      <Center>
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <ParticipantsRequestsItemView tabIndex={active} />
          <ArbitratingRequestControls steps={STEPS} active={active} setActive={setActive} />
        </Paper>
      </Center>

      {!isCommentHistoryLoading && commmentHistory?.length ? (
        <TableCommentHistory data={commmentHistory} />
      ) : null}

      {!ArbitratingLoading && ArbitratingAssignedUser?.length ? (
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <Flex justify="space-between" align="center" pos="relative">
            <Title order={3}>Judgments&apos; Score</Title>
            <Text
              align="right"
              color='#228be6'
              underline
              style={{cursor: 'pointer'}}
              onClick={() => {
                setOpenedModal(!openedModal)
                setSelectedUser([]);
              }}
            >+ New Assign</Text>

            {openedModal && (
              <>
                {user?.userRole.id === AdminRoles.SuperAdmin ? (
                  <AssignAdminUserModal
                    selectedUser={selectedUser}
                    handleSelectUser={handleSelectUser}
                    setOpenedModal={setOpenedModal}
                    handleRequest={handleRequest}
                  />
                ) : (
                  <AssignUserModal
                    selectedUser={selectedUser}
                    handleSelectUser={handleSelectUser}
                    setOpenedModal={setOpenedModal}
                    handleRequest={handleRequest}
                  />
                )}
              </>
            )}
          </Flex>

          <Stack>
            <Table sx={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>Judgment Name</th>
                  <th>Status</th>
                  <th>Final Score</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className={styles.table}>
                {ArbitratingAssignedUser?.map((item: TJudgmentUser, id: number) => (
                  <tr key={id}>
                    <td>{item.fullName}</td>
                    <td className={item.status_id === 1 ? styles.statusDone : styles.statusNotDone}>
                      <p>{item.status_id ? 'Done' : 'Not Done'}</p>
                    </td>
                    <td className={styles.score}>
                      {item.final_score ? `${item.final_score}% ` : '-'}
                      {item.final_score ? (
                        <span>out of 100%</span>
                      ) : null}
                    </td>
                    <td>
                      {item.status_id !== 1 ? (
                        <IconTrash size={20} color='#ff2929' cursor='pointer' onClick={() => openArbitratingAssignModal({user: item})} />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Stack>
        </Paper>
      ) : null}
    </Stack>
  );
};

export default ArbitrationAssignItemViewAndEvaluation;
