import { Button, Center, Loader } from '@mantine/core';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';

import { useParticipantsRequestByIdQuery } from 'src/api/participants-requests/hooks';

import styles from './GeneratePDF.module.scss'
import PersonalInformation from './pages/PersonalInformation';
import ParticipantData from './pages/ParticipantData';
import InnovationData from './pages/InnovationData';
import ImpactOfInnovation from './pages/ImpactOfInnovation';


const PdfGenerator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useParticipantsRequestByIdQuery(id || '');
  const pdfExportComponent = React.useRef<PDFExport>(null);

  if (isLoading && isVisible)
    return (
      <Center py={50}>
        <Loader size="lg" />
      </Center>
    );

  if (!data) return null;

  return (
    <div className={`${styles.mainContent} example-config`}>
      <Button type='button' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        onClick={() => {
          setIsVisible(true)
          setTimeout(() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }, 300)
          setTimeout(() => setIsVisible(false), 500)
        }}>Download PDF</Button>
      {isVisible && (
        <div className={styles.loader}>
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        </div>
      )}
      {isVisible && (
        <div style={{ width: "595px", height: "420px" }}>
          <PDFExport
            forcePageBreak=".page-break"
            fileName={data?.innovation_data?.innovation_name || ''}
            ref={pdfExportComponent}
          >
            <div>
              <h3>page</h3>
              <PersonalInformation data={data} />
              <h3 className="page-break">page</h3>
              <ParticipantData data={data} />
              <h3 className="page-break">page</h3>
              <InnovationData data={data} />
              <h3 className="page-break">page</h3>
              <ImpactOfInnovation data={data} />
            </div>
          </PDFExport>
        </div>
      )}
    </div>
  );
};

export default PdfGenerator;
