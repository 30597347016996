import { useMutation, useQuery } from "@tanstack/react-query";
import { FILTRATION_APPROVAL_KEY, FILTRATION_REQUEST_KEY } from "./constants";
import { getFiltrationApprovalList, getFiltrationRequestList, sendFiltrationApprovalEmails } from "./api";
import { ErrorResponse, SuccessResponse } from "src/common/types/api";
import { showErrorNotification, showSuccessNotification } from "src/common/helpers/notifications";

export const useFiltrationRequestQuery = () => {
  return useQuery<any>({
    queryKey: [FILTRATION_REQUEST_KEY],
    queryFn: getFiltrationRequestList
  });
};

export const useFiltrationApprovalQuery = () => {
  return useQuery<any>({
    queryKey: [FILTRATION_APPROVAL_KEY],
    queryFn: getFiltrationApprovalList
  });
};

export const useSendFiltrationApprovalEmailQuery = (isApproved: boolean) => {
  return useMutation<SuccessResponse, ErrorResponse>({
    mutationFn: () => sendFiltrationApprovalEmails(isApproved),
    onSuccess: () => {
      showSuccessNotification({ message: 'Emails send succesfully' });
    },
    onError: () => {
      showErrorNotification({ message: 'Try to resend email' });
    }
  });
};
