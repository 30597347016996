import {
  TArbitrationCriteriaCreatePayload,
  TArbitrationCriteriaUpdatePayload
} from 'src/api/arbitration-criteria/types';
import privateApi from 'src/common/configs/private-api';
import { SuccessResponse } from 'src/common/types/api';
import { TArbitrationCriteriaItem } from 'src/modules/arbitration-criteria/types';

export const getArbitrationCriteriaAll = async (): Promise<TArbitrationCriteriaItem[]> => {
  return privateApi
    .get('/arbitrationcriteria')
    .then((res) => res.data.data);
};

export const createArbitrationCriteria = async (
  payload: TArbitrationCriteriaCreatePayload
): Promise<SuccessResponse> => {
  return privateApi
    .post('/arbitrationcriteria', payload)
    .then((res) => res.data);
};

export const updateArbitrationCriteria = async (
  payload: TArbitrationCriteriaUpdatePayload
): Promise<SuccessResponse> => {
  return privateApi
    .put('/arbitrationcriteria', payload)
    .then((res) => res.data);
};

export const deleteArbitrationCriteria = async (id: string): Promise<SuccessResponse> => {
  return privateApi
    .delete('/arbitrationcriteria', { params: { id } })
    .then((res) => res.data);
};
