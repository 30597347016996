import ImpactOfInnovation from './ImpactOfInnovation';
import InnovationData from './InnovationData';
import ParticipantData from './ParticipantData';
import PersonalInformation from './PersonalInformation';

type Props = {
  tabIndex: number;
};

const ParticipantsRequestsItemView = ({ tabIndex }: Props) => {
  switch (tabIndex) {
    case 0:
      return <PersonalInformation />;
    case 1:
      return <ParticipantData />;
    case 2:
      return <InnovationData />;
    case 3:
      return <ImpactOfInnovation />;

    default:
      return null;
  }
};

export default ParticipantsRequestsItemView;
