import { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { usePhotosByIdQuery, useUpdatePhotosItemMutation } from 'src/api/photos/hooks';
import ManagePhoto from 'src/components/ManagePhoto';
import { photosFormDefaultValues } from 'src/modules/photos/constants';
import { TPhotosItemForm } from 'src/modules/photos/types';
import { Paths } from 'src/router/constants';

const PhotosItemEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, isLoading: isDataLoading } = usePhotosByIdQuery(id || '');
  const { mutate, isLoading: isUpdateNewsLoading } = useUpdatePhotosItemMutation();

  useEffect(() => {
    if (data === null) {
      navigate(Paths.photos);
    }
  }, [data, navigate]);

  const handleItemUpdate = (data: TPhotosItemForm) => {
    mutate({ id: id || '', ...data });
  };

  const form = useForm<TPhotosItemForm>({
    defaultValues: photosFormDefaultValues
  });

  useEffect(() => {
    if (data) {
      form.reset({
        summary_en: data.summary_en,
        summary_ar: data.summary_ar,
        image: data.image_path
      });
    }
  }, [form, data]);

  return (
    <ManagePhoto
      title="Edit photo"
      submitText="Save"
      form={form}
      onSubmit={handleItemUpdate}
      isLoading={isDataLoading || isUpdateNewsLoading}
    />
  );
};

export default PhotosItemEdit;
