import { AdminRoles } from '../admin/constants';
import { RequestStatus } from '../competitions/constants';

export const tableHeadings = {
  firstName: 'First Name',
  secondName: 'Second Name',
  lastName: 'Last Name',
  mobileNumber: 'Mobile Number',
  email: 'Email',
  interests: 'Interests',
  entity: 'Entity',
  company_name: 'Company Name'
};

export enum RequestFiltrationType {
  Individual = 1,
  Group = 2
}

type VisibleRequestStatusesByRole = Partial<{
  [key in AdminRoles]: RequestStatus[];
}>;

export const visibleRequestStatusesByRole: VisibleRequestStatusesByRole = {
  // AdminRoles.SuperAdmin can see all of them
  [AdminRoles.OperationCommittee]: [
    RequestStatus.Approved,
    RequestStatus.Rejected,
    RequestStatus.FinalApproved,
    RequestStatus.FinalRejected
  ]
  // AdminRoles.GeneralSecretariat can see all of them
};
