import axios, { AxiosError } from 'axios';

import { API_ACCESS_TOKEN_KEY, API_BASE_URL } from 'src/common/constants/api';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';
import { useAuthStore } from 'src/store/useAuthStore';

const privateApi = axios.create({
  baseURL: API_BASE_URL
});

privateApi.interceptors.request.use((config) => {
  const apiToken = localStorage.getItem(API_ACCESS_TOKEN_KEY);

  return {
    ...config,
    headers: {
      'Accept-Language': 'en',
      'x-api-key': process.env.REACT_APP_API_KEY || '',
      'Authorization': apiToken,
      ...config.headers
    }
  };
});

privateApi.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<any>) => {
    if (!error.isAxiosError) {
      throw 'Unknown error';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      // todo: refresh token, refetch and return original request
      // todo: invalidate all data
      localStorage.removeItem(API_ACCESS_TOKEN_KEY);
      useAuthStore.setState({ user: null, isLoggedIn: false });
      router.navigate(Paths.login);

      throw {
        type: 'Unauthorized',
        status: 401,
        message: 'Forbidden access'
      };
    }


    if (error.response?.status === 500) {
      router.navigate(Paths.error);

      throw {
        type: 'Server error',
        status: 500,
        message: 'Server error'
      };
    }

    if (error.response?.status === 422) {
      throw {
        type: 'Validation error',
        status: 422,
        errors: error.response.data?.errors
      };
    }

    throw 'Uncaught error';
  }
);

export default privateApi;
