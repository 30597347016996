import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Button, Paper, Table, Textarea, Title } from '@mantine/core';

import { TArbitratingCollectedData, TAssignQualifiedForInterviewToInterviewed } from 'src/api/arbitrating-requests/types';

import TablePercentInput from '../TablePercentInput';
import styles from '../TablePercentInput/TablePercentInput.module.scss';

interface TProps {
  id: string;
  interviewedComment: string;
  selectedItem: string;
  isStatusApproved: boolean;
  comment: string;
  setSum: React.Dispatch<React.SetStateAction<number>>;
  isChecingInfo: () => boolean;
  setComment: Dispatch<SetStateAction<string>>;
  AssignQualifiedForInterviewToInterviewed: (
    {participating_request_id, comment, degree}: TAssignQualifiedForInterviewToInterviewed
  ) => void;
}

const InterviewScoreTable = ({
  id,
  comment,
  isStatusApproved,
  selectedItem,
  interviewedComment,
  setSum,
  setComment,
  isChecingInfo,
  AssignQualifiedForInterviewToInterviewed
}: TProps) => {
  const [tableValues, setTableValues] = useState<TArbitratingCollectedData[]>([]);
  const [resetValue, setResetValue] = useState(false);

  const isTableValue = tableValues && tableValues[0] && tableValues[0][0];

  useEffect(() => {
    if (isTableValue) {
      setSum(+tableValues[0][0]);
    }
  }, [tableValues, resetValue, isTableValue, setTableValues]);

  return (
    <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
      <Title order={3}>Interview Score</Title>

      <Table>
        <tbody>
          <tr>
            <td className={styles.tableNumInput}>Percentage</td>
            <td>
              {isStatusApproved ? (
                <div className={`${styles.tableData} ${styles.tableNumInput}`}>
                  <div>{(+selectedItem)?.toFixed(2)}%</div>
                </div>
              ) : (
                <TablePercentInput
                  maxWidth
                  maxVal={100}
                  placeholder={`eg 53%`}
                  id={0}
                  tableValue={tableValues || ''}
                  setTableValue={setTableValues}
                  resetValue={resetValue}
                  setResetValue={setResetValue}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Comment</td>
            <td>
              <Textarea
                autosize
                maxRows={6}
                disabled={isStatusApproved}
                className={isStatusApproved ? styles.textArea : ''}
                placeholder='Enter comment'
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
                value={isStatusApproved ? interviewedComment : comment}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      {!isStatusApproved && (
        <Button
          w="max-content"
          disabled={!isChecingInfo() || !comment.length || !isTableValue}
          onClick={() => AssignQualifiedForInterviewToInterviewed({
            participating_request_id: id || '',
            comment: comment,
            degree: tableValues[0][0]?.toString()
          })}
        >
          Submit
        </Button>
      )}
    </Paper>
  );
};

export default InterviewScoreTable;
