import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';
import { TPhotosItemForm, TPhotosList } from 'src/modules/photos/types';

import { TPhotosItemUpdateRequest } from './types';

export const getPhotosList = async (): Promise<TPhotosList> => {
  return privateApi.get('/admin-content/photos').then((res) => res.data.data);
};

export const getPhotosById = async (id: string): Promise<TPhotosList> => {
  return privateApi.get('/admin-content/photos', {
    params: { id }
  }).then((res) => res.data.data);
};

export const createPhotosItem = async (data: TPhotosItemForm): Promise<SuccessResponse> => {
  return privateApi.post('/photo', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updatePhotosItem = async (data: TPhotosItemUpdateRequest): Promise<SuccessResponse> => {
  return privateApi.put('/photo', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deletePhotosItem = async (id: string): Promise<SuccessResponse> => {
  return privateApi.delete('/photo', {
    params: { id }
  }).then((res) => res.data);
};
