import privateApi from 'src/common/configs/private-api';
import { getFormData } from 'src/common/helpers/api';
import { SuccessResponse } from 'src/common/types/api';

import { TInnovation } from './types';

export const getInnovationList = async (): Promise<TInnovation[]> => {
  return privateApi.get('/innovation').then((res) => res.data);
};

export const putInnovationData = async (data: any): Promise<SuccessResponse> => {
  return privateApi.put('/innovation', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const deleteInnovation = async (request_id: string): Promise<SuccessResponse> => {
  return privateApi.delete(`/innovation/unpin?request_id=${request_id}`);
};

export const addToInnovation = async (request_id: string): Promise<SuccessResponse> => {
  return privateApi.put(`/innovation/pin?request_id=${request_id}`);
};

export const sortInnovation = async (data: string[]): Promise<SuccessResponse> => {
  return privateApi.post('/innovation/sort', data).then((res) => res.data);
};
