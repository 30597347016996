import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TEvaluationCriteria } from 'src/modules/evaluation-criteria/types';

import { getEvaluationCriteria, updateEvaluationCriteria } from './api';
import { EVALUATION_CRITERIA_QUERY_KEY } from './constants';

export const useEvaluationCriteriaQuery = () => {
  return useQuery<TEvaluationCriteria>({
    queryKey: [EVALUATION_CRITERIA_QUERY_KEY],
    queryFn: getEvaluationCriteria
  });
};

export const useUpdateEvaluationCriteriaMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TEvaluationCriteria>({
    mutationFn: updateEvaluationCriteria,
    onSuccess: async () => {
      await queryClient.invalidateQueries([EVALUATION_CRITERIA_QUERY_KEY]);
      showSuccessNotification({ message: 'Content updated successfully' });
    }
  });
};
