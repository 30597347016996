import { FC } from 'react';

import { ActionIcon, Center, clsx, Flex, Image, Input, Text } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons';
import { Accept } from 'react-dropzone';

import styles from './ImageUpload.module.scss';

type TImageUploadProps = {
  value: File | string | null;
  onChange: (file: File | string | null) => void;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  accept?: Accept | string[];
  formats?: string[];
  maxFileSize?: number;
  additionalStyles?: string;
};

const ImageUpload: FC<TImageUploadProps> = ({
  value,
  onChange,
  label,
  error,
  required,
  disabled,
  accept = [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.webp],
  formats = ['png', 'jpeg', 'webp'],
  maxFileSize = 5,
  additionalStyles
}) => {
  const handleUpload = (file: File) => {
    onChange(file);
  };

  return (
    <Input.Wrapper
      label={label}
      error={error}
      withAsterisk={required}
      classNames={{ label: styles.label }}
    >
      {value ? (
        <div className={clsx(styles.previewRoot, { [styles.disabledPreview]: disabled }, additionalStyles)}>
          {!disabled && (
            <ActionIcon
              color="red"
              radius="xl"
              size="sm"
              variant="filled"
              className={styles.deleteIcon}
              disabled={disabled}
              onClick={() => onChange(null)}
            >
              <IconX size={16} />
            </ActionIcon>
          )}
          <Image
            src={typeof value === 'string' ? value : URL.createObjectURL(value)}
            height={218}
            fit="contain"
          />
        </div>
      ) : (
        <Dropzone
          onDrop={(files) => handleUpload(files[0])}
          maxFiles={1}
          maxSize={maxFileSize * 1024 * 1024}
          disabled={disabled}
          accept={accept}
          className={clsx(styles.dropzoneRoot, {
            [styles.dropzoneDisabled]: disabled
          }, additionalStyles)}
        >
          <Center sx={{ height: 220, pointerEvents: 'none' }}>
            <Flex align="center" justify="center" gap={15}>
              <Dropzone.Accept>
                <IconUpload size={50} stroke={1.5} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={50} stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size={50} stroke={1.5} />
              </Dropzone.Idle>
              <div>
                <Text size="xl">
                  Drag image here or click to select file
                </Text>
                <Text size="sm" color="dimmed">
                  File should not exceed {maxFileSize}mb, allowed formats: {formats.join(', ')}
                </Text>
              </div>
            </Flex>
          </Center>
        </Dropzone>
      )}
    </Input.Wrapper>
  );
};

export default ImageUpload;
