import { FC, useCallback } from 'react';

import { Accordion, Badge, Button, Center, Flex, Loader, Stack, Text, Title } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useParams } from 'react-router-dom';

import { useParticipantRequestApprovesQuery } from 'src/api/participants-requests/hooks';
import InfoLabel from 'src/components/InfoLabel';
import Paper from 'src/components/Paper';
import { TParticipantsApprovalData } from 'src/modules/participants-requests/types';

import { getFullName } from '../ParticipantsRequestsItemView/helpers';
import ApprovalModalContent from './ApprovalModalContent';
import GeneratePDFModalContent from './GeneratePDFModalContent';
import ResetApprovalModalContent from './ResetApprovalModalContent';

const ParticipantsRequestsItemParticipantsApproval: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: approvalInfo, isLoading } = useParticipantRequestApprovesQuery(id || '');

  const openApprovalModal = useCallback((
    requestId: string,
    participantInfo: TParticipantsApprovalData
  ) => {
    openModal({
      title: 'Approve Participant',
      size: 700,
      padding: 30,
      closeOnClickOutside: false,
      centered: true,
      children: <ApprovalModalContent requestId={requestId} participantInfo={participantInfo} />
    });
  }, []);

  const openResetApprovalStatusModal = useCallback((
    requestId: string,
    participantInfo: TParticipantsApprovalData
  ) => {
    openModal({
      title: 'Reset Approval Status',
      size: 400,
      padding: 30,
      closeOnClickOutside: false,
      centered: true,
      children: <ResetApprovalModalContent requestId={requestId} participantInfo={participantInfo} />
    });
  }, []);

  const openGeneratePDFModal = useCallback((requestNumber: string, email: string) => {
    openModal({
      title: 'Generate PDF',
      size: 400,
      padding: 30,
      closeOnClickOutside: false,
      centered: true,
      children: <GeneratePDFModalContent requestNumber={requestNumber} email={email} />
    });
  }, []);

  return (
    <Center>
      <Paper w={700}>
        <Title order={3} mb={30}>
          Participants Approval
        </Title>
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {approvalInfo?.partcipants_data?.length ? (
          <Accordion>
            {approvalInfo.partcipants_data.map((item, index) => (
              <Flex key={`participant_${index}`} gap={10}>
                <Accordion.Item value={`participant_${index}`} sx={{ flexGrow: 1 }}>
                  <Accordion.Control>
                    {item.first_name && item.second_name && item.last_name ? (
                      `${item.first_name} ${item.second_name} ${item.last_name}`
                    ) : `Participant ${index + 1}`}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack>
                      <InfoLabel label="Full Name">
                        <Text size={14}>
                          {getFullName(item)}
                        </Text>
                      </InfoLabel>
                      <InfoLabel label="Nationality">
                        <Text size={14}>{item.nationality_id?.name || '-'}</Text>
                      </InfoLabel>
                      <InfoLabel label="Email">
                        <Text size={14}>{item.email || '-'}</Text>
                      </InfoLabel>
                      <InfoLabel label="Participation Percentage">
                        <Text size={14}>{item.participation_percentage || '-'}</Text>
                      </InfoLabel>
                      <InfoLabel label="Generate PDF">
                        <Button onClick={() => openGeneratePDFModal(approvalInfo.request_number, item.email || '')}>
                          Generate PDF file
                        </Button>
                      </InfoLabel>
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>

                <Flex align="center" justify="center" sx={{ width: 100, height: 52 }}>
                  {item.isAprroved === null ? (
                    <Button variant="filled" onClick={() => openApprovalModal(approvalInfo.id, item)}>
                      Approve
                    </Button>
                  ) : item.isAprroved === true ? (
                    <Badge color="green" variant="outline">
                      Approved
                    </Badge>
                  ) : item.isAprroved === false ? (
                    <Badge
                      color="red"
                      variant="outline"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => openResetApprovalStatusModal(approvalInfo.id, item)}
                    >
                      Rejected
                    </Badge>
                  ) : null}
                </Flex>
              </Flex>
            ))}
          </Accordion>
        ) : null}
      </Paper>
    </Center>
  );
};

export default ParticipantsRequestsItemParticipantsApproval;
