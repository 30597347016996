import { FC } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { useInitialAdminProfileQuery } from 'src/api/admin/hooks';
import queryClient from 'src/common/configs/query-client';
import MantineProvider from 'src/providers/MantineProvider';
import ModalsProvider from 'src/providers/ModalsProvider';


const Root: FC = () => {
  const { isFetching } = useInitialAdminProfileQuery();

  if (isFetching) {
    return <LoadingOverlay visible={isFetching} />;
  }

  return <Outlet />;
};

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <MantineProvider>
      <ModalsProvider>
        <NotificationsProvider position="top-right">
          <ScrollRestoration />
          <Root />
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  </QueryClientProvider>
);

export default App;
