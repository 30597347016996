import { FC } from 'react';

import { Box, Button, LoadingOverlay, Select, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useAddNewAdminMutation, useAdminRolesQuery } from 'src/api/admin/hooks';
import { emailRegExp, phoneNumberRegExp } from 'src/common/constants/regExp';
import { parseApiErrors } from 'src/common/helpers/api';
import { useNormalizedItemsData } from 'src/common/hooks/useNormalizedItemsData';
import Form from 'src/components/Form';
import MaskedInput from 'src/components/MaskedInput';
import TextInput from 'src/components/TextInput';
import { normalizeAdminAddNewForm } from 'src/modules/admin/normalizers';
import { TAdminAddNewForm } from 'src/modules/admin/types';

const AdminAddNewModal: FC<ContextModalProps> = ({
  context,
  id
}) => {
  const { data: adminRoles, isLoading: isAdminRolesLoading } = useAdminRolesQuery();
  const normalizedAdminRoles = useNormalizedItemsData(adminRoles);

  const form = useForm<TAdminAddNewForm>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      user_role: null
    }
  });

  const { mutate: addAdmin, isLoading: isAddAdminLoading } = useAddNewAdminMutation({
    onError: (error) => parseApiErrors( error, { form } )
  });

  const handleSubmit: SubmitHandler<TAdminAddNewForm> = (data) => {
    addAdmin(normalizeAdminAddNewForm(data));
    context.closeModal(id);
  };

  const isLoading = isAddAdminLoading || isAdminRolesLoading;

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Box
        component={Form}
        w="100%"
        onSubmit={form.handleSubmit(handleSubmit)}
        pos="relative"
      >
        <Stack spacing={30}>
          <Controller
            control={form.control}
            name="first_name"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="First Name"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="last_name"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Last Name"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="email"
            rules={{
              required: 'Required',
              pattern: {
                value: emailRegExp,
                message: 'Invalid email format'
              }
            }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                error={error?.message}
                label="Email"
                type="email"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="mobile_number"
            rules={{
              required: 'Required',
              pattern: {
                value: phoneNumberRegExp,
                message: 'The format of the mobile phone number must be (9665xxxxxxx)'
              }
            }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <MaskedInput
                value={value}
                onChange={onChange}
                error={error?.message}
                type="tel"
                label="Mobile number"
                required
                mask="\966 999-999-999"
                alwaysShowMask
              />
            )}
          />

          <Controller
            control={form.control}
            name="user_role"
            rules={{ required: 'Required' }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                error={error?.message}
                data={normalizedAdminRoles}
                label="Admin Role"
                required
                disabled={isLoading}
              />
            )}
          />

          <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
            Add
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default AdminAddNewModal;
