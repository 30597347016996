import { Box, Center, Text } from '@mantine/core';

import HomePageStatistics from './HomePageStatistics';

const Home = () => (
  <Box h="100%">
    <Center pb={30}>
      <Text size={30} weight={700}>
        Welcome to IAMI Control Panel
      </Text>
    </Center>
    <HomePageStatistics />
  </Box>
);

export default Home;
