import React, { useState } from 'react';

import { Paper, Flex, Stack, Center, Table, Textarea, Title, Button } from '@mantine/core';
import { useParams } from 'react-router-dom';

import {
  useAllWinnersQuery,
  useAssignQualifiedForInterviewToInterviewedQuery,
  useGetAllPercentagesQuery,
  useGetArbitratingCommentHistoryQuery,
  useGetLatestInterviewDegreeQuery,
  useInterviewedQuery,
  useQualifiedForInterviewQuery
} from 'src/api/arbitrating-requests/hooks';
import { TArbitratingJudgmentData, TArbitratingJudgmentTotalData } from 'src/api/arbitrating-requests/types';
import { useArbitrationCriteriaListQuery } from 'src/api/arbitration-criteria/hooks';
import { STEPS } from 'src/common/constants/defConstant';
import BackPageButton from 'src/components/BackPageButton';
import GeneratePDF from 'src/components/GeneratePDF';
import InterviewScoreTable from 'src/components/InterviewScoreTable';
import TableCommentHistory from 'src/components/TableCommentHistory';
import TablePercentInput from 'src/components/TablePercentInput';
import { RequestStatus } from 'src/modules/competitions/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import ArbitratingRequestControls from './ArbitratingInterviewControls';
import styles from '../ArbitratingApprovalEvaluation/ArbitratingApprovalEvaluation.module.scss';
import ParticipantsRequestsItemView from '../ParticipantsRequestsItemView';
import CustomStepper from '../ParticipantsRequestsItemView/CustomStepper';
import { useAddToInnovation } from 'src/api/innovations/hooks';
import { AdminRoles } from 'src/modules/admin/constants';

const ArbitrationInterviewItemViewAndEvaluation = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();

  const [active, setActive] = useState(0);
  const [sum, setSum] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  const isAdmin = user?.userRole.id === AdminRoles.SuperAdmin
    || user?.userRole.id === AdminRoles.GeneralSecretariat;

  const { data: QualifiedForInterview, isLoading: QualifiedForInterviewLoading } = useQualifiedForInterviewQuery();
  const { data: Interviewed, isLoading: InterviewedLoading } = useInterviewedQuery();
  const { data: ArbitrationWinners, isLoading: ArbitrationWinnersLoading } = useAllWinnersQuery();
  const isDataLoaded = !QualifiedForInterviewLoading && !InterviewedLoading && !ArbitrationWinnersLoading;
  const Data = isDataLoaded && QualifiedForInterview && Interviewed && ArbitrationWinners &&
  [...QualifiedForInterview.data, ...Interviewed.data, ...ArbitrationWinners.data];

  const selectedItem = (Data || []).find((el) => el.id === id);
  const isStatusApproved = selectedItem?.status?.id === RequestStatus.Interviewed ||
    selectedItem?.status?.id === RequestStatus.NotWinner;
  
  const { data: arbitrationCriteria, isLoading: isArbitrationCriteriaLoading } = useArbitrationCriteriaListQuery();
  const { data: arbitrationPercentage, isLoading: isArbitrationPercentageLoading } = useGetAllPercentagesQuery(id || '');
  const { data: commmentHistory, isLoading: iscommmentHistoryLoading } = useGetArbitratingCommentHistoryQuery(id || '');
  const { data: InterviewDegree, isLoading: isInterviewDegreeLoading } = useGetLatestInterviewDegreeQuery(id || '');
  const { mutate: addInnovation } = useAddToInnovation();

  const InterviewDegreeData = !isInterviewDegreeLoading && InterviewDegree?.data;

  const { mutate: AssignQualifiedForInterviewToInterviewed } = useAssignQualifiedForInterviewToInterviewedQuery(
    {
      participating_request_id: id || '',
      comment: comment,
      degree: sum?.toString()
    },
    id || ''
  );

  const maxPercentagesCoefficient = !isArbitrationPercentageLoading &&
    arbitrationPercentage?.total_data?.reduce((accumulator: number, item) => {
      return accumulator + item.avg_criteria;
    }, 0);

  const showAvgCriteria = !isArbitrationPercentageLoading && arbitrationPercentage;

  const [checkingInfo, setCheckingInfo] = useState({
    'Personal Information': false,
    'Participant Data': false,
    'Innovation Data': false,
    'Impact of Innovation': false
  });

  const isChecingInfo = () => {
    return Object.values(checkingInfo).every((el) => el === true);
  };

  return (
    <Stack>
      <Flex justify="space-between">
        <BackPageButton label="عرض الطلب" />
        <CustomStepper
          active={active}
          setActive={setActive}
          labels={STEPS}
          checkingInfo={!isStatusApproved ? checkingInfo : undefined}
          setCheckingInfo={setCheckingInfo}
        />
      </Flex>
      <Flex gap={16} justify='flex-end'>
        {isAdmin && (<Button onClick={() => addInnovation(id || '')}>To Innovation Page</Button>)}
        <GeneratePDF />
      </Flex>

      <Center>
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <ParticipantsRequestsItemView tabIndex={active} />
          <ArbitratingRequestControls steps={STEPS} active={active} setActive={setActive} />
        </Paper>
      </Center>

      {!iscommmentHistoryLoading && commmentHistory?.length ? (
        <TableCommentHistory data={commmentHistory} />
      ) : null}

      {!isArbitrationCriteriaLoading && !isArbitrationPercentageLoading && arbitrationPercentage && (
        <Paper w="100%" p="xl" radius="lg" display="grid" sx={{ gap: 32 }}>
          <Title order={3}>Percentage of Arbitration Criteria</Title>
          <Stack>
            <Table sx={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>Committee Name</th>
                  {arbitrationCriteria?.map((item) => (
                    <th key={item.id} className={styles.tableComments}>{item.title}</th>
                  ))}
                  <th className={styles.tableComments}>Total</th>
                </tr>
              </thead>
              <tbody>
                {!isArbitrationPercentageLoading && arbitrationPercentage?.judgment_data?.map((item: TArbitratingJudgmentData) => (
                  <React.Fragment key={item.adminId}>
                    <tr key={item.criteriaId}>
                      <td>{item.fullName}</td>
                      {arbitrationCriteria?.map((percentage, id) => (
                        <td key={percentage.id} className={styles.tableData}>
                          <div>{item?.judgment_Evaluation[id]?.judgmentPercentage || '-'}</div>
                        </td>
                      ))}
                      <td className={styles.tableData}>
                        <div>{item?.total_Percentage}%</div>
                      </td>
                    </tr>
                    <tr key={item.criteriaId}>
                      <td>Comments</td>
                      {arbitrationCriteria?.map((percentage, id) => (
                        <td key={percentage.id} className={styles.tableComments}>
                          <Textarea
                            autosize
                            maxRows={6}
                            disabled
                            className={styles.textArea}
                            value={item?.judgment_Evaluation[id]?.comment || '-'}
                          />
                        </td>
                      ))}
                      <td></td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <td>{user?.fullName}</td>
                  {showAvgCriteria && arbitrationPercentage?.total_data?.map((total: TArbitratingJudgmentTotalData, id: number) => (
                    <td key={total.criteriaId} className={styles.tableData}><div>{total.avg_criteria}</div></td>
                  ))}
                  <td>
                    <TablePercentInput
                      maxVal={100}
                      placeholder='eg 20%'
                      total
                      totalVal={maxPercentagesCoefficient ? +maxPercentagesCoefficient.toFixed(2) : 0}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Stack>
        </Paper>
      )}

      <InterviewScoreTable
        selectedItem={(InterviewDegreeData && InterviewDegreeData.degree)?.toString() || ''}
        isStatusApproved={isStatusApproved}
        interviewedComment={InterviewDegreeData && InterviewDegreeData.comment || ''}
        setSum={setSum}
        comment={comment}
        setComment={setComment}
        id={id || ''}
        isChecingInfo={isChecingInfo}
        AssignQualifiedForInterviewToInterviewed={AssignQualifiedForInterviewToInterviewed}
      />
    </Stack>
  );
};

export default ArbitrationInterviewItemViewAndEvaluation;
