import { FC } from 'react';

import { useForm } from 'react-hook-form';

import { useCreateNewsItemMutation } from 'src/api/news/hooks';
import ManageNews from 'src/components/ManageNews';
import { newsFormDefaultValues } from 'src/modules/news/constants';
import { TNewsItemForm } from 'src/modules/news/types';

const NewsAddItem: FC = () => {
  const { mutate, isLoading } = useCreateNewsItemMutation();

  const form = useForm<TNewsItemForm>({
    defaultValues: newsFormDefaultValues
  });

  const handleItemCreate = (data: TNewsItemForm) => {
    mutate(data);
  };

  return (
    <ManageNews
      title="Add news"
      submitText="Create"
      form={form}
      onSubmit={handleItemCreate}
      isLoading={isLoading}
    />
  );
};

export default NewsAddItem;
