import privateApi from 'src/common/configs/private-api';
import { SuccessResponse } from 'src/common/types/api';
import { TCompetitionConditions } from 'src/modules/competition-conditions/types';

export const getCompetitionConditions = async (): Promise<TCompetitionConditions> => {
  return privateApi.get('/admin-content/competitionconditions').then((res) => res.data.data);
};

export const updateCompetitionConditions = async (data: TCompetitionConditions): Promise<SuccessResponse> => {
  return privateApi.put('/competition-condition', data).then((res) => res.data);
};
