import { Flex, Text } from '@mantine/core';
import { IconFile } from '@tabler/icons';

import styles from '../AttachmentButton/styles.module.scss';

type Props = {
  filename?: string;
  href?: string | null;
};

const AttachmentButton = ({ filename, href }: Props) => {
  const name = filename || (href && href.length <= 18 ? href : href?.slice(-18));
  const Icon = IconFile;

  if (!href) return <>-</>;

  return (
    <Flex justify="space-between" gap="sm" p={4} className={styles.root}>
      <Flex align="center" gap="sm">
        <Flex>
          <Icon width={24} height={24} />
        </Flex>
        <Text color="initial">{name}</Text>
      </Flex>
    </Flex>
  );
};

export default AttachmentButton;
