import { closeAllModals } from '@mantine/modals';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/common/configs/query-client';
import { showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { TPhotosItem, TPhotosItemForm, TPhotosList } from 'src/modules/photos/types';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';

import {
  getPhotosList,
  getPhotosById,
  createPhotosItem,
  updatePhotosItem,
  deletePhotosItem
} from './api';
import { PHOTOS_LIST_QUERY_KEY, PHOTOS_ITEM_QUERY_KEY } from './constants';
import { TPhotosItemUpdateRequest } from './types';

export const usePhotosListQuery = () => {
  return useQuery<TPhotosList>({
    queryKey: [PHOTOS_LIST_QUERY_KEY],
    queryFn: getPhotosList
  });
};

export const usePhotosByIdQuery = (id: string) => {
  return useQuery<TPhotosItem | null>({
    queryKey: [PHOTOS_ITEM_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getPhotosById(id);
      const item = response[0];

      return item || null;
    }
  });
};

export const useCreatePhotosItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TPhotosItemForm>({
    mutationFn: createPhotosItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PHOTOS_LIST_QUERY_KEY]);
      router.navigate(Paths.photos);
      showSuccessNotification({ message: 'Item created successfully' });
    }
  });
};

export const useUpdatePhotosItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TPhotosItemUpdateRequest>({
    mutationFn: updatePhotosItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PHOTOS_LIST_QUERY_KEY]);
      await queryClient.invalidateQueries([PHOTOS_ITEM_QUERY_KEY]);
      router.navigate(Paths.photos);
      showSuccessNotification({ message: 'Item updated successfully' });
    }
  });
};

export const useDeletePhotosItemMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deletePhotosItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PHOTOS_LIST_QUERY_KEY]);
      closeAllModals();
      showSuccessNotification({ message: 'Item deleted successfully' });
    }
  });
};
