import { FC, useCallback } from 'react';

import { Button, Flex, Title, Text, Center, Loader } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { useDeletePhotosItemMutation, usePhotosListQuery } from 'src/api/photos/hooks';
import DataTable from 'src/components/DataTable';
import { usePhotosTableColumns, usePhotosTableFilters } from 'src/modules/photos/hooks';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';
import { AdminRoles } from 'src/modules/admin/constants';

const Photos: FC = () => {
  const { user } = useAuthStore();
  const { data, isLoading: isDataLoading } = usePhotosListQuery();
  const { mutate: deleteItem, isLoading: isDeleteItemLoading } = useDeletePhotosItemMutation();
  const isJudgment = user?.userRole.id === AdminRoles.Judgment;

  const openDeleteModal = useCallback((id: string) => openConfirmModal({
    title: 'Delete record',
    centered: true,
    children: <Text size="sm">Are you sure you want to delete this record?</Text>,
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onConfirm: () => deleteItem(id)
  }), [deleteItem]);

  const columns = usePhotosTableColumns({
    onDeleteClick: openDeleteModal
  });

  const filters = usePhotosTableFilters();

  return (
    <Flex direction="column">
      <Flex align="center" justify="space-between" pb={30}>
        <Title order={2}>
          Photos
        </Title>
        {!isJudgment && (
          <Button
            component={Link}
            to={Paths.photosAddItem}
            leftIcon={<IconPlus size={18} />}
          >
            Add
          </Button>
        )}
      </Flex>

      {isDataLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data ? (
        <DataTable
          data={data}
          columns={columns}
          filters={filters}
          isLoading={isDeleteItemLoading}
        />
      ) : null}
    </Flex>
  );
};

export default Photos;
