import { FC, ReactNode } from 'react';

import { ModalsProvider as Provider } from '@mantine/modals';

import { Modals } from './contants';

import AdminAddNewModal from './AdminAddNewModal';
import AdminChangeStatusModal from './AdminChangeStatusModal';
import LoginVerifyModal from './LoginVerifyModal';
import AdminDeleteUserModal from './AdminDeleteUserModal/AdminDeleteUserModal';
import FiltrationSendEmails from './FiltrationSendEmailsModal/FiltrationSendEmailsModal';
import ArbitratingAssignModal from './ArbitratingAssignModal/ArbitratingAssignModal';
import NewEmailTemplate from './NewEmailTemplate/NewEmailTemplate';
import DeleteEmailTemplate from './DeleteEmailTemplateModal/DeleteEmailTemplateModal';
import SendEmailToUsers from './SendEmailToUsers/SendEmailToUsers';
import InnovationDelete from './InnovationDelete/InnovationDelete';
import CreateGroupModal from './CreateGroupModal';

type Props = {
  children: ReactNode;
};

const ModalsProvider: FC<Props> = ({ children }) => (
  <Provider
    modalProps={{
      exitTransitionDuration: 300,
      overlayBlur: 0.4,
      overlayColor: 'rgba(9, 30, 66, 0.54)',
      styles: {
        header: { position: 'relative', height: 28 },
        title: { width: '100%', textAlign: 'center', marginRight: 0, fontWeight: 500 },
        close: { position: 'absolute', right: 0, top: 0 }
      }
    }}
    modals={{
      [Modals.LoginVerify]: LoginVerifyModal,
      [Modals.AdminChangeStatus]: AdminChangeStatusModal,
      [Modals.AdminDeleteUser]: AdminDeleteUserModal,
      [Modals.AdminAddNew]: AdminAddNewModal,
      [Modals.FiltrationSendEmails]: FiltrationSendEmails,
      [Modals.ArbitratingAssignDelete]: ArbitratingAssignModal,
      [Modals.NewEmailTemplate]: NewEmailTemplate,
      [Modals.DeleteEmailTemplate]: DeleteEmailTemplate,
      [Modals.SendEmailToUsers]: SendEmailToUsers,
      [Modals.InnovationDelete]: InnovationDelete,
      [Modals.CreateGroupModal]: CreateGroupModal
    }}
  >
    {children}
  </Provider>
);

export default ModalsProvider;
