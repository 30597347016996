import { useMutation, useQuery } from '@tanstack/react-query';

import {
  addNewAdmin,
  adminLogin,
  adminLoginVerify,
  adminLogout,
  adminVerify,
  getAdminList,
  getAdminProfile,
  getAdminRoles,
  updateAdminStatus,
  resendVerification,
  deleteAdminUser
} from 'src/api/admin/api';
import {
  AdminLoginRequest,
  AdminLoginResponse,
  AdminLoginVerifyRequest,
  AdminLoginVerifyResponse,
  GetAdminsListResponse,
  TAdminAddNewRequest,
  TAdminResendRequest,
  TAdminVerifyRequest,
  UpdateAdminStatusRequest,
  UpdateAdminStatusResponse
} from 'src/api/admin/types';
import queryClient from 'src/common/configs/query-client';
import { API_ACCESS_TOKEN_KEY } from 'src/common/constants/api';
import { showErrorNotification, showSuccessNotification } from 'src/common/helpers/notifications';
import { ErrorResponse, SuccessResponse } from 'src/common/types/api';
import { AdminRole, AdminUser } from 'src/modules/admin/types';
import {
  closeAdminAddNewModal,
  closeAdminChangeStatusModal,
  closeAdminDeleteUserModal,
  closeLoginVerifyModal,
  openLoginVerifyModal
} from 'src/providers/ModalsProvider/helpers';
import { Paths } from 'src/router/constants';
import router from 'src/router/router';
import { useAuthStore } from 'src/store/useAuthStore';

import { ADMIN_LIST, ADMIN_ROLES, ADMIN_USER } from './constants';

export const useInitialAdminProfileQuery = () => {
  const { setUser } = useAuthStore();

  return useQuery<AdminUser>({
    queryKey: [ADMIN_USER],
    queryFn: async () => {
      return getAdminProfile().then((res) => res.data);
    },
    onSuccess: async (admin) => {
      setUser(admin);
    },
    enabled: !!localStorage.getItem(API_ACCESS_TOKEN_KEY)
  });
};

export const useAdminLoginMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<AdminLoginResponse, ErrorResponse, AdminLoginRequest>({
    mutationFn: async (data) => {
      return adminLogin(data).then((res) => res.data);
    },
    onSuccess: (_, variables) => {
      openLoginVerifyModal({ email: variables.email });
    },
    onError: args?.onError
  });
};

export const useAdminLoginVerifyMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  const { setUser } = useAuthStore();

  return useMutation<AdminLoginVerifyResponse, ErrorResponse, AdminLoginVerifyRequest>({
    mutationFn: async (data) => {
      return adminLoginVerify(data).then((res) => res.data);
    },
    onSuccess: async ({ data }) => {
      localStorage.setItem(API_ACCESS_TOKEN_KEY, `${data.token_type} ${data.access_token}`);
      const admin = await getAdminProfile().then((res) => res.data);
      setUser(admin);
      closeLoginVerifyModal();
      router.navigate(Paths.homepage);
    },
    onError: args?.onError
  });
};

export const useAdminLogoutMutation = () => {
  const { removeUser } = useAuthStore();

  return useMutation<SuccessResponse>({
    mutationFn: () => {
      return adminLogout().then((res) => res.data);
    },
    onSuccess: async () => {
      localStorage.removeItem(API_ACCESS_TOKEN_KEY);
      removeUser();
      router.navigate(Paths.login);
      await queryClient.clear();
    }
  });
};

export const useAdminListQuery = () => {
  return useQuery<GetAdminsListResponse>({
    queryKey: [ADMIN_LIST],
    queryFn: () => getAdminList().then((res) => res.data)
  });
};

export const useUpdateAdminStatusMutation = () => {
  return useMutation<UpdateAdminStatusResponse, ErrorResponse, UpdateAdminStatusRequest>({
    mutationFn: updateAdminStatus,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ADMIN_LIST]);
      closeAdminChangeStatusModal();
      showSuccessNotification({ message: response.data });
    }
  });
};

export const useAddNewAdminMutation = ({
  onError
}: {
  onError: (error: ErrorResponse) => void;
}) => {
  return useMutation<SuccessResponse, ErrorResponse, TAdminAddNewRequest>({
    mutationFn: addNewAdmin,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ADMIN_LIST]);
      closeAdminAddNewModal();
      showSuccessNotification({ message: 'Admin added successfully' });
    },
    onError
  });
};

export const useAdminVerifyMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, TAdminVerifyRequest>({
    mutationFn: adminVerify,
    onError: () => {
      // router.navigate(Paths.error);
      showErrorNotification({ message: 'Try to resend verification email', autoClose: 6 });
    }
  });
};

export const useAdminRolesQuery = () => {
  return useQuery<AdminRole[]>({
    queryKey: [ADMIN_ROLES],
    queryFn: () => getAdminRoles().then((res) => res.data)
  });
};

export const useAdminResendVerification = () => {
  return useMutation<SuccessResponse, ErrorResponse, TAdminResendRequest>({
    mutationFn: resendVerification,
    onSuccess: () => {
      showSuccessNotification({ message: 'The email is send successfully', autoClose: 6 });
    }
  });
}

export const useDeleteAdminUser = () => {
  return useMutation<SuccessResponse, ErrorResponse, string>({
    mutationFn: deleteAdminUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries([ADMIN_LIST]);
      closeAdminDeleteUserModal();
      showSuccessNotification({ message: 'User deleted successfully' });
    }
  });
};
