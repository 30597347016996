import { TAdminAddNewRequest } from 'src/api/admin/types';
import { TAdminAddNewForm } from 'src/modules/admin/types';

export const normalizeAdminAddNewForm = (data: TAdminAddNewForm): TAdminAddNewRequest => ({
  first_name: data.first_name,
  last_name: data.last_name,
  email: data.email,
  mobile_number: data.mobile_number.replace(/[^\d]/g, ''),
  user_role: data.user_role ? +data.user_role : 0
});
