import { Button } from '@mantine/core';

import styles from './FinalEmailButton.module.scss';

import { openFiltrationSendEmailsModal } from 'src/providers/ModalsProvider/helpers';

type Props = {
  name: string;
  isApprove?: boolean;
  disabled: boolean;
};

const FinalEmailButton = ({
  name,
  isApprove = false,
  disabled
}: Props) => (
  <Button
    disabled={disabled}
    onClick={() => openFiltrationSendEmailsModal({isApproved: isApprove})}
    className={`${styles.button} ${isApprove ? styles.approve : ''}`}
  >
    {name}
  </Button>
);

export default FinalEmailButton;
