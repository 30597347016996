import { FC, useRef } from 'react';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { Table } from '@tanstack/react-table';

import { useFiltrationApprovalQuery } from 'src/api/filtration-request/hooks';
import DataTable from 'src/components/DataTable';
import ExportButton from 'src/components/ExportButton/ExportButton';
import { useParticipantsRequestsTableFilters } from 'src/modules/participants-requests/hooks';
import { useFiltrationApprovalTableColumns } from 'src/modules/filtration-approval/hooks';
import FinalEmailButton from 'src/components/FinalEmailButton';

import { useFiltrationRequestFilter } from './hooks';
import { getFiltrationApprovalForExport } from './utils';
import { RequestStatus } from 'src/modules/competitions/constants';

const FiltrationApproval: FC = () => {
  const tableInstanceRef = useRef<Table<any>>();

  const { data: filtrationApproval, isLoading } = useFiltrationApprovalQuery();

  const columns = useFiltrationApprovalTableColumns();
  const filters = useParticipantsRequestsTableFilters();

  const filtrationRequestExport = getFiltrationApprovalForExport(filtrationApproval?.data);
  const requestsFilteredByUserRole = useFiltrationRequestFilter(filtrationApproval?.data);


  const isDisabled = (fStatus: number, sStatus: number) => {
    return filtrationApproval.data
      .find((el: any) => {
        return (el.status.id === fStatus || el.status.id === sStatus)
      });
  }


  return (
    <Flex direction="column" h="100%">
      {isLoading ? (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      ) : (
        <>
          <Flex align="center" justify="space-between" pb={30}>
            <Title order={2}>Filtration Approval</Title>
            <Flex gap={16}>
              <FinalEmailButton name='Rejected Email' disabled={isDisabled(RequestStatus.Approved, RequestStatus.FinalApproved)} />
              <FinalEmailButton name='Approval Email' isApprove disabled={isDisabled(RequestStatus.Rejected, RequestStatus.FinalRejected)} />
              <ExportButton filename="Filtration Request" data={filtrationRequestExport} />
            </Flex>
          </Flex>
          
          {requestsFilteredByUserRole ? (
            <DataTable
              columns={columns}
              data={filtrationApproval?.data || []}
              filters={filters}
              tableInstanceRef={tableInstanceRef}
            />
          ) : null}
        </>
      )}
    </Flex>
  );
};

export default FiltrationApproval;
